import { Product } from './api';
import useQueryPharmacyAllocation from './useQueryPharmacyAllocation';

export function usePharmacyAllocation(products: Product[]) {
  const { isLoading, isError, isSuccess, data: pharmacyAllocationData } = useQueryPharmacyAllocation(products);

  return {
    query: { isLoading, isError, isSuccess },
    pharmacyAllocationData,
  };
}

export default usePharmacyAllocation;
