import React from 'react';

interface Props {
  width?: string;
  height?: string;
  fillColor?: string;
}

export default function AlternaleafLogo({ width = '170', height = '100%', fillColor = '#300431' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 170 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.6569 0.195374H26.0989V23.8019H29.6569V0.195374Z" fill={fillColor} />
      <path
        d="M39.5534 2.13046H36.0286V6.88389H33.0128V9.9672H36.0286V23.8019H39.5534V9.9672H44.1988V6.88389H39.5534V2.13046Z"
        fill={fillColor}
      />
      <path
        d="M54.2976 6.68854C49.4862 6.68854 45.9254 10.5239 45.9254 15.3443C45.9254 20.1646 49.4171 24 54.4664 24C59.856 24 61.8536 19.9987 62.1248 18.3928H58.4975C58.1572 19.3751 56.9039 20.9167 54.4636 20.9167C51.4811 20.9167 49.8542 18.9174 49.5499 16.3908H62.3599C62.3931 16.0295 62.4263 15.6039 62.4263 15.1462C62.4263 10.5239 58.9707 6.68854 54.2921 6.68854H54.2976ZM49.655 13.8347C50.0949 11.541 51.6886 9.76917 54.2976 9.76917C56.9067 9.76917 58.4671 11.5731 58.8379 13.8347H49.6523H49.655Z"
        fill={fillColor}
      />
      <path
        d="M66.1917 12.1967V23.8367H69.6474V12.1967C69.6474 11.0164 70.6296 9.96719 71.8829 9.96719H74.8987V6.88388H71.6782C68.6624 6.88388 66.1889 9.24454 66.1889 12.194L66.1917 12.1967Z"
        fill={fillColor}
      />
      <path
        d="M84.5601 6.68848H84.5269C80.2578 6.68848 77.1396 9.83602 77.1396 14.4262V23.8019H80.5953V14.4262C80.5953 11.739 82.0202 10.0314 84.5269 10.0314H84.5601C87.0667 10.0314 88.4584 11.7363 88.4584 14.4262V23.8019H91.9141V14.4262C91.9141 9.83602 88.796 6.68848 84.5601 6.68848Z"
        fill={fillColor}
      />
      <path
        d="M103.236 6.68854C98.9307 6.68854 96.2553 9.21246 95.9841 12.459H99.6446C99.8825 10.2964 101.645 9.6059 103.338 9.6059C105.203 9.57378 106.965 10.4249 106.965 12.9167V13.7357H103.407C98.0177 13.7357 95.6466 15.802 95.6466 19.0806C95.6466 21.6367 97.5778 24 101.407 24C103.847 24 106.152 22.6885 106.965 21.5724V23.802H110.424V13.1469C110.424 9.21246 108.017 6.68854 103.239 6.68854H103.236ZM106.827 16.6236C106.827 20.0013 104.42 21.3771 102.151 21.3771C100.187 21.3771 99.3042 20.36 99.3042 18.9843C99.3042 17.2473 100.931 16.3935 103.371 16.3613L106.827 16.3292V16.6236Z"
        fill={fillColor}
      />
      <path d="M118.824 0.195374H115.266V23.8019H118.824V0.195374Z" fill={fillColor} />
      <path
        d="M130.992 6.68848C126.178 6.68848 122.619 10.5239 122.619 15.3442C122.619 20.1646 126.111 23.9999 131.16 23.9999C136.55 23.9999 138.548 19.9986 138.819 18.3927H135.192C134.851 19.375 133.598 20.9166 131.158 20.9166C128.175 20.9166 126.548 18.9173 126.244 16.3907H139.054C139.087 16.0294 139.123 15.6038 139.123 15.1462C139.123 10.5239 135.665 6.68848 130.989 6.68848H130.992ZM126.349 13.8347C126.789 11.5409 128.383 9.7691 130.992 9.7691C133.601 9.7691 135.161 11.5731 135.532 13.8347H126.346H126.349Z"
        fill={fillColor}
      />
      <path
        d="M149.429 6.68848C145.124 6.68848 142.449 9.2124 142.178 12.459H145.838C146.076 10.2964 147.839 9.60584 149.532 9.60584C151.397 9.57372 153.159 10.4248 153.159 12.9166V13.7356H149.601C144.211 13.7356 141.84 15.8019 141.84 19.0806C141.84 21.6366 143.771 23.9999 147.601 23.9999C150.041 23.9999 152.346 22.6885 153.159 21.5724V23.8019H156.615V13.1468C156.615 9.2124 154.208 6.68848 149.429 6.68848ZM153.021 16.6236C153.021 20.0013 150.614 21.377 148.345 21.377C146.38 21.377 145.498 20.3599 145.498 18.9842C145.498 17.2472 147.125 16.3934 149.565 16.3613L153.021 16.3292V16.6236Z"
        fill={fillColor}
      />
      <path
        d="M165.493 6.8839V5.31014C165.493 3.70425 166.611 3.17966 167.967 3.17966C168.678 3.17966 169.425 3.34292 169.865 3.47407V0.361325C169.22 0.165942 168.407 0 167.289 0C164.104 0 161.968 2.13048 161.968 5.40916V6.8839H159.155V9.86818H161.968V23.8019H165.493V9.86818H170V6.8839H165.493Z"
        fill={fillColor}
      />
      <path
        d="M9.59231 0.192719L0 23.7993H3.79874C6.80067 23.7564 9.45121 22.3031 11.067 20.0923H5.31492L5.658 19.2519L7.73029 14.1265H7.72753L9.38758 10.0609C10.1678 8.1606 10.8097 6.51992 11.5567 4.61962H11.5899C12.301 6.52259 12.9456 8.1606 13.7258 10.0609L14.91 12.9622L15.3831 14.1265H15.3776L16.3847 16.6183C14.199 17.1134 12.3148 18.3714 11.0587 20.0923H17.7957L19.3119 23.7993H23.1411L13.5239 0.192719H9.59231Z"
        fill={fillColor}
      />
    </svg>
  );
}
