import { apiPatientPortalContract } from '@montugroup/pms-api-contracts';

import tsRestClient from './ts-rest.client';

let cachedClient: ReturnType<typeof tsRestClient<typeof apiPatientPortalContract>> | null = null;

export const getTsPatientPortalClient = () => {
  if (!cachedClient) {
    cachedClient = tsRestClient(apiPatientPortalContract);
  }
  return cachedClient;
};

export default null;
