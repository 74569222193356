import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import axios from 'axios';
import moment from 'moment';

import settings from '../../constants/constants';
import { creditConstants } from '../../constants/creditConstant';
import { AuthService } from '../../services/authentication.service';
import Button from '../common/button';
import CustomModal from '../common/CustomModal';
import FormInput from '../common/FormInput';

import PatientCreditTable from './patientCreditTable';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { FF_REFUND_NON_REFUND_CREDIT } from '@/constants/featureFlags';

import { Logger } from '@/utils/logger';

const logger = new Logger('PatientCreditModal');

function PatientCreditModal(props) {
  const { close, creditId, creditTableData, fetchData, isEdit, order, patient, show, dataBind, credit } = props;
  const [data, setData] = React.useState({
    discountTypes: [],
    creditType: [{ label: 'Select Credit Type', value: '0' }],
    orderCodes: [{ label: 'Select Order Code', value: '0' }],
    orderId: '',
    creditTypeId: '',
    discountType: '',
    showMedication: false,
    discountProducts: null,
    discountProductList: [],
    discountAmount: null,
    percentage: '1',
    endDate: '',
    indefinite: false,
    showIndefinite: true,
    discountId: null,
    edit: false,
    showOtherTypeInput: false,
    creditAmount: '',
    creditLimit: '',
    otherTypeInputText: '',
    patient: {
      firstName: '',
      lastName: '',
      id: '',
      code: '',
    },
  });

  const [orderList, setOrderList] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const refundCreditType = [
    settings.creditType.OrderIssue,
    settings.creditType.ProductDefect,
    settings.creditType.ReScriptCredit,
    settings.creditType.Other,
  ];

  const { flags, loading: ffLoading } = useFeatureFlags();

  React.useEffect(() => {
    if (isEdit) {
      axios.get(`${settings.url}/patientcredit/${creditId}`).then((response) => {
        const resData = {
          ...data,
          indefinite: response.data.result.is_indefinite,
          endDate: response.data.result.end_date ? new Date(response.data.result.end_date) : '',
          creditAmount: response.data.result.amount,
          creditType: [{ label: response.data.result.CreditType.name, value: response.data.result.CreditType.id }],
          orderCodes: [
            {
              label: response.data.result?.OrderManagement?.order_code ?? 'Select Order Code',
              value: response.data.result?.OrderManagement?.id ?? '0',
            },
          ],
          creditTypeId: response.data.result.CreditType.id,
          orderId: response.data.result?.OrderManagement?.id,
          patient: {
            firstName: response.data.result.Patient.PatientUser.first_name,
            lastName: response.data.result.Patient.PatientUser.last_name,
            id: response.data.result.Patient.id,
            code: response.data.result.Patient.patient_code,
          },
          showOtherTypeInput: false,
          otherTypeInputText: '',
        };
        if (response.data.result.CreditType.id === settings.creditType.Other) {
          resData.showOtherTypeInput = true;
          resData.otherTypeInputText = response.data.result.other_credit_type;
        }
        setData(resData);
        setloading(false);
        setOrderList(credit?.openOrders);
        if (refundCreditType.includes(response.data.result.CreditType.id.toString())) {
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      });
    } else {
      setOrderList(order);
      setData({
        ...data,
        endDate: moment().add(6, 'month').toDate(),
        patient: {
          firstName: patient.firstName,
          lastName: patient.lastName,
          id: patient.id,
          code: patient.code,
        },
      });
      setShowDropdown(false);
      setloading(false);
    }
  }, [props]);

  const customStyles = {
    content: {
      width: '35%',
      top: '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const creditValidator = new SimpleReactValidator({
    validators: {
      creditLimit: {
        message: 'Limit Exceeded for credit Amount.',
        rule: (val) => data.creditAmount === val && val < 501,
        required: true,
      },
    },
  });

  const [errors, setErrors] = React.useState({
    creditAmount: creditValidator.message('Credit Amount', data.creditAmount, 'required'),
    ...(data.creditAmount > 500 &&
      AuthService.isAdminOnly() && {
        creditLimit: creditValidator.message('creditLimit', data.creditLimit, 'creditLimit'),
      }),
    ...(!data.indefinite && {
      endDate: creditValidator.message('Expiration Date', data.endDate, 'required'),
    }),
    creditTypeId: creditValidator.message('Select Credit Type', data.creditTypeId, 'required'),
    ...(!ffLoading &&
      flags[FF_REFUND_NON_REFUND_CREDIT] &&
      refundCreditType.includes(data.creditTypeId.toString()) && {
        orderId: creditValidator.message('Select Order code', data.orderId, 'required'),
      }),
  });

  const checkSetErrors = () => {
    setErrors({
      creditAmount: creditValidator.message('Credit Amount', data.creditAmount, 'required'),
      ...(data.creditAmount > 500 &&
        AuthService.isAdminOnly() && {
          creditLimit: creditValidator.message('creditLimit', data.creditLimit, 'creditLimit'),
        }),
      ...(!data.indefinite && {
        endDate: creditValidator.message('Expiration Date', data.endDate, 'required'),
      }),
      creditTypeId: creditValidator.message('Select Credit Type', data.creditTypeId, 'required'),
      ...(!ffLoading &&
        flags[FF_REFUND_NON_REFUND_CREDIT] &&
        refundCreditType.includes(data.creditTypeId.toString()) && {
          orderId: creditValidator.message('Select Order code', data.orderId, 'required'),
        }),
    });
  };

  const submissionValidator = () => {
    creditValidator.purgeFields();
    checkSetErrors();
    if (!creditValidator.allValid()) {
      creditValidator.showMessages();
      checkSetErrors();
      return false;
    }
    return true;
  };

  const closeCreditModal = () => {
    setData({
      ...data,
      orderDiscount: [{ label: 'Select Discount', value: '' }],
      showOtherTypeInput: false,
      creditAmount: '',
      creditLimit: '',
      otherTypeInputText: '',
      creditType: [{ label: 'Select Credit Type', value: '' }],
      endDate: null,
      indefinite: false,
      orderCodes: [{ label: 'Select Order Code', value: '' }],
      creditTypeId: '',
      orderId: '',
    });
    close();
  };

  const handleCreditTypeChange = (selectedOptions) => {
    setData({
      ...data,
      creditTypeId: selectedOptions.value,
      creditType: selectedOptions,
    });
    if (refundCreditType.includes(selectedOptions.value.toString())) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleOrderCodeChange = (selectedOptions) => {
    setData({
      ...data,
      orderId: selectedOptions.value,
      orderCodes: selectedOptions,
    });
  };

  const handleTimeChange = () => {
    const currentDate = moment(new Date()).format();
    const createdDate = new Date(creditTableData.createdDate);
    const dateDiff = Math.abs(moment.utc(currentDate).diff(createdDate, 'hours'));
    if (dateDiff <= 24) {
      toast.warn('Credit limit reached for patient');
      return false;
    }
    return true;
  };

  const savePatientCredit = async (e) => {
    if (AuthService.isAdminOnly() && isEdit === false) {
      const canTimeLimitExceed = handleTimeChange();
      if (!canTimeLimitExceed) {
        return;
      }
    }
    e.preventDefault();
    const canSubmit = submissionValidator();
    if (!canSubmit) {
      return;
    }
    if (data.creditAmount > 0) {
      setloading(true);

      const creditDetail = {
        id: creditId,
        patientId: data.patient.id,
        indefinite: data.indefinite ? 1 : 0,
        endDate: data.indefinite ? null : moment(data.endDate).format('DD-MMM-YYYY'),
        amount: parseFloat(data.creditAmount).toFixed(2),
        typeId: data.creditType.value,
        userId: AuthService.getUserID(),
        otherCreditType: data.otherTypeInputText,
        orderId: refundCreditType.includes(data.creditTypeId.toString()) ? data?.orderCodes?.value : null,
      };
      try {
        let response = null;
        if (!isEdit) {
          response = await axios.post(`${settings.url}/patientcredit`, creditDetail);
          if (response?.data?.status === 200) {
            closeCreditModal();
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
            logger.error('savePatientCredit response?.data?.status === 200', response?.data?.message);
          }
        } else {
          response = await axios.put(`${settings.url}/patientcredit`, creditDetail);
          if (response?.data?.status === 200) {
            closeCreditModal();
            toast.success(response.data.message);
            dataBind();
          } else {
            toast.error(response.data.message);
            logger.error('/patientcredit`, creditDetail', response?.data?.message);
          }
        }
        setloading(false);
      } catch (err) {
        toast.error('Failed to add credit. Please try again later.');
        logger.error('Failed to add credit.', err);
        setloading(false);
      }
    } else {
      toast.error('Invalid credit amount');
      logger.error('Invalid credit amount');
    }
  };

  const handleCreditAmount = (creditAmt) => {
    setData({
      ...data,
      creditAmount: creditAmt,
    });
  };

  function orders() {
    const resp = orderList?.map((x) => ({
      label: x.order_code,
      value: x.id,
    }));
    return resp;
  }

  return (
    <CustomModal
      isOpen={show}
      onRequestClose={close}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <>
        <div className="modalheader">
          <h3>Add Credit</h3>
          <span>{`${data.patient.firstName} ${data.patient.lastName}`}</span>
          <br />
          <span>{data.patient.code}</span>
        </div>
        <div className="modalbody">
          <div className="p-0 mt-3 col-md-5">
            <label htmlFor="Amount column">{}Amount ($)</label>
            <FormInput
              type="number"
              name="creditAmount"
              value={data.creditAmount || ''}
              placeholder="Enter the Credit Amount"
              onChange={(evt) => handleCreditAmount(evt.target.value)}
              error={AuthService.isAdminOnly() && data.creditAmount > 500 ? errors.creditLimit : errors.creditAmount}
            />
          </div>

          <div className="p-0 mt-2">{AuthService.isAdminOnly() ? <p>Maximum Credit Amount 500$ </p> : ''}</div>

          <div className="mt-3">
            <label htmlFor="creditType">{}Credit Type</label>
            <br />
            <Select
              options={creditConstants.creditType}
              value={data.creditType || ''}
              width="20"
              onChange={handleCreditTypeChange}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.creditTypeId}
          </div>

          {!ffLoading && flags[FF_REFUND_NON_REFUND_CREDIT] && showDropdown && (
            <div className="mt-3">
              <label htmlFor="Display order codes">{}Order ID</label>
              <br />
              <Select
                options={orders()}
                value={data.orderCodes || ''}
                width="20"
                onChange={handleOrderCodeChange}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                id="Display order codes"
              />
              {errors.orderId}
            </div>
          )}

          {data.showIndefinite && (
            <div>
              <div className="form-group p-1">
                <div className="d-flex mt-2">
                  <div className="custom-control custom-switch" style={{ paddingLeft: '18px' }}>
                    <input
                      type="checkbox"
                      name="indefinite"
                      className="custom-control-input"
                      id="indefinite"
                      onChange={(e) => setData({ ...data, indefinite: e.target.checked })}
                      checked={data.indefinite}
                    />
                    <label htmlFor="indefinite" className="ml-3 checkbox custom-control-label">
                      {}Indefinite
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group p-1 col-sm-6">
                {!data.indefinite && (
                  <>
                    <label htmlFor="datePicker">{}Expiration Date</label> <br />
                    <div className="w-100 h-10">
                      <DatePicker
                        id="endDate"
                        selected={data.endDate || ''}
                        onChange={(e) => setData({ ...data, endDate: e })}
                        minDate={moment().toDate()}
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd/MM/yyyy"
                        dropdownMode="select"
                        className="form-control"
                        popperPlacement="top-start"
                        width="100%"
                      />
                      <label htmlFor="endDate">
                        {}
                        <i className="fa fa-calendar mx-2" aria-hidden="true" />
                      </label>
                    </div>
                    {errors.endDate}
                  </>
                )}
                {data.indefinite}
              </div>
            </div>
          )}
        </div>
        <div className="modalfooter">
          <Button
            text={data.edit ? 'Update Discount' : 'Add Credit'}
            type="submit"
            className="btn btn-primary float-right mt-5"
            onClick={savePatientCredit}
            disabled={loading}
          />
        </div>

        {AuthService.isAdmin() && creditTableData && creditTableData.creditAmount && (
          <div className="mt-6">
            <PatientCreditTable data={creditTableData} />
          </div>
        )}

        <div className="modalfooter">
          <Button
            text="Close"
            type="submit"
            className="btn btn-outline-primary float-right mt-5 mr-3"
            onClick={closeCreditModal}
          />
        </div>
      </>
    </CustomModal>
  );
}

export default PatientCreditModal;
