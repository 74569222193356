import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { Chip, ChipProps, Tooltip } from '@mui/material';

import { getDateTimeWithShippingTz } from '@/utils/orderAllocationTool';

const LABEL = 'same day delivery';
// 9am cutoff for each pharmacy, which will become unique to each pharmacy afte the trial
const DEFAULT_SAME_DAY_DELIVERY_CUTOFF = getDateTimeWithShippingTz().set({ hour: 9, minute: 0, second: 0 });

interface Props extends Omit<ChipProps, 'children'> {
  potentialSameDayDelivery: boolean;
}

function SameDayDeliveryChip({ potentialSameDayDelivery, ...chipProps }: Props) {
  // Order isn't eligible for same day delivery (or not allocated to a pilot pharmacy during the trial)
  if (!potentialSameDayDelivery) {
    return null;
  }

  // We've passed the time where these can be shipped for same day delivery
  const missedCutoff = getDateTimeWithShippingTz() >= DEFAULT_SAME_DAY_DELIVERY_CUTOFF;

  if (missedCutoff) {
    return (
      <Tooltip title={`Cutoff time: ${DEFAULT_SAME_DAY_DELIVERY_CUTOFF.toFormat('t')}`} arrow>
        <Chip
          label={LABEL}
          color="warning"
          icon={<PriorityHighOutlinedIcon fontSize="small" />}
          {...chipProps}
          sx={{ opacity: 0.5, textDecoration: 'line-through', fontStyle: 'italic', ...chipProps.sx }}
        />
      </Tooltip>
    );
  }

  return <Chip label={LABEL} color="primary" icon={<LocalShippingOutlinedIcon fontSize="small" />} {...chipProps} />;
}

export default SameDayDeliveryChip;
