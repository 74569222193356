import React, { createContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WithSnowplow } from '@montugroup/data-collection';
import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import enGB from 'date-fns/locale/en-GB';
import { SnackbarProvider } from 'notistack';

import settings from '@/constants/constants';
import { AuthService } from '@/services/authentication.service';
import { configure as configureBraze } from '@/services/braze.service';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Card } from './components/braze/components/ContentCard';
import CircleLoader from './components/common/circleLoader';
import { FF_ENABLE_SNOWPLOW_CIRCUIT, FF_SHOW_MAINTENANCE_SCREEN } from './constants/featureFlags';
import { SNOWPLOW_APP_ID, SNOWPLOW_TRACKER_NAME } from './constants/snowplow';
import { ErrorManagementProvider } from './context/ErrorManagement';
import { PatientReferralProvider } from './context/PatientReferral';
import useFeatureFlags from './hooks/useFeatureFlags';
import useTrackingProviders from './hooks/useTrackingProviders';
import Maintenance from './pages/maintenance/Maintenance';
import Routes from './routes/routes';
import { Logger } from './utils/logger';

const logger = new Logger('App');

const MAX_TOAST_STACKS = 10;

export const BrazeContentCardContext = createContext<Card[]>([]);

function App() {
  const queryClient = new QueryClient();
  const { flags, isReady, setUser } = useFeatureFlags();

  const [brazeContentCards, setBrazeContentCards] = useState<Card[]>([]);

  useTrackingProviders(settings);

  const user = AuthService.getUser();
  const email = user?.user?.email || '';

  useEffect(() => {
    if (isReady && user?.user) setUser(user.user);
  }, [email, isReady, setUser, user?.user]);

  useEffect(() => {
    configureBraze({
      userEmail: email,
      contentCardsSubscriber: ({ cards }) => setBrazeContentCards(cards as Card[]),
    });
  }, [email]);

  logger.debug('email: ', email);

  if (!isReady) {
    return <CircleLoader />;
  }

  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW_CIRCUIT];

  return WithSnowplow(
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={settings.GoogleAuthId}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <CssBaseline />
          <ToastContainer
            // Allowing unmasking of toast text in session replays, if any toast text is found to be PII the implementation of this will need to be changed
            toastClassName="dd-privacy-allow"
            position="top-right"
            autoClose={7500}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnHover
          />
          <SnackbarProvider maxSnack={MAX_TOAST_STACKS}>
            <ErrorManagementProvider initialErrors={[]}>
              <BrazeContentCardContext.Provider value={brazeContentCards}>
                {flags[FF_SHOW_MAINTENANCE_SCREEN] ? (
                  <Maintenance />
                ) : (
                  <PatientReferralProvider>
                    <BrowserRouter>
                      <Routes />
                    </BrowserRouter>
                  </PatientReferralProvider>
                )}
              </BrazeContentCardContext.Provider>
            </ErrorManagementProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>,
    {
      appId: SNOWPLOW_APP_ID,
      trackerName: SNOWPLOW_TRACKER_NAME,
    },
    enableSnowplow,
  );
}

export default App;
