import { SyntheticEvent, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import Link from '@mui/material/Link';

import useGetOrderReceipt from '@/hooks/rest/useGetOrderReceipt';
import { Button } from '@/ui-library';

import OrderBreakdown, { Product } from '../order-breakdown/OrderBreakdown';
import OrderStatusChip, { getOrderStatusChipProps } from '../OrderStatusChip/OrderStatusChip';
import PharmacyOrderDetails, { OrderStage } from '../pharmacy-order-details/PharmacyOrderDetails';

type OrderProduct = {
  name: string;
  quantity: number;
  price: number;
};

export type OrderHistoryCardProps = {
  orderNumber: string;
  orderLink: string;
  recieptLink?: string;
  numberOfItems: number;
  orderDate: Date;
  pharmacyName: string;
  pharmacyLogoUrl?: string;
  orderPrice: string;
  orderStatus: number;
  trackingLink?: string;
  delivered: boolean;
  shippingAddress?: string;
  orderProducts: OrderProduct[];
  dispensingFee: string;
  splitOrder?: boolean;
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: theme.spacing(12),
  borderRadius: 0,
  margin: 0,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: 0,
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(5),
  },
  [`&.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
    minHeight: theme.spacing(12),
  },
  [`& .${accordionSummaryClasses.content}`]: {
    gap: theme.spacing(2),
    margin: 0,
  },
  [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
    margin: 0,
  },
}));

const StyledAccordianDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(6),
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const OrderNumberContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'left',
  marginBottom: theme.spacing(2),
}));

const OrderDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const OrderStatusContainer = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(6),
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}));

const OrderTrackingButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
  width: '100%',
  fontSize: '1rem',
}));

const OrderItemsContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    background: '#EBEEEF',
    padding: theme.spacing(5),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
}));

const PharmacyDetailsContainer = styled(Box)(({ theme }) => ({
  border: '1px',
  borderStyle: 'solid',
  borderColor: '#DEDEE1',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(1),
}));

const OrderSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: '70%',
    flexDirection: 'column',
  },
}));

const FaqContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: '30%',
  },
}));

const Faq = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  background: '#F2EFE4',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(1),
}));

const ActionText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(2),
  color: theme.palette.primary.main,
  borderColor: 'var(--mui-palette-primary-_states-disabled)',
}));

const FaqButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > :first-child > *': {
    marginTop: 0,
  },
}));

const FaqButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const OrderStatusChipMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export function OrderHistoryCard(props: OrderHistoryCardProps) {
  const {
    orderNumber,
    orderDate,
    pharmacyName,
    orderPrice,
    orderStatus,
    shippingAddress,
    pharmacyLogoUrl,
    orderProducts,
    delivered,
    trackingLink,
    dispensingFee,
    splitOrder,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const { data: receiptData, isError: receiptError } = useGetOrderReceipt(orderNumber, expanded);

  const handleAccordionChange = (_e: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const readableOrderDate = orderDate
    .toLocaleDateString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
    .replace(/(\d{2}) (\w{3}) (\d{4})/, '$1 $2 $3');

  const orderStatusChipProps = getOrderStatusChipProps({ status: orderStatus, delivered });

  return (
    <Wrapper>
      <Accordion defaultExpanded={false} onChange={handleAccordionChange} elevation={0}>
        <StyledAccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
          <Box sx={{ flexDirection: 'column' }}>
            <OrderNumberContainer>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Order #{orderNumber}
              </Typography>
              <OrderStatusContainer>
                <span>
                  <OrderStatusChip {...orderStatusChipProps} />
                </span>
              </OrderStatusContainer>
            </OrderNumberContainer>
            <OrderDetailsContainer>
              <Typography variant="body2">Placed on {readableOrderDate}</Typography>
            </OrderDetailsContainer>
            <OrderStatusChipMobile>
              <OrderStatusChip {...orderStatusChipProps} />
            </OrderStatusChipMobile>
            {splitOrder ? (
              <Typography variant="body2" fontWeight="semibold" sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                This is a partial order. Some items may have been sent separately.
              </Typography>
            ) : null}
          </Box>
        </StyledAccordionSummary>

        <StyledAccordianDetails>
          <OrderSummaryContainer>
            <PharmacyDetailsContainer>
              <PharmacyOrderDetails
                pharmacyName={pharmacyName}
                pharmacyLogoUrl={pharmacyLogoUrl}
                shippingAddress={shippingAddress}
                stage={orderStatusChipProps.status as OrderStage}
              />
              {trackingLink && !receiptError ? (
                <OrderTrackingButtonContainer>
                  <Button href={trackingLink} fullWidth variant="outlined">
                    <ActionText variant="body1">Track delivery</ActionText>
                  </Button>
                </OrderTrackingButtonContainer>
              ) : null}
            </PharmacyDetailsContainer>

            <OrderItemsContainer>
              <OrderBreakdown
                orderData={{
                  totalPrice: Number(orderPrice),
                  products: orderProducts as Product[],
                  dispensingFee: Number(dispensingFee),
                }}
                invoiceLink={receiptData?.data?.signedUrl}
              />
            </OrderItemsContainer>
          </OrderSummaryContainer>

          <FaqContainer>
            <Faq>
              <Typography
                variant="subtitle1"
                fontWeight="semibold"
                sx={(theme) => ({ marginBottom: theme.spacing(6) })}
              >
                How can we help you?
              </Typography>
              <FaqButtonContainer>
                <Link
                  href="https://support.montu.com.au/hc/en-au/articles/23256708939929-How-long-will-my-order-take"
                  target="_blank"
                >
                  <FaqButton variant="outlined" fullWidth>
                    <ActionText variant="body1">Why is my order taking so long?</ActionText>
                  </FaqButton>
                </Link>

                <Link
                  href="https://support.montu.com.au/hc/en-au/articles/22616018335641-How-do-I-get-my-tracking-number"
                  target="_blank"
                >
                  <FaqButton variant="outlined" fullWidth>
                    <ActionText variant="body1"> How do I track my order?</ActionText>
                  </FaqButton>
                </Link>

                <Link href="https://support.montu.com.au/hc/en-au" target="_blank">
                  <FaqButton variant="outlined" fullWidth>
                    <ActionText variant="body1"> Get Help</ActionText>
                  </FaqButton>
                </Link>
              </FaqButtonContainer>
            </Faq>
          </FaqContainer>
        </StyledAccordianDetails>
      </Accordion>
    </Wrapper>
  );
}

export default OrderHistoryCard;
