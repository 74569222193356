import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import FullPageContainer from '@/components/common/FullPageContainer';
import { Papaya } from '@/components/common/Papaya';
import { ProductDetails } from '@/components/products/detail/ProductDetails';
import { ProductHowToUse } from '@/components/products/detail/ProductHowToUse';
import { ProductOverview } from '@/components/products/detail/ProductOverview';
import { PatientProductRating, Product } from '@/components/products/detail/types';
import { getProductOffering } from '@/services/product.service';
import Button from '@/ui-library/button/Button';

const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  zIndex: '9',
  borderRadius: '100px',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

function ProductOfferingRebrand() {
  const { id } = useParams();
  const history = useHistory();

  const [data, setData] = useState<{
    product?: Product | null;
    user_ratings?: PatientProductRating | null;
    product_rating: string;
  }>({
    product: null,
    user_ratings: null,
    product_rating: '0',
  });

  const productId = id;

  const fetchData = () => {
    getProductOffering(productId).then((resp) => {
      if (!resp.data.product || !resp.data.product.PatientProductRatings) {
        return;
      }

      setData({
        product: resp.data.product,
        product_rating: Number(resp.data.product_rating).toFixed(1),
        user_ratings: resp.data.patient_rating,
      });
    });
  };

  React.useEffect(() => {
    if (productId) {
      fetchData();
    }
  }, [productId]);

  const shouldShowBackBtn = history.length > 1;

  if (!data.product) {
    return null; // TODO: Show Loading Skeleton
  }

  return (
    <FullPageContainer>
      <Papaya />
      <Box zIndex={1} position="relative">
        <Box>
          {shouldShowBackBtn && (
            <BackButton
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </BackButton>
          )}
          <ProductOverview product={data.product} productRating={data.product_rating} />
        </Box>
        <ProductDetails product={data.product} />
        <ProductHowToUse product={data.product} />
      </Box>
    </FullPageContainer>
  );
}

export default ProductOfferingRebrand;
