import type { OnAcceptanceFields } from '@/hooks/rest/types';
import useGetUserAgreement from '@/hooks/rest/useGetUserAgreement';
import usePostUserAgreement from '@/hooks/rest/usePostUserAgreement';
import { AuthService } from '@/services/authentication.service';

import PatientConsent from '../common/Consent/PatientConsent';
import PrivacyPolicyDialog from '../common/PrivacyPolicy/PrivacyPolicyDialog';

export default function Notices() {
  const { loading, hasAgreement, setHasAgreement } = useGetUserAgreement();

  const { makeRequest: acceptUserAgreement } = usePostUserAgreement();
  const onAcceptance = async (data: OnAcceptanceFields) => {
    await acceptUserAgreement(data);
    setHasAgreement(true);
  };

  const isPatient = AuthService.isPatient();
  const showPrivacyPolicy = !hasAgreement && isPatient;

  return (
    <>
      {showPrivacyPolicy ? <PrivacyPolicyDialog open={!loading} onAcceptance={onAcceptance} /> : null}
      {isPatient && <PatientConsent />}
    </>
  );
}
