import React from 'react';

function ProductItem(props) {
  return (
    <div className="product-wrapper mx-4">
      <div className="row product-item bg-blue text-white py-3">
        <div className={`img-wrapper col-md-4 text-center ${props.customImgClass ? 'px-4' : 'px-5'}`}>
          <img
            className={props.customImgClass ? props.customImgClass : 'product-image'}
            src={props.imgUrl}
            alt={`${props.title}`}
          />
          {props.imgBannerText ? <p className="img-text">{props.imgBannerText}</p> : ''}
        </div>
        <div className="body col-md-8 mt-4">
          <h5 className="title font-bold">{props.title}</h5>
          <p className="desc-text mt-4 m-0">{props.dose.l1}</p>
          <p className="desc-text m-0">{props.dose.l2}</p>
          <p style={{ fontSize: '14px' }} className="desc-text m-0 font-light">
            {props.quantityDesc.l1}
          </p>
          <p style={{ fontSize: '14px' }} className="desc-text m-0 font-light">
            {props.quantityDesc.l2}
          </p>
        </div>
      </div>
      <div className="mt-2 desc-footer" style={{ fontSize: '14px' }}>
        <p>
          <b>TGA Registered Name:</b> {props.tgaName}
        </p>
        <p>
          <b>Titration:</b> {props.titration}
        </p>
        <p>
          <b>Treatment Area:</b> {props.treatmentArea}
        </p>
        <p>
          <b>Price:</b> ${props.price.cost}
          <sup>*</sup> <small>{props.price.perMg}</small>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
            style={{ fontSize: '15px' }}
            href={props.cmiSheet}
          >
            =&gt; CMI Sheet
          </a>
        </p>
      </div>
    </div>
  );
}

export default ProductItem;
