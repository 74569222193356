import { FF_ENABLE_PROFILE_PAGE } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import PatientRebrandProfile from '@/pages/profile/PatientRebrandProfile';
import Brand from '@/utils/brand';

import ProfileDetails from './profileDetails';
import ProfileDetailsRebrand from './profileDetailsRebrand';

// TODO: RouteProps to be removed once we remove the withFeatureFlags from routes
type RouteProps = {
  [key: string]: any;
};

export default function ProfileDetailsContainer(props: RouteProps) {
  const { flags } = useFeatureFlags();

  if (flags[FF_ENABLE_PROFILE_PAGE] && Brand.isRebrand()) {
    return <PatientRebrandProfile {...props} />;
  }
  if (Brand.isRebrand()) {
    return <ProfileDetailsRebrand {...props} />;
  }

  return <ProfileDetails {...props} />;
}
