import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';
import FilterableTable from 'rc-data-table';

import editIcon from '../../assets/images/edit.png';
import settings from '../../constants/constants';
import { creditConstants } from '../../constants/creditConstant';
import Button from '../common/button';
import CircleLoader from '../common/circleLoader';
import PatientCreditModal from '../patient/patientCreditModal';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { FF_REFUND_NON_REFUND_CREDIT } from '@/constants/featureFlags';
import { Logger } from '@/utils/logger';

const logger = new Logger('allCreditView');
const { url } = settings;

function allCreditView(props) {
  const [data, setData] = React.useState({
    creditData: [],
    creditId: null,
    isEdit: false,
    patient: {
      firstName: '',
      lastName: '',
      id: '',
      code: '',
    },
    creditStatusType: [{ label: 'Select Status', value: '0' }],
    selectedCreditItems: [],
    selectedCreditStatus: '',
    page: 0,
    pageSize: 100,
    count: 0,
    filter: null,
    sort: { name: 'created_date', reverse: true },
    loading: true,
    singleCredit: null,
  });
  const [showCreditModal, setShowCreditModal] = React.useState(false);

  const { flags, loading } = useFeatureFlags();

  // Get all credit from api
  const dataBindAllCredit = async (pageSize, page, filter, name, reverse) => {
    axios
      .get(`${url}/patientcredit`, {
        params: {
          limit: pageSize,
          offset: page * pageSize,
          sort: name,
          sortOrder: reverse ? 'DESC' : 'ASC',
          filter: filter,
          ts: new Date().getTime(),
        },
      })
      .then((resp) => {
        setData((prev) => ({
          ...prev,
          creditData: resp.data.result,
          count: resp.data.count,
          loading: false,
        }));
      });
  };

  const getCreditData = () => {
    const {
      pageSize,
      page,
      filter,
      sort: { name, reverse },
    } = data;
    dataBindAllCredit(pageSize, page, filter, name, reverse);
  };

  React.useEffect(() => {
    getCreditData();
  }, [data.pageSize, data.page, data.filter, data.sort.name, data.sort.reverse]);

  const checkChange = (e) => {
    const value = parseInt(e.target.value, 10);
    let selectedItems = [];
    if (e.target.checked) {
      selectedItems.push(value);
    } else {
      selectedItems = data.selectedCreditItems.filter((x) => x !== value);
    }
    setData((prev) => ({
      ...prev,
      selectedCreditItems: selectedItems,
    }));
  };

  // Update credit Status
  const updateCreditStatus = async (e) => {
    e.preventDefault();
    if (!data.selectedCreditStatus) {
      toast.error('Choose a status to continue');
      return;
    }
    if (data.selectedCreditItems.length === 0) {
      toast.error('Select one or more credit to proceed');
      return;
    }

    const response = await axios.put(`${url}/patientcredit/status`, {
      status_id: data.selectedCreditStatus,
      items: data.selectedCreditItems,
    });

    if (response.data.status === 200) {
      getCreditData();
      setData((prev) => ({
        ...prev,
        creditStatusType: [{ label: 'Select Status', value: '0' }],
        selectedCreditItems: [],
        selectedCreditStatus: '',
      }));

      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
      logger.error('updateCreditStatus-', response?.data?.message);
    }
  };

  // status change action
  const creditStatusChange = (e) => {
    setData((prev) => ({
      ...prev,
      creditStatusType: [{ label: e.label, value: e.value }],
      selectedCreditStatus: e.value,
    }));
  };

  const pageChange = (page, size) => {
    setData((prev) => ({
      ...prev,
      page,
      pageSize: size,
      loading: true,
    }));
  };

  const getSortColumn = (sort) => {
    setData((prev) => ({
      ...prev,
      sort: sort[0],
      loading: true,
    }));
  };

  const onFilter = (filter) => {
    setData((prev) => ({
      ...prev,
      filter,
      page: 0,
    }));
  };

  const editPatientCredit = (creditId) => {
    let filterCreditData = null;
    if (!loading && flags[FF_REFUND_NON_REFUND_CREDIT]) {
      filterCreditData = data.creditData.find((x) => x.id === creditId);
    }
    setData((prev) => ({
      ...prev,
      singleCredit: filterCreditData,
      creditId,
      isEdit: true,
    }));
    setShowCreditModal(true);
  };

  const fields = [
    { name: 'id', displayName: 'id', visible: false, sortable: true, tdClassName: 'sub-text' },
    { name: 'credit_code', displayName: 'Credit #', inputFilterable: true, sortable: false, tdClassName: 'sub-text' },
    {
      name: 'createdOn',
      displayName: 'Created Date',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text w-15',
    },
    {
      name: 'patientId',
      displayName: 'Patient ID',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text',
    },
    {
      name: 'patientName',
      displayName: 'Patient Name',
      inputFilterable: true,
      tdClassName: 'sub-text w-15',
      sortable: false,
    },
    {
      name: 'amount',
      displayName: 'Credit Amount',
      inputFilterable: true,
      tdClassName: 'sub-text w-5',
    },
    {
      name: 'creditType',
      displayName: 'Credit Type',
      inputFilterable: true,
      tdClassName: flags[FF_REFUND_NON_REFUND_CREDIT] ? 'sub-text w-5' : 'd-none',
      thClassName: flags[FF_REFUND_NON_REFUND_CREDIT] ? 'w-10' : 'd-none',
    },
    {
      name: 'orderCode',
      displayName: 'Order Code ',
      inputFilterable: true,
      tdClassName: flags[FF_REFUND_NON_REFUND_CREDIT] ? 'sub-text w-10' : 'd-none',
      thClassName: flags[FF_REFUND_NON_REFUND_CREDIT] ? 'w-10' : 'd-none',
    },
    {
      name: 'consumedAmount',
      displayName: 'Consumed Amount',
      inputFilterable: false,
      tdClassName: 'sub-text w-10',
    },
    {
      name: 'expiredOn',
      displayName: 'Expiration Date',
      inputFilterable: true,
      tdClassName: 'sub-text w-15',
    },
    {
      name: 'status',
      displayName: 'Status',
      inputFilterable: true,
      tdClassName: 'sub-text w-5',
    },
    {
      name: 'createdBy',
      displayName: 'Created by ',
      inputFilterable: true,
      tdClassName: 'sub-text w-10',
    },
    {
      name: 'id',
      displayName: 'Edit',
      render: (e) =>
        e.record.status === 'Active' ? (
          <img
            src={editIcon}
            alt="edit_icon"
            className="prescriptionIcon"
            role="presentation"
            onClick={() => editPatientCredit(e.value)}
          />
        ) : (
          ''
        ),
    },
    {
      name: 'id',
      displayName: 'Select',
      tdClassName: 'sub-text align-center',
      render: (e) =>
        e.record.isConsumed === false && e.record.status === 'Active' ? (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={data.selectedCreditItems.includes(e.value)}
              onChange={checkChange}
              value={e.value}
              id={`check${e.value}`}
            />
            <label className="custom-control-label" htmlFor={`check${e.value}`}>
              {}
            </label>
          </div>
        ) : (
          ''
        ),
    },
  ];

  return (
    <div>
      <div className="container-fluid">
        <h3 style={{ padding: '20px' }}>All Credits</h3>
        <div>
          <div>
            <div className="d-flex mt-lg-2 flex-wrap justify-content-lg-end">
              <div className="form-inline bulk-update mr-5">
                <Select
                  name="creditStatusItems"
                  value={data.creditStatusType}
                  isSearchable
                  placeholder="Select"
                  className="select-field mx-1 rounded"
                  options={creditConstants.creditStatus}
                  onChange={creditStatusChange}
                />
                <Button text="Change status" className="btn btn-md btn-primary mx-1" onClick={updateCreditStatus} />
              </div>
            </div>
          </div>
          {data.loading ? (
            <CircleLoader />
          ) : (
            <FilterableTable
              className="table-responsive w-100 p-2"
              trClassName={data.preferredPharmacyCheck}
              data={data.creditData}
              loading={data.loading}
              fields={fields}
              paginationOnServer
              initialSort={data.sort.name}
              initialSortDir={!data.sort.reverse}
              onPageChanged={pageChange}
              filterData={onFilter}
              serverSort={getSortColumn}
              totalRecords={data.count}
              currentPage={data.page}
              pageSize={data.pageSize}
              filterPlaceholder={data.filter || 'Search'}
              pageSizes={false}
              maintainPageOnSetData
              topPagerVisible={false}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          )}

          <PatientCreditModal
            show={showCreditModal}
            close={() => setShowCreditModal(false)}
            credit={data.singleCredit}
            patient={data.patient}
            isEdit={data.isEdit}
            creditId={data.creditId}
            dataBind={getCreditData}
          />
        </div>
      </div>
    </div>
  );
}

export default allCreditView;
