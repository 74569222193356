/* eslint-disable no-undefined */
import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import CircleLoader from '../common/circleLoader';
import FormInput from '../common/FormInput';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Button from '../common/button';
import { getCountries, getStates } from '@/services/data.service';
import { MedicalLiaisonService } from '@/services/medicalLiaison.service';
import axios from 'axios';
import settings from '../../constants/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('mlForm');
const url = settings.url;

class MLOnboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetched: this.props.match.params.id ? true : false,
      content: this.props.match.params.id === undefined ? true : false,
      id: this.props.match.params.id,
      user_id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      sub_address: '',
      country: 13,
      state: '',
      city: '',
      active: '1',
      date: moment().toDate(),
      defaultState: { value: '', label: 'Select State' },
      defaultCountry: { value: 'australia', label: 'Australia' },
      postal_code: '',
      countries: [],
      states: [],
      update: false,
      patientLimit: 10,
    };
    //copy for state
    this.initialState = this.state;
    this.validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    document.title = 'Onboard BDM - Montu Group | Making Medical Cannabis Accessible';
    const { country } = this.state;
    const countryList = await getCountries();
    if (countryList) {
      this.setState({
        countries: countryList,
        content: false,
      });
    }
    const stateList = await getStates(country);
    if (stateList) {
      this.setState({
        states: stateList,
      });
    }
    if (this.state.fetched) {
      await axios.get(url + `/liaison?id=${this.state.id}`).then((data) => {
        let user = data.data[0],
          split_address = user.address.split('$$$$'),
          current_country = countryList.find(({ name }) => name === user.country),
          current_state = stateList.find(({ name }) => name === user.state);
        this.handleState({ value: current_state.id });
        this.setState({
          user_id: user.user_id,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          phone: user.phone,
          address: split_address[0],
          sub_address: split_address.length > 1 ? split_address[1] : '',
          city: user.city,
          postal_code: user.zip_code,
          defaultState: { value: current_state.id, label: current_state.name },
          defaultCountry: { value: current_country.id, label: current_country.name },
          active: user.active ? '1' : '0',
          patientLimit: user.patient_limit,
          date: moment(user.joined_date).toDate(),
          fetched: false,
          update: true,
        });
      });
    }
  }

  handleActive = (e) => {
    this.setState({
      active: e.target.checked,
    });
  };
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  handleUserInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleState = async (e) => {
    this.setState({
      state: e.value,
    });
  };
  onSubmission = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    let sub_address = this.state.sub_address ? this.state.sub_address : '';
    this.setState({ loading: true });
    let user,
      data_collected = {
        id: this.state.id,
        user_id: this.state.user_id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address + '$$$$' + sub_address,
        city: this.state.city,
        state_id: this.state.state,
        country_id: this.state.country,
        zip_code: this.state.postal_code,
        active: this.state.active === '1' ? true : false,
        joined_date: moment(this.state.date).format('DD-MMM-YYYY'),
        patient_limit: this.state.patientLimit,
      };
    try {
      if (this.state.update) {
        user = await MedicalLiaisonService.updateMedicalLiaison(data_collected);
      } else {
        user = await MedicalLiaisonService.onboardMedicalLiaison(data_collected);
      }
    } catch (err) {
      toast.error(
        this.state.update
          ? 'Email Id already exist, BDM details are not added'
          : 'Email Id already exist, BDM details are not Updated',
      );
      logger.error(
        this?.state?.update
          ? 'Email Id already exist, BDM details are not added'
          : 'Email Id already exist, BDM details are not Updated',
        err,
      );
      this.setState({
        loading: false,
      });
      return 'Error';
    }

    if (user) {
      this.setState({ submitted: true });
      toast.success(this.state.update ? 'BDM successfully Updated!' : 'BDM successfully added!', {});
      this.setState({
        loading: false,
      });
      this.props.history.push({
        pathname: '/bd',
      });
    } else {
      toast.error(this.state.update ? 'BDM details are not updated' : 'BDM details are not added');
      this.setState({
        loading: false,
      });
      return 'Error';
    }
  };

  render() {
    const {
      loading,
      fetched,
      content,
      firstName,
      lastName,
      email,
      phone,
      active,
      date,
      update,
      state,
      states,
      defaultState,
      patientLimit,
    } = this.state;

    return (
      <React.Fragment>
        <div className="container">
          <h3 id="title">{update ? 'Update BDM Profile' : 'Onboarding BDM Profile'}</h3>
          <div className="row">
            <h5 className="p-2 ml-3">Business Development Manager Profile</h5>
            <div className="col-md-12 mt-3">
              {(this.props.match.params.id !== undefined ? !fetched : !content) ? (
                <div>
                  <div className="col-md-12">
                    <div className="form-row">
                      <FormInput
                        type="text"
                        name="firstName"
                        label="First Name"
                        className="col-md-6"
                        value={firstName}
                        onChange={this.handleUserInput}
                        error={this.validator.message('first name', firstName, 'required')}
                      />
                      <FormInput
                        type="text"
                        name="lastName"
                        label="Last Name"
                        className="col-md-6"
                        value={lastName}
                        onChange={this.handleUserInput}
                        error={this.validator.message('last name', lastName, 'required')}
                      />
                    </div>
                    <div className="form-row">
                      <FormInput
                        type="email"
                        name="email"
                        label="Email"
                        value={email}
                        className="col-md-6"
                        onChange={this.handleUserInput}
                        error={this.validator.message('email', email, 'required|email')}
                        readOnly={update ? true : false}
                      />
                      <FormInput
                        type="text"
                        name="phone"
                        label="Phone"
                        placeholder="eg.0343244556"
                        value={phone}
                        className="col-md-6"
                        onChange={this.handleUserInput}
                        error={this.validator.message('phone-number', phone, 'required|regex:^\\d{10}$')}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="d-block">State / Province / Region</label>
                        <Select
                          name="state"
                          className={`${
                            this.validator.message('State', state, 'required') ? 'rounded dropdown-border' : ''
                          }`}
                          defaultValue={defaultState}
                          onChange={this.handleState}
                          isSearchable={true}
                          placeholder="Select State"
                          options={states.map((x) => ({ label: x.name, value: x.id }))}
                        />
                        {this.validator.message('State', state, 'required')}
                      </div>
                      <div className="form-group col-md-3">
                        <label className="d-block">Dashboard Start Date</label>
                        <DatePicker
                          className="form-control"
                          id="dataPicker"
                          selected={date}
                          onChange={this.handleChange}
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          dropdownMode="select"
                        />
                        <label htmlFor={'dataPicker'}>
                          <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
                        </label>
                      </div>
                      <FormInput
                        type="number"
                        name="patientLimit"
                        label="Patient Limit"
                        placeholder=""
                        value={patientLimit}
                        className="col-md-3"
                        onChange={this.handleUserInput}
                        error={this.validator.message('Patient Limit', patientLimit, 'required|regex:^\\d+$')}
                      />
                    </div>
                    <div className="mt-4 p-3 d-flex flex-row">
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="1"
                        checked={active === '1'}
                        onChange={this.handleUserInput}
                        className="m-1"
                      />
                      <label htmlFor="active" className="ml-1">
                        Active
                      </label>
                      <input
                        type="radio"
                        id="inactive"
                        name="active"
                        value="0"
                        checked={active === '0'}
                        onChange={this.handleUserInput}
                        className="m-1 ml-4"
                      />
                      <label htmlFor="inactive" className="ml-1">
                        Inactive
                      </label>
                    </div>
                    <Button
                      text={update ? 'Update BDM' : 'Onboard BDM'}
                      type="submit"
                      loading={loading}
                      className="btn btn-primary btn-md ml-3 float-right"
                      onClick={this.onSubmission}
                    />
                  </div>
                </div>
              ) : (
                <CircleLoader />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MLOnboard;
