import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select as MuiSelect, styled } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface SelectProps extends Partial<SelectInputProps> {
  'data-testid'?: string;
  label: string;
  options: {
    value: string;
    label: string;
  }[];
  onChangeFn: (event: SelectChangeEvent) => void;
  defaultValue: {
    value: string;
    label: string;
  };
}

const Label = styled('span')`
  font-weight: 600;
`;

export default function Select({ label, options, onChangeFn, defaultValue, ...rest }: SelectProps) {
  const [dropDownValue, setDropDownValue] = useState(defaultValue?.value?.toString() || '');

  const handleChange = (event: SelectChangeEvent) => {
    setDropDownValue(event.target.value as string);

    if (typeof onChangeFn === 'function') {
      onChangeFn(event);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel className="">{label}</InputLabel>
      <MuiSelect
        {...rest}
        autoWidth={false}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        onChange={handleChange}
        value={dropDownValue}
      >
        <MenuItem value="">
          <Label>Select {label}</Label>
        </MenuItem>
        {options.map(({ value, label: labelText }) => (
          <MenuItem key={value} value={value}>
            {labelText}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
