import { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';

import SuppliersActions from '@/components/supplier/SuppliersActions';
import SuppliersTable from '@/components/supplier/SuppliersTable';
import { Supplier, SupplierService } from '@/services/supplier.service';

const SuppliersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

function Suppliers() {
  const [tableData, setTableData] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState(true);

  const retrieveSupplierDetails = async (active = 1) => {
    setLoading(true);
    const { data } = await SupplierService.getSupplierData(active, false);
    setTableData(data.result);
    setLoading(false);
  };

  useEffect(() => {
    retrieveSupplierDetails();
  }, []);

  const handleActiveFilterChange = (active: number) => {
    retrieveSupplierDetails(active);
  };

  return (
    <SuppliersContainer>
      <h3>Suppliers</h3>
      <SuppliersActions onActiveFilterChange={handleActiveFilterChange} />
      <SuppliersTable data={tableData} loading={loading} />
    </SuppliersContainer>
  );
}

export default Suppliers;
