import React from 'react';
import moment from 'moment';

const PatientCreditTable = (props) => {
  const { data } = props;
  return (
    <React.Fragment>
      {
        <div>
          <p className="mt-9">Applied Credit</p>
          <table className="table table-bordered">
            <thead align="center">
              <tr>
                <th>Credit #</th>
                <th>Created Date</th>
                <th>Credit Amount</th>
                <th>Status</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center">{data.creditId}</td>
                <td align="center">{moment(new Date(data.createdDate)).format('DD-MMM-YYYY')}</td>
                <td align="center">${' ' + data.creditAmount}</td>
                <td align="center">{data.status}</td>
                <td align="center">{data.createdBy}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </React.Fragment>
  );
};

export default PatientCreditTable;
