import { useEffect } from 'react';

import { EnvSettings } from '@/constants/constants';
import datadog from '@/providers/datadog';
import googleTagManager from '@/providers/googleTagManager';

export default function useTrackingProviders(settings: EnvSettings) {
  useEffect(() => {
    datadog(settings);
    googleTagManager(settings);
  }, [settings]);
  return {};
}
