import { useState } from 'react';
import styled from '@emotion/styled';

import { mq } from '@/theme';
import { ProductFormulationFilter, ProductFormulationFilters, ProductFormulations } from '@/types';

import ProductTypeButton from './ProductTypeButton';

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  overflow-x: auto;
`;

const ProductFilterLabel = styled.span`
  display: none;
  text-wrap: nowrap;
  ${mq('md')} {
    display: inline-block;
  }
`;

export const DEFAULT_ACTIVE_PRODUCT_TYPE = ProductFormulationFilters.ALL;

export function useProductTypeFilters() {
  const [activeFilters, setActiveFilters] = useState<ProductFormulationFilter[]>([DEFAULT_ACTIVE_PRODUCT_TYPE]);

  function updateActiveFilters(selectedFilterType: ProductFormulationFilter) {
    if (selectedFilterType === DEFAULT_ACTIVE_PRODUCT_TYPE) {
      setActiveFilters([DEFAULT_ACTIVE_PRODUCT_TYPE]);
    } else if (activeFilters.includes(selectedFilterType)) {
      if (activeFilters.length === 1) {
        setActiveFilters([DEFAULT_ACTIVE_PRODUCT_TYPE]);
      } else {
        setActiveFilters(activeFilters.filter((filter) => filter !== selectedFilterType));
      }
    } else {
      setActiveFilters([
        selectedFilterType,
        ...activeFilters.filter((filter) => filter !== DEFAULT_ACTIVE_PRODUCT_TYPE),
      ]);
    }
  }
  return { activeFilters, setActiveFilters, updateActiveFilters };
}

type Props = {
  activeFilters?: ProductFormulationFilter[];
  filters: ProductFormulationFilter[];
  onFilterSelection: (type: ProductFormulationFilter) => void;
};

function ProductTypeFilter(props: Props) {
  const { activeFilters, filters, onFilterSelection } = props;
  return (
    <Container>
      <ProductFilterLabel>Filter by</ProductFilterLabel>
      {filters?.map((filterType: ProductFormulationFilter) => (
        <ProductTypeButton
          key={filterType}
          type={filterType}
          isActive={activeFilters?.includes(filterType)}
          onClick={onFilterSelection}
        />
      ))}
    </Container>
  );
}

/** @deprecated - This should come from the backend */
export const getProductFilterTypeFromFormulationId = (id: number) => Object.values(ProductFormulations)[id - 1];

/** @deprecated - Non prescribable products should be derived differently */
export const getDeviceFormulationId = (): number => 5;

export default ProductTypeFilter;
