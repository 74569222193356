import Brand from '@/utils/brand';

import ProductOffering from './productOffering';
import ProductOfferingRebrand from './ProductOfferingRebrand';

// TODO: RouteProps to be removed once we remove the withFeatureFlags from routes
type RouteProps = {
  [key: string]: any;
};

export default function ProductOfferingContainer(props: RouteProps) {
  return Brand.isRebrand() ? <ProductOfferingRebrand {...props} /> : <ProductOffering {...props} />;
}
