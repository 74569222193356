import React, { useEffect, useReducer, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Divider, MenuItem, styled } from '@mui/material';

import { FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE_PT_PROFILE } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { validateParcelLocker } from '@/pages/profile/utils';
import { AuthService } from '@/services/authentication.service';
import { getStates } from '@/services/data.service';
import { UserService } from '@/services/user.service';
import { Button, DatePicker, LoadingButton, TextField } from '@/ui-library';
import isValidPhoneNumber from '@/utils/isValidPhoneNumber';
import { Logger } from '@/utils/logger';

import CircleLoader from '../common/circleLoader';
import LocationInput from '../common/LocationInput';
import { Papaya } from '../common/Papaya';

import '../../scss/patientRefill.scss';

import { Country, FormData, ResponseData, UserAddress, UserState } from './types';

const logger = new Logger('profileDetails');

const ContentContainer = styled(Box)`
  margin: initial;
  position: relative;
  z-index: 1;
`;

const PageContainer = styled(Box)(({ theme }) => ({
  padding: '2rem 1rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '2rem 10rem',
  },
}));

const PapayaComponent = styled(Papaya)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

function UserProfileRebrand() {
  const [data, setData] = useState<{
    user: {
      address: string;
      city: string;
      country_id: number;
      email: string;
      first_name: string;
      id: number;
      last_name: string;
      phone: string;
      state_id: number;
      zip_code: string;
    };
    patient: any;
    loading: boolean;
    userAddress: UserAddress;
    countries: Country[];
  }>({
    user: {
      address: '',
      city: '',
      country_id: -1,
      email: '',
      first_name: '',
      id: -1,
      last_name: '',
      phone: '',
      state_id: -1,
      zip_code: '',
    },
    patient: null,
    loading: true,
    userAddress: { line1: '', line2: '' },
    countries: [],
  });

  const { flags } = useFeatureFlags();
  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE_PT_PROFILE];

  const [userStates, setUserStates] = useState<UserState[]>([]);
  const [processLoader, setProcessLoader] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const user = AuthService.getUser()?.user;
  const history = useHistory();

  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      patientDob: null,
      email: '',
      phone: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      userState: '',
      postcode: '',
      userCountry: '',
    },
  });

  const setDefaultFormValues = () => {
    const formDefaultValues: Partial<FormData> = {
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      patientDob: data.patient?.dob ? new Date(data.patient.dob) : null,
      email: data.user.email,
      phone: data.user.phone,
      address_line_1: data.userAddress.line1,
      address_line_2: data.userAddress.line2,
      city: data.user.city,
      userState: userStates?.find((x) => x.value === data.user?.state_id?.toString())?.value || '',
      postcode: data.user.zip_code,
      userCountry: data?.countries?.find((x) => x.value === data.user.country_id?.toString())?.value || '',
    };

    Object.keys(formDefaultValues).forEach((fieldName) => {
      const field = fieldName as keyof FormData;
      const value = formDefaultValues[field];

      /* eslint-disable no-undefined */
      if (value !== undefined) {
        setValue(field, value);
      }
    });
  };

  async function fetchData() {
    const resp = await UserService.fetchUserProfile();
    const { userAddress } = data;

    const respData: ResponseData = {
      user: resp.data.user,
      patient: resp.data.patient,
      countries: resp.data.countries,
      states: resp.data.states,
    };

    if (respData.user.address) {
      const [line1, line2] = respData.user.address.split('$$$$');
      userAddress.line1 = line1;
      userAddress.line2 = line2 || '';
    }

    if (respData.user.country_id && respData.user.state_id) {
      setUserStates(
        respData.states.map((x: { name: string; id: number }) => ({ label: x.name, value: x.id.toString() })),
      );
    }

    setData({
      ...respData,
      loading: false,
      userAddress,
      countries: respData.countries.map((x) => ({
        label: x.name,
        value: x.id.toString(),
      })),
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setDefaultFormValues();
  }, [data]);

  if (!user) {
    logger.error('User not found');
    return null;
  }

  const onChangeCountry = (countryCode: string) => {
    getStates(countryCode).then((x: { name: string; id: number }[]) => {
      setUserStates(x.map((s) => ({ label: s.name, value: s.id.toString() })));
    });

    setValue('userCountry', countryCode);
    setValue('userState', '');
  };

  const handleUpdateProfile: SubmitHandler<FormData> = async (formData: FormData) => {
    setProcessLoader(true);

    const context = {
      patient: { ...data.patient, dob: formData.patientDob },
      user: {
        ...data.user,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        address: `${formData.address_line_1}$$$$${formData.address_line_2}`,
        city: formData.city,
        state_id: parseInt(formData.userState, 10),
        country_id: parseInt(formData.userCountry, 10),
        zip_code: formData.postcode,
      },
    };

    try {
      const resp = await UserService.updateUserProfile(context);
      if (resp.status !== 200) {
        throw new Error(`HTTP status code: ${resp.status}`);
      }
      toast.success('Profile Details Updated.');
      AuthService.updatePhone(data.user.phone);
      history.push(user.role_id === USER_ROLES.patient ? '/home' : '/orders');
    } catch (e) {
      toast.error('Profile Details not updated. Please try again later.');
      logger.error('Profile Details not updated. Please try again later.', e);
      setProcessLoader(false);
    }

    forceUpdate();
  };

  return (
    <PageContainer className="profile-details">
      {data.loading ? (
        <CircleLoader />
      ) : (
        <>
          <ContentContainer>
            <h3 className="form-heading">Profile details</h3>

            <Divider className="form-divider" />

            <form
              className="form-container col-sm-12 col-lg-9 px-0"
              onSubmit={handleSubmit(handleUpdateProfile)}
              data-private
            >
              <div className="form-row">
                <div className={`text-field col-sm-12 ${data.patient ? 'col-md-4' : 'col-md-6'}`}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                      <TextField
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        label="First Name"
                        error={!!error}
                        helperText={error?.message}
                        disabled
                      />
                    )}
                  />
                </div>

                <div className={`text-field col-sm-12 ${data.patient ? 'col-md-4' : 'col-md-6'}`}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                      <TextField
                        disabled
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        label="Last Name"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </div>

                {data.patient && (
                  <div className="date-picker w-100 col-sm-12 col-md-4">
                    <Controller
                      name="patientDob"
                      control={control}
                      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                        <DatePicker
                          disabled
                          value={value}
                          inputRef={ref}
                          onChange={onChange}
                          label="Date of Birth"
                          disableFuture
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="form-row">
                <div className="text-field col-sm-12 col-md-6">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, ref } }) => (
                      <TextField value={value} inputRef={ref} label="Email" disabled />
                    )}
                  />
                </div>

                <div className="text-field col-sm-12 col-md-6">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: 'You must enter the phone number',
                      validate: (value) => isValidPhoneNumber(value) || 'Invalid phone number format',
                    }}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                      <TextField
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        label="Phone"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="text-field col-12">
                  {isGooglePlacesEnabled ? (
                    <Controller
                      name="address_line_1"
                      control={control}
                      rules={{
                        required: 'You must enter the street address',
                        validate: (value) =>
                          validateParcelLocker(value) ||
                          'Parcel lockers are not allowed. Please enter a residential or PO Box address.',
                      }}
                      render={({ field: { value } }) => (
                        <LocationInput
                          id="address_line_1"
                          label="Street Address"
                          value={value}
                          name="address_line_1"
                          onSelectedAddress={(addressData) => {
                            setValue('address_line_1', `${addressData.street_number} ${addressData.address}`);
                            setValue('address_line_2', addressData.subpremise);
                            setValue('city', addressData.suburb || addressData.city);
                            setValue('userState', userStates.find((x) => x.label === addressData.state)?.value || '');
                            setValue('postcode', addressData.postcode);
                          }}
                          onReset={() => {
                            setValue('address_line_1', '');
                            setValue('city', '');
                            setValue('userState', '');
                            setValue('postcode', '');
                            setValue('address_line_2', '');
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name="address_line_1"
                      control={control}
                      rules={{
                        required: 'You must enter the street address',
                        validate: (value) =>
                          validateParcelLocker(value) ||
                          'Parcel lockers are not allowed. Please enter a residential or PO Box address.',
                      }}
                      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                        <TextField
                          value={value}
                          inputRef={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          label="Street Address"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="text-field col-12">
                  <Controller
                    name="address_line_2"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        label="Address Line 2"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="text-field col-sm-12 col-md-6">
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField value={value} inputRef={ref} onChange={onChange} onBlur={onBlur} label="City/Suburb" />
                    )}
                  />
                </div>

                <div className="drop-down col-sm-12 col-md-6">
                  <Controller
                    name="userState"
                    control={control}
                    rules={{ required: 'You must select a state' }}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                      <TextField
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        select
                        label="State"
                        error={!!error}
                        helperText={error?.message}
                      >
                        {userStates.map((userState) => (
                          <MenuItem key={userState.value} value={userState.value}>
                            {userState.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="text-field col-sm-12 col-md-6">
                  <Controller
                    name="postcode"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField value={value} inputRef={ref} onChange={onChange} onBlur={onBlur} label="Postcode" />
                    )}
                  />
                </div>

                <div className="drop-down col-sm-12 col-md-6">
                  <Controller
                    name="userCountry"
                    control={control}
                    rules={{ required: 'You must select a country' }}
                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                      <TextField
                        value={value}
                        inputRef={ref}
                        onChange={(e) => {
                          onChangeCountry(e.target.value);
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        select
                        label="Country"
                        error={!!error}
                        helperText={error?.message}
                      >
                        {data.countries.map((country) => (
                          <MenuItem key={country.value} value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </div>
              </div>

              <div className="form-actions">
                {user.role_id === USER_ROLES.patient && (
                  <Button type="button" variant="outlined" color="primary" onClick={() => history.push('/home')}>
                    Back
                  </Button>
                )}

                <LoadingButton loading={processLoader} id="btnSubmit" type="submit" variant="contained" color="primary">
                  <span>Update Profile</span>
                </LoadingButton>
              </div>
            </form>
          </ContentContainer>

          <PapayaComponent />
        </>
      )}
    </PageContainer>
  );
}

export default UserProfileRebrand;
