import { Box, Breakpoint, Stack, styled, Typography } from '@mui/material';

import { ProductFormulation } from '@/types';
import { ClickableTooltip as Tooltip } from '@/ui-library/tooltip/ClickableTooltip';

import ConcessionChip from '../components/ConcessionChip/ConcessionChip';
import FormulationChip from '../components/FormulationChip/FormulationChip';

const BREAKPOINT_TABLET: Breakpoint = 'md';

const ProductRatio = styled(Box)(({ theme }) => ({
  marginTop: 0,
  cursor: 'pointer',
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    marginTop: theme.spacing(6),
  },
}));

const ProductRatioDivider = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    display: 'none',
  },
}));

const ProductRatioName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightMedium,
  display: 'inline-block',
  textDecoration: 'underline',
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    display: 'block',
  },
}));

const ProductRatioValue = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  whiteSpace: 'nowrap',
  display: 'inline',
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    display: 'block',
  },
}));

const ProductRatioContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    gap: theme.spacing(6),
  },
}));

const ProductDetailsContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    gap: theme.spacing(0),
  },
}));

const ChipsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    marginTop: theme.spacing(2),
  },
}));

export const TOOLTIPS = {
  cbd: 'Total cannabidiol (CBD) content. CBD is an active medicinal compound with no psychoactive effects.',
  thc: 'Total tetrahydrocannabinol (THC) content. THC is an active medicinal compound and primary psychoactive cannabinoid.',
};

export type ProductDetailsProps = {
  product: {
    name: string;
    productFormulationName: ProductFormulation | null;
    thcStrengthForDisplay: string | null;
    cbdStrengthForDisplay: string | null;
    isConcession: boolean;
  };
};

export default function ProductDetails({ product }: ProductDetailsProps) {
  const { productFormulationName, name, thcStrengthForDisplay, cbdStrengthForDisplay, isConcession } = product;

  const canRenderRatioThc = Boolean(thcStrengthForDisplay);
  const canRenderRatioCbd = Boolean(cbdStrengthForDisplay);
  const canRenderRatioDivider = canRenderRatioThc && canRenderRatioCbd;

  return (
    <ProductDetailsContainer>
      <Typography variant="subtitle1" component="h1" color="primary" fontWeight="bold">
        {name}
      </Typography>

      <ProductRatioContainer sx={{ order: { xs: 2, [BREAKPOINT_TABLET]: 3 } }}>
        {canRenderRatioThc ? (
          <Tooltip arrow title={TOOLTIPS.thc}>
            <ProductRatio>
              <ProductRatioName>THC:</ProductRatioName>
              <ProductRatioValue>&nbsp;{thcStrengthForDisplay}</ProductRatioValue>
            </ProductRatio>
          </Tooltip>
        ) : null}
        {canRenderRatioDivider ? <ProductRatioDivider component="span">|</ProductRatioDivider> : null}
        {canRenderRatioCbd ? (
          <Tooltip arrow title={TOOLTIPS.cbd}>
            <ProductRatio>
              <ProductRatioName>CBD:</ProductRatioName>
              <ProductRatioValue>&nbsp;{cbdStrengthForDisplay}</ProductRatioValue>
            </ProductRatio>
          </Tooltip>
        ) : null}
      </ProductRatioContainer>

      <ChipsContainer sx={{ order: { xs: 3, [BREAKPOINT_TABLET]: 2 } }}>
        {productFormulationName ? <FormulationChip formulation={productFormulationName} /> : null}
        {isConcession ? <ConcessionChip /> : null}
      </ChipsContainer>
    </ProductDetailsContainer>
  );
}
