import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface ErrorDisplay {
  title: string;
  body: string | ReactNode;
}

type ErrorListener = (errorDisplay: ErrorDisplay) => void;

export interface ErrorManagementData {
  addErrorToTop: (error: ErrorDisplay) => void;
  enqueueError: (error: ErrorDisplay) => void;
  removeErrorAtIndex: (index: number) => void;
  removeAllErrors: () => void;
  getErrorAtIndex: (index: number) => ErrorDisplay | null;
  getAllErrors: () => ErrorDisplay[];
  addOnErrorAddedListener: (callback: ErrorListener) => void;
  removeOnErrorAddedListener: (callback: ErrorListener) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

export const ErrorManagementContext = createContext<ErrorManagementData>({
  addErrorToTop: noOp,
  enqueueError: noOp,
  removeErrorAtIndex: noOp,
  removeAllErrors: noOp,
  getErrorAtIndex: () => null,
  getAllErrors: () => [],
  addOnErrorAddedListener: noOp,
  removeOnErrorAddedListener: noOp,
});

interface ErrorManagementProviderProps {
  initialErrors: ErrorDisplay[] | undefined;
}

export function ErrorManagementProvider(props: PropsWithChildren<ErrorManagementProviderProps>) {
  const { children, initialErrors } = props;
  const [errors, setErrors] = useState<ErrorDisplay[]>(initialErrors || []);
  const [errorListeners, setErrorListeners] = useState<ErrorListener[]>([]);

  const addErrorToTop = useCallback(
    (error: ErrorDisplay) => {
      setErrors((errorDisplays) => [error, ...errorDisplays]);
      errorListeners.forEach((errorListener) => errorListener(error));
    },
    [setErrors, errorListeners],
  );

  const enqueueError = useCallback(
    (error: ErrorDisplay) => {
      setErrors((errorDisplays) => [...errorDisplays, error]);
      errorListeners.forEach((errorListener) => errorListener(error));
    },
    [setErrors, errorListeners],
  );

  const removeErrorAtIndex = useCallback(
    (index = 0) => {
      setErrors((errorDisplays) => {
        const newErrors = [...errorDisplays];
        newErrors.splice(index, 1);
        return newErrors;
      });
    },
    [setErrors],
  );

  const removeAllErrors = useCallback(() => setErrors([]), [setErrors]);

  const getErrorAtIndex = useCallback((index = 0) => errors[index] || null, [errors]);

  const getAllErrors = useCallback(() => errors, [errors]);

  const addOnErrorAddedListener = useCallback(
    (callback: ErrorListener) => {
      if (errorListeners.includes(callback)) {
        return;
      }
      setErrorListeners([...errorListeners, callback]);
    },
    [setErrorListeners, errorListeners],
  );

  const removeOnErrorAddedListener = useCallback(
    (callback: ErrorListener) => {
      const filtered = errorListeners.filter((el) => el !== callback);
      setErrorListeners(filtered);
    },
    [setErrorListeners, errorListeners],
  );

  const value: ErrorManagementData = useMemo(
    () => ({
      addErrorToTop,
      enqueueError,
      removeErrorAtIndex,
      removeAllErrors,
      getErrorAtIndex,
      getAllErrors,
      addOnErrorAddedListener,
      removeOnErrorAddedListener,
    }),
    [
      addErrorToTop,
      enqueueError,
      removeErrorAtIndex,
      removeAllErrors,
      getErrorAtIndex,
      getAllErrors,
      addOnErrorAddedListener,
      removeOnErrorAddedListener,
    ],
  );

  return <ErrorManagementContext.Provider value={value}>{children}</ErrorManagementContext.Provider>;
}

export interface ErrorManagementOptions {
  keepFromPrevious?: boolean;
}

export const useErrorManagement = (options: ErrorManagementOptions = {}) => {
  const errorManagement: ErrorManagementData = useContext(ErrorManagementContext);

  // Clear previous error stack on page mount
  useEffect(() => {
    if (!options.keepFromPrevious) {
      errorManagement.removeAllErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return errorManagement;
};
