import OptionsToggleBar, {
  Option,
  OptionsToggleBarProps,
} from '@/components/design/options-toggle-bar/OptionsToggleBar';
import ProductFormulationsMap from '@/constants/products/productFormulations';
import { ProductFormulation, ProductFormulations } from '@/types';

export interface FormulationFilterProps {
  selectedIds: ProductFormulation[];
  onChange: (newSelection: ProductFormulation[]) => void;
  availableIds?: ProductFormulation[];
}

const size: OptionsToggleBarProps['size'] = 'small';

const allFormulationOptions: Option<ProductFormulation>[] = Object.values(ProductFormulations).map((key) => {
  const { color, displayName, icon: FormulationIcon } = ProductFormulationsMap[key];
  return {
    id: key,
    label: displayName,
    icon: <FormulationIcon fontSize={size} sx={{ color }} />,
  };
});

export function FormulationFilter(props: FormulationFilterProps) {
  const { selectedIds, onChange, availableIds } = props;

  const toggleOptions = allFormulationOptions.filter(
    (formulationOption) => !availableIds || availableIds.includes(formulationOption.id),
  );

  return (
    <OptionsToggleBar
      size={size}
      selectedIds={selectedIds}
      onChange={onChange}
      options={toggleOptions}
      label="Filter by"
      overflowStrategy="scroll"
      showAllOption
    />
  );
}

export default FormulationFilter;
