import { useEffect, useState } from 'react';

import { PatientService } from '@/services/patient.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGetPatientConsultations');

export type Consultation = {
  id: number;
  consultation_code: string;
  start_time: string;
  end_time: string;
  payment_status: string;
  consult_type: string;
  calcom_consultation_uuid?: string;
  ConsultationStatus: {
    name: string;
  };
  ConsultationType: {
    duration_mins: number;
  };
  Doctor?: {
    id: number;
    DoctorUser: {
      first_name: string;
      last_name: string;
    };
  };
  Nurse?: {
    id: number;
    NurseUser: {
      first_name: string;
      last_name: string;
    };
  };
};

export type Patient = {
  id: number;
  doctor_id: number;
  Doctor: {
    id: number;
    calcom_follow_up_consult_event: string;
    DoctorUser: {
      first_name: string;
      last_name: string;
      email: string;
    };
  };
};

export function useGetPatientConsultations(patientID?: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [consultations, setConsultations] = useState<Consultation[]>([]);
  const [patient, setPatient] = useState<Patient>();
  const [hasError, setHasError] = useState(false);

  const execute = async () => {
    setIsLoading(true);
    try {
      const res = await PatientService.getPatientConsultations(patientID);
      setConsultations(res.data.Consultations);
      setPatient(res.data.Patient);
    } catch (error) {
      logger.error('Error fetching patient consultations', error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    hasError,
    patient,
    consultations,
    refetch: execute,
  };
}

export default useGetPatientConsultations;
