import axios, { AxiosResponse } from 'axios';

import { OrderStatus, OutOfStockOrders, Pharmacy, Product, ProductInventoryStatus, ReplaceOrderProduct } from '@/types';

import settings from '../constants/constants';

const API_URL = `${settings.url}/order`;

const findOutOfStockOrders = (id: number) => axios.get<OutOfStockOrders>(`${API_URL}/v1/findOutOfStockOrders?id=${id}`);

const getAllActiveAndInactiveProducts = () => axios.get(`${API_URL}/all-products`);

const getAllProducts = () => axios.get<Product[]>(`${API_URL}/product`);

export type PatientExistRefillResponse = {
  found: boolean;
};

const getPatientExistRefill = (userId: number, pIds: number[]) =>
  axios.get<PatientExistRefillResponse>(`${API_URL}/exist-refill/${userId}/${pIds.join(',')}`);

const getPharmacies = async () => axios.get<Pharmacy[]>(`${API_URL}/pharmacy`);

const getOrderStatus = async (): Promise<AxiosResponse<OrderStatus[]>> => axios.get(`${API_URL}/order-status`);

const getOrderStatusBulk = async (): Promise<AxiosResponse<OrderStatus[]>> => axios.get(`${API_URL}/order-status/bulk`);

const getProductsFromInventory = async (pharmacyId: number): Promise<AxiosResponse<ProductInventoryStatus[]>> =>
  axios.post(`${API_URL}/getProductsFromInventory`, { pharmacyId });

const replaceInvoiceOrderProducts = (orderId: number, products: ReplaceOrderProduct[]) =>
  axios.patch(`${API_URL}/v1/${orderId}/replaceInvoiceOrderProducts`, { products });

const getOrderCreditDiscount = (data: unknown) => axios.post(`${API_URL}/getOrderCreditDiscount`, data);

const getOrderForPatientRefill = (userId: string | number, orderId = 0) =>
  axios.get(`${API_URL}/patient-refill/${userId}/${orderId || ''}`);

// eslint-disable-next-line import/prefer-default-export
export const OrderService = {
  findOutOfStockOrders,
  getAllActiveAndInactiveProducts,
  getAllProducts,
  getOrderCreditDiscount,
  getPatientExistRefill,
  getPharmacies,
  getOrderForPatientRefill,
  getOrderStatus,
  getOrderStatusBulk,
  getProductsFromInventory,
  replaceInvoiceOrderProducts,
};
