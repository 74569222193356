import USER_ROLES from '@/constants/userRoles';
import { BRANDS } from '@/types';

/* TODO: Refactor with correct user roles for each menu */
export const mainMenu = [
  {
    id: 1,
    name: 'ONBOARD PATIENT',
    link: '/onboard',
    roleId: [USER_ROLES.doctor],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 2,
    name: 'UPLOAD APPROVAL',
    link: '/approval',
    roleId: [USER_ROLES.doctor],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 3,
    name: 'ORDERS',
    link: '/orders',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: USER_ROLES.ml,
    name: 'PHARMACIES',
    link: '/pharmacy',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 5,
    name: 'PATIENTS',
    link: '/patients',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 6,
    name: 'DOCTORS',
    link: '/doctors',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.ml, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 7,
    name: 'PATIENTS',
    link: '/gp/patients',
    roleId: [USER_ROLES.doctor],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 8,
    name: 'RESOURCES',
    link: '/resources',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 9,
    name: 'STUDY',
    link: '/study',
    roleId: [USER_ROLES.superAdmin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 10,
    name: 'ORDERS',
    link: '/pharmacist/orders',
    roleId: [USER_ROLES.pharmacist],
    brand: BRANDS.MONTU,
  },
  {
    id: 11,
    name: 'INVENTORY',
    link: '/pharmacist/inventory',
    roleId: [USER_ROLES.pharmacist],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 12,
    name: 'Home',
    link: '/home',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 13,
    name: 'Refill',
    link: '/patient/refill',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.MONTU,
  },
  {
    id: 14,
    name: 'Consultations',
    link: '/patient/consultations',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 17,
    name: 'Formulations',
    link: '/product-catalog',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 15,
    name: 'Orders',
    link: '/patient/orders',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.MONTU,
  },
  {
    id: 16,
    name: 'PRODUCT CATALOG',
    link: '/product-catalog',
    roleId: [USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: '18',
    name: 'Prescriptions',
    link: '/patient/prescription',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
  },
] as const;

export const extendedMenu = [
  {
    id: 1,
    name: 'BDMS',
    link: '/bd',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 2,
    name: 'SUPPLIERS',
    link: '/suppliers',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 3,
    name: 'PRODUCTS',
    link: '/products',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 4,
    name: 'PRODUCT CATALOG',
    link: '/product-catalog',
    roleId: [USER_ROLES.superAdmin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 5,
    name: 'INVOICES',
    link: '/invoices',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 6,
    name: 'CREDITS',
    link: '/credits',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 7,
    name: 'STUDY',
    link: '/study',
    roleId: [],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 8,
    name: 'RESOURCES',
    link: '/resources',
    roleId: [USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: 9,
    name: 'FAQs',
    link: '/faq',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
  },
  {
    id: '20',
    name: 'Referral',
    link: '/patient/refer-friend',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
  },
] as const;

export const userMenu = [
  {
    id: 1,
    name: 'Profile',
    link: '/profile-detail',
    roleId: [
      USER_ROLES.superAdmin,
      USER_ROLES.doctor,
      USER_ROLES.patient,
      USER_ROLES.ml,
      USER_ROLES.pharmacist,
      USER_ROLES.admin,
    ],
  },
  {
    id: 2,
    name: 'Password',
    link: '/password/patient',
    roleId: [USER_ROLES.patient],
  },
  {
    id: 3,
    name: 'Password',
    link: '/password/doctor',
    roleId: [USER_ROLES.doctor],
  },
  {
    id: 4,
    name: 'Password',
    link: '/password/admin',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin],
  },
  {
    id: 5,
    name: 'Help',
    link: '/faq',
    roleId: [USER_ROLES.patient],
  },
  {
    id: 6,
    name: 'Logout',
    link: '/logout',
    roleId: [
      USER_ROLES.superAdmin,
      USER_ROLES.doctor,
      USER_ROLES.patient,
      USER_ROLES.ml,
      USER_ROLES.pharmacist,
      USER_ROLES.admin,
    ],
  },
] as const;
