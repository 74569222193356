import { useEffect, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Box, Button, Divider, Modal, Snackbar, styled, Typography } from '@mui/material';

import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import { Logger } from '@/utils/logger';

import { PatientReferralActionType, usePatientReferral } from '../../context/PatientReferral';
import { useGenerateReferralCode } from '../../hooks/useGenerateReferralCode';
import { GoogleAnalyticsEventName } from '../../types';

const logger = new Logger('patientReferral');

const modalData = [
  {
    id: 1,
    value: 'Reveal your unique promo code below.',
  },
  {
    id: 2,
    // eslint-disable-next-line quotes
    value: "Share it with friends and family who could benefit from Alternaleaf's services.",
  },
  {
    id: 3,
    value: 'They save $20 on their initial consultation - only $39 instead of $59.',
  },
];

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 2rem)',
  maxWidth: '340px',
  borderRadius: '4px',
  backgroundColor: theme.palette.background.paper,
  padding: '2rem 1.5rem',
  boxShadow: '0px 11px 15px -7px #00000033',
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
    padding: '2rem 1.9rem',
  },
}));

const ModalHeader = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '1.5rem',
  padding: '1rem 2rem',
  fontWeight: 600,
  color: '#181B25DE',
}));

const ImgContainer = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StepDivider = styled(Divider)(({ theme }) => ({
  height: '10px',
  width: '2px',
  justifyContent: 'center',
  marginLeft: '10px',
  [theme.breakpoints.up('md')]: {
    height: '20px',
  },
}));

const DialogContent = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '0 8',
  gridTemplateColumns: '15% 85%',
  alignItems: 'center',
  gridAutoRows: 'auto 20px',
  [theme.breakpoints.up('md')]: {
    gridAutoRows: 'auto 35px',
    gridTemplateColumns: '8% 92%',
  },
}));

const PromoCodeLabel = styled(Typography)(() => ({
  color: '#181B25DE',
  width: '100%',
  marginTop: '1.5rem',
  marginBottom: '0.5rem',
  letterSpacing: '1px',
  fontWeight: '400',
  border: '1px solid #3004313B',
  padding: '0.5rem',
  borderRadius: '4px',
  textAlign: 'center',
}));

function ModalContent() {
  return (
    <>
      <ImgContainer>
        <img src="/assets/images/word_of_mouth.png" alt="Circuit" />
      </ImgContainer>
      <ModalHeader id="refer-modal-title" variant="h4">
        Share the Care with Alternaleaf
      </ModalHeader>
      <Typography textAlign="center" marginBottom="1.5rem">
        Give your friends and family a $20 discount on their Alternaleaf initial consultation and they’ll only pay $39.
      </Typography>
      {modalData.map((referralStep) => (
        <DialogContent key={referralStep.id}>
          <Avatar
            sx={{
              bgcolor: (theme) => theme.palette.secondary.main,
              height: '24px',
              width: '24px',
              fontSize: '16px',
            }}
          >
            {referralStep.id}
          </Avatar>
          <Typography id="modal-modal-description" fontSize="14px">
            {referralStep.value}
          </Typography>
          {referralStep.id !== modalData.length && <StepDivider orientation="vertical" />}
        </DialogContent>
      ))}
    </>
  );
}

function PromoCodeSection({ promoCode }: { promoCode: string }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();

  const onCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(promoCode);
      setSnackbarOpen(true);
      sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.STC_COPY_TO_CLIPBOARD, {
        event_category: 'stc_promo_code_copy',
        event_label: 'stc_code_copied',
      });
    } catch (err) {
      logger.error('Failed to copy to clipboard');
    }
  };

  return (
    <>
      <PromoCodeLabel data-testid="promo-code">{promoCode}</PromoCodeLabel>
      <Button
        fullWidth
        sx={{
          marginBottom: '1rem',
          boxShadow: '0px 3px 1px -2px #00000033',
          letterSpacing: '1px',
        }}
        onClick={onCopyToClipboard}
      >
        Copy code
      </Button>
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            minWidth: '160px',
          },
        }}
        message="Copied to clipboard!"
      />
    </>
  );
}

function GenerateButton({ isLoading, onClick }: { isLoading: boolean; onClick: () => void }) {
  return (
    <LoadingButton
      fullWidth
      sx={{
        margin: '1rem 0',
        boxShadow: '0px 3px 1px -2px #00000033',
        letterSpacing: '1px',
      }}
      onClick={onClick}
      loading={isLoading}
      variant="contained"
      data-testid="generate-code"
    >
      Generate code
    </LoadingButton>
  );
}

function PatientReferralModal({
  open,
  isReferralCodeCheckLoading,
}: {
  open: boolean;
  isReferralCodeCheckLoading: boolean;
}) {
  const { generateReferralCode, isLoading, isError, isSuccess, data } = useGenerateReferralCode();
  const { promoCode, dispatch } = usePatientReferral();
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();

  const onGenerateCodeClick = async () => {
    await generateReferralCode();
  };

  useEffect(() => {
    if (isSuccess && data) {
      dispatch({ type: PatientReferralActionType.SET_PROMO_CODE, payload: data.promoCode });
    }
  }, [isSuccess, data]);

  const onClose = () => {
    dispatch({ type: PatientReferralActionType.TOGGLE_OPEN });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="patient-referral-modal"
      aria-describedby="modal used to generate promo codes"
      data-testid="modal-open"
    >
      <ModalContainer>
        <Button
          aria-label="close"
          onClick={onClose}
          variant="text"
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="close-modal-button"
        >
          <CloseIcon />
        </Button>
        <ModalContent />
        {isSuccess || promoCode ? (
          <PromoCodeSection promoCode={promoCode} />
        ) : (
          <>
            <GenerateButton isLoading={isLoading || isReferralCodeCheckLoading} onClick={onGenerateCodeClick} />
            {isError && <Alert severity="error">There was an error generating your referral code</Alert>}
          </>
        )}
        <a
          href="https://www.alternaleaf.com.au/share-terms"
          className="btn"
          style={{
            textDecoration: 'underline',
            fontSize: '10px',
            textAlign: 'center',
            width: '100%',
            color: '#181B2561',
          }}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.STC_TERMS_CONDITIONS, {
              event_category: 'stc_terms_conditions',
              event_label: 'terms_conditions_link',
            })
          }
        >
          View terms & conditions
        </a>
      </ModalContainer>
    </Modal>
  );
}

export default PatientReferralModal;
