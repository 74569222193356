import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import './styles.scss';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '../../components/common/button';

const DateRangeComp = (props) => {
  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [open, setOpen] = useState(props.open);
  // get the target element to toggle
  const refOne = useRef(null);
  const refTwo = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
    return () => {
      document.removeEventListener('keydown', hideOnEscape, true);
      document.removeEventListener('click', hideOnClickOutside, true);
    };
  }, [range]);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
      props.closeCalendar();
    }
  };

  // Hide on outside click
  const hideOnClickOutside = async (e) => {
    // if clicked on exportAll will close calendar and downloads file
    if (refTwo.current && refTwo.current.contains(e.target)) {
      setOpen(false);
      props.closeCalendar();
      props.onChange(
        moment(range[0].startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        moment(range[0].endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      );
      // if clicked outside other than exportAll and Calendar button will close the dropdown
    } else if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
      props.closeCalendar();
    }
  };

  return (
    <>
      {open && (
        <div className="calendarWrap">
          <div ref={refOne}>
            <>
              <DateRange
                classNames="myDatePicker"
                onChange={(item) => setRange([item.selection])}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={range}
                months={1}
                maxDate={
                  moment(range[0].startDate).add(6, 'month').toDate() > new Date()
                    ? new Date()
                    : moment(range[0].startDate).add(6, 'month').toDate()
                }
                direction="horizontal"
                className="calendarElement"
                style={props.style}
              />
            </>
          </div>
          <div ref={refTwo}>
            <Button
              text={props.buttonName ? props.buttonName : 'Export'}
              className="btn btn-md btn-primary float-right w-100"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DateRangeComp;
