import React from 'react';

interface Props {
  width?: string;
  height?: string;
  strokeColor?: string;
  strokeWidth?: string;
}

export default function CurvedUnderline({
  width = '166',
  height = '10',
  strokeColor = '#FF5D2B',
  strokeWidth = '6',
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 166 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7C22.1639 3.12781 135.134 0.379864 162.889 6.99966"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
}
