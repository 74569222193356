import { SyntheticEvent } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  LinkProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { getCreatedDate, getExpiryDate, getInterval, getProductName, getQuantity, Prescription } from './common';
import PrescriptionDosageChip from './PrescriptionDosageChip';
import PrescriptionStatusChip from './PrescriptionStatusChip';

export interface IPrescriptionAccordion {
  prescription: Prescription;
  expanded: boolean | number;
  onChange: (productId: number) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

interface StyledLinkProps extends LinkProps {
  component: typeof ReactRouterLink;
  to: string;
}

const Accordion = styled(MUIAccordion)(() => ({
  border: 'none',
  boxShadow: 'none',
}));

const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
  '&.MuiAccordionSummary-root': {
    paddingLeft: theme.spacing(4),
  },
  '&.Mui-expanded': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const AccordionRow = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  flexDirection: 'row',
}));

const DosageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '3px',
  margin: theme.spacing(0, 2, 4, 2),
}));

const StyledLink = styled(Link)<StyledLinkProps>(({ theme }) => ({
  color: theme.palette.info.main,
  textDecorationColor: theme.palette.info.main,
  fontFamily: theme.typography.body1.fontFamily,
}));

export function PrescriptionAccordion({ prescription, expanded, onChange }: IPrescriptionAccordion) {
  return (
    <Accordion
      disableGutters
      expanded={expanded === prescription.productId}
      onChange={onChange(prescription.productId)}
      data-testid="prescription-accordion"
    >
      <AccordionHeader expandIcon={<ExpandMoreIcon />} aria-controls="prescription-content" id="prescription-content">
        <Typography>{getProductName(prescription)}</Typography>
      </AccordionHeader>
      <AccordionDetails
        sx={{
          paddingX: 0,
        }}
      >
        <AccordionRow justifyContent="space-between">
          <Typography>Created </Typography>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>
            {getCreatedDate(prescription)}
          </Typography>
        </AccordionRow>
        <Divider />
        <AccordionRow justifyContent="space-between">
          <Typography>Expiry </Typography>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>
            {getExpiryDate(prescription)}
          </Typography>
        </AccordionRow>
        <Divider />

        <AccordionRow gap="1rem" alignItems="center" marginTop={(theme) => theme.spacing(1)}>
          <Typography>Status </Typography>
          <PrescriptionStatusChip prescription={prescription} size="small" />
        </AccordionRow>
        <Divider />
        {(prescription.thcStrength || prescription.cbdStrength) && (
          <AccordionRow gap="0.5rem" marginY={(theme) => theme.spacing(1)}>
            {prescription.thcStrength && <PrescriptionDosageChip dosage="THC" strength={prescription.thcStrength} />}
            {prescription.cbdStrength && <PrescriptionDosageChip dosage="CBD" strength={prescription.cbdStrength} />}
          </AccordionRow>
        )}
        <DosageContainer>
          <Typography fontWeight={600}>Dosage </Typography>
          <Typography
            paddingTop={(theme) => theme.spacing(2)}
            fontSize={(theme) => theme.typography.fontSize}
            lineHeight="1.5rem"
          >
            {prescription?.dosage || '-'}
          </Typography>
        </DosageContainer>
        <Divider />
        <AccordionRow justifyContent="space-between">
          <Typography>Max quantity per repeat</Typography>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>{getQuantity(prescription)}</Typography>
        </AccordionRow>
        <Divider />
        <AccordionRow justifyContent="space-between">
          <Typography>Interval time</Typography>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>{getInterval(prescription)}</Typography>
        </AccordionRow>
        <Divider />
        <AccordionRow justifyContent="space-between">
          <Typography>View product</Typography>
          <StyledLink component={ReactRouterLink} to={`/product-detail/${prescription.productId}`}>
            View
          </StyledLink>
        </AccordionRow>
      </AccordionDetails>
    </Accordion>
  );
}

export default PrescriptionAccordion;
