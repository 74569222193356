import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Button from '../common/button';
import { SupplierService } from '@/services/supplier.service';
import { getSupplierRelatedProducts } from '@/services/product.service';
import { OrderService } from '@/services/order.service';
import FormInput from '../common/FormInput';
import { useHistory, useParams } from 'react-router-dom';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import settings from '@/constants/constants';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Logger } from '@/utils/logger';

const logger = new Logger('supplierOnboarding');

const SuppliersOnboarding = () => {
  const url = settings.url;
  const { id } = useParams();
  const history = useHistory();
  let active = true;
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    supplierName: '',
    firstName: '',
    lastName: '',
    email: '',
    secondayEmail: '',
    phone: '',
    products: [],
    active: active,
    isMontuBrand: false,
  });

  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getSpecificData = async () => {
    return (await axios.get(url + `/supplier/${id}`)).data;
  };

  useEffect(() => {
    OrderService.getAllActiveAndInactiveProducts().then((data) =>
      setProducts(data.data.map((ele) => ({ value: ele.id, label: ele.name, sku_code: ele.sku_code }))),
    );
    if (id) {
      let supplierProducts = [];
      getSpecificData().then((data) => {
        getSupplierRelatedProducts(id).then((selProductsRes) => {
          supplierProducts = selProductsRes.data.map((pr) => ({
            label: pr.Product.name,
            value: pr.product_id,
            sku_code: pr.Product.sku_code,
          }));
          setFormData({
            supplierName: data.supplier_name,
            firstName: data.Suppliers.first_name,
            lastName: data.Suppliers.last_name,
            email: data.Suppliers.email,
            secondayEmail: data.secondary_email,
            phone: data.Suppliers.phone,
            products: supplierProducts,
            active: data.Suppliers.active,
            isMontuBrand: data.is_montu_brand,
          });
        });
      });
    }
  }, []);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const handleProductChange = (selectedOptions) => {
    setFormData({
      ...formData,
      products: selectedOptions,
    });
  };

  // const handleActive = async () => {
  //   setFormData({
  //     ...formData,
  //     active: !formData.active
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    }

    if (formValid) {
      let user;
      if (id) {
        try {
          user = await SupplierService.updateSupplier(formData, id);
        } catch (e) {
          toast.error('Supplier already exists');
          logger.error('Supplier already exists', e);
        }
        if (user) {
          toast.success('Supplier successfully updated', {});
          history.push('/suppliers');
        }
      }

      if (!id) {
        try {
          user = await SupplierService.onboardSupplier(formData);
        } catch (e) {
          toast.error('Supplier already exists');
          logger.error('Supplier already exists', e);
        }
        if (user) {
          toast.success('Supplier successfully added', {});
          history.push('/suppliers');
        }
      }
    }
  };

  return (
    <div className="container">
      <h3 id="title"> {id ? 'Update Suppliers Profile' : 'Onboarding Suppliers Profile'}</h3>
      <div className="row">
        <h5 className="p-2 ml-3">Suppliers Profile</h5>
        <div className="col-md-12 mt-3">
          <form>
            <div className="col-md-12">
              <div className="form-row">
                <FormInput
                  type="text"
                  name="supplierName"
                  label="Supplier Name"
                  className="col-md-6"
                  value={formData.supplierName}
                  onChange={handleChange}
                  error={validator.current.message('Supplier Name', formData.supplierName, 'required')}
                />
                <FormInput
                  type="text"
                  name="phone"
                  label="Phone"
                  value={formData.phone}
                  placeholder="eg.0343244556"
                  className="col-md-6"
                  onChange={handleChange}
                />
              </div>
              <div className="form-row">
                <FormInput
                  type="text"
                  name="firstName"
                  label="Contact First Name"
                  className="col-md-6"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={validator.current.message('First Name', formData.firstName, 'required')}
                />
                <FormInput
                  type="text"
                  name="lastName"
                  label="Contact Last Name"
                  className="col-md-6"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={validator.current.message('Last Name ', formData.lastName, 'required')}
                />
              </div>
              <div className="form-row">
                <FormInput
                  type="email"
                  name="email"
                  label="Email"
                  value={formData.email}
                  className="col-md-6"
                  onChange={handleChange}
                  error={validator.current.message('Email', formData.email, 'required')}
                />
                <FormInput
                  type="email"
                  name="secondayEmail"
                  label="Secondary Email"
                  value={formData.secondayEmail}
                  className="col-md-6"
                  onChange={handleChange}
                />
              </div>
              <div className="form-row"></div>
              <div className="form-row">
                <div className="mt-3 mb-3">
                  <label htmlFor="isMontuBrandToggle" className="ml-2 checkbox">
                    Is a Montu Brand
                  </label>
                </div>
                <div className="custom-control custom-switch">
                  <div className="form-group col-2 mt-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isMontuBrandToggle"
                      checked={formData.isMontuBrand}
                      onChange={(e) => setFormData({ ...formData, isMontuBrand: e.target.checked })}
                    />
                    <label htmlFor="isMontuBrandToggle" className="ml-3 checkbox custom-control-label"></label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12">
                  <label>Product Selection</label>
                  <Select
                    isMulti
                    name="product"
                    label="Product Selection"
                    className={`${
                      validator.current.message('Product', formData.products, 'required')
                        ? 'rounded dropdown-border'
                        : ''
                    }`}
                    options={products}
                    onChange={handleProductChange}
                    value={formData.products}
                  />
                  {validator.current.message('Product', formData.products, 'required')}
                </div>
              </div>
              {/* <div className="form-row">
                <div className="form-group col-md-6 mt-4 p-3">
                  <label>Active</label>
                  <input type="checkbox" name="active" label="Active" checked={formData.active} className="m-1 ml-3" onChange={(e) => handleActive(e)} />
                </div>
              </div> */}
              <Button
                text={id ? 'Update Suppliers' : 'Onboard Suppliers'}
                type="submit"
                className="btn btn-primary btn-md ml-3 float-right mt-5"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SuppliersOnboarding;
