import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getProductOffering, submitPatientProductRating } from '@/services/product.service';
import { AuthService } from '../../services/authentication.service';
import Button from '../../components/common/button';
import FormInput from '../../components/common/FormInput';
import StarRating from '../../components/common/starRating';
import { ConcessionProductCatalogueTag } from '../../components/concession';
import { FF_PRODUCT_IMAGE_CAROUSEL, FF_USE_SHOPIFY_PRODUCT_IMAGES } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import outofstock from '../../assets/images/outofstock.png';
import editIcon from '../../assets/images/reviewedit.png';
import NewProduct from '../../assets/images/new_product.png';
import Comingsoon from '../../assets/images/coming_soon.png';
import NewlowPrice from '../../assets/images/new_low_price.png';
import getProductImageUrl from '@/utils/getProductImageUrl';
import ProductImagesCarousel from '@/components/products/detail/ProductImagesCarousel';
import USER_ROLES from '@/constants/userRoles';

const ProductOffering = (props) => {
  const history = useHistory();
  const { flags } = useFeatureFlags();
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES] || ffDefaults[FF_USE_SHOPIFY_PRODUCT_IMAGES];
  const useProductImageCarousel = flags[FF_PRODUCT_IMAGE_CAROUSEL] || ffDefaults[FF_PRODUCT_IMAGE_CAROUSEL];

  const [data, setData] = React.useState({
    product: null,
    product_id: props.match.params.id,
    product_review: [],
    user_ratings: null,
    product_rating: 0,
    feature_review: [],
    all_review: [],
  });

  const isConcessionProduct = data.product?.is_concession;

  const user = AuthService.getUser().user;
  const [review, setReview] = React.useState({
    starValue: 0,
    header: '',
    report: '',
  });
  const [loadmore, setLoadMore] = React.useState(10);
  const [reviewDiv, setReviewDiv] = React.useState(false);

  const fetchData = () => {
    getProductOffering(props.match.params.id).then((resp) => {
      if (!resp.data.product || !resp.data.product.PatientProductRatings) {
        return;
      }
      const productReview = resp.data.product.PatientProductRatings;
      const featureReview = productReview.filter((review) => review.show_review);
      const allReview = productReview.filter((review) => !review.show_review);

      // Sort Reviews from Latest Date to oldest
      const sortedReviews = allReview.sort((a, b) => {
        return new Date(b.modified_date) - new Date(a.modified_date);
      });

      setData({
        product: resp.data.product,
        product_rating: Number(resp.data.product_rating).toFixed(1),
        product_review: productReview,
        product_id: props.match.params.id,
        user_ratings: resp.data.patient_rating,
        feature_review: featureReview,
        all_review: sortedReviews,
      });
      if (!resp.data.patient_rating) {
        setReviewDiv(true);
      }
    });
  };

  React.useEffect(() => {
    if (props.match.params.id) {
      fetchData();
    }
  }, [props.match.params.id]);

  const [ratingsubmitted, setRatingSubmitted] = React.useState(false);

  const checkValidations = () => {
    if (user.role_id !== USER_ROLES.patient) {
      toast.warning('Ratings are available only to patients now.');
      return false;
    }
    if (review.starValue === 0 || review.header === '' || review.report === '') {
      toast(
        <div className="m-3">
          <p className="mb-0">Cannot Submit your review as:</p>
          <ul className="ml-2" style={{ listStyle: 'none' }}>
            {review.starValue === 0 && <li>- Product rating cannot be zero.</li>}
            {review.header === '' && <li>- Review Header cannot be empty.</li>}
            {review.report === '' && <li>- Please add some comments.</li>}
          </ul>
        </div>,
        { toastId: 'product-rating-submission', type: 'warning' },
      );
      return false;
    }
    return true;
  };

  const submitRating = async () => {
    const canProceed = checkValidations();
    if (!canProceed) {
      return;
    }
    await submitPatientProductRating({
      patientId: user.id,
      ratingData: {
        productId: data.product.id,
        name: review.header,
        rating: review.starValue,
        remarks: review.report,
        displayReview:
          data && data.user_ratings && data.user_ratings.display_review ? data.user_ratings.display_review : false,
        showReview: data && data.user_ratings && data.user_ratings.show_review ? data.user_ratings.show_review : false,
      },
    });
    setRatingSubmitted(true);
    fetchData('user-id-pane');
  };

  const setUserRatingforUpdate = () => {
    if (ratingsubmitted) {
      setRatingSubmitted(false);
    }
    setReview({
      starValue: data.user_ratings.rating,
      header: data.user_ratings.name,
      report: data.user_ratings.remarks,
    });
    setReviewDiv(true);
    document.getElementById('product-feedback').scrollIntoView();
  };

  const featureReview = data.product_review.filter((review) => review.show_review);
  let loadValue = 10;

  const loadMoreReview = () => {
    loadValue = loadmore + 10;
    setLoadMore(loadValue);
    document.getElementById('review-pane').scrollIntoView();
  };

  const productImages = data.product?.ProductImages?.map((img) => img.image_url) || [];
  return (
    <div
      id="product-detail-head"
      className={
        user.role_id === USER_ROLES.patient
          ? 'container-fluid patient-product-detail-wrapper product-offering d-flex mt-5'
          : 'container-fluid mt-3 product-offering d-flex mt-5'
      }
    >
      {data.product && (
        <div className="row product-offering-wrapper mx-auto position-relative">
          {history.length > 1 && (
            <Button
              text="Back"
              className="btn position-absolute"
              style={{
                width: '65px',
                right: '0',
                zIndex: '9',
                top: '-50px',
                backgroundColor: '#fff',
                color: '#1c4457',
                border: '2px solid #1c4457',
                borderRadius: '5px',
              }}
              onClick={() => {
                history.goBack();
              }}
            />
          )}
          <div className="col-md-4 d-flex px-0-small align-items-center">
            {data.product && (
              <div className={`position-relative ${useProductImageCarousel ? 'w-100' : ''}`}>
                {useShopifyProductImages && useProductImageCarousel && productImages.length > 1 ? (
                  <ProductImagesCarousel images={productImages} />
                ) : (
                  <img
                    src={getProductImageUrl(
                      useShopifyProductImages,
                      data.product.image_url,
                      data.product.ProductImages,
                    )}
                    className="img-fluid mx-auto position-relative"
                    style={{ objectFit: 'cover' }}
                  />
                )}

                <div className="position-product">
                  {data.product && data.product.new_at_lowprice && <img src={NewlowPrice} className="img-fluid" />}
                  {data.product && data.product.new_product && <img src={NewProduct} className="img-fluid" />}
                  {data.product && data.product.coming_soon && <img src={Comingsoon} className="img-fluid" />}
                  {data.product && (data.product.is_out_of_stock || data.product.reasoning_toggle) && (
                    <img src={outofstock} className="img-fluid out-of-stock" />
                  )}
                  {isConcessionProduct && <ConcessionProductCatalogueTag className="img-fluid" sx={{ minWidth: 90 }} />}
                </div>
              </div>
            )}
          </div>
          <div className={`col-md-8 d-flex px-0-small ${useProductImageCarousel ? 'mt-4 mt-lg-0' : ''}`}>
            <div className="des-product-content">
              <StarRating value={data.product_rating} small starCount={data.product_rating} displayOnly />
              <p className="product-head-supplier-name">
                {data.product.Suppliers[0] ? data.product.Suppliers[0].supplier_name : '-'}
              </p>
              <p className="pat-product-name">{data.product.short_name || data.product.name}</p>
              <p className="pat-product-ratio" style={{ color: '#505050', fontSize: '20px', marginBottom: '0' }}>
                THC {data.product.thc_strength_for_display} : CBD {data.product.cbd_strength_for_display}
              </p>
              <p className="pro-description" style={{ color: '#505050', fontSize: '20px', marginBottom: '0' }}>
                ${data.product.price}
              </p>
              <div className="d-flex align-items-center">
                <div className="product-highlights d-flex">
                  {data.product.ProductFormulation.name}
                  <img
                    src={data.product.ProductFormulation.image_url}
                    className="img-fluid ml-1"
                    style={{ width: '16px', objectFit: 'contain' }}
                  />
                </div>

                {data.product.size && <div className="product-highlights align-items-center">{data.product.size}</div>}
                {data.product.spectrum_id && (
                  <div className="product-highlights align-items-center">
                    {data.product.spectrum_id === 1 ? 'Broad-Spectrum' : 'Full-Spectrum'}
                  </div>
                )}
              </div>
              <p className="mt-3 pro-description">{data.product.description_for_display}</p>

              {data.product.reasoning_toggle && (
                <p className="mt-3 pro-description out-of-stock-text" style={{ color: 'red' }}>
                  {'Out of Stock: ' + ' ' + data.product.reasoning}{' '}
                </p>
              )}
            </div>

            <div className="mob-product-content w-100">
              <p className="product-head-supplier-name text-center">
                {data.product.Suppliers[0] ? data.product.Suppliers[0].supplier_name : '-'}
              </p>
              <p className="pat-product-name text-center">{data.product.short_name || data.product.name}</p>
              <StarRating value={data.product_rating} starCount={data.product_rating} small className="text-center" />
              <p className="pat-product-ratio text-center">
                THC {data.product.thc_strength_for_display} : CBD {data.product.cbd_strength_for_display}
              </p>
              <p className="pro-description text-center" style={{ color: '#505050', marginBottom: '0' }}>
                ${data.product.price}
              </p>
              <div className="d-flex align-items-center  justify-content-center">
                <div className="product-highlights d-flex">
                  {data.product.ProductFormulation.name}
                  <img
                    src={data.product.ProductFormulation.image_url}
                    className="img-fluid ml-2"
                    style={{ width: '18px', height: '20px' }}
                  />
                </div>
                {data.product.size && <div className=" product-highlights align-items-center">{data.product.size}</div>}
              </div>
              <p className="mt-3 pro-description text-center">{data.product.description_for_display}</p>
              {data.product.reasoning_toggle && (
                <p className="mt-3 pro-description text-center out-of-stock-text" style={{ color: 'red' }}>
                  {'Out of Stock: ' + ' ' + data.product.reasoning}{' '}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <hr className="w-100 m-0 p-0 mx-auto" />
      <div>
        <h3 className="mt-5 heading">DETAILS</h3>
        <div className="des-detail-view">
          {data.product && (
            <div className="row mx-auto product-detail-wrapper">
              <table className="table details-table">
                <tbody style={{ verticalAlign: 'top' }}>
                  <tr>
                    <td className="pat-detail-table">Brand:</td>
                    <td className="pat-font-sec pat-value-table">
                      {data.product.Suppliers[0] ? data.product.Suppliers[0].supplier_name : '-'}
                    </td>
                    <td className="pat-detail-table">CBD-THC Ratio:</td>
                    <td className="pat-font-sec pat-value-table">{data.product.cbd_thc_ratio}</td>
                  </tr>
                  <tr>
                    <td className="pat-detail-table">Strain:</td>
                    <td className="pat-font-sec pat-value-table">
                      {data.product.ProductStrain ? data.product.ProductStrain.name : '-'}
                    </td>
                    <td className="pat-detail-table">Schedule:</td>
                    <td className="pat-font-sec pat-value-table">
                      {data.product.ProductSchedule ? data.product.ProductSchedule.name : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="pat-detail-table">Category:</td>
                    <td className="pat-font-sec pat-value-table">
                      {data.product.ProductIngredient ? data.product.ProductIngredient.name.split(':')[0] : '-'}
                    </td>
                    <td className="pat-detail-table">Condition used:</td>
                    <td rowSpan="2" className="pat-font-sec pat-value-table">
                      {data.product.conditions ? data.product.conditions.replaceAll('\n', ', ') : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="pat-detail-table">Terpene profile:</td>
                    <td className="pat-font-sec pat-value-table">
                      {data.product.contents ? data.product.contents.replaceAll('\n', ', ') : '-'}
                    </td>

                    <td className="w-10 d-none pat-detail-table">Price:</td>
                    <td className="d-none">
                      <span className="price">
                        $
                        {data.product.size
                          ? `${(Number(data.product.price) / Number(data.product.size.replace(/[^0-9]/gi, ''))).toFixed(
                              2,
                            )}`
                          : data.product.price}
                      </span>
                      {data.product.size && `/${data.product.size.replace(/[^a-z]/gi, '')}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="mob-detail-view">
          {data.product && (
            <div className="row product-detail-wrapper mx-auto">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 px-0-small">
                <table className="table">
                  <tbody style={{ verticalAlign: 'top' }}>
                    <tr>
                      <td className="pat-detail-table" style={{ borderTop: '0px solid #fff' }}>
                        Brand:
                      </td>
                      <td className="pat-font-sec">
                        {data.product.Suppliers[0] ? data.product.Suppliers[0].supplier_name : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="pat-detail-table">CBD-THC Ratio:</td>
                      <td className="pat-font-sec">{data.product.cbd_thc_ratio}</td>
                    </tr>
                    <tr>
                      <td className="pat-detail-table">Schedule:</td>
                      <td className="pat-font-sec">
                        {data.product.ProductSchedule ? data.product.ProductSchedule.name : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 px-0-small">
                <table className="table">
                  <tbody style={{ verticalAlign: 'top' }}>
                    <tr>
                      <td className="pat-detail-table">Strain:</td>
                      <td className="pat-font-sec">
                        {data.product.ProductStrain ? data.product.ProductStrain.name : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="pat-detail-table">Category:</td>
                      <td className="pat-font-sec">
                        {data.product.ProductIngredient ? data.product.ProductIngredient.name.split(':')[0] : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="pat-detail-table">Condition used:</td>
                      <td className="pat-font-sec">
                        {data.product.conditions ? data.product.conditions.replaceAll('\n', ', ') : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="pat-detail-table">Terpene profile:</td>
                      <td className="pat-font-sec">
                        {data.product.contents ? data.product.contents.replaceAll('\n', ', ') : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-10 d-none pat-detail-table">Price:</td>
                      <td className="w-50 d-none">
                        <span className="price">
                          $
                          {data.product.size
                            ? `${(
                                Number(data.product.price) / Number(data.product.size.replace(/[^0-9]/gi, ''))
                              ).toFixed(2)}`
                            : data.product.price}
                        </span>
                        {data.product.size && `/${data.product.size.replace(/[^a-z]/gi, '')}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr className="mt-5 w-100 mx-auto" />
      <div>
        <h3 className="mt-5 heading">HOW TO USE</h3>
        <div className="d-flex">
          <ul className="mt-3 mx-auto product-use-list-wrapper py-2" style={{ listStyle: 'none' }}>
            {data.product?.how_to_use &&
              data.product.how_to_use.split('\n').map((x, idx) => (
                <li key={idx} style={{ margin: '10px 0' }}>
                  {x}
                </li>
              ))}
          </ul>
        </div>
      </div>

      <hr className="mt-5 w-100 mx-auto" />
      <div className="mb-5">
        <h3 className="mt-5 heading">REVIEWS</h3>
        <div className="d-flex flex-direction-col">
          <div className="col mx-auto px-0-small">
            <div className="d-flex align-items-center">
              <p className="review-rating-text">{data.product_rating}</p>
              <StarRating value={data.product_rating} starCount={data.product_rating} displayOnly />
              <div className="review-rating-size">
                ({data.user_ratings ? data.product_review.length + 1 : data.product_review.length})
              </div>
            </div>
          </div>

          <div>
            <div className="des-review-list">
              <div style={{ marginBottom: '20px' }}>
                {featureReview.length > 0 && (
                  <div className="review-pane">
                    <div className="d-flex">
                      <StarRating
                        value={featureReview[0].rating}
                        small
                        starCount={featureReview[0].rating}
                        displayOnly
                      />
                      <div>
                        <p className="ml-4 text-white rating-date pat-font-sec">
                          {`${moment(featureReview[0].modified_date).format('MMMM DD, YYYY')} by ${
                            featureReview[0].Patient.PatientUser.first_name
                          } ${featureReview[0].Patient.PatientUser.last_name.charAt(0)}.`}
                        </p>
                      </div>
                      <div className="ml-auto" style={{ color: '#f6981c' }}>
                        <p>Featured Review</p>
                      </div>
                    </div>
                    <h3 className="pat-font-sec font-weight-bold font-bold" style={{ color: '#67c49d' }}>
                      {featureReview[0].name}
                    </h3>
                    <p className="text-white pat-font-sec mb-0" style={{ lineHeight: '35px' }}>
                      {featureReview[0].remarks}
                    </p>
                  </div>
                )}
              </div>
              <div style={{ marginBottom: '20px' }}>
                {data.user_ratings && (
                  <div className="review-pane-your">
                    <div className="d-flex">
                      <StarRating
                        value={data.user_ratings.rating}
                        small
                        starCount={data.user_ratings.rating}
                        displayOnly
                      />
                      <p className="ml-4 text-white rating-date pat-font-sec">
                        {`${moment(data.user_ratings.modified_date).format('MMMM DD, YYYY')} by ${
                          data.user_ratings.Patient.PatientUser.first_name
                        } ${data.user_ratings.Patient.PatientUser.last_name.charAt(0)}.`}
                      </p>
                      <div className="ml-auto" style={{ color: '#194457', right: '0px' }}>
                        Your Review!
                        <img
                          src={editIcon}
                          alt="edit_icon"
                          className="reviewEditIcon"
                          onClick={setUserRatingforUpdate}
                        />
                      </div>
                    </div>
                    <h3 className="pat-font-sec font-weight-bold font-bold" style={{ color: '#194457' }}>
                      {data.user_ratings.name}
                    </h3>

                    <p className="text-white pat-font-sec mb-0" style={{ lineHeight: '35px' }}>
                      {data.user_ratings.remarks}
                    </p>
                  </div>
                )}
              </div>
              <div id="review-pane">
                {data.all_review.slice(0, loadmore).map((review) => (
                  <div className="review-pane-all" style={{ marginBottom: '20px' }} key={review.id}>
                    <div className="d-flex">
                      <StarRating value={review.rating} small starCount={review.rating} displayOnly />
                      <p className="ml-4 rating-date pat-font-sec" style={{ color: '#194457' }}>
                        {`${moment(review.modified_date).format('MMMM DD, YYYY')} by ${
                          review.Patient.PatientUser.first_name
                        } ${review.Patient.PatientUser.last_name.charAt(0)}.`}
                      </p>
                    </div>
                    <h3 className="pat-font-sec font-weight-bold font-bold" style={{ color: '#67c49d' }}>
                      {review.name}
                    </h3>
                    <p className="pat-font-sec mb-0" style={{ color: '#194457', lineHeight: '35px' }}>
                      {review.remarks}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="mob-review-list w-100">
              <div style={{ marginBottom: '20px' }}>
                {featureReview.length > 0 && (
                  <div className="review-pane">
                    <div className="d-block">
                      <div className="ml-auto" style={{ color: '#67c49d' }}>
                        Featured Review
                      </div>
                      <StarRating
                        value={featureReview[0].rating}
                        small
                        starCount={featureReview[0].rating}
                        displayOnly
                      />
                      <div>
                        <p className="text-white rating-date pat-font-sec">
                          {`${moment(featureReview[0].modified_date).format('MMMM DD, YYYY')} by ${
                            featureReview[0].Patient.PatientUser.first_name
                          } ${featureReview[0].Patient.PatientUser.last_name.charAt(0)}.`}
                        </p>
                      </div>
                    </div>
                    <h3 className="pat-font-sec font-weight-bold font-bold" style={{ color: '#67c49d' }}>
                      {featureReview[0].name}
                    </h3>
                    <p className="text-white pat-font-sec mb-0" style={{ lineHeight: '35px' }}>
                      {featureReview[0].remarks}
                    </p>
                  </div>
                )}
              </div>
              <div style={{ marginBottom: '20px' }}>
                {data.user_ratings && (
                  <div className="review-pane-your">
                    <div className="d-block">
                      <div className="ml-auto" style={{ color: '#194457', right: '0px' }}>
                        Your Review!
                        <img
                          src={editIcon}
                          alt="edit_icon"
                          className="reviewEditIcon"
                          onClick={setUserRatingforUpdate}
                        />
                      </div>
                      <StarRating
                        value={data.user_ratings.rating}
                        small
                        starCount={data.user_ratings.rating}
                        displayOnly
                      />
                      <p className="text-white rating-date pat-font-sec">
                        {`${moment(data.user_ratings.modified_date).format('MMMM DD, YYYY')} by ${
                          data.user_ratings.Patient.PatientUser.first_name
                        } ${data.user_ratings.Patient.PatientUser.last_name.charAt(0)}.`}
                      </p>
                    </div>
                    <h3 className="pat-font-sec font-weight-bold font-bold" style={{ color: '#194457' }}>
                      {data.user_ratings.name}
                    </h3>

                    <p className="text-white pat-font-sec mb-0" style={{ lineHeight: '35px' }}>
                      {data.user_ratings.remarks}
                    </p>
                  </div>
                )}
              </div>
              <div id="review-pane">
                {data.all_review.slice(0, loadmore).map((review) => (
                  <div className="review-pane-all" style={{ marginBottom: '20px' }} key={review.id}>
                    <div className="d-block">
                      <StarRating value={review.rating} small starCount={review.rating} displayOnly />
                      <p className="rating-date pat-font-sec" style={{ color: '#194457' }}>
                        {`${moment(review.modified_date).format('MMMM DD, YYYY')} by ${
                          review.Patient.PatientUser.first_name
                        } ${review.Patient.PatientUser.last_name.charAt(0)}.`}
                      </p>
                    </div>
                    <h3 className="pat-font-sec font-weight-bold font-bold" style={{ color: '#67c49d' }}>
                      {review.name}
                    </h3>
                    <p className="pat-font-sec mb-0" style={{ color: '#194457', lineHeight: '35px' }}>
                      {review.remarks}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-5 product-feedback-wrapper mx-auto" id="product-feedback">
            {ratingsubmitted ? (
              <div className="w-100">
                <p className="text-center">Thank you for your review.</p>
              </div>
            ) : (
              <>
                {data.all_review.length >= 10 && data.all_review.length > loadmore && (
                  <div className="w-100" onClick={loadMoreReview}>
                    <p className="text-center">Load more</p>
                  </div>
                )}
                {reviewDiv && (
                  <>
                    <p className="pat-font-sec" style={{ color: '#67c49d', fontSize: '18px' }}>
                      Leave a review to help others learn more about this product
                    </p>
                    <div className="product-feedback-form position-relative">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="text-right pat-font-sec">Rating</label>
                        </div>
                        <div className="col-md-9">
                          <StarRating
                            value={review.starValue}
                            onChange={(e) => setReview({ ...review, starValue: e })}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <label className="text-right mt-3 pat-font-sec">Header</label>
                        </div>
                        <FormInput
                          type="text"
                          name="header"
                          className="col-md-9"
                          value={review.header}
                          onChange={(e) => setReview({ ...review, header: e.target.value })}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-3 pt-2">
                          <label className="pat-font-sec">
                            Share with us how this product helped your medical condition or why you liked the product
                          </label>
                        </div>
                        <FormInput
                          type="text"
                          name="report"
                          className="col-md-9 align-items-center"
                          rows={10}
                          value={review.report}
                          onChange={(e) => setReview({ ...review, report: e.target.value })}
                        />
                      </div>
                      <div className="d-flex">
                        <Button
                          text="Submit"
                          type="submit"
                          className="btn btn-warning  review-button"
                          onClick={submitRating}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductOffering;
