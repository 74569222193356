import { useCallback } from 'react';
import { Box, Radio, styled, Typography, useTheme } from '@mui/material';

import { formatPrice } from './util';

interface RefillDoseOptionProps {
  quantity: number;
  setQuantity: (qty: number) => void;
  maxQuantity: number;
  prescriptionPeriodDays: number;
  pricePerUnit: number;
  isSelected: boolean;
}

interface DoseDisclaimerParams {
  maxQuantity: number;
  prescriptionPeriodDays: number;
}

export const generateMaxDoseDisclaimer = (params: DoseDisclaimerParams) => {
  const { maxQuantity, prescriptionPeriodDays } = params;
  return `Your prescription lets you order a maximum of ${maxQuantity} ${
    maxQuantity === 1 ? 'unit' : 'units'
  } within a ${prescriptionPeriodDays}-day period`;
};

export const generateReducedDoseDisclaimer = (params: DoseDisclaimerParams) => {
  const { prescriptionPeriodDays } = params;
  return `If you purchase a reduced dose you can still order the remainder of your prescribed dose within ${prescriptionPeriodDays} days`;
};

const DoseOptionContainer = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderRadius: theme.shape.borderRadius * 2,
  borderColor: '#C3CCCF',
  columnGap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingRight: theme.spacing(4), // The radio control takes up space on the left. Adjusting withspacing on the right.
  paddingBottom: theme.spacing(4), // The radio control takes up space on the top. Adjusting withspacing on the bottom.
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: `"control header"
  ". content"`,
}));

const DoseOptionControls = styled(Box)(() => ({
  gridArea: 'control',
}));

const DoseOptionContent = styled(Box)(() => ({
  gridArea: 'content',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

const DoseOptionHeader = styled(Box)(() => ({
  gridArea: 'header',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const DoseOptionDisclaimer = styled(Box)(() => ({}));

export function RefillDoseOption(props: RefillDoseOptionProps) {
  const theme = useTheme();
  const { quantity, maxQuantity, prescriptionPeriodDays, pricePerUnit, isSelected, setQuantity } = props;
  const price = formatPrice(pricePerUnit * quantity);

  const isMaxDose = quantity === maxQuantity;
  const label = `${quantity} ${quantity === 1 ? 'unit' : 'units'}`;
  const disclaimer = isMaxDose
    ? generateMaxDoseDisclaimer({ maxQuantity, prescriptionPeriodDays })
    : generateReducedDoseDisclaimer({ maxQuantity, prescriptionPeriodDays });

  const onClick = useCallback(() => {
    setQuantity(quantity);
  }, [setQuantity, quantity]);

  // TODO: Replace hard coded color with semantic colour, once UX resolve the designs.
  return (
    <DoseOptionContainer sx={{ backgroundColor: isSelected ? '#C3CCCF' : theme.palette.background.default }}>
      <DoseOptionControls>
        <Radio checked={isSelected} onChange={onClick} />
      </DoseOptionControls>
      <DoseOptionHeader>
        <Typography>{label}</Typography>
        <Typography>{price}</Typography>
      </DoseOptionHeader>
      <DoseOptionContent>
        <DoseOptionDisclaimer>
          <Typography>{disclaimer}</Typography>
        </DoseOptionDisclaimer>
      </DoseOptionContent>
    </DoseOptionContainer>
  );
}

export default RefillDoseOption;
