import React from 'react';
import '../../scss/patientReferFriend.scss';
import settings from '../../constants/constants';
import AccordionFAQ from './accordionFAQ';
import AccordionTermsAndConditions from './accordionTermsAndConditions';
import { AuthService } from '../../services/authentication.service';
import axios from 'axios';
import { toast } from 'react-toastify';
import Mixpanel from '../../services/mixpanel.service';

const ReferPatient = () => {
  const user = AuthService.getUser().user;
  const [showFAQ, setShowFAQ] = React.useState(true);
  const [showConditionAccordion, setshowConditionAccordion] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [patientData, setPatientData] = React.useState({
    isCopied: false,
    referralCode: '',
  });

  React.useEffect(() => {
    axios
      .get(`${settings.url}/patient/patientReferral/${user.id}`)
      .then((resp) =>
        setPatientData({ ...patientData, referralCode: resp.data.patientReferralCode?.referral_code ?? '' }),
      );
  }, []);
  const copyReferralLinkweb = () => {
    const validateUrl = `${settings.Referal}/refer/${patientData.referralCode}`;
    navigator.clipboard.writeText(
      'Hi! I think you’d be interested in alternaleaf, my plant medicine clinic. Here’s $50 off your initial consultation :-)' +
        '   ' +
        validateUrl,
    );
    setDisable(true);
    setPatientData({
      ...patientData,
      isCopied: true,
    });
    toast.success('Referral link copied successfully');
    Mixpanel('Copy referral link - Referral Landing Page');
    setTimeout(() => {
      setDisable(false);
    }, 10000);
  };
  const copyReferralLinkmobile = () => {
    const validateUrl = `${settings.Referal}/refer/${patientData.referralCode}`;
    navigator.clipboard.writeText(
      'Hi! I think you’d be interested in alternaleaf, my plant medicine clinic. Here’s $50 off your initial consultation :-)' +
        '   ' +
        validateUrl,
    );
    setDisable(true);
    setPatientData({
      ...patientData,
      isCopied: true,
    });
    toast.success('Referral link copied successfully');
    setTimeout(() => {
      setDisable(false);
    }, 10000);
  };
  // const sharePage = () => {
  //   const validateUrl = `${settings.Referal}/refer/${patientData.referralCode}`;
  //   navigator.share({
  //     title: 'Get $50 off your consult with alternaleaf today!',
  //     text: 'Hi! I think you’d be interested in alternaleaf, my plant medicine clinic. Here’s $50 off your initial consultation :-)',
  //     url: validateUrl
  //   });
  // };
  return (
    <React.Fragment>
      <div className="patient-refer-frient-wrapper">
        <div className="row" style={{ background: '#FFFBF6' }}>
          <div className="col-md-5 logoclmbc">
            <img
              className="img-fluid d-none d-md-block logoimg w-75 mx-auto p-0"
              src={settings.assetsUrl + 'logo_final.svg'}
              alt="shareButton"
            ></img>
            <p
              className="refer-head-text-font text-center d-none d-md-block mt-5"
              style={{ fontSize: '25px', color: '#0F455D' }}
            >
              Tell your friends how your <br />
              experience with us has helped you, <br /> and you can both benefit.
            </p>
            <img
              className="img-fluid mx-auto w-100 d-sm-block d-md-none d-block banerimg"
              src={settings.assetsUrl + 'referal_dummy.png'}
              alt="shareButton"
            />
            <div className="row imagetext d-sm-block d-md-none d-block">Give $50, Get $50</div>
          </div>
          <div className="col-md-7">
            <img
              className="img-fluid d-sm-block d-md-none d-block logoimg"
              src={settings.assetsUrl + 'logo_final.svg'}
              alt="shareButton"
            ></img>
            <p className="referalDpara d-sm-block d-md-none d-block">
              Tell your friends how your <br /> experience with us has helped you, <br /> and you can both benefit.
            </p>
            <img
              className="img-fluid mx-auto w-100 d-none d-md-block banerimg"
              src={settings.assetsUrl + 'referal_dummy.png'}
              alt="shareButton"
              style={{ height: '72vh' }}
            />
            <div className="row imagetext d-none d-md-block">Give $50, Get $50</div>
          </div>
        </div>
        <div className="row align-items-center patient-refer-copy-text-wrapper">
          <div className="col-xl-3">
            <h4 className="text-center mt-4 refer-font" style={{ color: '#6FB6C8' }}>
              HOW IT WORKS
            </h4>
          </div>
          <div className="col-xl-3">
            <div className="d-flex justify-content-start mt-4 ">
              <div
                className="bg-white rounded-circle d-flex justify-content-center align-items-center refer-head-text-font"
                style={{ padding: '15px', height: '35px' }}
              >
                1
              </div>
              <p className="text-white ml-2 mb-0 refer-head-text-font">
                Give $50 off your friend’s initial <br /> alternaleaf consultation
              </p>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="d-flex justify-content-start  mt-4 ">
              <div
                className="bg-white rounded-circle d-flex justify-content-center align-items-center refer-head-text-font"
                style={{ padding: '15px', height: '35px' }}
              >
                2
              </div>
              <div>
                <p className="text-white ml-2 pb-0 mb-0 refer-head-text-font">Get $50 credit on your Montu account</p>
                <small className="text-white ml-2 refer-head-text-font">*See full terms below</small>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="d-none d-md-block mx-md-auto share-wrapper">
              <img
                src={settings.assetsUrl + 'arrow.svg'}
                className="img-fluid position-absolute share-arrow"
                alt="copyimgButton"
              />
              <p className="text-right text-white mb-0 refer-head-text-font">Start sharing!</p>
              <button
                className="btn refer-friend-cta d-flex align-items-center justify-content-center mt-1"
                disabled={disable}
                onClick={() => copyReferralLinkweb()}
              >
                <p className="text-center refer-font text-white mb-0">{disable ? 'COPIED' : 'COPY YOUR LINK'}</p>
                <img src={settings.assetsUrl + 'copy.svg'} alt="copyimgButton" className="img-fluid copy-icon"></img>
              </button>
            </div>

            {/* Mobile CTA */}
            <div className="d-sm-block d-md-none mx-auto share-wrapper">
              <img
                src={settings.assetsUrl + 'arrow.svg'}
                className="img-fluid position-absolute share-arrow"
                alt="copyimgButton"
              />
              <p className="text-right text-white mb-0 refer-head-text-font">Get Started !</p>
              <button
                className="btn refer-friend-cta d-flex align-items-center justify-content-center mt-1"
                disabled={disable}
                onClick={() => copyReferralLinkmobile()}
              >
                <p className="text-center refer-font text-white mb-0">{disable ? 'COPIED' : 'COPY YOUR LINK'}</p>
                <img src={settings.assetsUrl + 'copy.svg'} alt="copyimgButton" className="img-fluid copy-icon"></img>
              </button>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: '#6FB6C8' }}>
          <h1 className="d-block mx-auto text-center text-white refer-font pt-5" style={{ fontSize: '17px' }}>
            3 THINGS TO KNOW
          </h1>
          <div className="thinks-content pb-5">
            <div className="row">
              <div className="col-md-4">
                <ul>
                  <li>
                    There is no limit to how many people
                    <br />
                    you can refer.
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>
                    Each $50 credit is automatically <br /> added to your Montu account and <br /> applied to your next
                    order.
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>
                    You'll get $50 credit when your friend
                    <br />
                    receives a prescription.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="p-5 paddingfaq">
          <p
            className="m-0 text-center  text-md-left lead text-font text-centers"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowFAQ(!showFAQ)}
          >
            FREQUENTLY ASKED QUESTIONS
          </p>
          {showFAQ ? <AccordionFAQ /> : ''}
        </div>
        <div className="p-5" style={{ backgroundColor: '#FFFBF6', marginTop: '-3%' }}>
          <p
            className="m-0 text-center text-md-left lead text-font text-centers"
            style={{ cursor: 'pointer', color: ' #004658' }}
            onClick={() => setshowConditionAccordion(!showConditionAccordion)}
          >
            TERMS & CONDITIONS
          </p>
          {showConditionAccordion ? <AccordionTermsAndConditions /> : ''}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReferPatient;
