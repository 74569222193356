import { Check } from '@mui/icons-material';
import { Chip } from '@mui/material';

import constants from '../../../constants/constants';

type OrderStatus = 'delivered' | 'shippedByPharmacy' | 'orderPlaced' | 'cancelled';
type OrderStatusChipProps = {
  status: OrderStatus;
  text?: string;
};
const defaultTexts: Record<OrderStatus, string> = {
  orderPlaced: 'Order placed',
  shippedByPharmacy: 'Shipped by pharmacy',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
};

const statusStyles: Record<
  OrderStatus,
  { variant: 'filled' | 'outlined'; colour: 'default' | 'primary' | 'secondary' | 'success' | 'warning' }
> = {
  orderPlaced: { variant: 'filled', colour: 'default' },
  shippedByPharmacy: { variant: 'filled', colour: 'default' },
  delivered: { variant: 'filled', colour: 'success' },
  cancelled: { variant: 'filled', colour: 'warning' },
};

export const getOrderStatusChipProps = ({
  status,
  delivered,
}: {
  status: number;
  delivered: boolean;
}): OrderStatusChipProps => {
  if (
    status === constants.orderStatus.AWAITING_PAYMENT ||
    status === constants.orderStatus.PAID_NOT_APPROVED ||
    status === constants.orderStatus.APPROVED_NOT_PAID ||
    status === constants.orderStatus.AWAITING_SHIPMENT ||
    status === constants.orderStatus.AWAITING_DISPENSE
  ) {
    return { status: 'orderPlaced' };
  }

  if (status === constants.orderStatus.SHIPPED || status === constants.orderStatus.PHARMACY_DISPENSED) {
    return { status: 'shippedByPharmacy' };
  }

  if (delivered) {
    return { status: 'delivered' };
  }

  if (status === constants.orderStatus.CANCELLED) {
    return { status: 'cancelled' };
  }

  return { status: 'orderPlaced' };
};

export function OrderStatusChip(props: OrderStatusChipProps) {
  const { status, text } = props;
  const { variant, colour } = statusStyles[status];

  return (
    <Chip
      label={text || defaultTexts[status]}
      {...(status === 'delivered' && { icon: <Check /> })}
      variant={variant} // using MUI built-in prop to match with theme
      color={colour}
    />
  );
}

export default OrderStatusChip;
