import { createSvgIcon } from '@mui/material';

/**
 * An icon for an concentrated extract (resin)
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2706-505&m=dev
 *
 * @returns An SvgIcon
 */
export const MgResin = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2706_505"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="4"
      width="20"
      height="16"
    >
      <path d="M2 16.6667V12L8.66667 4H19.3333L22 13.3333L12.6667 20L2 16.6667Z" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_2706_505)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7356 4.00042L16.7947 10.5867L19.8525 14.8677L19.2015 15.3326L16.2776 11.2391L6.35516 18.0281L5.38263 17.7242L16.0053 10.456L16.9275 4.00042H17.7356ZM17.7356 4.00042H19.3333L22 13.3337L19.8525 14.8677L20.0353 15.1236L19.3843 15.5885L19.2015 15.3326L12.6667 20.0004L6.35516 18.0281L3.93559 19.6836L3.48384 19.0233L5.38263 17.7242L2 16.6671V12.0004L8.66667 4.00042H16.9275L17.3137 1.29688L18.1057 1.41001L17.7356 4.00042Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'MgResin',
);

export default MgResin;
