import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@montugroup/design-system';
import { Box, FormControl, MenuItem, Select, styled } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import settings from '@/constants/constants';
import useDownload from '@/hooks/useDownload';
import AuthService from '@/services/authentication.service';
import { SupplierService } from '@/services/supplier.service';

const SUPPLIER_FILTER_OPTIONS = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
];

const ActionsContainer = styled(Box)`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
`;

const ActiveFilterControl = styled(FormControl)`
  min-width: 105px;
  padding-block: 1px;
`;

type SuppliersActionsProps = {
  onActiveFilterChange: (active: number) => void;
};

type SupplierExportData = {
  'Supplier Code': string;
  'Supplier Name': string;
  'First Name': string;
  'Last Name': string;
  Email: string;
  Phone: string;
  'Monthly Order Value': string;
  'Total Order Value': string;
};

function SuppliersActions({ onActiveFilterChange }: SuppliersActionsProps) {
  const history = useHistory();
  const { isDownloading, downloadFile } = useDownload();
  const [active, setActive] = useState(1);

  const handleOnboard = () => {
    history.push('/suppliers/onboard/');
  };

  const handleActiveFilterChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setActive(value);
    onActiveFilterChange(value);
  };

  const handleExport = async () => {
    const { data } = await SupplierService.getSupplierData(active, true);
    const excelData: SupplierExportData[] = data.result.map((item) => ({
      'Supplier Code': item.supplier_code,
      'Supplier Name': item.supplier_name,
      'First Name': item.first_name,
      'Last Name': item.last_name,
      Email: item.email,
      Phone: item.phone,
      'Monthly Order Value': `$${item.montly}`,
      'Total Order Value': `$${item.total}`,
    }));

    const fileName = `${new Date().getTime()}-montu-supplier.xlsx`;
    await downloadFile(fileName, `${settings.url}/excel/ml/download`, {
      method: 'POST',
      data: excelData,
      responseType: 'blob',
    });
  };

  return (
    <ActionsContainer>
      <Button size="small" color="info" onClick={handleOnboard}>
        Onboard new suppliers
      </Button>
      {AuthService.isSuperAdmin() && (
        <Button size="small" color="info" onClick={handleExport}>
          {isDownloading ? 'Downloading...' : 'Export all'}
        </Button>
      )}
      <ActiveFilterControl size="small">
        <Select
          data-testid="supplier-active-filter"
          name="suppliersActive"
          placeholder="Filter by Suppliers"
          value={active}
          onChange={handleActiveFilterChange}
        >
          {SUPPLIER_FILTER_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </ActiveFilterControl>
    </ActionsContainer>
  );
}

export default SuppliersActions;
