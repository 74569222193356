import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import BookConsultationButton from '@/components/common/BookConsultationButton';

import { AlertInfo, ENQUIRY_PRODUCT_TOAST_MESSAGE, TextHeading } from './common';

const BOOK_CONSULTATION_TEXT = 'Book Consultation';

const TextContainer = styled(Box)`
  margin-bottom: 1.5rem;
`;

export default function BookConsultation({
  rescriptRequestDate,
  handleBookingSuccess,
  hasScheduledConsultation,
  hasPatientDoctorChanged,
  canRequestRescript,
  calcomFollowUpConsultEvent,
  doctorEmail,
}: {
  rescriptRequestDate?: string | null;
  hasScheduledConsultation?: boolean;
  handleBookingSuccess: () => void;
  canRequestRescript: boolean;
  calcomFollowUpConsultEvent?: string;
  doctorEmail?: string;
  hasPatientDoctorChanged?: boolean;
}) {
  const disabled = hasScheduledConsultation || (!hasPatientDoctorChanged && !!rescriptRequestDate);
  const theme = useTheme();

  return (
    <Box>
      <TextContainer>
        {!hasScheduledConsultation && (
          <Typography color={disabled ? theme.palette.grey[500] : {}}>
            If you would like to speak to your doctor click 'Book consultation'
          </Typography>
        )}
        {hasScheduledConsultation && (
          <>
            <TextHeading variant="h6">Consultation booked</TextHeading>
            <Typography>{ENQUIRY_PRODUCT_TOAST_MESSAGE.consultationReview}</Typography>
          </>
        )}
      </TextContainer>
      <BookConsultationButton
        buttonProps={{
          fullWidth: true,
          color: 'primary',
          ...(canRequestRescript && !hasScheduledConsultation && { variant: 'outlined' }),
        }}
        bookDoctorConsultationButtonText={BOOK_CONSULTATION_TEXT}
        calcomFollowUpConsultEvent={calcomFollowUpConsultEvent}
        disabled={disabled}
        handleBookingSuccess={handleBookingSuccess}
        doctorEmail={doctorEmail}
      />

      {!hasScheduledConsultation && (
        <AlertInfo
          sx={{
            border: 'none',
            padding: '0',
            marginTop: '0',
            ...(disabled && {
              color: theme.palette.grey[500],
              '& svg': {
                color: theme.palette.grey[500],
              },
            }),
          }}
        >
          {ENQUIRY_PRODUCT_TOAST_MESSAGE.consultationReview}
        </AlertInfo>
      )}
    </Box>
  );
}
