/* eslint-disable no-console */
import React from 'react';

import DefaultLogInPage from '@/components/auth/login/DefaultLogin';
import PatientLogin from '@/components/auth/login/PatientLogin';
import { LoginType } from '@/components/auth/login/types';
import PharmacistSignInForm from '@/components/auth/pharmacistSignIn';
import USER_ROLES from '@/constants/userRoles';

export interface SignInFormData {
  email: string;
  password: string;
  rePassword: string;
  remember: boolean;
}

interface SignInPageProps {
  match: {
    params: { [key: string]: string };
  };
}

export function SignInPage(props: SignInPageProps) {
  const { match } = props;

  const loginType = (match?.params?.loginType as LoginType) || 'patient';
  const onBoardKey = match?.params?.onBoardKey || null;

  const userRolesKeys = Object.keys(USER_ROLES);
  if (!userRolesKeys.includes(loginType)) {
    return <PatientLogin onBoardKey={onBoardKey} />;
  }

  if (loginType === 'pharmacist') {
    return <PharmacistSignInForm {...props} />;
  }

  if (loginType === 'patient') {
    return <PatientLogin onBoardKey={onBoardKey} />;
  }

  return <DefaultLogInPage onBoardKey={onBoardKey} loginType={loginType} />;
}

export default SignInPage;
