import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, styled, Typography } from '@mui/material';

import { IGetPatientSecondaryProfiles, IPatientSecondaryProfile } from '@/services/patientMerge.service';

const SecondaryProfileContainer = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  padding: '1rem',
  border: `1px solid ${theme.palette.grey[500]}`,
}));

const SecondaryProfilePatientCode = styled(Typography)(({ theme }) => ({
  borderRadius: '6px',
  padding: '0.5rem',
  marginBottom: '0.25rem',
  border: `1px solid ${theme.palette.grey[300]}`,
}));

interface ISecondaryProfiles {
  isLoading: boolean;
  errorMessage: string;
  isValidPatientCode: boolean;
  patientCode: string;
  onSecondaryButtonClick: () => void;
  data: IGetPatientSecondaryProfiles | null | undefined;
  isError: boolean;
}

function SecondaryProfiles({
  isLoading,
  errorMessage,
  isValidPatientCode,
  patientCode,
  onSecondaryButtonClick: onClick,
  data,
  isError,
}: ISecondaryProfiles) {
  const queryClient = useQueryClient();
  const handleOnResetClick = () => {
    queryClient.setQueryData('getSecondaryProfiles', null);
  };

  return (
    <SecondaryProfileContainer>
      <Typography variant="h6" component="h3" marginBottom="1rem" textAlign="center">
        Secondary Profiles
      </Typography>
      {data && patientCode ? (
        <>
          {data?.duplicates?.map((patient: IPatientSecondaryProfile) => (
            <SecondaryProfilePatientCode key={patient.patientCode}>{patient.patientCode}</SecondaryProfilePatientCode>
          ))}
          <Button fullWidth onClick={handleOnResetClick}>
            Clear Profiles
          </Button>
        </>
      ) : (
        <>
          <LoadingButton
            fullWidth
            variant="contained"
            onClick={onClick}
            loading={isLoading}
            disabled={isValidPatientCode || patientCode.length < 9}
          >
            Get Profiles
          </LoadingButton>
          {isError && (
            <Alert
              severity="error"
              sx={{
                marginTop: '0.25rem',
              }}
            >
              {errorMessage}
            </Alert>
          )}
        </>
      )}
    </SecondaryProfileContainer>
  );
}

export default SecondaryProfiles;
