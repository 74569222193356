import { Button, buttonClasses, styled } from '@mui/material';

import envSettings from '@/constants/constants';
import { ErrorDisplay } from '@/context/ErrorManagement';

const phoneNum = envSettings.support.phone.display;

const ErrorBody = styled('span')(({ theme }) => ({
  color: theme.palette.error.contrastText,
  [`& button.${buttonClasses.text}`]: {
    color: theme.palette.error.contrastText,
    textDecoration: 'underline',
    padding: 0,
    minWidth: 'auto',
    verticalAlign: 'top',
  },
  '& a': {
    color: theme.palette.error.contrastText,
    textDecoration: 'underline',
  },
}));

export const PageLoadError: ErrorDisplay = {
  title: 'Page load error',
  body: (
    <ErrorBody>
      Oops! Something went wrong. Please{' '}
      <Button onClick={() => window.location.reload()} variant="text">
        refresh
      </Button>{' '}
      the page. If the problem persists, please call our support team on <a href={`tel:${phoneNum}`}>{phoneNum}</a>
    </ErrorBody>
  ),
};

export default PageLoadError;
