import { createSvgIcon } from '@mui/material';

/**
 * An icon for a vape cartridge
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2677-48930&m=dev
 *
 * @returns An SvgIcon
 */
export const MgCartridge = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.55994 3.94002V3.22001C8.55994 2.90525 8.70813 2.60886 8.95994 2.42001L10.5866 1.2C10.7597 1.07018 10.9702 1 11.1866 1H12.8133C13.0297 1 13.2402 1.07018 13.4133 1.2L15.04 2.42001C15.2918 2.60886 15.44 2.90525 15.44 3.22001V3.94002C15.44 4.21616 15.2161 4.44002 14.94 4.44002H9.05994C8.78379 4.44002 8.55994 4.21616 8.55994 3.94002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05994 5.29956C8.78379 5.29956 8.55994 5.52342 8.55994 5.79956V21.4997C8.55994 22.0519 9.00765 22.4997 9.55994 22.4997H14.44C14.9923 22.4997 15.44 22.0519 15.44 21.4997V5.79956C15.44 5.52342 15.2161 5.29956 14.94 5.29956H9.05994ZM12.0572 10.6773C12.7221 10.6773 13.2612 10.1383 13.2612 9.47332C13.2612 8.80836 12.7221 8.26931 12.0572 8.26931C11.3922 8.26931 10.8532 8.80836 10.8532 9.47332C10.8532 10.1383 11.3922 10.6773 12.0572 10.6773Z"
      fill="currentColor"
    />
  </svg>,
  'MgCartridge',
);

export default MgCartridge;
