import React, { useCallback } from 'react';
import { Done } from '@mui/icons-material';
import {
  Box,
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  ToggleButtonProps,
  Typography,
} from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  whiteSpace: 'nowrap',
}));

const SingleRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const ScrollContainer = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const WrapContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  flexWrap: 'wrap',
  [`&>.${toggleButtonGroupClasses.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    '&> *': {
      whiteSpace: 'pre-wrap',
    },
  },
}));

const SpacedToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& button.${toggleButtonGroupClasses.grouped}`]: {
    transition: 'filter 0.4s',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    gap: theme.spacing(1),
    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.text.primary,
    },
    // Minor Hack: MUI isn't exposing all classes properly, so we're using over-specific selectors to give them more weight, so they always override MUI
    [`&:not(:first-of-type), &:not(:last-of-type), &.${toggleButtonClasses.selected}:not(:first-of-type), &.${toggleButtonClasses.selected}:not(:last-of-type)`]:
      {
        marginLeft: 0,
        border: '1px solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.divider,
      },
    [`&.${toggleButtonClasses.selected}+button.${toggleButtonGroupClasses.grouped}.${toggleButtonClasses.selected}`]: {
      border: '1px solid',
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.divider,
    },
  },
  // Desaturate unselected items
  '&[data-all="false"] [aria-pressed=false]': {
    filter: 'saturate(0)',
  },
}));

const LabelWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type OverflowStrategy = 'wrap' | 'scroll';
const OverflowStrategyMap = new Map<OverflowStrategy, React.FunctionComponent>([
  ['wrap', WrapContainer],
  ['scroll', ScrollContainer],
]);

export interface Option<T extends string = string> {
  id: T;
  label: string;
  icon?: React.ReactNode;
}

export interface OptionsToggleBarProps<T extends string = string> {
  label?: string;
  options: Option<T>[];
  selectedIds: T[];
  showAllOption?: boolean;
  overflowStrategy?: OverflowStrategy;
  onChange: (selectedIds: T[]) => void;
  size?: ToggleButtonProps['size'];
}

export function OptionsToggleBar<T extends string = string>({
  options,
  selectedIds,
  onChange,
  label,
  showAllOption = true,
  overflowStrategy = 'scroll',
  size = 'medium',
}: OptionsToggleBarProps<T>) {
  const ToggleGroupContainer = OverflowStrategyMap.get(overflowStrategy) || ScrollContainer;

  const allIds = options.map((option) => option.id);
  const hasSelectedAll = selectedIds.length === allIds.length;

  const handleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newSelection: T[]) => {
      onChange(newSelection);
    },
    [onChange],
  );

  const handleClickAll = useCallback(() => {
    // Toggle between selecting all, or selecting none if all options are already selected
    if (hasSelectedAll) {
      onChange([]);
    } else {
      onChange([...allIds]);
    }
  }, [onChange, hasSelectedAll]);

  const effectiveSelection = hasSelectedAll ? [] : selectedIds;

  return (
    <Root>
      <SingleRowContainer>
        {Boolean(label) && (
          <LabelWrapper>
            <Typography variant="body2" fontWeight="medium">
              {label}
            </Typography>
          </LabelWrapper>
        )}
        {showAllOption && (
          <ToggleButton
            size={size}
            sx={{
              textTransform: 'none',
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
            value="ALL"
            selected={hasSelectedAll}
            aria-label="All"
            onClick={handleClickAll}
          >
            <Done fontSize={size} />
            All
          </ToggleButton>
        )}
      </SingleRowContainer>
      <ToggleGroupContainer>
        <SpacedToggleGroup
          size={size}
          value={effectiveSelection}
          onChange={handleChange}
          aria-label="Filter by"
          data-all={hasSelectedAll ? 'true' : 'false'}
        >
          {options.map((option) => (
            <ToggleButton
              key={option.id}
              size={size}
              sx={{
                textTransform: 'none',
                fontWeight: (theme) => theme.typography.fontWeightBold,
              }}
              selected={hasSelectedAll || effectiveSelection.includes(option.id)}
              value={option.id}
              aria-label={option.label}
            >
              {option.icon || null}
              {option.label}
            </ToggleButton>
          ))}
        </SpacedToggleGroup>
      </ToggleGroupContainer>
    </Root>
  );
}

export default OptionsToggleBar;
