/* eslint-disable @typescript-eslint/no-explicit-any */
const isEqual = (x: any, y: any): boolean => {
  const ok = Object.keys;
  const typeX = typeof x;
  const typeY = typeof y;

  if (x && y && typeX === 'object' && typeY === typeX) {
    return ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key]));
  }
  return x === y;
};

export default isEqual;
