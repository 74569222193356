import { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import settings from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGenerateReferralCode');

export interface ICheckReferralPayload {
  promoCode?: string;
  isNew?: boolean;
  message?: string;
}

export function useCheckReferralCode() {
  const [catchError, setCatchError] = useState(false);
  const checkReferralCodeQuery = useQuery<ICheckReferralPayload>(
    ['checkReferralCode'],
    async () => {
      try {
        const token = AuthService.getUser()?.token;

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(`${settings.url}/patient/consultations/referrals/check`, {}, config);

        return response.data as ICheckReferralPayload;
      } catch (error) {
        logger.error('Error fetching referral code check:', error);
        setCatchError(true);
        throw new Error(String(error));
      }
    },
    {
      retry: false,
    },
  );

  return {
    isLoading: checkReferralCodeQuery.isLoading,
    isError: checkReferralCodeQuery.isError || catchError,
    isSuccess: checkReferralCodeQuery.isSuccess,
    data: checkReferralCodeQuery.data,
  };
}

export default useCheckReferralCode;
