import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import '../../scss/patientRefill.scss';

interface ToastConfirmModalProps extends React.PropsWithChildren {
  closeToast?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

function ToastConfirmModal({ closeToast, ...props }: ToastConfirmModalProps) {
  const onConfirm = () => {
    if (closeToast) closeToast();
    if (props.onConfirm) props.onConfirm();
  };

  const onCancel = () => {
    if (closeToast) closeToast();
    if (props.onCancel) props.onCancel();
  };

  return (
    <>
      <div>{props.children}</div>
      <div className="custom-popup mt-3">
        <button type="button" className="btn btn-alert" onClick={onConfirm}>
          Yes
        </button>
        <button type="button" className="btn btn-alert ml-2" onClick={onCancel}>
          No
        </button>
      </div>
    </>
  );
}

const toastOptions: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  className: 'custom-toast',
  autoClose: false as const,
  closeOnClick: false,
  toastId: 'popup-confirm-modal',
  closeButton: false,
};

export { ToastConfirmModal, toastOptions };
