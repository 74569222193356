import { ReactNode } from 'react';
import { Alert, AlertProps, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import ProductCard, { ProductCardProduct } from '@/components/products/productCard/ProductCard';

import BookConsultation from './BookConsultation';
import { ENQUIRY_PRODUCT_TOAST_MESSAGE, ProductEnquiryStatus } from './common';
import EnquiryDialog from './EnquiryDialog';
import ProductReplacementFound from './ProductReplacementFound';
import RequestAlternativeProduct from './RequestAlternativeProduct';

export type EnquiryProductContentProps = {
  open: boolean;
  onClose: () => void;
  product: ProductCardProduct;
  handleRequestAlternativeProduct: () => void;
  productEnquiryStatus: ProductEnquiryStatus;
  handleBookingSuccess: () => void;
};

function AlertInfo({ children, sx }: { children: ReactNode } & Pick<AlertProps, 'sx'>) {
  return (
    <Alert variant="outlined" severity="info" sx={{ margin: '0.75rem 0', ...sx }}>
      {children}
    </Alert>
  );
}

const ContentDivider = styled(Divider)`
  margin: 1.5rem 0;
`;

export default function EnquiryProductDialog({
  open,
  onClose,
  product,
  handleRequestAlternativeProduct,
  productEnquiryStatus,
  handleBookingSuccess,
}: EnquiryProductContentProps) {
  const {
    hasScheduledConsultation,
    rescriptActionDate,
    rescriptRequestDate,
    hasPatientDoctorChanged,
    doctor,
    rescriptCreatedWithoutRequest,
  } = productEnquiryStatus;
  const canRequestRescript = !hasPatientDoctorChanged;
  const { isOutOfStock, isGenerativeScripting } = product;

  return (
    <EnquiryDialog open={open} onClose={onClose}>
      <Box>
        <ProductCard
          product={product}
          quantity={0}
          disabled={isOutOfStock || isGenerativeScripting}
          hideCheckEnquiryStatusBtn
        />

        {isGenerativeScripting && <AlertInfo>{ENQUIRY_PRODUCT_TOAST_MESSAGE.discontinued}</AlertInfo>}
        {isOutOfStock && (canRequestRescript || rescriptActionDate) && !hasScheduledConsultation && (
          <AlertInfo>{ENQUIRY_PRODUCT_TOAST_MESSAGE.highDemand}</AlertInfo>
        )}
        {isOutOfStock && !rescriptActionDate && (!canRequestRescript || hasScheduledConsultation) && (
          <AlertInfo>{ENQUIRY_PRODUCT_TOAST_MESSAGE.outOfStock}</AlertInfo>
        )}

        {rescriptCreatedWithoutRequest || rescriptActionDate ? (
          <ProductReplacementFound
            rescriptedProduct={rescriptCreatedWithoutRequest}
            requestActionDate={rescriptActionDate}
            onViewProduct={onClose}
          />
        ) : (
          <>
            {canRequestRescript && !hasScheduledConsultation && (
              <>
                <RequestAlternativeProduct
                  rescriptRequestDate={rescriptRequestDate}
                  handleRequestAlternativeProduct={handleRequestAlternativeProduct}
                />
                <ContentDivider />
              </>
            )}
            <BookConsultation
              rescriptRequestDate={rescriptRequestDate}
              hasScheduledConsultation={hasScheduledConsultation}
              handleBookingSuccess={handleBookingSuccess}
              canRequestRescript={canRequestRescript}
              calcomFollowUpConsultEvent={doctor?.calcom_follow_up_consult_event}
              doctorEmail={doctor?.email}
              hasPatientDoctorChanged={hasPatientDoctorChanged}
            />
          </>
        )}
      </Box>
    </EnquiryDialog>
  );
}
