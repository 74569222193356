import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter , useLocation } from 'react-router-dom';
import axios from 'axios';

import { LaunchDarklyProvider } from './components/featureFlags/FFLoadingStateProvider';
import withFeatureFlagProvider from './utils/withFeatureFlagProvider';
import App from './App';
import ErrorFallback from './ErrorFallback';

axios.defaults.headers.post['Content-Type'] = 'application/json';

function AppWrapper() {
  const location = useLocation();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[location.pathname]}>
      <App />
    </ErrorBoundary>
  );
}

const LDApp = withFeatureFlagProvider(() => (
  <LaunchDarklyProvider>
    <BrowserRouter>
      <AppWrapper />
    </BrowserRouter>
  </LaunchDarklyProvider>
));

ReactDOM.render(<LDApp />, document.getElementById('root'));
