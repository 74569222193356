import { useCallback, useState } from 'react';
import axios from 'axios';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

import type { OnAcceptanceFields, RequestError } from './types';

const logger = new Logger('usePostUserAgreement');

export function usePostUserAgreement() {
  const [error, setError] = useState<RequestError | null>(null);
  const [loading, setLoadingState] = useState(false);

  const makeRequest = useCallback(async (data: OnAcceptanceFields) => {
    setLoadingState(true);

    axios
      .post(`${settings.url}/user/agreement`, data)
      .then(() => {
        setError(null);
      })
      .catch((err) => {
        logger.error(err);
        setError(err);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }, []);

  return {
    error,
    loading,
    makeRequest,
  };
}

export default usePostUserAgreement;
