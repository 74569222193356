import { HighlightOff as HighlightOffIcon, History as HistoryIcon } from '@mui/icons-material';
import {
  Alert,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
} from '@mui/material';

import CalendarPopupButton from '@/components/common/CalendarPopupButton';
import RoundedPaper from '@/components/common/RoundedPaper';
import { lightBlue } from '@/components/products/detail/common';
import { Consultation } from '@/hooks/rest/useGetPatientConsultations';
import { buildCancellationLinks, buildRescheduleLinks, DoctorBookingDetails } from '@/utils/calendar';

import { CONSULTATION_STATUS, getConsultationStatus, getConsultationType, getDate, getPaymentStatus } from './common';

export type ConsultationsTableProps = {
  consultations: Consultation[];
  tablePaginationProps?: TablePaginationProps;
  doctorBookingDetails?: DoctorBookingDetails;
};

function ConsultationsTable({ consultations, tablePaginationProps, doctorBookingDetails }: ConsultationsTableProps) {
  const hasAnyData = consultations.length > 0;
  const hasScheduledConsultation = consultations.some(
    (consult) => consult.ConsultationStatus?.name === CONSULTATION_STATUS.Scheduled,
  );

  const StyledHistoryIcon = styled(HistoryIcon)`
    pointer-events: none;
    color: ${lightBlue};
  `;

  const StyledHighlightOffIcon = styled(HighlightOffIcon)`
    pointer-events: none;
  `;

  return (
    <TableContainer component={RoundedPaper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>I.D.</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Nurse</TableCell>
            <TableCell>Doctor</TableCell>
            <TableCell>Payment Status</TableCell>
            {hasScheduledConsultation && <TableCell>Reschedule/Cancel</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasAnyData && (
            <TableRow>
              <TableCell colSpan={8}>
                <Alert severity="info">No consultations found</Alert>
              </TableCell>
            </TableRow>
          )}
          {consultations.map((consultation) => {
            const cancellationLinks = buildCancellationLinks({
              calcom_consultation_uuid: consultation.calcom_consultation_uuid || null,
            });
            const rescheduleLinks = buildRescheduleLinks({
              calcom_consultation_uuid: consultation.calcom_consultation_uuid || null,
              doctorBookingDetails: doctorBookingDetails || null,
            });

            return (
              <TableRow key={consultation.id}>
                <TableCell>{getConsultationStatus(consultation.ConsultationStatus?.name)}</TableCell>
                <TableCell>{consultation.consultation_code}</TableCell>
                <TableCell>{getDate(consultation.start_time, consultation.end_time).date}</TableCell>
                <TableCell>{getDate(consultation.start_time, consultation.end_time).time}</TableCell>
                <TableCell>{getConsultationType(consultation.consult_type)}</TableCell>
                <TableCell>
                  {consultation.Nurse?.NurseUser.first_name} {consultation.Nurse?.NurseUser.last_name}
                </TableCell>
                <TableCell>
                  {consultation.Doctor?.DoctorUser.first_name} {consultation.Doctor?.DoctorUser.last_name}
                </TableCell>
                <TableCell>{getPaymentStatus(consultation.payment_status)}</TableCell>
                {hasScheduledConsultation && (
                  <TableCell>
                    {consultation.ConsultationStatus?.name === CONSULTATION_STATUS.Scheduled && (
                      <Stack direction="row" spacing={2}>
                        {rescheduleLinks.calcom && (
                          <CalendarPopupButton
                            link={rescheduleLinks.calcom}
                            buttonProps={{ className: 'table-btn', style: { backgroundColor: 'transparent' } }}
                          >
                            <StyledHistoryIcon />
                          </CalendarPopupButton>
                        )}
                        {cancellationLinks.calcom && (
                          <CalendarPopupButton link={cancellationLinks.calcom}>
                            <StyledHighlightOffIcon />
                          </CalendarPopupButton>
                        )}
                      </Stack>
                    )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
        {tablePaginationProps && (
          <TableFooter>
            <TableRow>
              <TablePagination {...tablePaginationProps} />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export default ConsultationsTable;
