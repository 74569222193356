const nameIdToLabelValue = ({ name, id }) => ({ label: name, value: id });
const constantsViewModel = (productData) => ({
  suppliers: productData.suppliers.map((supplier) => ({
    label: supplier.supplier_name,
    value: supplier.id,
  })),
  ingredients: [
    { label: 'None', value: 'approved' },
    ...productData.ingredients.map((x) => ({ label: String(x.name), value: x.id })),
  ],
  schedules: productData.schedules.map(nameIdToLabelValue),
  formulations: productData.formulation.map(nameIdToLabelValue),
  thcs: productData.thc.map(nameIdToLabelValue),
  cbds: productData.cbd.map(nameIdToLabelValue),
  hybrids: productData.hybrid.map(nameIdToLabelValue),
  spectrums: productData.spectrum.map(nameIdToLabelValue),
  strains: productData.strain.map(nameIdToLabelValue),
  rxRange: productData.rxRange.map(nameIdToLabelValue),
});

export default constantsViewModel;
