import React from 'react';
import { Link } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import './signIn.scss';
import { AuthService } from '../../services/authentication.service';
import logo from '../layout/montu.png';
import { toast } from 'react-toastify';
import { changeUser } from '../../services/braze.service';
import identifyUserForLaunchDarkly from '../../utils/identifyUserForLaunchDarkly';
import { Logger } from '@/utils/logger';

const logger = new Logger('PharmacistSignInForm');

const PharmacistSignInForm = (props) => {
  const ldClient = useLDClient();
  const [loading, setLoad] = React.useState(false);
  const [data, setData] = React.useState({
    email: '',
    password: '',
    remember: true,
    loginType: 'pharmacist',
  });

  const validator = new SimpleReactValidator();

  const [errors, setErrors] = React.useState({
    email: validator.message('email', data.email.trim(), 'required|email'),
    password: validator.message('password', data.password, 'required'),
  });

  // use window.location.replace to reload the browser
  // this forces feature flags to re-evaluate based on the
  // logged in user email.
  const loggedIn = AuthService.getUser();
  if (loggedIn && loggedIn.user) {
    window.location.replace('/pharmacist/orders');
  }

  React.useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get('expired')) {
      toast.error('Your session has expired, please login again.', {});
      logger.error('Your session has expired, please login again.');
    }
  }, []);

  const handleUserInput = (prop) => (e) => {
    setData({ ...data, [e.target.name]: prop === 'checkbox' ? e.target.checked : e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        email: validator.message('email', data.email, 'required|email'),
        password: validator.message('password', data.password, 'required'),
      });
      return;
    }
    setLoad(true);

    let user;
    try {
      user = await AuthService.login(data.email.toLowerCase().trim(), data.password, data.remember, data.loginType);
      if (ldClient && user.user) await identifyUserForLaunchDarkly(ldClient, user.user);
    } catch (err) {
      logger.error(err);
      setLoad(false);
    }
    if (user) {
      if (user?.user?.email) {
        changeUser(user.user.email);
      }
      window.location.replace('/pharmacist/orders');
    }
  };
  return (
    <React.Fragment>
      <div className="auth-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 mx-auto mob-signin">
              <div className="container p-3">
                <div>
                  <a href="https://www.montu.com.au" target="blank">
                    <img src={logo} alt="montu" className="p-2 montu-logo" />
                  </a>
                </div>
                <div className="card w-100 shadow-lg auth-border">
                  <div className="row auth-border">
                    <div className="col-md-6">
                      <div className="auth-opacity auth-image-border">
                        <img
                          src="/assets/images/back.png"
                          className={'w-100 auth-image auth-image-border img-fluid'}
                          alt="MONTU"
                        />
                        <div className="centered text-left">
                          A platform for healthcare professionals to manage their medicinal cannabis patients and
                          prescriptions.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 login-response mt-5">
                      <div className="sign-in mt-5">
                        <div className="auth-wrapper mx-auto d-flex justify-content-center">
                          <form className="p-4" data-private method="post">
                            <h2 className="mb-3">Pharmacist Login</h2>
                            <div>
                              <FormInput
                                type="text"
                                name="email"
                                id="useremailthree"
                                label="Your Email"
                                error={errors.email}
                                onChange={handleUserInput('text')}
                                value={data.email}
                              />
                              <FormInput
                                type="password"
                                name="password"
                                id="userpasswordthree"
                                label="Password"
                                error={errors.password}
                                onChange={handleUserInput('text')}
                                value={data.password}
                              />
                            </div>
                            <Button
                              type="submit"
                              text={data.onBoardKey ? 'Register' : 'Login'}
                              id="btnSubmitthree"
                              className="btn btn-primary w-100"
                              loading={loading}
                              onClick={handleSubmit}
                            />
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-between mt-3">
                              <div className="form-check mt-2">
                                <input
                                  type="checkbox"
                                  checked={data.remember}
                                  name="remember"
                                  onChange={handleUserInput('checkbox')}
                                  className="form-check-input"
                                />
                                <label className="sub-text">Remember me</label>
                              </div>
                              <label className="mt-2 ml-3 sub-text">
                                <Link to={`/forgot-password/${data.loginType}`}>Forgot Password?</Link>
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PharmacistSignInForm;
