import axios, { AxiosError, HttpStatusCode } from 'axios';

import settings from '../constants/constants';

interface HasPatientGivenConsentResult {
  requiresConsentSubmission: boolean;
  pendingConsentUuid: string | null;
  requireReload?: boolean; // Used in case an API call fails, requires the user to reload and try again
}

export interface PatientConsentFormData {
  firstName: string;
  lastName: string;
  signature: string;
}

export const fetchConsentStatus = async (userId: number) => {
  try {
    const result = await axios<HasPatientGivenConsentResult>(`${settings.url}/patient/${userId}/consent-status`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return result.data as HasPatientGivenConsentResult;
  } catch (err) {
    // Non-Patients will 403 from the server; only patient need to submit consent
    if ((err as AxiosError)?.response?.status === HttpStatusCode.Forbidden) {
      return {
        requiresConsentSubmission: false,
        pendingConsentUuid: null,
        requireReload: false,
      };
    }
    throw err;
  }
};

type SubmittedData = PatientConsentFormData & { uuid: string | null; userId: number };

export const submitPatientConsent = async (data: SubmittedData) =>
  axios.post(`${settings.url}/patient/submit-consent`, data);
