import { useCallback, useState } from 'react';

import { PaymentService } from '@/services/payment.service';
import { Logger } from '@/utils/logger';

import { PersistedProductDetails } from './persistedOrderDetails';
import { nullToUndefined } from './util';

const logger = new Logger('useShopifyCheckout');

const useShopifyCheckout = (
  orderId: number,
  orderCode: string,
  selectedProducts: PersistedProductDetails[] | undefined,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const placeOrderShopify = useCallback(async () => {
    if (!selectedProducts || selectedProducts.length === 0) {
      throw new Error('No products selected for checkout');
    }

    const productsPayload = selectedProducts.map(({ quantity, id }) => ({
      quantity,
      productId: id,
    }));

    const { checkoutRedirectUrl, error: responseError } = await PaymentService.getCheckoutRedirectUrl(
      productsPayload,
      orderId,
      nullToUndefined(orderCode),
    );

    setIsSubmitting(false);

    if (checkoutRedirectUrl) {
      window.location.replace(checkoutRedirectUrl);
    } else {
      logger.error(responseError || 'Failed to retrieve checkoutUrl');
      throw new Error('Something went wrong, please try again');
    }
  }, [selectedProducts]);

  return { isSubmitting, placeOrderShopify };
};

export default useShopifyCheckout;
