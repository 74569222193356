import axios from 'axios';

import settings from '@/constants/constants';

const API_URL = `${settings.url}/liaison`;

export type MedicalLiaisonRequestPayload = {
  id?: number;
  user_id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state_id: number;
  country_id: number;
  zip_code: string;
  joined_date: string;
  patient_limit: number;
  active: boolean;
};

const onboardMedicalLiaison = (data: MedicalLiaisonRequestPayload) => axios.post(API_URL, data);

const updateMedicalLiaison = (data: MedicalLiaisonRequestPayload) => axios.put(`${API_URL}/${data.user_id}`, data);

export const MedicalLiaisonService = {
  onboardMedicalLiaison,
  updateMedicalLiaison,
};
