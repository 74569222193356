import { Link as RouterLink } from 'react-router-dom';
import { Alert, AlertTitle, Box, CardActionArea, Grid } from '@mui/material';

import FormulationFilter from '@/components/products/components/FormulationFilter/FormulationFilter';
import ProductTile from '@/components/products/components/ProductTile/ProductTile';
import useMedicineInformation from '@/hooks/medicine-information/useMedicineInformation';

import CTASection from './components/CTASection';
import HeadingSection from './components/HeadingSection';
import PageError from './components/PageError';
import PageSkeleton from './components/PageSkeleton';

export default function MedicineInformation() {
  const {
    query: { isLoading, isError },
    filteredProducts,
    getProductCardProps,
    getFormulationFilterProps,
  } = useMedicineInformation();

  if (isLoading) {
    return <PageSkeleton />;
  }

  if (isError) {
    return <PageError />;
  }

  const hasAnyProducts = filteredProducts.length > 0;

  return (
    <Box maxWidth="xl">
      <HeadingSection />
      <CTASection />
      <Box marginBottom={4}>
        <FormulationFilter {...getFormulationFilterProps()} />
      </Box>
      {hasAnyProducts ? (
        <Grid container spacing={2}>
          {filteredProducts.map((product) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={product.id}>
              <CardActionArea>
                <RouterLink to={`/product-detail/${product.id}`}>
                  <ProductTile {...getProductCardProps(product)} />
                </RouterLink>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info" sx={{ maxWidth: 600 }}>
          <AlertTitle>No Products</AlertTitle>
          Please check your filter options, or your prescriptions page.
        </Alert>
      )}
    </Box>
  );
}
