import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Chip, ChipProps, lighten } from '@mui/material';

export type ConcessionChipProps = {
  size?: ChipProps['size'];
};

export default function ConcessionChip({ size }: ConcessionChipProps) {
  return (
    <Chip
      size={size}
      color="default"
      label="Concession"
      variant="outlined"
      icon={<LocalOfferIcon color="info" sx={{ scale: '0.8' }} />}
      sx={{ backgroundColor: (theme) => lighten(theme.palette.info.light, 0.5) }}
    />
  );
}
