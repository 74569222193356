import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Close as CloseIcon, Search as SearchIcon, Tune as TuneIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  Drawer,
  drawerClasses,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  styled,
  Typography,
} from '@mui/material';

import { BRAZE_CONTENT_CARD } from '@/assets/data/enums';
import SinglePageContentCard from '@/components/braze/SinglePageContentCard';
import FullPageContainer from '@/components/common/FullPageContainer';
import PageDivider from '@/components/common/PageDivider';
import { Papaya } from '@/components/common/Papaya';
import { ProductCatalog, ProductFormulation, Supplier } from '@/components/products/detail/types';
import { AvailabilityFilterList, BrandFilterList, TypeFilterList } from '@/components/products/ProductFilterList';
import ProductImageTile from '@/components/products/ProductImageTileRebrand';
import Mixpanel from '@/services/mixpanel.service';
import { productCatalog } from '@/services/product.service';
import { Button, TextField } from '@/ui-library';

interface Availability {
  id: number;
  name: string;
}

interface Rating {
  product_id: number;
  ratingAvg: string;
}

interface Filter {
  formulation: number[];
  brand: number[];
  exclude_oos: boolean;
  search: string;
}

type Anchor = 'right';

export const ContentContainer = styled(Box)`
  display: flex;
  gap: 2rem;
  z-index: 1;
`;

export const Divider = styled(PageDivider)(({ theme }) => ({
  marginBottom: '2rem',
  borderColor: theme.palette.primary.main,
  marginTop: '0.5rem',
  [theme.breakpoints.down('md')]: {
    marginTop: '0.5rem',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '1.5rem',
  [theme.breakpoints.up('md')]: {
    marginBottom: '3.5rem',
  },
}));

export const FilterSection = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    flexBasis: '20%',
    overflow: 'auto',
  },
}));

export const SearchField = styled(TextField)`
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const PapayaContainer = styled(Papaya)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    zIndex: '100',
  },
}));

export const DrawerIconButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '0.25rem',
  alignItems: 'center',
  marginLeft: 'auto',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const DrawerContainer = styled(Drawer)(({ theme }) => ({
  [`.${drawerClasses.paper}`]: {
    width: '100%',
    maxWidth: '25rem',
    padding: '1rem',
    backgroundColor: theme.palette.background.default,
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const DrawerList = styled(List)`
  margin-top: 0.5rem;
`;

function ProductCatalogRebrand() {
  const location = useLocation();
  const history = useHistory();
  const anchor: Anchor = 'right';

  const [drawerState, setDrawerState] = useState({ right: false });
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [products, setProducts] = useState<ProductCatalog[]>([]);
  const [data, setData] = useState<{
    formulations: ProductFormulation[];
    brands: Supplier[];
    loading: boolean;
    ratings: Rating[];
    availability: Availability[];
  }>({
    formulations: [],
    brands: [],
    loading: true,
    ratings: [],
    availability: [{ id: 0, name: 'In-Stock' }],
  });

  const [filters, setFilters] = useState<Filter>({
    formulation: [],
    brand: [],
    exclude_oos: false,
    search: '',
    ...(location.state?.filters ?? {}),
  });

  const { control, setValue } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  });

  function sortData<T>(arr: T[], property: keyof T, customComparator?: (a: T) => number): T[] {
    return arr.sort((a, b) => {
      const textA = String(a[property]).toUpperCase();
      const textB = String(b[property]).toUpperCase();

      if (customComparator) {
        const customComparison = customComparator(a);
        if (customComparison !== 0) {
          return customComparison;
        }
      }

      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search: location.search,
      state: {
        ...location.state,
        filters,
      },
    });

    setValue('search', filters.search);
  }, [filters]);

  useEffect(() => {
    const loadData = async () => {
      const resp = await productCatalog(true);
      setData((prevData) => ({
        ...prevData,
        formulations: sortData(resp.data.formulation, 'name'),
        brands: sortData(resp.data.suppliers, 'supplier_name', (a) =>
          a.supplier_name.toUpperCase() === 'CIRCLE' ? -1 : 1,
        ),
        loading: false,
        ratings: resp.data.ratings,
      }));

      setProducts(resp.data.products);
    };

    loadData();
  }, []);

  const updateFilter = (id: number, filterType: string, name?: string, filterValue?: boolean | string) => {
    const newFilters = { ...filters } as Filter;

    if (filterType === 'formulation' || filterType === 'brand') {
      newFilters[filterType] = [...(newFilters[filterType] as number[]), id];

      const filterChecked = newFilters[filterType].includes(id);

      if (filterValue && !filterChecked) {
        newFilters[filterType].push(id);
      }

      if (!filterValue && filterChecked) {
        newFilters[filterType] = newFilters[filterType].filter((x) => x !== id);
      }

      Mixpanel(`Select By ${filterType === 'formulation' ? 'Type' : 'Brand'}`, { product_name: name });
    } else if (filterType === 'exclude_oos') {
      newFilters[filterType] = filterValue as boolean;
    } else if (filterType === 'search') {
      newFilters[filterType] = filterValue as string;
    }

    setFilters(newFilters);
  };

  const filteredProducts = useMemo(() => {
    let filteredProductsArr = products;
    if (filters.formulation.length > 0) {
      // setting up formulation based filter
      filteredProductsArr = filteredProductsArr.filter((x) => filters.formulation.includes(x.formulation_id as number));
    }
    if (filters.brand.length > 0) {
      // setting up Brand based filter
      filteredProductsArr = filteredProductsArr.filter((x) =>
        filters.brand.some((r) => x.Suppliers.find((sx) => sx.id === r)),
      );
    }
    if (filters.exclude_oos) {
      // Exclude Out of Stock Filter
      filteredProductsArr = filteredProductsArr.filter((x) => !x.is_out_of_stock && !x.reasoning_toggle);
    }
    if (filters.search !== '') {
      // search field filter
      filteredProductsArr = filteredProductsArr.filter((x) => {
        const searchFields = [(x.short_name || x.name).toLowerCase()]; // filtering product name with regard to search field
        if (x.Suppliers.length > 0) {
          // filtering supplier name with regard to search field
          searchFields.push(x.Suppliers.map((s) => s.supplier_name.toLowerCase()).join(''));
        }
        return searchFields.some((r) => r.includes(filters.search.toLowerCase()));
      });
    }
    return filteredProductsArr;
  }, [products, filters]);

  const handleReset = () => {
    setFilters({ formulation: [], brand: [], exclude_oos: false, search: '' });
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
    setSelectedFilter('');
  };

  return (
    <FullPageContainer>
      <PageTitle variant="h5" color="primary" fontWeight="bold">
        Formulations our doctors prescribe
      </PageTitle>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        {filteredProducts.length > 0 && (
          <Typography variant="body2" color="primary" fontWeight="bold">
            {filteredProducts.length} item(s)
          </Typography>
        )}

        <DrawerIconButton startIcon={<TuneIcon color="primary" />} onClick={toggleDrawer(true)} variant="text">
          Filter
        </DrawerIconButton>

        <DrawerContainer anchor={anchor} open={drawerState[anchor]} onClose={toggleDrawer(false)}>
          <Box role="presentation">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button type="button" size="small" variant="contained" color="inherit" onClick={handleReset}>
                Clear Filters
              </Button>

              <IconButton aria-haspopup="true" onClick={toggleDrawer(false)}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>

            {selectedFilter === '' && (
              <DrawerList>
                {data.formulations.length > 0 && (
                  <ListItem disablePadding key="formulations">
                    <ListItemButton
                      onClick={() => {
                        setSelectedFilter('formulations');
                      }}
                    >
                      <Typography variant="h6" color="primary" textAlign="center" width="100%">
                        Type
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )}
                {data.brands.length > 0 && (
                  <ListItem disablePadding key="brands">
                    <ListItemButton
                      onClick={() => {
                        setSelectedFilter('brands');
                      }}
                    >
                      <Typography variant="h6" color="primary" textAlign="center" width="100%">
                        Brand
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding key="availability">
                  <ListItemButton
                    onClick={() => {
                      setSelectedFilter('availability');
                    }}
                  >
                    <Typography variant="h6" color="primary" textAlign="center" width="100%">
                      Availability
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </DrawerList>
            )}

            {selectedFilter !== '' && (
              <Box marginTop="0.5rem">
                <Button
                  type="button"
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={() => {
                    setSelectedFilter('');
                  }}
                >
                  Back
                </Button>

                {selectedFilter === 'formulations' && (
                  <TypeFilterList
                    filter={filters.formulation}
                    filtersArr={data.formulations}
                    onFilterChange={updateFilter}
                  />
                )}
                {selectedFilter === 'brands' && (
                  <BrandFilterList filter={filters.brand} filtersArr={data.brands} onFilterChange={updateFilter} />
                )}
                {selectedFilter === 'availability' && (
                  <AvailabilityFilterList
                    filter={filters.exclude_oos}
                    filtersArr={data.availability}
                    onFilterChange={updateFilter}
                  />
                )}
              </Box>
            )}
          </Box>
        </DrawerContainer>
      </Box>

      <Divider />

      <Box marginBottom={6}>
        <SinglePageContentCard displayType={BRAZE_CONTENT_CARD.PRODUCT_CATALOG} />
      </Box>

      {data.loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <ContentContainer>
          <FilterSection>
            <Button type="button" variant="contained" color="inherit" fullWidth onClick={handleReset}>
              Clear Filters
            </Button>

            <Box>
              <TypeFilterList
                filter={filters.formulation}
                filtersArr={data.formulations}
                onFilterChange={updateFilter}
              />
              <BrandFilterList filter={filters.brand} filtersArr={data.brands} onFilterChange={updateFilter} />
              <AvailabilityFilterList
                filter={filters.exclude_oos}
                filtersArr={data.availability}
                onFilterChange={updateFilter}
              />
            </Box>
          </FilterSection>

          <Box width="100%">
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchField
                  name="search"
                  placeholder="Search"
                  type="search"
                  size="small"
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateFilter(-1, 'search', '', e.target.value);
                    onChange(e);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {filteredProducts.length === 0 ? (
              <Alert severity="info">No Products Available</Alert>
            ) : (
              <Grid
                container
                rowSpacing={{ xs: 10, lg: 15 }}
                columnSpacing={{ xs: 2, md: 4, lg: 6 }}
                columns={{ xs: 2, md: 3, lg: 4, xl: 5 }}
              >
                {filteredProducts.map((ele) => (
                  <Grid item xs={1} key={ele.id}>
                    <ProductImageTile
                      product={ele}
                      type={data.formulations.find((x) => x.id === ele.formulation_id) ?? { image_url: '', name: '' }}
                      rating={
                        data.ratings.length > 0
                          ? data.ratings.find((x) => Number(x.product_id) === ele.id) || { ratingAvg: 0 }
                          : { ratingAvg: 0 }
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </ContentContainer>
      )}

      <PapayaContainer />
    </FullPageContainer>
  );
}

export default ProductCatalogRebrand;
