import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, ButtonProps, Stack, styled, Theme, Typography } from '@mui/material';

import { teal } from '@/components/products/detail/common';

import logo from '../../assets/images/montu_high_resolution.png';

type OrderMedicationCardProps = {
  primaryButtonText?: string;
  primaryButtonLink: string;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
};

interface CustomButtonProps extends ButtonProps {
  primary?: boolean;
  component?: typeof RouterLink;
  to?: string;
}

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'primary',
})<CustomButtonProps>(({ theme, primary }: { theme: Theme; primary?: boolean }) => ({
  height: '2.5rem',
  color: primary ? theme.palette.common.white : theme.palette.text.primary,
  border: primary ? 'none' : '1px solid rgba(24, 27, 37, 0.23)',
  boxShadow: primary ? theme.shadows[1] : 'none',
  backgroundColor: primary ? teal : theme.palette.common.white,
  '&:hover, &:focus, &:active': {
    backgroundColor: primary ? '#102d39' : theme.palette.action.hover,
    color: primary ? theme.palette.common.white : `${theme.palette.text.primary} !important`,
    boxShadow: primary ? theme.shadows[1] : 'none',
  },
}));

const OrderCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, 0.05)',
  boxShadow: theme.shadows[1],
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));

const TextWithLogo = styled(Box)(({ theme }) => ({
  position: 'relative',
  bottom: theme.spacing(2),
  '& img': {
    width: '5.3125rem',
    height: '1.375rem',
    position: 'absolute',
    left: theme.spacing(18),
    bottom: theme.spacing(-0.5),
  },
}));

export function OrderMedicationCard({
  primaryButtonText = 'Create order',
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  showPrimaryButton = true,
  showSecondaryButton = true,
}: OrderMedicationCardProps) {
  return (
    <OrderCard>
      <TextWithLogo>
        <Typography variant="subtitle2" sx={{ color: teal, fontWeight: 600, letterSpacing: 0 }}>
          Order my medication
        </Typography>

        <Typography variant="subtitle2" sx={{ fontSize: '0.875rem', color: teal, marginBottom: '-0.25rem' }}>
          Powered by <img src={logo} alt="Powered by Montu" />
        </Typography>
      </TextWithLogo>
      {showPrimaryButton && (
        <CustomButton variant="contained" component={RouterLink} to={primaryButtonLink} size="large" primary>
          {primaryButtonText}
        </CustomButton>
      )}
      {showSecondaryButton && secondaryButtonText && secondaryButtonLink && (
        <CustomButton variant="contained" component={RouterLink} to={secondaryButtonLink} size="large">
          {secondaryButtonText}
        </CustomButton>
      )}
    </OrderCard>
  );
}

export default OrderMedicationCard;
