import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink, styled } from '@mui/material';
import USER_ROLES from '@/constants/userRoles';
import userIcon from '../../../assets/images/user.png';

import sagedLogo from '../saged.png';

const SagedLink = styled(MuiLink)(({ theme }) => ({
  position: 'relative',
  top: '-2px',
  color: theme.palette.grey[700],
  width: 'fit-content',
  padding: '8px 12px',
  borderRadius: '8px',
  gap: '2px',
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  justifyContent: 'center',
  height: '40px',
  alignItems: 'center',
  fontSize: '.875rem',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
    textDecoration: 'none',
  },
  '& *': {
    zIndex: 1,
  },
}));

const AdminMenu = ({ role_id, patNumber, firstName, gp_code, client_id }) => {
  const shouldRenderSagedLink = (role_id === USER_ROLES.doctor && !client_id) || role_id === USER_ROLES.pharmacist;
  return (
    <>
      {shouldRenderSagedLink && (
        <SagedLink href="https://saged.com.au/" target="_blank" rel="noopener" underline="none">
          <span>Learn with</span>
          <img src={sagedLogo} alt="Saged" />
        </SagedLink>
      )}
      <div
        className={` ${
          role_id === USER_ROLES.patient ? ' drpbtn float-right' : ' float-right p-2 flex align-items-center'
        } ${role_id === USER_ROLES.doctor ? 'no-list-style' : 'navbar-nav'}`}
      >
        <div className="dropdown">
          <button type="button" id="buttonuser" className="button_user_icon" data-bs-toggle="dropdown">
            {role_id === USER_ROLES.patient ? (
              <>
                <div className="desUserIcon">
                  <i className="fa fa-user-circle h5 p-1 "></i>
                  <span className="text-capitalize h5">{patNumber}</span>
                </div>
                <img src={userIcon} alt="user_icon" className="mobUserIcon" />
              </>
            ) : (
              <>
                <i className="fa fa-user-circle h5 p-1"></i>
                <span className="text-capitalize h5">{firstName}</span>
              </>
            )}
          </button>

          <div
            className={`${
              role_id === USER_ROLES.patient
                ? 'dropdown-content dropdown-menu nav-menu ml-5 mb-5'
                : 'dropdown-content dropdown-menu nav-menu'
            }`}
          >
            <span className="nav-item text-left">
              <li>
                <Link to="/profile-detail" className="dropdown-item">
                  <i className="fa fa-account"></i>Profile
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to={`${
                    role_id === USER_ROLES.patient
                      ? '/password/patient'
                      : role_id === USER_ROLES.doctor
                      ? '/password/doctor'
                      : '/password/admin'
                  }`}
                >
                  <i className="fa fa-account"></i>Password
                </Link>
              </li>

              <li className="text-white">
                <Link className="dropdown-item" to="/logout">
                  <i className="fa fa-logout"></i>Logout
                </Link>
              </li>
            </span>
          </div>
          {role_id === USER_ROLES.doctor && <li className="gp-code">{gp_code}</li>}
        </div>
      </div>
    </>
  );
};

export default AdminMenu;
