import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Check as MdCheck } from '@mui/icons-material';
import { SvgIcon, ThemeOptions, Typography, useTheme } from '@mui/material';

import { ProductFormulationFilter, ProductFormulationFilters } from '@/types';
import {
  MgCannabis,
  MgCapsule,
  MgCartridge,
  MgDevice,
  MgInhaler,
  MgOralLiquid,
  MgPastille,
  MgResin,
  MgSpray,
  MgTablets,
  MgTopical,
  MgWafer,
} from '@/ui-library/icons';
import { Logger } from '@/utils/logger';

const logger = new Logger('ProductTypeButton');

export const BaseStyle = ({ theme }: { theme: ThemeOptions }) => css`
  display: inline-flex;
  background-color: ${theme?.palette?.common?.white};
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid ${theme?.palette?.grey?.[300]};
  padding: 0.25rem 0.5rem;

  ${theme?.breakpoints?.values?.md} {
    padding: 0.25rem 0.75rem;
  }

  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  align-items: center;
`;

const StyledButton = styled.button`
  ${BaseStyle}
  &:hover {
    background-color: ${({ theme }) => theme.palette?.grey?.[200]};
  }
`;

const StyledDiv = styled.div`
  ${BaseStyle}
  ${({ isActive, theme }: { isActive: boolean; theme: ThemeOptions }) =>
    isActive ? `background:${theme?.palette?.grey?.[200]}` : null}
`;

const FilterLabel = styled(Typography)`
  margin-top: 2px; // makes text appear more centred in relation to icon
  text-transform: capitalize;
`;

type IconByProductType = Record<
  ProductFormulationFilter,
  { icon?: typeof SvgIcon; color: string; displayName: string }
>;

export const iconMap: IconByProductType = {
  [ProductFormulationFilters.ALL]: {
    icon: MdCheck,
    color: '#41c206',
    displayName: 'All',
  },
  [ProductFormulationFilters.HERB_DRIED]: {
    icon: MgCannabis,
    color: '#64A570',
    displayName: 'Flower',
  },
  [ProductFormulationFilters.INHALATION]: {
    icon: MgCartridge,
    color: '#00465B',
    displayName: 'Inhalation',
  },
  [ProductFormulationFilters.CAPSULE]: {
    icon: MgCapsule,
    color: '#1DBA81',
    displayName: 'Capsule',
  },
  [ProductFormulationFilters.ORAL_LIQUID]: {
    icon: MgOralLiquid,
    color: '#f89a1b',
    displayName: 'Oral liquid',
  },
  [ProductFormulationFilters.PASTILLE]: {
    icon: MgPastille,
    color: '#30e6d3',
    displayName: 'Pastille',
  },
  [ProductFormulationFilters.TABLET_CHEWABLE]: {
    icon: MgTablets,
    color: '#ba2cbf',
    displayName: 'Chewable tablet',
  },
  [ProductFormulationFilters.DEVICE]: {
    icon: MgDevice,
    color: '#406AC9',
    displayName: 'Device',
  },
  [ProductFormulationFilters.SPRAY]: {
    icon: MgSpray,
    color: '#406ac9', // todo real colour
    displayName: 'Spray',
  },
  [ProductFormulationFilters.TOPICAL]: {
    icon: MgTopical,
    color: '#406ac9', // todo real colour
    displayName: 'Topical',
  },
  [ProductFormulationFilters.WAFER]: {
    icon: MgWafer,
    color: '#406ac9', // todo real colour
    displayName: 'Wafer',
  },
  [ProductFormulationFilters.CONCENTRATED_EXTRACT]: {
    icon: MgResin,
    color: '#5C361A',
    displayName: 'Concentrated Extract',
  },
  [ProductFormulationFilters.INHALANT_PRESSURIZED]: {
    icon: MgInhaler,
    color: '#0697D6',
    displayName: 'Inhalant (pressure)',
  },
} as const;

type Props = {
  // emotion.sh requires a prop className so override can work
  className?: string;
  isActive?: boolean;
  fixed?: boolean;
  type: ProductFormulationFilter;
  onClick?: (type: ProductFormulationFilter) => void;
};

export default function ProductTypeButton({ className, isActive = false, fixed = false, type, onClick }: Props) {
  const theme = useTheme();
  const label = iconMap[type]?.displayName;
  const Icon = iconMap[type]?.icon;

  if (!label) {
    logger.error(`ProductTypeButton doesnt exist type ${type}`);
  }

  const textColor = theme.palette?.common?.black ?? 'black';
  const iconColor = isActive || fixed ? iconMap[type]?.color ?? theme.palette?.common.black : theme.palette?.grey[400];
  const Component = isActive || fixed ? StyledDiv : StyledButton;

  return (
    <Component
      data-testid={`productTypeButton-${label}`}
      theme={theme as any}
      className={className}
      data-active={isActive}
      isActive={isActive}
      style={{ color: textColor }}
      onClick={() => onClick && onClick(type)}
    >
      {Icon && (
        <Icon
          sx={{
            display: 'inline-block',
            marginRight: '0.5rem',
            color: iconColor,
            fontSize: '1rem',
          }}
        />
      )}
      <FilterLabel>{label}</FilterLabel>
    </Component>
  );
}
