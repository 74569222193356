import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ToastConfirmModal, toastOptions } from '../../components/common/toastConfirm';
import settings from '../../constants/constants';
import axios from 'axios';
import { AuthService } from '../../services/authentication.service';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import {
  FF_ENABLE_SHOPIFY_CHECKOUT,
  FF_ENABLE_SHOPIFY_DISCOUNTS,
  FF_SHOPIFY_DISCOUNT_SUPER_ADMINS,
} from '@/constants/featureFlags';
import { Logger } from '@/utils/logger';
import { Chip } from '@/ui-library';

const logger = new Logger('patientDiscounts');

const PatientDiscounts = (props) => {
  const { flags } = useFeatureFlags();
  const isShopifyDiscountEnabled = flags[FF_ENABLE_SHOPIFY_DISCOUNTS];
  const redirectToShopify = (shopifyDiscountId) => {
    const { shopifyDiscountsURL, shopifyShippingDiscountAppURL } = settings;
    // only shipping discount available
    window.open(
      `${shopifyDiscountsURL}/${shopifyShippingDiscountAppURL}/${shopifyDiscountId}`,
      '_blank',
      'noopener noreferrer',
    );
  };
  const handleEditModal = (e) => {
    const id = e.target.dataset.key;
    const selected = props.patientDiscounts.find((x) => x.id === Number(id));
    const shopifyDiscountId = selected.PatientDiscountsShopify[0]?.shopify_discount_id;
    if (isShopifyDiscountEnabled && shopifyDiscountId) {
      redirectToShopify(shopifyDiscountId);
      return;
    }
    props.setSelectedDiscount(selected);
    props.setShowModal({
      create: false,
      edit: true,
    });
  };

  const deleteDiscount = async (e) => {
    e.preventDefault();
    const id = e.target.dataset.key;
    const selected = props.patientDiscounts.find((x) => x.id === Number(id));
    const shopifyDiscountId = selected.PatientDiscountsShopify[0]?.shopify_discount_id;
    if (isShopifyDiscountEnabled && shopifyDiscountId) {
      if (isShopifyDiscountEnabled) {
        redirectToShopify(shopifyDiscountId);
        return;
      }
    }

    toast(
      <ToastConfirmModal
        onConfirm={async () =>
          await axios.delete(settings.url + `/discount/delete/${id}`).then((response) => {
            if (response.status === 200) {
              toast.success('Discount removed successfully');
              props.history.push('/patients');
            } else {
              toast.error('Discount could not be removed');
              logger.error('deleteDiscount Discount could not be removed');
            }
          })
        }
      >
        <p>Are you sure you want to delete this?</p>
      </ToastConfirmModal>,
      toastOptions,
    );
  };

  const isSuperAdmin = AuthService.isSuperAdmin() || flags[FF_SHOPIFY_DISCOUNT_SUPER_ADMINS];
  return (
    <React.Fragment>
      {props.patientDiscounts.length > 0 && (
        <>
          <p className="mt-5">Patient Discounts</p>
          <small className="text-muted">
            The discounts listed here are either active or inactive, this does not reflect their usage status.
          </small>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Discount Type</th>
                <th>Reason</th>
                <th>Discount Amount</th>
                <th>Product Name</th>
                <th>Status</th>
                <th>Start Date</th>
                <th>End Date</th>
                {isSuperAdmin && (
                  <>
                    <th>Edit</th>
                    <th>Delete</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {props.patientDiscounts.map((disObj, index) => (
                <tr key={index}>
                  <td>
                    {disObj.DiscountType.name}
                    {disObj.is_concession && <Chip sx={{ ml: 1 }} color="primary" label="concession" size="small" />}
                  </td>
                  <td>{disObj.DiscountReason?.reason}</td>
                  <td>
                    {disObj.discount_percentage ? disObj.discount_percentage + '%' : '$' + disObj.discount_amount}
                  </td>
                  <td>{disObj.Product && disObj.discount_type_id === 3 ? disObj.Product.name : '-'}</td>
                  <td>
                    <Chip
                      sx={{ ml: 1 }}
                      color={disObj.active ? 'success' : 'warning'}
                      label={disObj.active ? 'active' : 'inactive'}
                      size="small"
                    />
                  </td>
                  <td>{disObj.start_date ? moment(disObj.start_date).format('DD-MMM-YYYY') : '-'}</td>
                  <td>{disObj.end_date ? moment(disObj.end_date).format('DD-MMM-YYYY') : '-'}</td>
                  {isSuperAdmin && (
                    <>
                      <td>
                        {disObj.active && (
                          <button
                            className="btn btn-primary btn-md d-block font-weight-bold"
                            disabled={
                              props.downloading ||
                              (disObj.order_limit <= 0 && disObj.order_limit !== null) ||
                              disObj.is_concession
                            }
                            onClick={handleEditModal}
                            data-key={disObj.id}
                            data-old={true}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>
                        {disObj.active && (
                          <button
                            className="btn btn-primary btn-md d-block font-weight-bold"
                            onClick={deleteDiscount}
                            disabled={props.downloading || disObj.is_concession}
                            data-key={disObj.id}
                            data-old={true}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </React.Fragment>
  );
};

export default PatientDiscounts;
