export const numberFormat = (
  value: number,
  locale = 'en-AU',
  options: Partial<Intl.NumberFormatOptions> = {
    style: 'currency',
    currency: 'AUD',
  },
) => new Intl.NumberFormat(locale, options).format(value);

export default {
  numberFormat,
};
