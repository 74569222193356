import { ReactNode } from 'react';
import { Alert, AlertProps, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from '@/ui-library';

export const ENQUIRY_PRODUCT_TOAST_MESSAGE = {
  highDemand: 'This product is unavailable due to high demand',
  outOfStock: (
    <Box>
      This product is out of stock. <br /> Please book a consultation to get a replacement.
    </Box>
  ),
  discontinued: 'This product has been discontinued',
  consultationReview: 'During this consultation the doctor will review all your out of stock items',
};

export function AlertInfo({ children, sx }: { children: ReactNode } & Pick<AlertProps, 'sx'>) {
  return (
    <Alert variant="outlined" severity="info" sx={{ margin: '0.75rem 0', ...sx }}>
      {children}
    </Alert>
  );
}

export const RequestButton = styled(Button)`
  width: 100%;
  margin: 0.75rem 0;
`;

export const RequestButtonFilled = styled(RequestButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export const TextHeading = styled(Typography)`
  margin-bottom: 8px;
`;

export const REQUEST_CONFIRMATION_MESSAGE =
  // eslint-disable-next-line quotes
  "Once approved this request will be sent to your doctor. We will notify you by email once it's been processed.";

export type ProductEnquiryStatus = {
  hasPatientDoctorChanged?: boolean;
  hasScheduledConsultation?: boolean;
  rescriptRequestDate?: string | null;
  rescriptActionDate?: string | null;
  doctor?: {
    calcom_follow_up_consult_event: string;
    email: string;
  };
  rescriptCreatedWithoutRequest?: {
    productName: string;
    rescriptCode: string;
  } | null;
};

export const GENERATIVE = 'GENERATIVE';
export const OUT_OF_STOCK = 'OUT_OF_STOCK';
