import { Box, styled } from '@mui/material';

import { APP_BAR_HEIGHT } from './navbar/navbar.config';

export const MainContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    minHeight: `calc(100dvh - ${APP_BAR_HEIGHT})`,
    marginTop: APP_BAR_HEIGHT,
  },
}));

export const ContentWrapper = styled(Box)<{ removepadding: 'true' | undefined }>(({ theme, removepadding }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: removepadding ? 0 : theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    padding: removepadding ? 0 : theme.spacing(27, 16),
  },
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(27, 16, 20, 16),
  },
}));

export const UmedsMainContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const UmedsContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 32),
  },
}));
