import { PropsWithChildren, useEffect } from 'react';
import { getCalApi } from '@calcom/embed-react';
import type { TooltipProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import settings from '@/constants/constants';
import { Button, ButtonProps } from '@/ui-library';

export type BookingDetails = {
  confirmed: boolean;
  date: string;
  booking: {
    attendees: Array<{
      email: string;
      name: string;
      timeZone: string;
    }>;
    description: string;
    endTime: string;
    startTime: string;
    status: string;
    title: string;
  };
};

const defaultButtonProps: Partial<ButtonProps> = {
  color: 'secondary',
};

interface PrefilledConfig {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export type Props = PropsWithChildren<{
  link: string;
  formData?: PrefilledConfig;
  onOpen?: () => void;
  disabled?: boolean;
  buttonProps?: Omit<ButtonProps, 'children'>;
  tooltipProps?: Omit<TooltipProps, 'children'>; // only present when the button is disabled
  handleBookingSuccess?: (value?: BookingDetails) => void;
  handleRescheduleSuccess?: (value?: BookingDetails) => void;
  handleCancelSuccess?: (value?: BookingDetails) => void;
  testId?: string;
}>;

export default function CalendarPopupButton({
  children,
  link,
  formData,
  onOpen,
  disabled = false,
  buttonProps = {},
  tooltipProps,
  handleBookingSuccess,
  handleRescheduleSuccess,
  handleCancelSuccess,
  testId,
}: Props) {
  const allButtonProps = { ...defaultButtonProps, ...buttonProps, disabled };

  useEffect(() => {
    (async function initCalendar() {
      const cal = await getCalApi(settings.calendarConfig.embedJsUrl);
      cal('ui', { theme: 'light' });
      if (handleBookingSuccess) {
        cal('on', {
          action: 'bookingSuccessful',
          callback: ({ detail }) => {
            handleBookingSuccess(detail.data as BookingDetails);
          },
        });
      }
      if (handleRescheduleSuccess) {
        cal('on', {
          action: 'rescheduleBookingSuccessful',
          callback: ({ detail }) => {
            handleRescheduleSuccess(detail.data as BookingDetails);
          },
        });
      }
      if (handleCancelSuccess) {
        cal('on', {
          action: 'bookingCancelled',
          callback: ({ detail }) => {
            handleCancelSuccess(detail.data as BookingDetails);
          },
        });
      }

      if (typeof onOpen === 'function') {
        cal('on', { action: 'linkReady', callback: onOpen });
      }
    })();
  }, [onOpen, handleBookingSuccess, handleRescheduleSuccess, handleCancelSuccess]);

  const button = (
    <Button
      data-cal-link={link}
      data-cal-config={JSON.stringify(formData ?? {})}
      data-cal-origin={settings.calendarConfig.calOrigin}
      data-testid={testId || 'book-consultation'}
      {...allButtonProps}
    >
      {children}
    </Button>
  );

  if (disabled && tooltipProps?.title) {
    return <Tooltip {...tooltipProps}>{button}</Tooltip>;
  }

  return button;
}
