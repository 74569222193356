import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../common/button';
import Select from 'react-select';
import FilterableTable from 'rc-data-table';
import axios from 'axios';
import CircleLoader from '../common/circleLoader';
import { AuthService } from '@/services/authentication.service';
import { SupplierService } from '@/services/supplier.service';
import editIcon from '../../assets/images/edit.png';

const SuppliersTable = () => {
  const [active, setActive] = useState(1);
  const [TableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    await SupplierService.getSupplierData(active, false).then((data) => setTableData(data.data.result));
  };

  useEffect(() => {
    getData();
    setLoading(false);
  }, [active]);

  const options = [
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Inactive',
      value: 0,
    },
  ];

  const handleActiveState = (e) => {
    setActive(e.value);
  };

  const downloadFile = async () => {
    const data = await SupplierService.getSupplierData(active, true).then((data) => {
      return data.data.result;
    });
    let excelData = [];
    data.forEach((item) => {
      excelData.push({
        'Supplier Code': item.supplier_code,
        'Supplier Name': item.supplier_name,
        'First Name': item.first_name,
        'Last Name': item.last_name,
        Email: item.email,
        Phone: item.phone,
        'Monthly Order Value': `$${item.montly}`,
        'Total Order Value': `$${item.total}`,
      });
    });

    let fileTitle = new Date().getTime() + '-montu-supplier';

    await axios({
      url: 'excel/ml/download',
      method: 'POST',
      data: excelData,
      responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const list = TableData.map((ele) => {
    return {
      id: ele.id,
      supplier_code: ele.supplier_code,
      supplier_name: ele.supplier_name,
      contact_supplier_name: ele.first_name + ' ' + ele.last_name,
      email: ele.email,
      phone: ele.phone,
    };
  });

  const fields = [
    {
      name: 'id',
      displayName: 'id',
      inputFilterable: true,
      sortable: true,
      visible: false,
    },
    {
      name: 'supplier_code',
      displayName: 'Supplier Id',
      inputFilterable: true,
      sortable: true,
      visible: true,
      render: function selectPatient(e) {
        return <Link to={`/suppliers/onboard/${e.record.id}`}>{e.record.supplier_code}</Link>;
      },
    },
    {
      name: 'supplier_name',
      displayName: 'Supplier Name',
      inputFilterable: true,
      sortable: true,
      visible: true,
    },
    {
      name: 'contact_supplier_name',
      displayName: 'Contact Supplier Name',
      inputFilterable: true,
      sortable: true,
      visible: true,
    },
    {
      name: 'email',
      displayName: 'Email',
      inputFilterable: true,
      sortable: true,
      visible: true,
    },
    {
      name: 'phone',
      displayName: 'Phone',
      inputFilterable: true,
      sortable: true,
      visible: true,
    },
    {
      name: 'id',
      displayName: 'Edit',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      render: function selectPatient(e) {
        return (
          <Link to={`/suppliers/onboard/${e.record.id}`}>
            <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      {loading ? (
        <CircleLoader />
      ) : (
        <div>
          <h3>Suppliers</h3>
          <div className="float-right d-flex">
            <Link to={{ pathname: '/suppliers/onboard/' }} className="btn btn-primary mr-2">
              Onboard New Suppliers
            </Link>
            {AuthService.isSuperAdmin() && (
              <Button text="Export All" className="btn btn-md btn-primary float-right" onClick={downloadFile} />
            )}
            <div className="form-inline bulk-update mr-5">
              <Select
                name="suppliersActive"
                placeholder="Filter by Suppliers"
                className="select-field mx-1 rounded"
                options={options}
                onChange={handleActiveState}
              />
            </div>
          </div>
          <FilterableTable
            className="table-responsive"
            topPagerVisible={false}
            data={list}
            fields={fields}
            pageSizes={false}
            initialSort={'id'}
            serverSort={() => {}}
            filterPlaceholder="Search"
            initialSortDir={false}
            noRecordsMessage="Ther are no record to display"
            noFilteredRecordsMessage="No record match your filters"
          />
        </div>
      )}
    </div>
  );
};

export default SuppliersTable;
