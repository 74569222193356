import { useEffect, useState } from 'react';
import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('googlePlacesAutoComplete');

type GooglePlaces = {
  onLoad: () => void;
};

let googlePlacesLoader: Loader | null = null;

export default function useGooglePlaces({ onLoad }: GooglePlaces) {
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      logger.info('Initializing GooglePlaces');
      setLoading(true);

      if (googlePlacesLoader?.status === LoaderStatus.INITIALIZED) {
        logger.info('GooglePlaces Autocomplete init', {
          status: googlePlacesLoader.status,
        });
        setLoading(false);
        onLoad();
      }

      if (googlePlacesLoader?.status === LoaderStatus.LOADING) {
        return;
      }

      googlePlacesLoader = new Loader({
        apiKey: settings.googlePlacesApiKey,
        version: 'weekly',
      });

      await googlePlacesLoader.importLibrary('places');
      logger.info('GooglePlaces initialized');

      onLoad();
    } catch (error) {
      if (error instanceof Error) {
        logger.error('Error initializing GooglePlaces', error);
      }
      logger.error('Unexpected error initializing GooglePlaces');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, [googlePlacesLoader?.status]);

  return { loading };
}
