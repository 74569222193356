import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AccountCircleRounded as AccountCircleRoundedIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import {
  AppBar,
  backdropClasses,
  Box,
  IconButton,
  Menu,
  menuClasses,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';

import AlternaleafLogo from '@/components/common/AlternaleafLogo';
import MontuLogo from '@/components/common/MontuLogo';
import { mainMenu, userMenu } from '@/components/layout/navbar/MenuItems';
import { teal } from '@/components/products/detail/common';
import { FF_ENABLE_WOM_CAMPAIGN } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { alternaleafMQ, alternaleafTheme } from '@/theme';
import { BRANDS } from '@/types';

import { AuthService } from '../../services/authentication.service';
import PatientReferralView from '../patientReferral/patientReferralView';

import '../dashboard/dashboard.scss';

const MQLarge = alternaleafMQ('lg');
const MQMedium = alternaleafMQ('md');

const NavigationBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: theme.mixins.toolbar.height,
  width: '100%',
}));

const NavToolbar = styled(Toolbar)(({ theme }) => ({
  padding: '0 1.5rem',
  justifyContent: 'space-between',
  gap: '2rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '0 10rem',
  },
}));

const MenuIconContainer = styled(Box)`
  display: flex;
`;

const UserMenuContainer = styled(Menu)`
  .${menuClasses.paper} {
    min-width: 10rem;
    background-color: ${alternaleafTheme.colorSchemes.light.palette.background.default};
  }
`;

const MainMenuContainer = styled(Menu)`
  .${menuClasses.paper} {
    width: 80%;
    max-width: 20rem;
    height: 100%;
    max-height: 100%;
    right: 0;
    bottom: 0;
    left: unset !important;
    top: unset !important;
    background-color: ${teal};
    border-radius: 0;

    ${MQMedium} {
      height: calc(100vh - (${alternaleafTheme.mixins.toolbar.height} - 30px));
      width: 25vw;
      max-width: 100%;
      min-width: 20rem;
    }

    > ul {
      height: 100%;
      padding: 0;
    }
  }

  .${backdropClasses.root} {
    background-color: ${alternaleafTheme.colorSchemes.light.palette.common.black};
    opacity: 0.8 !important;

    ${MQMedium} {
      top: unset;
      height: calc(100vh - ${alternaleafTheme.mixins.toolbar.height});
    }
  }
`;

const AlternaleafMenuContainer = styled(Box)`
  padding: 0 0 1rem;
  color: ${alternaleafTheme.colorSchemes.light.palette.primary.main};
  background-color: ${alternaleafTheme.colorSchemes.light.palette.background.default};
`;

const MontuMenuContainer = styled(Box)`
  padding: 0 0 1rem;
  background-color: ${teal};
  color: ${alternaleafTheme.colorSchemes.light.palette.common.white};
`;

const UserMenuItemsWrapper = styled(Box)`
  > li {
    padding: 0;
    color: ${alternaleafTheme.colorSchemes.light.palette.primary.main};

    > a {
      padding: 0.75rem 1.5rem;
      color: inherit;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const MainMenuItemsWrapper = styled(Box)`
  padding-top: 1rem;

  > li {
    padding: 0;
    color: inherit;

    > a {
      padding: 0.75rem 1.5rem;
      color: inherit;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${alternaleafTheme.colorSchemes.light.palette.primary.main};

  ${MQLarge} {
    &:first-of-type {
      margin-right: 1.5rem;
    }

    &:only-of-type {
      margin: 0;
    }
  }

  &:focus {
    outline: none;
  }
`;

const CloseIconContainer = styled(Box)`
  padding: 1rem 1.5rem;
  width: 100%;
  background-color: ${alternaleafTheme.colorSchemes.light.palette.background.default};
  color: ${alternaleafTheme.colorSchemes.light.palette.primary.main};
  display: flex;
  justify-content: flex-end;

  ${MQMedium} {
    justify-content: flex-start;
  }

  > button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const LogoContainer = styled(Box)`
  padding: 1rem 1.5rem;
  width: 100%;
  background-color: ${alternaleafTheme.colorSchemes.light.palette.background.default};
`;

const MontuFooter = styled(Typography)`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  line-height: 2;
  padding: 1rem 1.5rem;
`;

function Navbar() {
  const { flags } = useFeatureFlags();
  const roleId = AuthService.getUser()?.user?.role_id || USER_ROLES.doctor;

  const userMenuItems = userMenu.filter((userMenuItem) => userMenuItem.roleId.includes(roleId));
  const mainMenuItemsAlternaleaf = mainMenu.filter(
    (menuItem) => menuItem.brand === BRANDS.ALTERNALEAF && menuItem.roleId.includes(roleId),
  );
  const mainMenuItemsMontu = mainMenu.filter(
    (menuItem) => menuItem.brand === BRANDS.MONTU && menuItem.roleId.includes(roleId),
  );

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <NavigationBar>
      <NavToolbar disableGutters>
        <Link to={roleId === USER_ROLES.patient ? '/home' : 'https://www.montu.com.au'}>
          <AlternaleafLogo />
        </Link>

        <MenuIconContainer>
          <StyledIconButton size="large" aria-haspopup="true" onClick={handleOpenUserMenu}>
            <AccountCircleRoundedIcon />
          </StyledIconButton>

          <UserMenuContainer
            data-dd-privacy="allow"
            id="user-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <UserMenuItemsWrapper>
              {userMenuItems.map((profileMenuItem) => (
                <MenuItem key={profileMenuItem.id} onClick={handleCloseUserMenu}>
                  <Link to={profileMenuItem.link}>{profileMenuItem.name}</Link>
                </MenuItem>
              ))}
            </UserMenuItemsWrapper>
          </UserMenuContainer>

          <StyledIconButton size="large" aria-haspopup="true" onClick={handleOpenNavMenu}>
            <MenuIcon />
          </StyledIconButton>

          <MainMenuContainer
            data-dd-privacy="allow"
            id="menu-appbar"
            anchorEl={anchorElNav}
            marginThreshold={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {flags[FF_ENABLE_WOM_CAMPAIGN] && roleId === USER_ROLES.patient && <PatientReferralView isNavBanner />}
            <CloseIconContainer>
              <StyledIconButton size="large" aria-haspopup="true" onClick={handleCloseNavMenu}>
                <CloseIcon />
              </StyledIconButton>
            </CloseIconContainer>

            <AlternaleafMenuContainer>
              <LogoContainer>
                <AlternaleafLogo />
              </LogoContainer>

              <MainMenuItemsWrapper>
                {mainMenuItemsAlternaleaf.map((mainMenuItem) => (
                  <MenuItem key={mainMenuItem.id} onClick={handleCloseNavMenu}>
                    <Link to={mainMenuItem.link}>{mainMenuItem.name}</Link>
                  </MenuItem>
                ))}
              </MainMenuItemsWrapper>
            </AlternaleafMenuContainer>

            <MontuMenuContainer>
              <MainMenuItemsWrapper>
                {mainMenuItemsMontu.map((mainMenuItem) => (
                  <MenuItem key={mainMenuItem.id} onClick={handleCloseNavMenu}>
                    <Link to={mainMenuItem.link}>{mainMenuItem.name}</Link>
                  </MenuItem>
                ))}
              </MainMenuItemsWrapper>

              <MontuFooter>
                powered by
                <MontuLogo />
              </MontuFooter>
            </MontuMenuContainer>
          </MainMenuContainer>
        </MenuIconContainer>
      </NavToolbar>
    </NavigationBar>
  );
}

export default Navbar;
