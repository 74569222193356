import React from 'react';
import ProductListing from '../../components/products/productListing';
import ProductReviews from '../../components/products/productReview';

const tabs = {
  PRODUCTS: 'productListing',
  REVIEWS: 'allReviews',
};

const Products = (props) => {
  const [state, setState] = React.useState({
    activeTab: tabs.PRODUCTS,
  });

  const toggleTab = (activeTab) => {
    setState({ activeTab: activeTab });
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="btn-group" role="group">
            <button
              type="button"
              className={state.activeTab === tabs.PRODUCTS ? 'btn btn-primary' : 'btn btn-outline-primary'}
              onClick={() => toggleTab(tabs.PRODUCTS)}
            >
              Products
            </button>
            <button
              type="button"
              className={state.activeTab === tabs.REVIEWS ? 'btn btn-primary' : 'btn btn-outline-primary'}
              onClick={() => toggleTab(tabs.REVIEWS)}
            >
              Reviews
            </button>
          </div>
        </div>
      </div>
      {state.activeTab === tabs.PRODUCTS && <ProductListing {...props} />}
      {state.activeTab === tabs.REVIEWS && <ProductReviews {...props} />}
    </React.Fragment>
  );
};

export default Products;
