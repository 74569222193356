import React, { useEffect, useRef } from 'react';
import { BsFillCheckCircleFill, BsFillFlagFill } from 'react-icons/bs';
import { Tooltip as BsTooltip } from 'bootstrap';
import truncateString from '../../utils/common';

function UnableToDispenseModal(props) {
  const { unableToDispenseData, hideUnableToDispenseModal } = props;
  const Tooltip = (p) => {
    const childRef = useRef();

    useEffect(() => {
      const t = new BsTooltip(childRef.current, {
        title: p.text,
        placement: 'top',
        trigger: 'hover',
      });
      return () => t.dispose();
    }, [p.text]);

    return React.cloneElement(p.children, { ref: childRef });
  };
  return (
    <div
      className="modal"
      id="UnableToDispenseModal"
      ref={props.unableToDispenseRef}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="UnableToDispenseModal"
      aria-hidden="true"
      data-testid="open-unable-to-dispense"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document" data-testid="trigger-me-in-jest1">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Unable to Dispense {unableToDispenseData && unableToDispenseData.orderCode}</h5>
            <button
              type="button"
              className="close"
              data-testid="close-unable-to-dispense"
              onClick={() => hideUnableToDispenseModal()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {unableToDispenseData ? (
              <div>
                <table className="table margin-bottom-0" id="orderTable">
                  <thead>
                    <tr>
                      <th>Paid Date</th>
                      <th>Patient ID</th>
                      <th>Prescription</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ color: '#6c757d' }}>
                      <td style={{ verticalAlign: 'middle' }}>{unableToDispenseData.paidDate}</td>
                      <td className="custom-align-center">{unableToDispenseData.patientCode}</td>
                      <td className="custom-align-center">
                        {unableToDispenseData.prescriptions.length ? unableToDispenseData.prescriptions.join(', ') : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table" id="orderProductTable" style={{ width: '90%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>Product</th>
                      <th className="custom-align-left" style={{ width: '5%' }}>
                        Qty
                      </th>
                      <th className="custom-align-left" style={{ width: '50%' }}>
                        Reason
                      </th>
                      <th className="custom-align-left" style={{ width: '5%' }} />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(unableToDispenseData.products) &&
                      unableToDispenseData.products.map((prd, index) => (
                        <tr style={{ color: '#6c757d' }} key={index}>
                          <td style={{ verticalAlign: 'top', width: '40%' }}>
                            <Tooltip text={prd.name}>
                              <span> {truncateString(prd.name, 25)}</span>
                            </Tooltip>
                          </td>
                          <td className="text-align-center" style={{ width: '5%' }}>
                            {prd.qty}
                          </td>
                          <td className="custom-center-left" style={{ width: '50%', paddingRight: '0px' }}>
                            <span style={{ width: 'max-content' }}>{prd.reason} </span>
                            <Tooltip text={prd.otherDispenseReasonTxt}>
                              <span>
                                {prd.otherDispenseReasonTxt && ' - ' + truncateString(prd.otherDispenseReasonTxt, 12)}
                              </span>
                            </Tooltip>
                          </td>
                          <td style={{ paddingLeft: '0px', width: '5%' }}>
                            <span>
                              {prd.canDispense ? (
                                <BsFillCheckCircleFill style={{ color: 'green' }} />
                              ) : (
                                <BsFillFlagFill style={{ color: 'red' }} />
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p style={{ padding: '20px', color: 'red' }}>No data found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnableToDispenseModal;
