import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import CircleLoader from '@/components/common/circleLoader';
import OrderConfirmed from '@/components/payment/OrderConfirmed';
import { FF_ENABLE_SHOPIFY_CHECKOUT } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import montuTheme from '@/theme';

function PaymentSuccessAuth() {
  const history = useHistory();
  const location = useLocation();

  const { orderCode, transactionType, bankTransferReference } = location.state;

  const user = AuthService.getUser();

  const { flags, isIdentified } = useFeatureFlags();

  // TODO analytics
  // For logged in patients we check if isIdentified is true
  if (!isIdentified) return <CircleLoader />;

  if (!flags[FF_ENABLE_SHOPIFY_CHECKOUT]) {
    history.push('/404');
  } else if (!orderCode) {
    history.push('/patient/orders');
  }

  return (
    <CssVarsProvider theme={montuTheme}>
      <OrderConfirmed
        orderCode={orderCode}
        transactionType={transactionType}
        bankTransferReference={bankTransferReference}
        patientName={user?.user?.first_name}
        patientEmail={user?.user?.email}
        loggedIn
      />
    </CssVarsProvider>
  );
}

export default PaymentSuccessAuth;
