import { Box, Typography } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider, styled } from '@mui/material/styles';

import { alternaleafTheme } from '@/theme';

export const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  margin: '0 auto',
  maxWidth: '650px',
  height: '100%',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    padding: '20px',
  },
}));

export default function Maintenance() {
  return (
    <CssVarsProvider theme={alternaleafTheme}>
      <PageContainer>
        <Typography component="h4" lineHeight={1} mt={2} fontWeight="bold">
          Scheduled Maintenance Notice
        </Typography>
        <Typography variant="h2" component="h1" mt={2} fontWeight="bold">
          Alternaleaf
          <br /> Patient Portal
        </Typography>
        <hr />
        <Typography component="p" lineHeight={1.5} mt={2}>
          Please be advised that the Alternaleaf Patient Portal is currently unavailable as we undertake scheduled
          maintenance.
        </Typography>
        <Typography component="p" lineHeight={1.5} mt={2}>
          We apologise for any inconvenience and thank you for your patience. We look forward to being back up and
          running soon.
        </Typography>
        <Typography component="p" lineHeight={1.5} mt={2}>
          Thank you -{' '}
          <Typography component="span" fontWeight="bold">
            Montu Team
          </Typography>
        </Typography>
      </PageContainer>
    </CssVarsProvider>
  );
}
