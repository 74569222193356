import React from 'react';
import CircleLoader from '../common/circleLoader';
import { toast } from 'react-toastify';
import FilterableTable from 'rc-data-table';
import moment from 'moment';
import { INVENTORY_STATUS } from '@/constants/inventory';
import { PharmacyService } from '@/services/pharmacy.service';
import Button from '../common/button';
import axios from 'axios';
import { Logger } from '@/utils/logger';
import { getInventoryHistory, getPharmacistDetails } from '@/services/pharmacist.service';
import { FF_ENABLE_SUPER_ADMIN_INVENTORY } from '@/constants/featureFlags';

const logger = new Logger('adjustmentOverview');

class AdjustmentOverview extends React.Component {
  state = {
    inventoryHistory: [],
    loading: true,
    pageSize: 100,
    count: 0,
    page: 0,
    filter: '',
    sortingOrder: { name: 'created_date', reverse: true },
  };

  componentDidMount() {
    this.getInventoryHistory();
  }

  render() {
    if (this.state.loading) {
      return <CircleLoader />;
    }

    return (
      <div className="container-fluid">
        <h3>Adjustment Overview</h3>
        <div className="d-flex mt-lg-2 flex-wrap justify-content-lg-end">
          <div className="d-flex mt-3 mt-lg-0">
            <Button text="Export All" className="btn btn-md btn-primary mx-1" onClick={() => this.exportAll()} />
          </div>
        </div>
        <FilterableTable
          className={'table-responsive d-lg-table'}
          data={this.state.inventoryHistory}
          loading={this.state.loading}
          fields={this.getFields()}
          paginationOnServer={true}
          initialSort={this.state.sortingOrder.name}
          initialSortDir={!this.state.sortingOrder.reverse}
          onPageChanged={this.onPageChange}
          filterData={this.onFilter}
          serverSort={this.getSortColumn}
          totalRecords={this.state.count}
          currentPage={this.state.page}
          pageSize={this.state.pageSize}
          filterPlaceholder={this.state.filter ? this.state.filter : 'Search'}
          pageSizes={false}
          maintainPageOnSetData={true}
          topPagerVisible={false}
          noRecordsMessage="There are no record to display"
          noFilteredRecordsMessage="No record match your filters!"
        />
      </div>
    );
  }

  getInventoryHistory = async () => {
    const params = {
      limit: this.state.pageSize,
      offset: this.state.page * this.state.pageSize,
      sort: this.state.sortingOrder.name,
      sortOrder: this.state.sortingOrder.reverse ? 'DESC' : 'ASC',
      filter: this.state.filter,
    };
    let pharmacyId = this.props.pharmacy_id;
    if (this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] && !pharmacyId) {
      const { data: pharmacistDetails } = await getPharmacistDetails();
      pharmacyId = pharmacistDetails.pharmacy_id;
    }

    const response = this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY]
      ? await PharmacyService.getInventoryHistory(pharmacyId, params)
      : await getInventoryHistory(params);

    if (!response.data.status) {
      toast.error(response.data.message);
      logger.error('getInventoryHistory !response.data.status', response?.data?.message);
    }
    this.setState({
      inventoryHistory: response.data.inventoryHistory,
      count: response.data.count,
      loading: false,
    });
  };

  onPageChange = (page, size) => {
    this.setState(
      {
        page,
        size,
        loading: true,
      },
      () => {
        this.getInventoryHistory();
      },
    );
  };

  onFilter = (value) => {
    this.setState(
      {
        page: 0,
        filter: value,
      },
      () => {
        this.getInventoryHistory();
      },
    );
  };

  getSortColumn = (sort) => {
    this.setState(
      {
        sortingOrder: sort[0],
      },
      () => {
        this.getInventoryHistory();
      },
    );
  };

  exportAll = async () => {
    await axios({
      url: 'excel/pharmacist/downloadInventoryHistoryOfPharmacy',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      const fileTitle = new Date().getTime() + '-inventory-history';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  getFields = () => {
    return [
      {
        name: 'createdDate',
        displayName: 'Date',
        inputFilterable: false,
        sortable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        sortFieldName: 'created_date',
        render: (e) => {
          return moment(new Date(e.value)).format('DD-MMM-YYYY');
        },
      },
      {
        name: 'productName',
        displayName: 'Product',
        inputFilterable: true,
        sortable: false,
        tdClassName: 'sub-text',
      },
      {
        name: 'adjustedBy',
        displayName: 'Adjusted By',
        inputFilterable: false,
        sortable: false,
        tdClassName: 'sub-text',
      },
      {
        name: 'adjustmentType',
        displayName: 'Adjusted Type',
        inputFilterable: false,
        sortable: false,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        render: (e) => {
          if (e.record.adjustmentType === INVENTORY_STATUS.INCREMENT) {
            return 'Increase';
          }
          return 'Decrease';
        },
      },
      {
        name: 'quantity',
        displayName: 'Quantity Change',
        inputFilterable: false,
        sortable: false,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        render: (e) => {
          if (e.record.adjustmentType === INVENTORY_STATUS.INCREMENT) {
            return e.value;
          }
          return `-${e.value}`;
        },
      },
      {
        name: 'reference',
        displayName: 'Reference',
        inputFilterable: false,
        sortable: false,
        tdClassName: 'sub-text',
        render: (e) => {
          return e.value.substring(0, 50);
        },
      },
    ];
  };
}

export default AdjustmentOverview;
