import { NightlightRound as MdNightlightRound, WbSunny as MdWbSunny } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

import { ProductStrain, ProductStrains } from '@/types';
import MgBalanced from '@/ui-library/icons/MgBalanced';

interface StrainIconDefn {
  icon: typeof SvgIcon;
  color: string;
  displayName: string;
  bgColor: string;
}

const ProductStrainsMap: Record<ProductStrain, StrainIconDefn> = {
  [ProductStrains.SATIVA]: {
    icon: MdWbSunny,
    color: '#FFA726',
    bgColor: '#FFF3E0',
    displayName: 'Sativa',
  },
  [ProductStrains.INDICA]: {
    icon: MdNightlightRound,
    color: '#4DB6AC',
    bgColor: '#E0F2F1',
    displayName: 'Indica',
  },
  [ProductStrains.BALANCED]: {
    icon: MgBalanced,
    color: '#555555',
    bgColor: '#F5F5F5',
    displayName: 'Balanced',
  },
} as const;

export default ProductStrainsMap;
