import { AiOutlineShoppingCart } from 'react-icons/ai';
import { datadogRum } from '@datadog/browser-rum';

import { FF_ENABLE_SHOPIFY_CHECKOUT, FF_ENABLE_SHOPIFY_DISCOUNTS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Button } from '@/ui-library';

interface RefillCheckoutButtonProps {
  totalNumberOfProducts: number;
  handleCheckout: () => void;
  amount?: string;
  disabled?: boolean;
}

export function RefillCheckoutButton({
  totalNumberOfProducts,
  handleCheckout,
  amount,
  disabled,
}: RefillCheckoutButtonProps) {
  const countSuffix = totalNumberOfProducts === 1 ? 'item' : 'items';
  const textSuffix = amount ? ` - ${amount}` : '';
  const text = `Checkout ${totalNumberOfProducts} ${countSuffix}${textSuffix}`;
  const { flags } = useFeatureFlags();
  const isShopifyCheckoutEnabled = flags[FF_ENABLE_SHOPIFY_CHECKOUT];
  const isShopifyDiscountEnabled = flags[FF_ENABLE_SHOPIFY_DISCOUNTS];

  const onCheckoutButtonClick = () => {
    datadogRum.addAction('refill-checkout-button-click', {
      amount,
      totalNumberOfProducts,
      isShopifyCheckoutEnabled,
      isShopifyDiscountEnabled,
    });
    handleCheckout();
  };

  return (
    <Button
      // data-dd-action-name below is to it's test the behaviour in DD (it is redundant due to the datadogRum.addAction present above)
      data-dd-action-name="refill-checkout-button-click-action-name"
      onClick={onCheckoutButtonClick}
      startIcon={<AiOutlineShoppingCart />}
      sx={{ marginBottom: '0.5rem', width: '100%' }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}

export default RefillCheckoutButton;
