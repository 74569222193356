import { LDClient } from 'launchdarkly-js-client-sdk';
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';

import { User } from '@/types';
import { Logger } from '@/utils/logger';

const logger = new Logger('identifyUserForLaunchDarkly');

export const makeUserIdentityContext = (user: User) => {
  const ldUserName = `${user.first_name} ${user.last_name}`;

  const userContext: LDSingleKindContext = {
    kind: 'user',
    key: `${user.id}`,
    name: ldUserName,
    email: user.email,
    role: user.role_id,
  };

  return userContext;
};

const identifyUserForLaunchDarkly = async (ldClient: LDClient, user: User) => {
  const userContext = makeUserIdentityContext(user);
  await ldClient.waitUntilReady();
  await ldClient.identify(userContext);
  logger.info('LD client identify success', { ...userContext });
};

export default identifyUserForLaunchDarkly;
