import React from 'react';
import { Typography, TypographyProps, useTheme } from '@mui/material';

interface PageHeadingProps extends TypographyProps {
  children: React.ReactNode;
}

export default function PageHeading({ children, ...typographyProps }: PageHeadingProps) {
  const theme = useTheme();

  return (
    <Typography variant="h5" color="primary" mb={theme.spacing(6)} {...typographyProps}>
      {children}
    </Typography>
  );
}
