import React from 'react';
import HeaderUnauth from '../layout/header-unauth';

function pharmacySuccess() {
  return (
    <React.Fragment>
      <HeaderUnauth />
      <div className="text-center center-box shadow mx-auto my-auto" style={{ width: '25%', height: '30vh' }}>
        <div className="card-body text-center">
          <h3 className="card-title mt-5">Thank you!</h3>
          <p className="card-text mt-5" style={{ fontSize: '16px' }}>
            {' '}
            Your pharmacy account submission has been submitted
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
export default pharmacySuccess;
