import React from 'react';
import StarRatings from 'react-star-ratings';

const StarRating = (props) => {
  const starCount = props.startCount ? props.starCount : 5;
  const ratingVal = props.value ? Number(props.value) : 0;
  const color = props.color || '#ffd73d';

  return (
    <div className={`star-rating ${props.className}`}>
      <StarRatings
        rating={ratingVal}
        starDimension="20px"
        starSpacing="0"
        starRatedColor={color}
        starHoverColor={color}
        numberOfStars={Number(starCount)}
        {...(!props.displayOnly && { changeRating: (rating) => props.onChange && props.onChange(rating) })}
      />
    </div>
  );
};

export default StarRating;
