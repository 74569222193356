import { useHistory } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function BackButton() {
  const history = useHistory();

  return (
    <IconButton
      color="primary"
      size="large"
      onClick={() => history.goBack()}
      sx={(theme) => ({
        padding: 0,
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      })}
    >
      <ChevronLeft fontSize="large" />
    </IconButton>
  );
}
