import React from 'react';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import CustomModal from '../common/CustomModal';
import FormInput from '../common/FormInput';
import Select from 'react-select';
import settings from '../../constants/constants';
import Button from '../common/button';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Logger } from '@/utils/logger';
import useShippingPrice from '@/hooks/useShippingPrice';

const logger = new Logger('OrderDiscountModal');

const OrderDiscountModal = (props) => {
  const [discountTypes, setDiscountTypes] = React.useState([]);
  const [discountReasons, setDiscountReasons] = React.useState([]);
  const [data, setData] = React.useState({
    orderDiscount: [{ label: 'Select Discount', value: '' }],
    orderDiscountReason: [{ label: 'Select Reason', value: '' }],
    discountReason: '',
    discountNote: '',
    discountType: '',
    showMedication: false,
    discountProducts: [],
    discountAmount: null,
    percentage: '1',
  });
  const discountValidator = new SimpleReactValidator();
  const [discountProductList, setDiscountProductlist] = React.useState([]);
  const [errors, setErrors] = React.useState({
    discountReason: discountValidator.message('Discount Reason', data.discountReason, 'required'),
    discountType: discountValidator.message('Discount Type', data.discountType, 'required'),
    discountNote: discountValidator.message('Discount Notes', data.discountNote, 'required'),
    ...(data.discountType === 3 && {
      discountProducts: discountValidator.message('Product', data.discountProducts, 'required'),
    }),
    discountAmount: discountValidator.message('discount', data.discountAmount, 'required'),
  });
  const [loading, setloading] = React.useState(false);

  const shippingParts = useShippingPrice();

  const fetchDiscountReasonData = React.useCallback(async () => {
    await axios.get(`${settings.url}/data/discount-reasons`).then((resp) => {
      setDiscountReasons(resp.data.map((x) => ({ label: x.reason, value: x.id })));
    });
  });

  const fetchData = React.useCallback(async () => {
    await axios.get(`${settings.url}/data/discount-types`).then((resp) => {
      setDiscountTypes(resp.data.map((discount) => ({ label: discount.name, value: discount.id })));
    });
  });

  React.useEffect(() => {
    if (props.availableProducts && props.availableProducts.length > 0) {
      setDiscountProductlist(props.availableProducts);
    }
  }, [props.availableProducts]);

  React.useEffect(() => {
    if (props.existingDiscounts && props.existingDiscounts.length > 0) {
      const discountDetails = {};
      for (const each of props.existingDiscounts) {
        discountDetails.discountReason = each.discount_reason_id;
        discountDetails.discountNote = each.discount_note;
        discountDetails.discountType = each.discount_type_id;
        discountDetails.showMedication = each.discount_type_id === 3;
        discountDetails.discountAmount = each.discount_amount || each.discount_percentage;
        discountDetails.percentage = each.discount_amount ? '0' : '1';
        discountDetails.orderDiscount = [{ label: each.DiscountType.name, value: each.discount_type_id }];
        if (each.discount_type_id === 3) {
          discountDetails.discountProducts = discountDetails.discountProducts
            ? [...discountDetails.discountProducts, { label: each.Product.name, value: each.Product.id }]
            : [{ label: each.Product.name, value: each.Product.id }];
        }
      }
      setData({ ...data, ...discountDetails });
    }
  }, [props.existingDiscounts]);

  React.useEffect(() => {
    fetchData();
  }, []);
  React.useEffect(() => {
    fetchDiscountReasonData();
  }, []);

  const handleDiscount = (selecteDiscountValue) => {
    if (selecteDiscountValue.value === 3) {
      setData({
        ...data,
        orderDiscount: selecteDiscountValue,
        showMedication: true,
        discountType: selecteDiscountValue.value,
      });
    } else {
      setData({
        ...data,
        orderDiscount: selecteDiscountValue,
        showMedication: false,
        discountType: selecteDiscountValue.value,
      });
    }
  };

  const handleProductChange = (selectedOptions) => {
    setData({
      ...data,
      discountProducts: selectedOptions,
    });
  };

  const handleReasonChange = (selectedOptions) => {
    setData({
      ...data,
      orderDiscountReason: selectedOptions,
      discountReason: selectedOptions.value,
    });
  };

  const closeModal = () => {
    setData({
      ...data,
      orderDiscount: [{ label: 'Select Discount', value: '' }],
      discountType: '',
      discountReason: '',
      discountNote: '',
      orderDiscountReason: [{ label: 'Select Reason', value: '' }],
      discountProducts: [],
      showModal: false,
      showMedication: false,
      discountAmount: null,
    });
    props.close();
  };

  const handleUserInput =
    (prop, type = 'text') =>
    (e) => {
      setData({ ...data, [prop]: e.target.value });
    };

  const onSubmissionModal = async (e) => {
    e.preventDefault();
    if (!discountValidator.allValid()) {
      discountValidator.showMessages();
      setErrors({
        discountReason: discountValidator.message('Discount Reason', data.discountReason, 'required'),
        discountNote: discountValidator.message('Discount Notes', data.discountNote, 'required'),
        discountType: discountValidator.message('Discount Type', data.discountType, 'required'),
        ...(data.discountType === 3 && {
          discountProducts: discountValidator.message('Product', data.discountProducts, 'required'),
        }),
        discountAmount: discountValidator.message('discount', data.discountAmount, 'required'),
      });
      return;
    }

    if (data.discountAmount <= 0) {
      toast.warn('Discount Amount should not be negative and zero', { toastId: 'discount-max-amount' });
      return false;
    }

    if (
      (data.discountType === 2 && data.discountAmount > shippingParts.shipping && data.percentage === '0') ||
      (data.discountAmount > 100 && data.percentage === '1')
    ) {
      toast.warn('Discount Amount is higher than maximum limit.', { toastId: 'discount-max-amount' });
      return;
    }
    setloading(true);
    const discountData = {
      orderId: props.orderId,
      discountReason: data.discountReason,
      discountType: data.discountType,
      patientId: props.patient,
      discountNote: data.discountNote,
      ...(data.percentage === '1'
        ? { discountPercentage: parseInt(data.discountAmount) }
        : { discountAmount: data.discountAmount }),
      ...(data.discountProducts.length > 0 ? { productIds: data.discountProducts.map((x) => x.value) } : null),
    };
    try {
      const createOrderDiscount = await axios.post(`${settings.url}/discount/order/create`, discountData);
      if (createOrderDiscount) {
        toast.success('Create discount successfully');
        props.history.push('/orders');
      }
    } catch (err) {
      toast.error('Fail to create Discount');
      logger.error('Fail to create Discount', err);
      setloading(false);
    }
  };

  const validatePercentage = (evt) => {
    if (evt.target.value < 101) {
      setData({
        ...data,
        discountAmount: Number(evt.target.value),
      });
    }
  };

  const customStyles = {
    content: {
      width: '35%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <React.Fragment>
      <CustomModal
        isOpen={props.show}
        onRequestClose={props.close}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <React.Fragment>
          <div className="modalheader">
            <h3>Add Discount</h3>
          </div>
          <div className="modalbody">
            <label>Items</label>
            <Select options={discountTypes} value={data.orderDiscount || ''} onChange={handleDiscount} width="20" />
            {errors.discountType}
            {data.showMedication && (
              <div className="mt-3">
                <label>Available Medications</label>
                <Select
                  isMulti
                  value={data.discountProducts || ''}
                  options={discountProductList}
                  onChange={handleProductChange}
                />
                {data.discountType === 3 ? errors.discountProducts : ''}
              </div>
            )}
            <div className="mt-3">
              <label>Reason</label>
              <Select
                options={discountReasons}
                value={data.orderDiscountReason || ''}
                onChange={handleReasonChange}
                width="20"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.discountReason}
            </div>
            <div className="mt-3">
              <FormInput
                noLabel={true}
                name={'discountNote'}
                value={data.discountNote}
                onChange={handleUserInput('discountNote')}
                placeholder={'Discount Notes'}
                maxlength={100}
              />
              {errors.discountNote}
            </div>
            <div className="p-0 mt-3 col-md-5">
              <label>Discount</label>
              <div className="input-group">
                <input
                  type="number"
                  name="discountAmount"
                  value={parseInt(data.discountAmount) || ''}
                  placeholder={'100%'}
                  onChange={validatePercentage}
                  className="form-control"
                />
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    %
                  </span>
                </div>
              </div>
              {errors.discountAmount}
            </div>
          </div>
          <div className="modalfooter">
            <Button
              text="Add Discount"
              type="submit"
              className="btn btn-primary float-right mt-5"
              onClick={onSubmissionModal}
              disabled={loading}
            />
            <Button
              text="Close"
              type="submit"
              className="btn btn-outline-primary float-right mt-5 mr-3"
              onClick={closeModal}
            />
          </div>
        </React.Fragment>
      </CustomModal>
    </React.Fragment>
  );
};

export default OrderDiscountModal;
