import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import FilterableTable from 'rc-data-table';
import Button from '../common/button';
import settings from '../../constants/constants';
import Select from 'react-select';
import { AuthService } from '../../services/authentication.service';
import editIcon from '../../assets/images/edit.png';

const url = settings.url;

let filterData;
const filter = (props) => {
  filterData = props.records;
};

class ViewML extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      active: 1,
    };
  }

  setActive = async (props) => {
    let action = props;
    this.setState(
      {
        active: action.value,
      },
      this.dataBind,
    );
    this.forceUpdate();
  };
  dataBind = async () => {
    await axios
      .get(url + '/liaison/', {
        params: { active: this.state.active },
      })
      .then((data) => this.setState({ data: data.data }));
  };
  async componentDidMount() {
    document.title = 'BD - Montu Group | Making Medical Cannabis Accessible';
    await this.dataBind();
    document.getElementsByClassName('filter-input')[0].placeholder = 'Search';
  }
  downloadFile = async () => {
    const { data } = this.state;
    let itemsNotFormatted = filterData ? filterData : data;
    let itemsFormatted = [];

    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        'ML ID': item.ml_code,
        'First Name': item.first_name,
        'Last Name': item.last_name,
        Email: item.email,
        Phone: item.phone,
        State: item.state,
        Patients: item.patients_count,
        GPs: item.gp_count,
      });
    });

    let fileTitle = new Date().getTime() + '-montu-ml';
    await axios({
      url: 'excel/ml/download',
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    const { data } = this.state;
    const fields = [
      { name: 'id', displayName: 'id', inputFilterable: true, sortable: true, visible: false },
      { name: 'ml_code', displayName: 'BDMS', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'joined_date',
        displayName: 'Joined Date',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      { name: 'ml_name', displayName: 'Name', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'email', displayName: 'Email', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'phone', displayName: 'Phone', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'state', displayName: 'State', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'gp_count', displayName: 'Doctors', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'patients_count',
        displayName: 'Patients',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'order_count',
        displayName: 'Prescriptions',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      { name: 'filter', displayName: 'Filter', render: filter, tdClassName: 'd-none sub-text', thClassName: 'd-none' },
      {
        name: 'id',
        displayName: 'Edit',
        thClassName: 'text-center',
        render: (e) => {
          return (
            <Link to={`/bd/onboard/${e.record.id}`}>
              <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
            </Link>
          );
        },
      },
    ];

    return (
      <div className="container-fluid">
        <h3>Business Development Managers</h3>
        <div className="float-right d-flex">
          <Link to={{ pathname: '/bd/onboard/' }} className="btn btn-primary mr-2">
            Onboard New BDM
          </Link>
          {AuthService.isSuperAdmin() && (
            <Button text="Export All" className="btn btn-md btn-primary float-right" onClick={this.downloadFile} />
          )}
          <div className="form-inline bulk-update mr-5">
            <Select
              name="mlActive"
              placeholder="Filter by Active"
              className="select-field mx-1 rounded"
              options={[
                { label: 'Active', value: 1 },
                { label: 'Inactive', value: 0 },
              ]}
              onChange={this.setActive}
            />
          </div>
        </div>
        <FilterableTable
          className="table-responsive"
          serverSort={() => {}}
          topPagerVisible={false}
          data={data}
          fields={fields}
          pageSizes={false}
          initialSort={'id'}
          initialSortDir={false}
          noRecordsMessage="There are no record to display"
          noFilteredRecordsMessage="No record match your filters!"
        />
      </div>
    );
  }
}

export default ViewML;
