import { Box, BoxProps, Card, styled } from '@mui/material';

import { Button } from '@/ui-library';

interface Props {
  count: number;
  isAddDisabled?: boolean;
  isRemoveDisabled?: boolean;

  sx?: BoxProps['sx'];
  onClickAdd: (qty: number) => void;
  onClickRemove: (qty: number) => void;
}

const ButtonContainer = styled(Box)`
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 6.5rem;
  border: 1px solid ${({ theme }) => theme?.palette?.grey?.[300]};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme?.palette?.common?.white};
  height: fit-content;
`;

const BaseStyle = {
  height: '1.5rem',
  width: '1.5rem',
  textAlign: 'center',
  fontSize: '1.125rem',
  border: '0 none',
  borderRadius: '0.25rem',
  boxShadow: 'none',
  minWidth: 'auto',
};

export default function AddRemoveButton({
  isAddDisabled = false,
  isRemoveDisabled = false,
  sx,
  onClickAdd,
  onClickRemove,
  count,
}: Props) {
  const handleClickRemove = () => {
    onClickRemove(count - 1);
  };

  const handleClickAdd = () => {
    onClickAdd(count + 1);
  };

  return (
    <ButtonContainer data-testid="add-remove-button" sx={sx}>
      <Button
        aria-label="remove"
        disabled={isRemoveDisabled}
        sx={() => ({ ...BaseStyle, padding: 0 })}
        variant="contained"
        onClick={handleClickRemove}
      >
        -
      </Button>
      <Card sx={BaseStyle} data-testid="add-remove-button-count">
        {count ?? 0}
      </Card>
      <Button
        aria-label="add"
        disabled={isAddDisabled}
        sx={{ ...BaseStyle, padding: 0 }}
        variant="contained"
        onClick={handleClickAdd}
      >
        +
      </Button>
    </ButtonContainer>
  );
}
