import { createSvgIcon } from '@mui/material';

/**
 * An icon for an cannabis flower
 *
 * @see https://www.figma.com/file/7lIk9w1nmLm9dKZRFvivt8/2-Filters%3A-Concentrated-extract-%26-Inhalation-(pressure)--%5BB2CS-464%5D?type=design&node-id=3202-369&mode=design&t=R9aWCRFetu22CDdF-0
 *
 * @returns An SvgIcon
 */
export const MgCannabis = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4356 12.7323C13.6384 11.6188 13.7579 10.2513 13.7579 8.77319C13.7579 5.03246 12.9925 2 12.0484 2C11.1042 2 10.3388 5.03246 10.3388 8.77319C10.3388 10.3088 10.4678 11.725 10.6852 12.8611C10.0262 12.0779 9.17322 11.1866 8.19625 10.2813C5.59818 7.87363 3.10742 6.27826 2.63299 6.71792C2.15856 7.15758 3.88011 9.46576 6.47819 11.8734C7.82079 13.1176 9.13473 14.1449 10.1566 14.7865C9.3732 14.7009 8.42279 14.6508 7.39826 14.6508C4.71682 14.6508 2.54309 14.9941 2.54309 15.4176C2.54309 15.8411 4.71682 16.1844 7.39826 16.1844C8.28008 16.1844 9.10699 16.1472 9.81974 16.0823C9.59857 16.2451 9.37405 16.4227 9.151 16.6127C7.79762 17.7654 7.011 18.9383 7.39401 19.2325C7.77703 19.5266 9.18466 18.8306 10.538 17.6779C10.936 17.3389 11.285 16.9981 11.5696 16.6785V20.5044C11.5696 20.731 11.7533 20.9147 11.9799 20.9147C12.2065 20.9147 12.3902 20.731 12.3902 20.5044V16.6199C12.6839 16.9563 13.0511 17.3181 13.4735 17.6779C14.8269 18.8306 16.2345 19.5266 16.6175 19.2325C17.0005 18.9383 16.2139 17.7654 14.8605 16.6127C14.6399 16.4247 14.4178 16.2489 14.1989 16.0876C14.8983 16.1492 15.7042 16.1844 16.562 16.1844C19.2435 16.1844 21.4172 15.8411 21.4172 15.4176C21.4172 14.9941 19.2435 14.6508 16.562 14.6508C15.5643 14.6508 14.6368 14.6983 13.8655 14.7798C14.8858 14.1378 16.1954 13.1133 17.5333 11.8734C20.1314 9.46576 21.853 7.15758 21.3785 6.71792C20.9041 6.27826 18.4133 7.87363 15.8153 10.2813C14.8915 11.1374 14.0785 11.9809 13.4356 12.7323Z"
      fill="currentColor"
    />
  </svg>,
  'MgCannabis',
);

export default MgCannabis;
