import { createSvgIcon } from '@mui/material';

/**
 * An icon for device
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2677-48924&m=dev
 *
 * @returns An SvgIcon
 */

export const MgDevice = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 2H8.99989V3.36401H8.16651C7.70625 3.36401 7.33313 3.73712 7.33313 4.19739V21.165C7.33313 21.6252 7.70625 21.9984 8.16651 21.9984H14.8335C15.2938 21.9984 15.6669 21.6252 15.6669 21.165V4.19739C15.6669 3.73712 15.2938 3.36401 14.8335 3.36401H14.0002V2ZM14.0148 4.50143C14.0184 4.50127 14.0221 4.50112 14.0257 4.50098C14.1656 4.49543 14.8336 4.51212 14.8336 5.33623V20.3368C14.8335 21.1701 14.0002 21.1702 14.0002 21.1702L9.02595 21.1702C9.01218 21.1702 8.9987 21.1708 8.98506 21.1714C8.98149 21.1715 8.97792 21.1717 8.97432 21.1718C8.83428 21.1774 8.16651 21.1608 8.16651 20.3368L8.16653 5.33623C8.16653 4.51193 8.83477 4.49521 8.97447 4.50072C8.97811 4.50086 8.98172 4.50102 8.98533 4.50117C8.99882 4.50174 9.01217 4.50231 9.02579 4.50231L13.9742 4.50258C13.9879 4.50258 14.0013 4.50201 14.0148 4.50143ZM8.99991 7.50275V19.5034C8.99991 19.9637 9.37303 20.3368 9.83329 20.3368H13.1668C13.6271 20.3368 14.0002 19.9637 14.0002 19.5034V7.50276C14.0002 7.22661 13.7763 7.00275 13.5002 7.00275H9.49991C9.22377 7.00275 8.99991 7.22661 8.99991 7.50275Z"
      fill="currentColor"
    />
  </svg>,
  'MgDevice',
);

export default MgDevice;
