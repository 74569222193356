import { styled, Tab as MUITab, TabProps } from '@mui/material';

const StyledTab = styled(MUITab)(({ theme }) => ({
  fontFamily: theme.typography.subtitle1.fontFamily,
  fontWeight: 600,
  textTransform: 'capitalize',
  padding: theme.spacing(0, 2),
  '&:focus': {
    outline: 'none',
  },
  '&:focus-visible': {
    outline: 'auto',
  },
}));

export default function Tab(props: TabProps) {
  return <StyledTab {...props} />;
}
