import { createSvgIcon } from '@mui/material';

/**
 * An icon for pastille
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2677-48890&m=dev
 *
 * @returns An SvgIcon
 */

export const MgPastille = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.504 15.4135L21.4962 15.4059C20.6838 14.5976 19.6056 14.1525 18.459 14.1525C17.9836 14.1525 17.5203 14.2301 17.0824 14.3774C18.052 12.2994 17.6807 9.74534 15.9677 8.03218C14.908 6.97261 13.499 6.38898 12.0004 6.38898C11.1644 6.38898 10.3564 6.57106 9.62104 6.9157C10.124 5.41483 9.77976 3.68999 8.58663 2.49676C8.26632 2.1764 7.84035 2 7.38723 2C6.93411 2 6.50801 2.1764 6.18738 2.4969C5.81728 2.86732 5.65438 3.3676 5.69876 3.85236C5.64694 3.84766 5.59453 3.84531 5.5418 3.84531C5.08881 3.84531 4.66278 4.02178 4.34234 4.34221C3.97191 4.7125 3.80895 5.21285 3.85339 5.69767C3.80157 5.69297 3.7493 5.69062 3.69663 5.69062C3.24345 5.69062 2.81735 5.86709 2.49691 6.18759C2.17647 6.50788 2 6.93384 2 7.38688C2 7.83986 2.17647 8.26588 2.49691 8.58644C3.31034 9.39959 4.39174 9.84741 5.54193 9.84741C6.01606 9.84741 6.47806 9.77014 6.9148 9.62369C6.57112 10.3581 6.3895 11.1648 6.3895 11.9997C6.38956 13.4983 6.97321 14.9071 8.03301 15.9668C9.0928 17.0267 10.502 17.6104 12.0006 17.6104C12.8368 17.6104 13.6448 17.4284 14.3801 17.0836C13.8781 18.5816 14.2197 20.3025 15.407 21.4954L15.4143 21.503C15.7348 21.8235 16.1607 22 16.6139 22C17.067 22 17.493 21.8235 17.8132 21.503C18.1337 21.1826 18.3102 20.7566 18.3102 20.3035C18.3102 20.2511 18.3078 20.1991 18.3032 20.1476C18.3547 20.1523 18.4067 20.1546 18.459 20.1546C18.9121 20.1546 19.3382 19.9781 19.6586 19.6577C19.9791 19.3374 20.1557 18.9114 20.1557 18.4582C20.1557 18.4058 20.1533 18.3538 20.1486 18.3022C20.2 18.3068 20.2519 18.3092 20.3042 18.3092C20.7573 18.3092 21.1834 18.1327 21.5041 17.8122C22.1654 17.1511 22.1653 16.075 21.504 15.4135Z"
      fill="currentColor"
    />
  </svg>,
  'MgPastille',
);

export default MgPastille;
