import { ComponentType } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { LDEvaluationDetail, withLDProvider } from 'launchdarkly-react-client-sdk';

import settings from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

import { makeUserIdentityContext } from './identifyUserForLaunchDarkly';

const logger = new Logger('withFeatureFlagProvider');

const LAUNCH_DARKLY_API_KEY = settings.launchDarklyConfig.clientSideId;

function getInitialContext() {
  try {
    // Presumes CSR on initial load
    const initialUser = AuthService.getUser();
    if (initialUser && initialUser.user) {
      return makeUserIdentityContext(initialUser.user);
    }
    // We lose the ability to do percentage rollouts and experiments on un-authenticated pages (login,
    // forgot password etc) with this approach, however this saves us from automated tests increasing our MAU.
    // https://docs.launchdarkly.com/home/contexts/anonymous-contexts#using-a-shared-key-between-anonymous-contexts
    return {
      kind: 'user',
      anonymous: true,
      key: 'shared-anonymous',
    };
  } catch (err) {
    logger.error('Error initializing LD context', err);
    return null;
  }
}

const initialContext = getInitialContext();

export default function withFeatureFlagProvider(app: ComponentType) {
  return withLDProvider({
    clientSideID: LAUNCH_DARKLY_API_KEY,
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    ...(initialContext && { context: initialContext }),
  })(app);
}
