import axios from 'axios';

import settings from '../constants/constants';

export interface IPatientSecondaryProfile {
  patientCode: string;
}
export interface IGetPatientSecondaryProfiles {
  duplicates: IPatientSecondaryProfile[];
}

export const fetchPatientSecondaryProfiles = async (patientCode: string) => {
  const response = await axios.get<IGetPatientSecondaryProfiles>(
    `${settings.url}/patient/findDuplicatePatients/${patientCode}`,
  );
  return response.data;
};

export const mergePatientProfiles = async (fromPatientCodes: string[], toPatientCode: string) => {
  const response = await axios.post(`${settings.url}/patient/mergeDuplicateCircuitPatientProfiles`, {
    fromPatientCodes,
    toPatientCode,
  });
  return response.data;
};
