import { Card, CardContent, CardMedia, Skeleton } from '@mui/material';

export function ProductTileSkeleton() {
  return (
    <Card
      variant="outlined"
      sx={{
        display: { xs: 'flex', sm: 'block' },
        flexDirection: 'row',
      }}
    >
      <CardMedia>
        <Skeleton
          variant="rounded"
          sx={{
            height: 100,
            width: { xs: 100, sm: '100%' },
          }}
        />
      </CardMedia>
      <CardContent sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </CardContent>
    </Card>
  );
}

export default ProductTileSkeleton;
