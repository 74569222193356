import React, { useEffect, useState } from 'react';
import CircleLoader from '../common/circleLoader';
import FilterableTable from 'rc-data-table';
import settings from '@/constants/constants';
import axios from 'axios';
import { PharmacyService } from '@/services/pharmacy.service';

const PharmacyUpdateHistory = () => {
  const url = settings.url;
  const API_URL = `${url}/pharmacy`;
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const pageSize = 50;
  useEffect(() => {
    getUpdateHistoryData();
  }, []);

  const getUpdateHistoryData = async () => {
    const response = await PharmacyService.getUpdateHistory();
    setHistory(response.data);
    setLoading(false);
  };

  const downloadFile = async (fileName) => {
    setDownloading(true);
    await axios({
      url: `${API_URL}/update-history-file-download`,
      method: 'GET',
      params: {
        name: fileName,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const downloadPath = fileName;
      link.setAttribute('download', downloadPath);
      document.body.appendChild(link);
      link.click();
    });
    setDownloading(false);
  };

  const getTableFields = [
    {
      name: 'createdDate',
      displayName: 'Created Date',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text w-5',
      sortFieldName: 'createdDate',
      render: (e) => {
        return e.value;
      },
    },
    {
      name: 'mailId',
      displayName: 'Created By',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text w-5',
      sortFieldName: 'mailId',
    },
    {
      name: 'uploadedFile',
      displayName: 'Uploaded File',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text w-15',
      render: (e) => {
        if (e.value) {
          return (
            <a
              onClick={() => downloadFile(e.value)}
              disabled={downloading}
              style={{ color: '#0372F9', cursor: 'pointer' }}
            >
              Uploaded .csv
            </a>
          );
        }
      },
    },
    {
      name: 'resultFile',
      displayName: 'Result File',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text w-15',
      render: (e) => {
        if (e.value) {
          return (
            <a
              onClick={() => downloadFile(e.value)}
              disabled={downloading}
              style={{ color: '#0372F9', cursor: 'pointer' }}
            >
              Result .xlsx
            </a>
          );
        }
      },
    },
  ];
  return loading ? (
    <CircleLoader />
  ) : (
    <div>
      <h3>Pharmacy Update History</h3>
      <FilterableTable
        topPagerVisible={false}
        data={history}
        loading={loading}
        fields={getTableFields}
        currentPage={0}
        pageSize={pageSize}
        pageSizes={false}
        serverSort={() => {}}
        initialSort={'createdDate'}
        initialSortDir={false}
        filterPlaceholder="Search"
        noRecordsMessage="There are no record to display"
        noFilteredRecordsMessage="No record match your filters!"
      />
    </div>
  );
};

export default PharmacyUpdateHistory;
