import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    gtag: (command: 'event', eventName: string, params: Record<string, unknown>) => void;
  }
}

const useGoogleAnalytics = () => {
  const sendGoogleAnalyticsEvent = useCallback((eventCategory: string, params: Record<string, unknown>) => {
    TagManager.dataLayer({
      dataLayer: {
        eventCategory,
        ...params,
      },
    });
  }, []);

  const sendGoogleAnalyticsGTagEvent = useCallback((eventCategory: string, params: Record<string, unknown>) => {
    window.gtag('event', eventCategory, params);
  }, []);

  return { sendGoogleAnalyticsEvent, sendGoogleAnalyticsGTagEvent };
};

export default useGoogleAnalytics;
