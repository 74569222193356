import moment from 'moment';

const productViewModel = (productData) => {
  let viewModel = {};
  const [product] = productData.products;
  if (product) {
    const productSupplier = product.Suppliers.length > 0 ? product.Suppliers[0].id : '';
    viewModel = {
      name: product.name,
      short_name: product.short_name,
      active: product.active,
      approved: product.pre_approved,
      price: product.price,
      sku: product.sku_code,
      description: product.description,
      available2all: product.available_to_all_gp,
      new_product: product.new_product,
      coming_soon: product.coming_soon,
      new_at_lowprice: product.new_at_lowprice,
      campaign_discount_price: product.campaign_discount_price,
      formulation: { value: product.formulation_id },
      cultivar: product.cultivar,
      strain: { value: product.strain_id },
      supplier: { value: productSupplier },
      hadSupplier: productSupplier !== '',
      ingredient: { value: product.ingredient_id },
      wasAvailable2doc: product.available_to_all_gp,
      wasApproved: product.pre_approved,
      thc_percentage: product.thc_percentage,
      thc_strength: product.thc_strength,
      cbd_strength: product.cbd_strength,
      schedule: { value: product.schedule_id },
      thc: { value: product.thc_id },
      cbd: { value: product.cbd_id },
      hybrid: { value: product.hybrid_id },
      spectrum: { value: product.spectrum_id },
      range: { value: product.rx_range_id },
      interval: product.interval,
      dosage: product.dosage,
      outOfStock: product.is_out_of_stock,
      wasOutOfStock: product.is_out_of_stock,
      intervalLimit: product.interval,
      reasoningToggle: product.reasoning_toggle,
      wasReasoningToggle: product.reasoning_toggle,
      reasoning: product.reasoning,
      weight: product.weight,
      stockReturnDate: product.stock_return_date ? moment(product.stock_return_date).toDate() : null,
      existingsku_codes: productData.existingsku_codes,
      existingsku_code: product.sku_code,
      order: product.order,
      size: product.size,
      how_to_use: product.how_to_use,
      contents: product.contents,
      conditions: product.conditions,
      thc_strength_for_display: product.thc_strength_for_display,
      cbd_strength_for_display: product.cbd_strength_for_display,
      description_for_display: product.description_for_display,
      patient_catalog: product.patient_catalog,
      cbd_thc_ratio: product.cbd_thc_ratio,
      isSupplyChainRisk: product.is_supply_chain_risk,
      isGenerativeScripting: product.is_generative_scripting,
      isExcludedFromNewPrescriptions: product.is_excluded_from_new_prescriptions,
      ...(product.image_url && { product_image: product.image_url }),
      ...(product.ProductImages && { ProductImages: product.ProductImages }),
    };
  } else if (productData.existingsku_codes) {
    viewModel.existingsku_codes = productData.existingsku_codes;
  }

  return viewModel;
};

export default productViewModel;
