import { Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';

interface Props {
  categories: string[];
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
}

const formatTabHash = (hash: string) => hash.toLowerCase().replace(/[^a-z]/g, '');

function SupportTabButtons({ categories, tabIndex, setTabIndex }: Props) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const hash = decodeURIComponent(formatTabHash(location.hash.replace('#tab=', '')));
    const categoryIndex = categories.map(formatTabHash).indexOf(hash);
    if (categoryIndex !== -1) {
      setTabIndex(categoryIndex);
    }
  }, [location.hash, categories, setTabIndex]);

  const handleTabChange = (_: unknown, i: number) => {
    setTabIndex(i);
    history.push(`#tab=${encodeURIComponent(formatTabHash(categories[i]))}`);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Support tasks by category">
        {categories.map((category) => (
          <Tab label={category} key={category} sx={{ fontWeight: 'bold', flexDirection: 'row' }} />
        ))}
      </Tabs>
    </Box>
  );
}

export default SupportTabButtons;
