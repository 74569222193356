import { styled } from '@mui/material';

import { Heading } from './common';
import { Product } from './types';

const ProductUseList = styled('ul')`
  margin-left: 0%;
  list-style: none;
  padding: 0.5rem 0;
`;

interface HowToUseProps {
  product: Product;
}

export function ProductHowToUse(props: HowToUseProps) {
  const { product } = props;

  if (!product.how_to_use) {
    return null;
  }

  const steps = product.how_to_use.split('\n').map((text: string, index: number) => ({
    text,
    key: index,
  }));

  return (
    <div>
      <Heading>How to Use</Heading>
      <div className="d-flex">
        <ProductUseList>
          {steps.map((step) => (
            <li key={step.key} className="my-3">
              {step.text}
            </li>
          ))}
        </ProductUseList>
      </div>
    </div>
  );
}

export default ProductHowToUse;
