import axios from 'axios';

import settings from '@/constants/constants';

const API_URL = `${settings.url}/supplier`;

const onboardSupplier = (data: unknown) => axios.post(`${API_URL}/`, { data });

const updateSupplier = (data: unknown, supplierId: number) => axios.put(`${API_URL}/${supplierId}`, { data });

export type Supplier = {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  supplier_name: string;
  supplier_code: string;
  montly?: number;
  total?: number;
};

export type SupplierResults = {
  result: Supplier[];
};

const getSupplierData = (active: number, excelData: boolean) =>
  axios.get<SupplierResults>(`${API_URL}/`, {
    params: {
      active,
      excelData,
    },
  });

// eslint-disable-next-line import/prefer-default-export
export const SupplierService = {
  onboardSupplier,
  updateSupplier,
  getSupplierData,
};
