/* eslint-disable camelcase */

// Partial interfaces only containing the fields used for building links

export interface DoctorBookingDetails {
  calcom_follow_up_consult_event: string | null;
  email: string | null;
}
interface Consult {
  calcom_consultation_uuid: string | null;
  doctorBookingDetails?: DoctorBookingDetails | null;
}
interface Doctor {
  calcom_follow_up_consult_event: string | null;
  email: string | null;
}

interface BookingLinks {
  // Cal.com uses relative urls, as we set the host env in the calendar component...
  calcom: string | null;
}

export const getCalcomUsernameFromEmail = (email: string): string => {
  const emailUsername = email.substring(0, email.lastIndexOf('@')).replace(/\.|\+|_/g, '-');
  return emailUsername.toLowerCase();
};

// Generates a doctors cal.com followup calendar url
export const buildBookingLinks = ({ calcom_follow_up_consult_event, email }: Doctor): BookingLinks => ({
  calcom:
    calcom_follow_up_consult_event && email
      ? `/${getCalcomUsernameFromEmail(email)}/${calcom_follow_up_consult_event}`
      : '',
});

export const buildCancellationLinks = ({ calcom_consultation_uuid }: Consult): BookingLinks => ({
  calcom: calcom_consultation_uuid && `/booking/${calcom_consultation_uuid}?cancel=true&allRemainingBookings=false`,
});

export const buildDoctorFollowUpLink = (
  calcom_consultation_uuid: string,
  doctorBookingDetails: DoctorBookingDetails,
): string | null => {
  const followUpConsultSlug = doctorBookingDetails?.calcom_follow_up_consult_event;
  const doctorUsername = doctorBookingDetails.email && getCalcomUsernameFromEmail(doctorBookingDetails.email);

  if (!followUpConsultSlug || !doctorUsername) {
    return null;
  }

  return `${doctorUsername}/${followUpConsultSlug}?rescheduleUid=${calcom_consultation_uuid}`;
};

/**
 * Passing calcom_consultation_uuid to reschedule/{uid} will redirect user to the correct event type calendar.  No need to generate username and eventtype slugs
 * This route will also prescheck if consult has been rescheduled before.  Other url will not.
 */
export const buildRescheduleLinks = ({ calcom_consultation_uuid }: Consult): BookingLinks => ({
  calcom: calcom_consultation_uuid && `reschedule/${calcom_consultation_uuid}`,
});

export default buildBookingLinks;
