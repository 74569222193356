import { HighlightOff as HighlightOffIcon, History as HistoryIcon } from '@mui/icons-material';
import { Box, Card, CardContent, Stack, styled, Typography } from '@mui/material';

import CalendarPopupButton from '@/components/common/CalendarPopupButton';
import { lightBlue } from '@/components/products/detail/common';
import { Consultation } from '@/hooks/rest/useGetPatientConsultations';
import { buildCancellationLinks, buildRescheduleLinks, DoctorBookingDetails } from '@/utils/calendar';

import { CONSULTATION_STATUS, getConsultationStatus, getConsultationType, getDate, getPaymentStatus } from './common';

function ConsultationCard({
  consultation,
  doctorBookingDetails,
}: {
  consultation: Consultation;
  doctorBookingDetails: DoctorBookingDetails;
}) {
  const cancellationLinks = buildCancellationLinks({
    calcom_consultation_uuid: consultation.calcom_consultation_uuid || null,
  });
  const rescheduleLinks = buildRescheduleLinks({
    calcom_consultation_uuid: consultation.calcom_consultation_uuid || null,
    doctorBookingDetails: doctorBookingDetails || null,
  });

  const StyledHistoryIcon = styled(HistoryIcon)`
    pointer-events: none;
    color: ${lightBlue};
  `;

  const StyledHighlightOffIcon = styled(HighlightOffIcon)`
    pointer-events: none;
  `;

  return (
    <Card variant="outlined" sx={{ marginBottom: '1rem' }}>
      <CardContent>
        <Stack direction="row" spacing={5}>
          <Box>{getConsultationStatus(consultation.ConsultationStatus?.name)}</Box>
          <Box flexGrow={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography fontWeight="bold">{getDate(consultation.start_time, consultation.end_time).date}</Typography>
              {consultation.ConsultationStatus?.name === CONSULTATION_STATUS.Scheduled && (
                <Stack direction="row" spacing={2}>
                  {rescheduleLinks.calcom && (
                    <CalendarPopupButton
                      link={rescheduleLinks.calcom}
                      buttonProps={{ className: 'table-btn', style: { backgroundColor: 'transparent' } }}
                    >
                      <StyledHistoryIcon />
                    </CalendarPopupButton>
                  )}
                  {cancellationLinks.calcom && (
                    <CalendarPopupButton link={cancellationLinks.calcom}>
                      <StyledHighlightOffIcon />
                    </CalendarPopupButton>
                  )}
                </Stack>
              )}
            </Stack>
            <Typography>{getDate(consultation.start_time, consultation.end_time).time}</Typography>
            <Typography margin="1rem 0">I.D. {consultation.consultation_code}</Typography>
            <Typography color="primary">
              Nurse: {consultation.Nurse?.NurseUser.first_name} {consultation.Nurse?.NurseUser.last_name}
            </Typography>
            <Typography color="primary">
              Doctor: {consultation.Doctor?.DoctorUser.first_name} {consultation.Doctor?.DoctorUser.last_name}
            </Typography>
            <Stack direction="row" marginTop="1rem" spacing={2}>
              <>{getConsultationType(consultation.consult_type)}</>
              <>{getPaymentStatus(consultation.payment_status)}</>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ConsultationCard;
