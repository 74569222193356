import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Container, styled } from '@mui/material';

import CircleLoader from '@/components/common/circleLoader';
import Error, { ErrorProps } from '@/components/error/Error';
import UnauthenticatedLayout from '@/components/layout/UnauthenticatedLayout';
import settings from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { PaymentService } from '@/services/payment.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('PayByInvoice');

const ContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PayByInvoice() {
  const params = useParams<{ token: string }>();
  const [error, setError] = useState<boolean | ErrorProps>(false);
  const history = useHistory();

  const loggedInPatient = AuthService.isPatient();

  const handleRefillClick = () => {
    if (!loggedInPatient) {
      history.push('/login/patient', { from: { pathname: '/patient/refill' } });
    } else {
      history.push('/patient/refill');
    }
  };

  const getCheckoutRedirectUrlForUrl = async () => {
    setError(false);
    const { token } = params;
    const {
      checkoutRedirectUrl,
      orderCode,
      error: responseError,
      isPaid,
      paidOrderAttributes,
      userStatus,
      isUnavailable,
    } = await PaymentService.getCheckoutRedirectUrlForInvoice(token);

    if (isPaid) {
      history.push('/payment-success', {
        orderCode,
        firstName: paidOrderAttributes?.userFirstName,
        email: paidOrderAttributes?.userEmail,
        onBoardKey: paidOrderAttributes?.onBoardKey,
        transactionType: paidOrderAttributes?.transactionType,
      });
    } else if (checkoutRedirectUrl) {
      window.location.replace(checkoutRedirectUrl);
    } else if (isUnavailable) {
      setError({
        message: 'This order is no longer valid, please visit the Refill page to place a new order',
        buttons: [
          <Button variant="contained" onClick={handleRefillClick}>
            Refill medication
          </Button>,
        ],
      });
    } else if (userStatus?.isDischarged) {
      setError({
        message: 'You have been discharged. Please contact our support team.',
        buttons: [
          <Button variant="contained" href={settings.support.site.url}>
            Contact support
          </Button>,
        ],
      });
    } else if (userStatus?.isUserActive === false) {
      setError({
        message: 'Your account is not active. Please contact our support team.',
        buttons: [
          <Button variant="contained" href={settings.support.site.url}>
            Contact support
          </Button>,
        ],
      });
    } else {
      logger.error('Error retrieving checkout redirect URL', responseError);
      setError(true);
    }
  };

  useEffect(() => {
    getCheckoutRedirectUrlForUrl();
  }, []);

  return (
    <UnauthenticatedLayout>
      {!error && <CircleLoader />}
      {error && <ContentContainer>{typeof error === 'boolean' ? <Error /> : <Error {...error} />}</ContentContainer>}
    </UnauthenticatedLayout>
  );
}

export default PayByInvoice;
