import React from 'react';
import FilterableTable from 'rc-data-table';
import CircleLoader from '../common/circleLoader';
import { getProductReviews, setProductRatingDisplay, setProductRatingDisplayAll } from '@/services/product.service';
import { ToastConfirmModal, toastOptions } from '../common/toastConfirm';
import { toast } from 'react-toastify';
import Button from '../common/button';
import axios from 'axios';
import SwitchProduct from '../../components/toggle/switchProduct';

const ProductReviews = (props) => {
  const [data, setData] = React.useState({
    loading: true,
    reviews: [],
    product_id: false,
  });

  const loadData = React.useCallback(async () => {
    const product_id = props.product_id ? props.product_id : null;
    const resp = await getProductReviews(product_id);
    setData({ reviews: resp.data, loading: false, product_id: Boolean(product_id) });
  }, []);
  React.useEffect(() => {
    loadData();
  }, [props.product_id]);

  const setRatingDisplay = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-console
    const dataset = e.target.dataset;
    const featuredReview = data.reviews;
    const review = featuredReview.find((itm) => itm.id === Number(dataset.key));
    if (!review.show_review) {
      const previousReview = featuredReview.find((itm) => itm.product_id === review.product_id && itm.show_review);
      toast(
        <ToastConfirmModal
          onConfirm={async () => {
            await setProductRatingDisplay({
              ratingId: Number(dataset.key),
              productId: Number(dataset.product),
            });
            loadData();
          }}
        >
          {previousReview ? (
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              <p>Are you sure you want to replace the existing featured review?</p>
              <p>{previousReview.remarks}</p>
            </div>
          ) : (
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              {' '}
              <p>Are you sure you want to configure this as a featured review?</p>
              <p>{review.remarks}</p>
            </div>
          )}
        </ToastConfirmModal>,
        toastOptions,
      );
    }
  };

  const setRatingDisplayAll = async (e) => {
    e.preventDefault();
    const dataset = e.target.dataset;
    const checkReview = data.reviews;
    const review = checkReview.find((itm) => itm.id === Number(dataset.key));

    if (!review.show_review) {
      let displayReview = true;
      if (dataset.set === 'true') {
        displayReview = false;
      }
      await setProductRatingDisplayAll({
        ratingId: Number(dataset.key),
        productId: Number(dataset.product),
        displayReview: displayReview,
      });
      loadData();
    }
  };

  const downloadFile = async () => {
    await axios({ url: 'excel/product/ratings', method: 'POST' }).then((resp) => {
      toast.success(resp.data.message);
    });
  };

  const fields = [
    {
      name: 'created_date',
      displayName: 'Date Created',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
    },
    { name: 'modified_date', displayName: 'Edited', inputFilterable: true, sortable: false, tdClassName: 'sub-text' },
    {
      name: 'patient_code',
      displayName: 'Patient ID',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text',
    },
    {
      name: 'patient_name',
      displayName: 'Patient Name',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
    },
    {
      name: 'product_name',
      displayName: 'Product Name',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
      visible: !data.product_id,
    },
    { name: 'rating', displayName: 'Rating', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
    { name: 'remarks', displayName: 'Feedback', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
    {
      name: 'id',
      displayName: 'Featured review',

      // eslint-disable-next-line react/display-name
      render: (e) => (
        <SwitchProduct
          dataKey={e.value}
          dataProduct={e.record.product_id}
          dataSet={'featured'}
          isOn={e.record.show_review}
          handleToggle={(e) => setRatingDisplay(e)}
        />
      ),
    },
    {
      name: 'display_id',
      displayName: 'Display review',
      // eslint-disable-next-line react/display-name
      render: (e) => (
        <SwitchProduct
          dataKey={e.value}
          dataProduct={e.record.product_id}
          dataSet={e.record.display_review}
          isOn={e.record.display_review}
          handleToggle={(e) => setRatingDisplayAll(e)}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="container-fluid">
        <h3>Product Reviews</h3>
        {data.loading ? (
          <CircleLoader />
        ) : (
          <>
            <Button text="Export All" className="btn btn-md btn-primary float-sm-right" onClick={downloadFile} />
            <FilterableTable
              className="table-responsive"
              topPagerVisible={false}
              data={data.reviews}
              fields={fields}
              pageSizes={false}
              pageSize={50}
              initialSort={'id'}
              serverSort={() => {}}
              filterPlaceholder="Search"
              initialSortDir={false}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductReviews;
