import React, { Component } from 'react';
import AllCreditView from '../../components/credit/allCreditView';
import SummaryCreditView from '../../components/credit/summaryCreditView';

class creditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'credit',
    };
  }

  handleToggle = (activeTab) => {
    this.setState({ activeTab });
  };

  render() {
    return <div>{this.state.activeTab === 'credit' ? <AllCreditView {...this.props} /> : <SummaryCreditView />}</div>;
  }
}

export default creditView;
