import React from 'react';
import './switchProduct.scss';

const SwitchProduct = ({ isOn, handleToggle, dataKey, dataProduct, dataSet }) => {
  return (
    <div className="d-flex justify-content-center">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new-${dataSet}-${dataKey}`}
        type="checkbox"
        data-key={dataKey}
        data-product={dataProduct}
        data-set={dataSet}
      />
      <label
        style={{ background: isOn && '#194457' }}
        className="react-switch-label"
        htmlFor={`react-switch-new-${dataSet}-${dataKey}`}
      >
        <span className={'react-switch-button'} />
      </label>
    </div>
  );
};

export default SwitchProduct;
