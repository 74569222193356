import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { User } from '@/types';
import identifyUserForLaunchDarkly from '@/utils/identifyUserForLaunchDarkly';
import { Logger } from '@/utils/logger';

const logger = new Logger('FFLoadingStateProvider');

interface LaunchDarklyContextContextType {
  isReady: boolean;
  isIdentified: boolean;
  setUser: (user: User) => void;
}

const LaunchDarklyContext = createContext<LaunchDarklyContextContextType>({
  isReady: false,
  isIdentified: false,
  setUser: () => null,
} as LaunchDarklyContextContextType);

export const useLaunchDarkly = () => {
  const context = useContext(LaunchDarklyContext);
  if (!context) {
    throw new Error('useLaunchDarkly must be used within a LaunchDarklyProvider');
  }
  return context;
};

export function LaunchDarklyProvider({ children }: { children: ReactNode }) {
  const ldClient = useLDClient();
  const [isReady, setIsReady] = useState(false);
  const [isIdentified, setIsIdentified] = useState(false);

  const setUser = useCallback(
    (user: User) => {
      if (ldClient) {
        identifyUserForLaunchDarkly(ldClient, user).then(() => {
          setIsIdentified(true);
        });
      }
    },
    [ldClient],
  );

  useEffect(() => {
    if (ldClient) {
      ldClient
        .waitUntilReady()
        .then(() => {
          setIsReady(true);
        })
        .catch(() => {
          setIsReady(true);
          logger.error('LaunchDarkly client failed to initialize');
        });
    }
  }, [ldClient]);

  const value = useMemo(
    () => ({ isReady, setUser, isIdentified: isReady && isIdentified }),
    [isReady, isIdentified, setUser],
  );

  return <LaunchDarklyContext.Provider value={value}>{children}</LaunchDarklyContext.Provider>;
}
