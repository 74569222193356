import React, { useState } from 'react';
import Slider from 'react-slick';
import { useTheme } from '@emotion/react';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const maxSlidesToShow = 4;

const ArrowButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  height: '40px',
  width: '40px',
  position: 'absolute',
  ':hover': {
    color: theme.palette.common.black,
  },
  '::before': {
    content: 'none',
  },
  svg: {
    width: '100%',
  },
}));

const ThumbnailsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

type ProductImagesCarouselProps = {
  images: string[];
};

function ProductImagesCarousel({ images }: ProductImagesCarouselProps) {
  const theme = useTheme();
  const [mainSlider, setMainSlider] = useState<Slider | undefined>();
  const [thumbnailsSlider, setThumbnailsSlider] = useState<Slider | undefined>();

  return (
    <Box>
      <Slider
        asNavFor={thumbnailsSlider}
        ref={(slider: Slider) => setMainSlider(slider)}
        slidesToShow={1}
        slidesToScroll={1}
        infinite
        adaptiveHeight
        responsive={[
          {
            breakpoint: theme.breakpoints.values.lg,
            settings: {
              dots: true,
              prevArrow: (
                <ArrowButton
                  disableRipple
                  sx={{
                    marginLeft: '25px',
                    zIndex: 999,
                  }}
                >
                  <ChevronLeftIcon />
                </ArrowButton>
              ),
              nextArrow: (
                <ArrowButton
                  disableRipple
                  sx={{
                    marginRight: '25px',
                    zIndex: 999,
                  }}
                >
                  <ChevronRightIcon />
                </ArrowButton>
              ),
            },
          },
        ]}
      >
        {images.map((image, index) => (
          <Box key={`${image}`}>
            <img src={image} alt={`Product ${index}`} width="100%" />
          </Box>
        ))}
      </Slider>
      <ThumbnailsContainer>
        <Slider
          asNavFor={mainSlider}
          ref={(slider: Slider) => setThumbnailsSlider(slider)}
          slidesToScroll={1}
          slidesToShow={Math.min(maxSlidesToShow, images.length)}
          infinite={false}
          centerMode={false}
          focusOnSelect
          prevArrow={
            <ArrowButton size="small" disableRipple>
              <ChevronLeftIcon />
            </ArrowButton>
          }
          nextArrow={
            <ArrowButton size="small" disableRipple>
              <ChevronRightIcon />
            </ArrowButton>
          }
        >
          {images.map((image, index) => (
            <Box key={image}>
              <img
                src={image}
                alt={`Product ${index}`}
                style={{
                  maxWidth: '100%',
                  padding: '5px',
                }}
              />
            </Box>
          ))}
        </Slider>
      </ThumbnailsContainer>
    </Box>
  );
}
export default ProductImagesCarousel;
