import { useQuery } from 'react-query';

import { getPatientOrderSummary, IPatientOrderSummary } from '@/services/data.service';

function useGetPatientOrderSummary() {
  return useQuery(
    ['getPatientOrderSummary'],
    async (): Promise<{ data: IPatientOrderSummary }> => {
      const { data } = await getPatientOrderSummary();
      return data;
    },
    {
      select: (response) => response.data,
    },
  );
}

export default useGetPatientOrderSummary;
