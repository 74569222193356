import React from 'react';
import Header from '../layout/header';
import { AuthService } from '../../services/authentication.service';

const SignOut = () => {
  React.useEffect(() => {
    AuthService.logout();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div>Signing out...</div>
    </React.Fragment>
  );
};

export default SignOut;
