import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Autocomplete, ListItem, TextField } from '@mui/material';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import useGooglePlaces from '@/providers/googlePlacesAutoComplete';
import { Logger } from '@/utils/logger';

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CED4DA',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CDD4D8',
  },
}));

type AddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

type PlaceDetails = {
  address_components: AddressComponents[];
};

type MainTextMatchedSubstrings = {
  offset: number;
  length: number;
};

type StructuredFormatting = {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
};

type PlaceType = {
  description: string;
  structured_formatting: StructuredFormatting;
};

type AddressDataType = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  street_number: string;
  city: string;
  subpremise: string;
};

export type LocationInputProps = {
  name: string;
  value: string;
  id: string;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  onSelectedAddress: (address: AddressDataType) => void;
  onReset?: () => void;
};

const logger = new Logger('LocationInput');

const fillInAddressComponents = (addressComponents: AddressComponents[]) =>
  addressComponents.reduce<AddressDataType>(
    (acc, component) => {
      const [componentType] = component.types;
      switch (componentType) {
        case 'locality':
          return { ...acc, suburb: component.long_name };

        case 'administrative_area_level_1':
          return { ...acc, state: component.short_name };

        case 'administrative_area_level_2':
          return { ...acc, city: component.short_name };

        case 'postal_code':
          return { ...acc, postcode: component.long_name };

        case 'route':
          return { ...acc, address: component.long_name };

        case 'street_number':
          return { ...acc, street_number: component.long_name };

        case 'subpremise':
          return { ...acc, subpremise: component.long_name };

        default:
          return acc;
      }
    },
    {
      address: '',
      suburb: '',
      city: '',
      state: '',
      postcode: '',
      street_number: '',
      subpremise: '',
    },
  );

export default function LocationInput({
  name,
  value,
  label,
  className,
  style,
  id,
  onSelectedAddress,
  onReset,
}: LocationInputProps) {
  const [selectedAddress, setSelectedAddress] = useState<PlaceDetails | null>(null);
  const [error, setError] = useState<string>('');

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    debounce: 300,
    callbackName: 'initMap',
    requestOptions: {
      componentRestrictions: { country: 'au' },
      types: ['address'],
    },
    initOnMount: false,
  });

  const { loading } = useGooglePlaces({ onLoad: () => init() });

  const options = !data.length ? { freeSolo: true, autoSelect: true } : {};

  const validateAddress = (result: PlaceDetails) => {
    if (!result.address_components.find((c) => c.types.includes('street_number'))) {
      setError('Invalid address, please type street number.');
      logger.debug('Invalid address, please type street number.');
    }
  };

  const fillInAddress = (result: PlaceDetails) => {
    onSelectedAddress(fillInAddressComponents(result.address_components));
  };

  useEffect(() => {
    if (selectedAddress) {
      validateAddress(selectedAddress);
      fillInAddress(selectedAddress);
    }
  }, [selectedAddress]);

  const resetForm = () => {
    clearSuggestions();
    setValue('');
    setError('');
    setSelectedAddress(null);
    if (onReset) {
      onReset();
    }
  };

  const handleSelect = async (addressResult: PlaceType | string | null) => {
    if (!addressResult) {
      resetForm();
      return;
    }

    const address = typeof addressResult === 'string' ? addressResult : addressResult.description;

    if (options.freeSolo) {
      onSelectedAddress({
        suburb: '',
        city: '',
        state: '',
        postcode: '',
        street_number: '',
        address,
        subpremise: '',
      });
      return;
    }

    logger.info('Google Geocode API request.');

    if (address) {
      try {
        const results = await getGeocode({ address });
        if (results.length > 0) {
          resetForm();
          setSelectedAddress(results[0]);
          logger.info('Google Geocode API request successful.');
        }

        clearSuggestions();
      } catch (e) {
        if (e instanceof Error) {
          logger.error('Error during Google Geocode API request', e);
        }
        logger.error('Unexpected error during Google Geocode API request');
      }
    }
  };

  return (
    <div>
      <Autocomplete
        disabled={loading}
        id={id}
        className="combobox-input"
        style={style}
        value={value}
        options={status === 'OK' ? data : []}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        size="small"
        onChange={(_event, newValue) => {
          handleSelect(newValue);
        }}
        {...options}
        renderInput={(params) => (
          <CustomTextField
            name={name}
            label={label}
            variant="outlined"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            style={error ? { border: '1px solid red' } : {}}
            error={!!error}
            className={className}
            {...params}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.place_id}>
            {option.description}
          </ListItem>
        )}
      />
      {error && <div>{error}</div>}
    </div>
  );
}
