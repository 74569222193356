// import settings from '@/constants/constants';

/**
 * Generates a URL for a doctor's booking page on Cal.com.
 *
 * @param {string} calcomUsername - The Cal.com username of the doctor.
 * @param {string} consultationSlug - The slug of the consultation.
 * @return {string} The URL of the doctor's booking page.
 */
const buildDoctorBookingLink = (calcomUsername: string | undefined, consultationSlug: string | undefined): string => {
  if (!calcomUsername || !consultationSlug) {
    return '';
  }
  // return `${settings.calendarConfig.calOrigin}/${calcomUsername}/${consultationSlug}`;
  return `/${calcomUsername}/${consultationSlug}`;
};

export default buildDoctorBookingLink;
