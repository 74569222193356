import { AppRouter, initClient, tsRestFetchApi } from '@ts-rest/core';

import getAuthBearerToken from '@/utils/getAuthBearerToken';

import { apiConfig } from './api.config';

/**
 * The default tsrest core client to use for retrieving a response from Circuit API endpoints.
 * @param contract {T extends AppRouter} - the contract to initialise with this client.
 */
const tsRestClient = <T extends AppRouter>(contract: T) =>
  initClient(contract, {
    validateResponse: true,
    baseUrl: apiConfig().baseApiUrl,
    baseHeaders: {
      'Content-Type': 'application/json',
    },
    api: async (args) => {
      const authBearerToken = getAuthBearerToken();
      return tsRestFetchApi({
        ...args,
        headers: {
          ...args.headers,
          authorization: authBearerToken,
        },
      });
    },
  });

export default tsRestClient;
