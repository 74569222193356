import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './accordion.scss';
import TopBanner from './top-banner';

function Research() {
  return (
    <React.Fragment>
      <div className="h-100 resources-content">
        <TopBanner pText="Montu Research Library" h1Text="Medical Cannabis Research Reports" />
        <div className="container form-container mt-5">
          <h2>Montu Research Library</h2>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Austism Spectrum Disorder</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Autism+Spectrum+Disorder/ASD+-+Austism+Spectrum+Disorder.+A+Genetics+Review+-+2011.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    ASD - Austism Spectrum Disorder. A Genetics Review - 2011
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Autism+Spectrum+Disorder/ASD+-+Cannabinoids+for+People+with+ASD+A+Systematic+Review+of+Published+and+Ongoing+Studies+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    ASD - Cannabinoids for People with ASD A Systematic Review of Published and Ongoing Studies - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Autism+Spectrum+Disorder/Autism+Spectrum+Disorder+-+Cannabis+and+Autism+-+A+Complex+Pairing+-+CBD+and+THC+Ease+Behavioural+and+Health+Problems+Linked+to+Autism+Spectrum+Disorder+-+2021.pdf"
                    target="blank"
                  >
                    Autism Spectrum Disorder - Cannabis and Autism - A Complex Pairing - CBD and THC Ease Behavioural
                    and Health Problems Linked to Autism Spectrum Disorder - 2021
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Autoimmune Diseases
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a href="/*" rel="noreferrer noopener" target="_blank">Multiple Sclerosis - The Effect of Cannabis on the Clinical and Cytokine Profiles in Patients with Multiple Sclerosis - 2021</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Cancer</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Breast+Cancer+-+Cannabinoids+Reduce+ErbB2-Driven+Breast+Cancer+Progression+Through+Akt+Inhibition+-+2010.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Breast Cancer - Cannabinoids Reduce ErbB2-Driven Breast Cancer Progression Through Akt Inhibition -
                    2010
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+-+A+User's+Guide+to+Cannabinoid+Therapies+in+Oncology+-+2016.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer - A User's Guide to Cannabinoid Therapies in Oncology - 2016
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+-+Integrating+Cannabis+into+Clinical+Cancer+Care+-+2016.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer - Integrating Cannabis into Clinical Cancer Care - 2016
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+-+Rates+of+Cannabis+Use+in+Patients+with+Cancer+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer - Rates of Cannabis Use in Patients with Cancer - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+(Gynecologic)+-+Prescribed+Medical+Cannabis+in+Women+with+Gynecologic+Malignancies+a+Single-Institution+Survey-Based+Study+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer (Gynecologic) - Prescribed Medical Cannabis in Women with Gynecologic Malignancies a
                    Single-Institution Survey-Based Study - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+(Melanoma)+-+Roles+of+Cannabinoids+in+Melanoma+Evidence+From+in+vivo+Studies+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer (Melanoma) - Roles of Cannabinoids in Melanoma Evidence From in vivo Studies - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+(Paediatric)+-+The+Role+of+Cannabinoids+as+Anticancer+Agents+in+Pediatric+Oncology+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer (Paediatric) - The Role of Cannabinoids as Anticancer Agents in Pediatric Oncology - 2021
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer+(Paeds+Neuro)+-+Assessment+of+Cannabidiol+and+9-Tetrahydrocannabiol+in+Mouse+Models+of+Medulloblastoma+and+Ependymoma+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer (Paeds Neuro) - Assessment of Cannabidiol and 9-Tetrahydrocannabiol in Mouse Models of
                    Medulloblastoma and Ependymoma - 2021
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cancer/Cancer%2C+Microbial+Disease+and+Immunity+-+Cannabinoids+in+Medicine%3A+Cancer%2C+Immunity+and+Microbial+Diseases+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cancer, Microbial Disease and Immunity - Cannabinoids in Medicine: Cancer, Immunity and Microbial
                    Diseases - 2021
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Cardiovascular Disease</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Cardiovascular+Disease/Cardiovascular+Disease+-+The+Effects+of+Cannabidiol%2C+a+Non-Intoxicating+Compound+of+Cannabis%2C+on+the+Cardiovascular+System+in+Health+and+Disease+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Cardiovascular Disease - The Effects of Cannabidiol, a Non-Intoxicating Compound of Cannabis, on the
                    Cardiovascular System in Health and Disease - 2020
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>CINV - Chemotherapy Induced Nausea and Vomiting</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chemotherapy+Induced+Nausea+and+Vomiting+(CINV)/CINV+-+Oral+THC%3ACBD+Cannabis+Extract+for+Refractory+Chemotherapy-Induced+Nausea+and+Vomiting+-+a+Randomised%2C+Placebo-Controlled%2C+Phase+II+Crossover+Trial+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    CINV - Oral THC/CBD Cannabis Extract for Refractory Chemotherapy-Induced Nausea and Vomiting - a
                    Randomised, Placebo-Controlled, Phase II Crossover Trial - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chemotherapy+Induced+Nausea+and+Vomiting+(CINV)/CINV+-+Recent+Developments+in+the+Prevention+of+CINV.+A+Comprehensive+Review+-+2015.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    CINV - Recent Developments in the Prevention of CINV. A Comprehensive Review - 2015
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Chronic Pain</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Arthritis+-+The+Non-Psychoactive+Cannabis+Constituent+Cannabidiol+is+an+Oral+Anti-Arthritic+Therapeutic+in+Murine+Collagen-Induced+Arthritis+-+2000.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Arthritis - The Non-Psychoactive Cannabis Constituent Cannabidiol is an Oral Anti-Arthritic
                    Therapeutic in Murine Collagen-Induced Arthritis - 2000
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Chronic+Pain+-+Cannabinoid-Opioid+Interaction+in+Chronic+Pain+-+2011.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Chronic Pain - Cannabinoid-Opioid Interaction in Chronic Pain - 2011
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Chronic+Pain+and+Opioid+Reduction+-+Medical+Cannabis+for+the+Reduction+of+Opioid+Dosage+in+the+Treatment+of+Non-Cancer+Chronc+Pain+-+A+Systematic+Review+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Chronic Pain and Opioid Reduction - Medical Cannabis for the Reduction of Opioid Dosage in the
                    Treatment of Non-Cancer Chronc Pain - A Systematic Review - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Fibromyalgia+-+Association+of+Concomitant+Fibromyalgia+With+Worse+Disease+Activity+Score+in+28+Joints%2C+Health+Assessment+Questionnaire%2C+and+Short+Form+36+Scores+in+Patients+with+Rheumatoid+Arthritis+-+2009.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Fibromyalgia - Association of Concomitant Fibromyalgia With Worse Disease Activity Score in 28
                    Joints, Health Assessment Questionnaire, and Short Form 36 Scores in Patients with Rheumatoid
                    Arthritis - 2009
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Fibromyalgia+-+Effect+of+Adding+Medical+Cannabis+to+Analgesic+Treatment+in+Patients+with+Low+Back+Pain+Related+to+Fibromyalgia%3A+An+Observational+Cross-Over+Single+Centre+Study+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Fibromyalgia - Effect of Adding Medical Cannabis to Analgesic Treatment in Patients with Low Back
                    Pain Related to Fibromyalgia: An Observational Cross-Over Single Centre Study - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Fibromyalgia+-+Medical+Cannabis+for+the+Treatment+of+Fibromyalgia+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Fibromyalgia - Medical Cannabis for the Treatment of Fibromyalgia - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Fibromyalgia+-+Safety+and+Efficacy+of+Medical+Cannabis+in+Fibromyalgia+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Fibromyalgia - Safety and Efficacy of Medical Cannabis in Fibromyalgia - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Medical+management+of+osteoarthritis+of+the+knee+and+hip+joints.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Medical management of osteoarthritis of the knee and hip joints
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/National+Action+Plan+for+Pain+Management+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    National Action Plan for Pain Management - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Neuro%3AChronic+Pain+-+Migraine+Frequency+Decrease+Following+Prolonged+Medical+Cannabis+Treatment+A+Cross-Sectional+Study+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Neuro/Chronic Pain - Migraine Frequency Decrease Following Prolonged Medical Cannabis Treatment A
                    Cross-Sectional Study - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Pain+-+Cannabidiol+for+Pain+Treatment+Focus+on+Pharmacology+and+Mechanism+of+Action+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pain - Cannabidiol for Pain Treatment Focus on Pharmacology and Mechanism of Action - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Pain+-+Management+of+Chronic+Pain+-+1999.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pain - Management of Chronic Pain - 1999
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Pain+-+The+Effectiveness+of+Self-Directed+Medical+Cannabis+Treatment+for+Pain+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pain - The Effectiveness of Self-Directed Medical Cannabis Treatment for Pain - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Pain+(Arthritis)+-+The+Prevalence%2C+Cost+and+Disease+Burden+of+Arthritis+in+Australia+-+2001.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pain (Arthritis) - The Prevalence, Cost and Disease Burden of Arthritis in Australia - 2001
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Pain+(Neuro)+-+Pharmacologic+Management+of+Neuropathic+Pain+Evidence-Based+Recommendations+-+2007.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pain (Neuro) - Pharmacologic Management of Neuropathic Pain Evidence-Based Recommendations - 2007
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Chronic+Pain/Pain+(Sickle+Cell+Disease)+-+Considerations+for+Cannabis+Use+to+Treat+Pain+in+Sickle+Cell+Disease+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pain (Sickle Cell Disease) - Considerations for Cannabis Use to Treat Pain in Sickle Cell Disease -
                    2020
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Dementia</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Dementia/Alzheimer's+-+A+Review+on+Studies+of+Marijuana+for+Alzheimer's+Disease+-+Focusing+on+CBD%2C+THC+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Alzheimer's - A Review on Studies of Marijuana for Alzheimer's Disease - Focusing on CBD, THC - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Dementia/Alzheimer's+-+Cannabidiol+Amerliorates+Cognitive+Function+via+Regulation+of+IL-33+and+TREM2+Upregulation+in+a+Murine+Model+of+Alzheimer's+Disease+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Alzheimer's - Cannabidiol Amerliorates Cognitive Function via Regulation of IL-33 and TREM2
                    Upregulation in a Murine Model of Alzheimer's Disease - 2021
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Dementia/Alzheimer's+-+Destabilisation+of+the+Alzheimer's+Amyloid-Beta+Protofibrils+by+THC%3A+Molecular+Dynamics+Simulation+Study+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Alzheimer's - Destabilisation of the Alzheimer's Amyloid-Beta Protofibrils by THC: Molecular
                    Dynamics Simulation Study - 2021
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Drug Interactions</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Drug+Interactions/Benzodiazepines+-+Reduction+of+Benzodiazepine+Use+in+Patients+Prescribed+Medical+Cannabis+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Benzodiazepines - Reduction of Benzodiazepine Use in Patients Prescribed Medical Cannabis - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Drug+Interactions/CBD+Drug+Interactions+-+Potential+Adverse+Drug+Events+and+Drug-Drug+Interactions+with+Medical+and+Consumer+Cannabidiol+(CBD)+Use+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    CBD Drug Interactions - Potential Adverse Drug Events and Drug-Drug Interactions with Medical and
                    Consumer Cannabidiol (CBD) Use - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Drug+Interactions/Chronic+Pain+and+Opioid+Reduction+-+Medical+Cannabis+for+the+Reduction+of+Opioid+Dosage+in+the+Treatment+of+Non-Cancer+Chronc+Pain+-+A+Systematic+Review+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Chronic Pain and Opioid Reduction - Medical Cannabis for the Reduction of Opioid Dosage in the
                    Treatment of Non-Cancer Chronc Pain - A Systematic Review - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Drug+Interactions/Opioid+Use+Disorder+-+Emerging+Evidence+for+Cannabis'+Role+in+Opioid+Use+Disorder+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Opioid Use Disorder - Emerging Evidence for Cannabis' Role in Opioid Use Disorder - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Drug+Interactions/THC+Drug+Interactions+-+Potential+Adverse+Drug+Events+with+Tetrahydrocannabinol+(THC)+Due+to+Drug-Drug+Interactions+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    THC Drug Interactions - Potential Adverse Drug Events with Tetrahydrocannabinol (THC) Due to
                    Drug-Drug Interactions - 2020
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Epilepsy</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Epilepsy/Epilepsy+-+Epilepsy+and+Cannabis.+A+literaute+review+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Epilepsy - Epilepsy and Cannabis. A literaute review - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Epilepsy/Epilepsy+-+Evidence+for+cannabis+and+cannabinoids+for+epilepsy.+A+systematic+review+of+controlled+and+observational+evidence.+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Epilepsy - Evidence for cannabis and cannabinoids for epilepsy. A systematic review of controlled
                    and observational evidence - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Epilepsy/Epilepsy+-+Trial+of+Cannabidiol+for+Drug-Resistant+Seizures+in+the+Dravet+Syndrome.+2017.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Epilepsy - Trial of Cannabidiol for Drug-Resistant Seizures in the Dravet Syndrome - 2017
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Epilepsy/Epilepsy+-+Use+of+Cannabidiol+in+the+Treatment+of+Epilepsy%3A+Efficacy+and+Security+in+Clinical+Trials+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Epilepsy - Use of Cannabidiol in the Treatment of Epilepsy: Efficacy and Security in Clinical Trials
                    - 2019
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Epilepsy/Epilepsy+-+Use+of+Cannabidiol+in+the+Treatment+of+Epilepsy.+Efficacy+and+Security+in+Clinical+Trials.+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Epilepsy - Use of Cannabidiol in the Treatment of Epilepsy. Efficacy and Security in Clinical Trials
                    - 2019
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Geriatrics</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Geriatrics/Geriatrics+-+Cannabis+an+Emerging+Treatment+for+Common+Symptoms+in+Older+Adults+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Geriatrics - Cannabis an Emerging Treatment for Common Symptoms in Older Adults - 2021
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Geriatrics/Geriatrics+-+Characteristics+of+Older+Adults+Who+Were+Early+Adopters+of+Medical+Cannabis+in+the+Florida+Medical+Marijuana+Use+Registry+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Geriatrics - Characteristics of Older Adults Who Were Early Adopters of Medical Cannabis in the
                    Florida Medical Marijuana Use Registry - 2020
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Inflammatory Bowel Syndrome or Disease</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Inflammatory+Bowel+Syndrome+or+Disease/Gastrointestinal+Disorders+-+Cannabidiol+and+Other+Non-Psychoactive+Cannabinoids+for+Prevention+and+Treatment+of+Gastrointestinal+Disorders+Useful+Nutraceuticals%3F+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Gastrointestinal Disorders - Cannabidiol and Other Non-Psychoactive Cannabinoids for Prevention and
                    Treatment of Gastrointestinal Disorders Useful Nutraceuticals? - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Inflammatory+Bowel+Syndrome+or+Disease/IBD+-+Cannabis+and+Canabidinoids+on+the+Inflammatory+Bowel+Diseases+Going+Beyond+Misuse+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    IBD - Cannabis and Canabidinoids on the Inflammatory Bowel Diseases Going Beyond Misuse - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Inflammatory+Bowel+Syndrome+or+Disease/Inflammatory+Bowel+Disease+(IBD)+-+Medicinal+Cannabis+for+Inflammatory+Bowel+Disease%3A+A+Survey+of+Perspectives%2C+Experiences+and+Current+Use+in+Australian+Patients+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Inflammatory Bowel Disease (IBD) - Medicinal Cannabis for Inflammatory Bowel Disease: A Survey of
                    Perspectives, Experiences and Current Use in Australian Patients - 2020
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Insomnia</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Insomnia/Insomnia+-+Cannabis+and+Insomnia+-+2011.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Insomnia - Cannabis and Insomnia - 2011
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Insomnia/Insomnia+-+Effectiveness+of+Raw%2C+Natural+Medical+Cannabis+Flower+for+Treating+Insomnia+Under+Naturalistic+Conditions+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Insomnia - Effectiveness of Raw, Natural Medical Cannabis Flower for Treating Insomnia Under
                    Naturalistic Conditions - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Insomnia/Insomnia+-+Prevelence+of+DSM-IV+Diagnostic+Critera+of+Insomnia.+Distinguishing+Insomnia+Related+to+Mental+Disorders+from+Sleep+Disorders+-+1996.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Insomnia - Prevelence of DSM-IV Diagnostic Critera of Insomnia. Distinguishing Insomnia Related to
                    Mental Disorders from Sleep Disorders - 1996
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Mental Health</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Mental+Health/Psychiatric+Disorders+-+New+Perspectives+on+the+Use+of+Cannabis+in+the+Treatment+of+Psychiatric+Disorders+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Psychiatric Disorders - New Perspectives on the Use of Cannabis in the Treatment of Psychiatric
                    Disorders - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Mental+Health/PTSD+-+Use+of+Medicinal+Cannabis+and+Synthetic+Cannabinoids+in+Post-Traumatic+Stress+Disorder+(PTSD)%3A+A+Systematic+Review+-+2019.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    PTSD - Use of Medicinal Cannabis and Synthetic Cannabinoids in Post-Traumatic Stress Disorder
                    (PTSD): A Systematic Review - 2019
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Multiple Scelrosis</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Multiple+Scelrosis/Multiple+Sclerosis+-+The+Effect+of+Cannabis+on+the+Clinical+and+Cytokine+Profiles+in+Patients+with+Multiple+Sclerosis+-+2021.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Multiple Sclerosis - The Effect of Cannabis on the Clinical and Cytokine Profiles in Patients with
                    Multiple Sclerosis - 2021
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Neurological Disorders</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Neurological+Disorders/Gilles+de+la+Tourette+Symdrome+-+Speechlessness+in+Gilles+de+la+Tourette+Syndrome+Cannabis+Based+Medicines+Improve+Severe+Vocal+Blocking+Tics+in+Two+Patients+-+2017.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Gilles de la Tourette Symdrome - Speechlessness in Gilles de la Tourette Syndrome Cannabis Based
                    Medicines Improve Severe Vocal Blocking Tics in Two Patients - 2017
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Neurological+Disorders/Neuro+Activity+Diseases+-+Structure+Activity+Relationship+of+Cannabis+Derived+Compounds+for+the+Treatment+of+Neuronal+Activity+Related+Diseases+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Neuro Activity Diseases - Structure Activity Relationship of Cannabis Derived Compounds for the
                    Treatment of Neuronal Activity Related Diseases - 2018
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Neurological+Disorders/Neuro%3AChronic+Pain+-+Migraine+Frequency+Decrease+Following+Prolonged+Medical+Cannabis+Treatment+A+Cross-Sectional+Study+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Neuro:Chronic Pain - Migraine Frequency Decrease Following Prolonged Medical Cannabis Treatment A
                    Cross-Sectional Study - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Neurological+Disorders/Parkinson's+-+Neuroprotection+or+Neurotoxicity+of+Illicit+Drugs+on+Parkinson's+Disease+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Parkinson's - Neuroprotection or Neurotoxicity of Illicit Drugs on Parkinson's Disease - 2020
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Palliative Care</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Palliative+Care/Palliative+Cancer+-+Short-Term+Medical+Cannabis+Treatment+Regimens+Produced+Beneficial+Effects+Among+Palliative+Cancer+Patients+-+2020.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Palliative Cancer - Short-Term Medical Cannabis Treatment Regimens Produced Beneficial Effects Among
                    Palliative Cancer Patients - 2020
                  </a>
                </p>
                <p>
                  <a
                    href="https://circuit.montu.com.au/assets/docs/research/Palliative+Care/Palliative+Care+-+Cannabis+in+palliative+care.+Current+Challenges+and+Practical+Recommendations+-+2018.pdf"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Palliative Care - Cannabis in palliative care. Current Challenges and Practical Recommendations -
                    2018
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Research;
