import { useState } from 'react';
import { InfoOutlined as BlueInfoIcon, WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';

import themeAlt from '../../theme';

import BookConsultationButton, { BookConsultationButtonProps } from './BookConsultationButton';

type BookConsultationProps = BookConsultationButtonProps & {
  showDoctorFollowupRoundRobin: boolean;
  doctorName?: string;
  doctorWaitTime: number | null;
  currentDoctorIsUnavailable: boolean;
};

const DOCTOR_FOLLOW_UP_ROUND_ROBIN_URL = 'team/alternaleaf-doctors/follow-up-doctor-consultation';

/**
 * For use when a patient makes the choice to see different Doctor to their currently assigned
 */
export default function BookRegularOrNewDoctorButton({
  showDoctorFollowupRoundRobin,
  calcomFollowUpConsultEvent,
  buttonProps,
  bookDoctorConsultationButtonText,
  disabled,
  handleBookingSuccess,
  handleRescheduleSuccess,
  handleCancelSuccess,
  doctorEmail,

  doctorName,
  currentDoctorIsUnavailable,
  doctorWaitTime,
}: BookConsultationProps) {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [modalOneOpen, setModalOneOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const useAltDoctorText = !currentDoctorIsUnavailable && doctorWaitTime && doctorWaitTime < 3;
  const altDoctorText =
    'As a returning patient, you can book with your regular doctor.  Alternatively, you can book with the first available doctor for an earlier appointment.';
  const doctorUnavailableText = `Your current doctor doesn’t have any appointments available for the next ${doctorWaitTime} day${
    doctorWaitTime && doctorWaitTime > 1 ? 's' : ''
  }. If you are willing to wait, you may book with your doctor.  Alternatively, you can book with the first available doctor for an earlier appointment.`;

  const doctorInitials = doctorName
    ? doctorName
        .split(' ')
        .map((e) => e.substring(0, 1))
        .join('')
    : '';

  const handleModalOneClose = (modal: boolean) => {
    if (modal) {
      setModalOneOpen(false);
    }
  };

  const renderRegularDoctorButton = (buttonText: string, modal = false) => (
    <BookConsultationButton
      testId={modal ? 'regular-or-new-doctor-dialog-button-regular' : 'book-a-consultation'}
      buttonProps={
        modal
          ? {
              variant: 'outlined',
              color: 'secondary',
              sx: { marginRight: '0.5rem' },
              fullWidth: !isSmUp,
            }
          : buttonProps
      }
      bookDoctorConsultationButtonText={buttonText}
      calcomFollowUpConsultEvent={calcomFollowUpConsultEvent}
      doctorEmail={doctorEmail}
      disabled={disabled}
      handleBookingSuccess={handleBookingSuccess}
      handleRescheduleSuccess={handleRescheduleSuccess}
      handleCancelSuccess={handleCancelSuccess}
      onOpen={() => handleModalOneClose(modal)}
    />
  );

  if (!showDoctorFollowupRoundRobin) {
    return renderRegularDoctorButton(bookDoctorConsultationButtonText);
  }

  return (
    <>
      <Button data-testid="regular-or-new-doctor-prompt-button" {...buttonProps} onClick={() => setModalOneOpen(true)}>
        Book a consultation
      </Button>
      <Dialog fullWidth maxWidth="lg" open={modalOneOpen} onClose={() => setModalOneOpen(false)}>
        <Box className="p-2">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle
              data-testid="regular-or-new-doctor-dialog-title"
              variant="h5"
              sx={{
                paddingLeft: '0.5rem',
              }}
            >
              Book a consultation
            </DialogTitle>
            <IconButton
              aria-label="close"
              data-testid="regular-or-new-doctor-dialog-button-cancel"
              onClick={() => setModalOneOpen(false)}
              size="small"
            >
              <GridCloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <Stack spacing={4}>
              <Typography>You are currently assigned to Doctor: </Typography>
              <Stack direction="row" spacing={4} alignItems="center">
                <Avatar>{doctorInitials}</Avatar>
                <Typography>{doctorName}</Typography>
              </Stack>
              <Stack
                direction="row"
                className="p-3"
                sx={{
                  backgroundColor: currentDoctorIsUnavailable
                    ? '#FFF4E5'
                    : themeAlt.colorSchemes.light.palette.info.main,
                }}
              >
                {currentDoctorIsUnavailable && isSmUp && (
                  <WarningAmberIcon sx={{ color: theme.palette.secondary.main }} />
                )}
                {!currentDoctorIsUnavailable && isSmUp && <BlueInfoIcon sx={{ color: theme.palette.info.main }} />}
                <Typography data-testid="doctor-availability-info" className={isSmUp ? 'pl-2' : ''}>
                  {useAltDoctorText ? altDoctorText : doctorUnavailableText}
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={isSmUp ? 'row' : 'column'} spacing={3}>
              {renderRegularDoctorButton('Book with My Doctor', true)}
              <Button
                data-testid="regular-or-new-doctor-dialog-button-new"
                color="secondary"
                variant="contained"
                onClick={() => {
                  setModalOneOpen(false);
                  setConfirmationModalOpen(true);
                }}
                disabled={disabled}
                sx={{ width: { xs: '100%', sm: 'inherit' } }}
              >
                Book with first Available Doctor
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        data-testid="confirm-new-doctor-dialog"
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Box className="p-2 pt-4">
          <Stack direction="row" alignItems="center" className="pl-3">
            {isSmUp && <WarningAmberIcon sx={{ color: theme.palette.secondary.main }} />}
            <Typography
              data-testid="confirm-new-doctor-dialog-title"
              variant={isSmUp ? 'h5' : 'h6'}
              className={isSmUp ? 'pl-2' : ''}
            >
              Consultation with a new Doctor
            </Typography>
          </Stack>
          <DialogContent>
            <Typography fontWeight={theme.typography.fontWeightBold}>
              Please be advised that once you consult with a new doctor and receive a new prescription, your existing
              prescriptions will be discontinued.
            </Typography>
            <Typography>
              If you require new scripts for previously prescribed products, please discuss this with the new doctor
              during your consultation.
            </Typography>
          </DialogContent>
          <DialogContent>
            <Typography>Do you wish to proceed?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="confirm-new-doctor-dialog-button-cancel"
              color="secondary"
              variant="text"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancel
            </Button>
            <BookConsultationButton
              testId="confirm-new-doctor-dialog-button-confirm"
              onOpen={() => setConfirmationModalOpen(false)}
              bookDoctorConsultationButtonText="Confirm"
              calcomUrlOverride={DOCTOR_FOLLOW_UP_ROUND_ROBIN_URL}
              disabled={disabled}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
