import React from 'react';

export default function TopBanner(props) {
  return (
    <section className="cpd-banner">
      <div className="cpd-transform">
        <div className="d-flex h-100 text-white text-center flex-column justify-content-center">
          <p className="mt-5 font-weight-bold mb-1">{props.pText}</p>
          <h1 className="font-weight-bold">{props.h1Text}</h1>
        </div>
      </div>
    </section>
  );
}
