import React from 'react';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Timeline,
  timelineClasses,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineDotClasses,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
  typographyClasses,
} from '@mui/material';
import moment from 'moment';

import MontuLogo from '@/components/common/MontuLogo';
import settings from '@/constants/constants';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import { PatientOrderTrackingStatus } from '@/services/patient.service';
import { GoogleAnalyticsEventName } from '@/types';

const MainCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // below is required as we do not want to pass through the extra property to `<IconButton>`.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledCardHeader = styled(CardHeader)(() => ({
  padding: '1rem',
  cursor: 'pointer',
}));

const StyledCardContent = styled(CardContent)(() => ({
  position: 'relative',
  padding: '0 1rem 1rem',
  '&:last-child': {
    paddingBottom: '1rem',
  },
}));

const StyledTimeline = styled(Timeline)(({ theme }) => ({
  [`&.${timelineClasses.root}`]: {
    padding: 0,
  },
  [`& .${timelineItemClasses.root}`]: {
    '&:before': {
      flex: 0,
      padding: 0,
    },
    '&:last-child': {
      minHeight: 0,
    },
  },
  marginBottom: 0,
  [`& .${typographyClasses.root}`]: {
    fontSize: '1rem',
  },
  '& a': {
    color: theme.palette.info.main,
  },
}));

const StyledTimelineDot = styled(TimelineDot)(() => ({
  [`&.${timelineDotClasses.filledPrimary}`]: {
    backgroundColor: '#194457',
  },
  [`&.${timelineDotClasses.root}`]: {
    boxShadow: 'none',
  },
}));

const MontuLogoBox = styled(Box)(() => ({
  marginRight: '1.5rem',
  padding: '6px 0',
}));

export default function OrderTrackingStatus(order: PatientOrderTrackingStatus) {
  const { orderCode, orderDate, trackingId, carrier, orderStatus } = order;
  const [expanded, setExpanded] = React.useState(false);
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();

  let toastTitle = 'Your order has been placed!';
  let isPlaced = false;
  let isProcessing = false;
  let isWithCarrier = false;

  switch (orderStatus) {
    case settings.orderStatus.AWAITING_PAYMENT:
    case settings.orderStatus.APPROVED_NOT_PAID:
      isPlaced = true;
      break;
    case settings.orderStatus.PAID_NOT_APPROVED:
    case settings.orderStatus.AWAITING_DISPENSE:
    case settings.orderStatus.AWAITING_SHIPMENT:
      isProcessing = true;
      toastTitle = 'Your order is being processed!';
      break;
    case settings.orderStatus.SHIPPED:
    case settings.orderStatus.COMPLETED:
    case settings.orderStatus.PHARMACY_DISPENSED:
    case settings.orderStatus.PHARMACY_COMPLETED:
      isWithCarrier = true;
      toastTitle = 'Your order has been shipped!';
      break;
    default:
  }

  let trackParcelLink: string;
  switch (carrier) {
    case settings.shipmentCarrier.ausPost:
      trackParcelLink = `${settings.ausPostTrackingUrl}${trackingId}`;
      break;
    case settings.shipmentCarrier.shippit:
      trackParcelLink = `${settings.shippitTrackingUrl}${trackingId}`;
      break;
    default:
      trackParcelLink = '#';
  }

  const isOutForDelivery = isWithCarrier && trackingId;

  const handleExpandClick = () => {
    sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.ACCORDION_CLICK, {
      event_category: 'order_status_toast_accordion',
      event_label: expanded ? 'toast_closed' : 'toast_opened',
    });
    setExpanded(!expanded);
  };

  return (
    <MainCard>
      <StyledCardHeader
        onClick={handleExpandClick}
        title={toastTitle}
        titleTypographyProps={{ sx: { fontSize: '1rem', lineHeight: '1.5rem', fontWeight: '700' } }}
        action={
          <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <StyledCardContent>
          <StyledTimeline>
            <TimelineItem>
              <TimelineSeparator>
                <StyledTimelineDot
                  variant={isPlaced || isProcessing || isWithCarrier ? 'filled' : 'outlined'}
                  color={isPlaced || isProcessing || isWithCarrier ? 'primary' : 'grey'}
                />
                <TimelineConnector sx={{ ...((isProcessing || isWithCarrier) && { bgcolor: '#194457' }) }} />
              </TimelineSeparator>
              <TimelineContent>
                <Typography fontWeight="700">Order placed</Typography>
                <Typography>{moment(orderDate).format('MMM DD')}</Typography>
                <Link
                  to={`/patient/order-detail/${orderCode}`}
                  className="view-order"
                  onClick={() =>
                    sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.LINK_CLICK, {
                      event_category: 'toast_view_order',
                      event_label: 'view_order',
                    })
                  }
                >
                  View order
                </Link>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <StyledTimelineDot
                  variant={isProcessing || isWithCarrier ? 'filled' : 'outlined'}
                  color={isProcessing || isWithCarrier ? 'primary' : 'grey'}
                />
                <TimelineConnector sx={{ ...(isWithCarrier && { bgcolor: '#194457' }) }} />
              </TimelineSeparator>
              <TimelineContent>
                <Typography fontWeight="700" color={isProcessing || isWithCarrier ? 'primary' : 'grey'}>
                  Processing order
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <StyledTimelineDot
                  variant={isWithCarrier ? 'filled' : 'outlined'}
                  color={isWithCarrier ? 'primary' : 'grey'}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Typography fontWeight="700" color={isWithCarrier ? 'inherit' : 'grey'}>
                  Out for delivery
                </Typography>
                {isOutForDelivery && (
                  <Link
                    to={{ pathname: trackParcelLink }}
                    target="_blank"
                    className="view-parcel-tracking"
                    rel="noopener noreferrer"
                    onClick={() =>
                      sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.LINK_CLICK, {
                        event_category: 'toast_track_parcel',
                        event_label: 'track_parcel',
                      })
                    }
                  >
                    Track parcel
                  </Link>
                )}
              </TimelineContent>
              <MontuLogoBox>
                <Box>
                  <MontuLogo fillColor="#1a4457" />
                </Box>
              </MontuLogoBox>
            </TimelineItem>
          </StyledTimeline>
        </StyledCardContent>
      </Collapse>
    </MainCard>
  );
}
