import React from 'react';
import moment from 'moment';
import settings from '../../constants/constants';
import splitIcon from '../../assets/images/split.png';
import {
  FF_UPDATED_PATIENT_ORDER_STATUSES,
  FF_USE_SHOPIFY_PRODUCT_IMAGES,
  FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES,
} from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import ProductWhiteLabelImage from '../products/productDetailsV2/ProductWhiteLabelImage';
import { getWhiteLabelProductImageUrl } from '@/utils/getProductImageUrl';
import getProductImageUrl from '@/utils/getProductImageUrl';

const OrderTile = (props) => {
  const [maxProductTiles, setMaxImageTiles] = React.useState(4);
  const { flags } = useFeatureFlags();
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES] || ffDefaults[FF_USE_SHOPIFY_PRODUCT_IMAGES];

  React.useEffect(() => {
    const boxWidth = document.getElementsByClassName('product-listing-order-tile')[0].clientWidth;
    const maxTileSpace = Math.floor(boxWidth / (props.screenWidth < settings.screenResolution.small ? 100 : 200));
    setMaxImageTiles(maxTileSpace);
  }, [props.screenWidth]);

  const extraProductsCount = props.order ? props.order.OrderProducts.length - maxProductTiles + 1 : 0;
  return (
    <React.Fragment>
      {props.order && (
        <div
          className="p-2 row m-3 patient-order-tile col-mob-row"
          onClick={() => props.onClick(props.order.order_code)}
        >
          <div className="table-sec">
            <table style={{ border: 'none' }} className="patient-order-details-table mx-auto">
              <tbody>
                <tr>
                  <td className="head">Order ID.</td>
                  <td className="value">
                    <span className="underline">{props.order.order_code}</span>
                  </td>
                </tr>
                <tr>
                  <td className="head">Order Status</td>
                  <td className="value">
                    {props.order.orderStatus ? (
                      getOrderStatus(props.order)
                    ) : (
                      <img src={splitIcon} style={{ width: '20px' }} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="head">Date</td>
                  <td className="value">{moment(props.order.order_date).format('MMMM DD, YYYY')}</td>
                </tr>
                <tr>
                  <td className="head">Amount</td>
                  <td className="value">${props.order.total_price}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex product-listing-order-tile product-tile-sec">
            {props.order.OrderProducts.map((prdt, idx) => {
              if (idx + 1 > maxProductTiles - 1) {
                return '';
              }

              const imageUrl = getWhiteLabelProductImageUrl({
                whiteLabelImageUrl: prdt.Product.ProductFormulation?.whitelabel_image_url,
              });

              return (
                <div key={`order-${props.order_code}-prdt-${idx}`} className="order-prdt-image-tile">
                  {flags[FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES] ? (
                    <ProductWhiteLabelImage whiteLabelImageUrl={imageUrl} className="img-fluid" />
                  ) : (
                    <img
                      src={getProductImageUrl(
                        useShopifyProductImages,
                        prdt.Product.image_url,
                        prdt.Product.ProductImages,
                      )}
                      className="img-fluid"
                    />
                  )}

                  <div>
                    <p className="value">
                      {(prdt.Product.Suppliers.length > 0 ? prdt.Product.Suppliers[0].supplier_name + ' ' : '') +
                        (prdt.Product.short_name || prdt.Product.name)}
                    </p>
                  </div>
                </div>
              );
            })}
            {extraProductsCount > 0 && (
              <div className="extra-prodcut-count">
                <p>+{extraProductsCount}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderTile;

export const getOrderStatus = (order) => {
  const { flags } = useFeatureFlags();
  const useUpdatedPatientOrderStatuses =
    flags[FF_UPDATED_PATIENT_ORDER_STATUSES] || ffDefaults[FF_UPDATED_PATIENT_ORDER_STATUSES];
  const orderStatus = order.orderStatus || order.order_status_id;
  if ([settings.orderStatus.AWAITING_PAYMENT, settings.orderStatus.APPROVED_NOT_PAID].includes(orderStatus)) {
    // Prior to Payment
    return 'Awaiting Payment';
  }
  if (orderStatus === settings.orderStatus.PAID_NOT_APPROVED) {
    // Paid but not approved
    if (useUpdatedPatientOrderStatuses) {
      return 'Order Processing';
    }
    return 'Awaiting Approval';
  }
  if (
    [settings.orderStatus.SHIPPED, settings.orderStatus.COMPLETED, settings.orderStatus.PHARMACY_COMPLETED].includes(
      orderStatus,
    )
  ) {
    // shipped
    if (order.delivered) {
      return 'Delivered';
    }
    return 'Shipped';
  }
  if (orderStatus === settings.orderStatus.PHARMACY_DISPENSED) {
    // Dispensed (includes 'Ready For Shipment', 'Shipped', 'Delivered' Based on Auspost Tracking ID)
    if (order.shipment_tracking_id === null || '') {
      if (useUpdatedPatientOrderStatuses) {
        return 'Dispensed';
      }
      return 'Ready For Shipment';
    } else if (order.shipment_tracking_id && (order.deliveredDate || order.delivered_date != null)) {
      return 'Delivered';
    } else if (order.shipment_tracking_id != null) {
      if (useUpdatedPatientOrderStatuses) {
        return 'Out for Delivery';
      }
      return 'Shipped';
    }
  }
  if (orderStatus === settings.orderStatus.CANCELLED) {
    // Cancelled
    return 'Cancelled';
  }
  if (orderStatus === settings.orderStatus.EXPIRED) {
    // Expired
    return 'Cancelled - Invoice Expired';
  }
  if (orderStatus === settings.orderStatus.OUT_OF_STOCK_REASONING) {
    // Out of Stock - Reasoning
    return 'Out of Stock - Unknown return date';
  }
  if (orderStatus === settings.orderStatus.INTERVAL_TIME && order.intervalDate != null) {
    // Interval Time
    if (useUpdatedPatientOrderStatuses) {
      return 'Order Processing';
    }
    return `Interval Ends: ${order.intervalDate}`;
  }
  if (orderStatus === settings.orderStatus.OUT_OF_REPEATS) {
    // Show message Need new script for OUT_OF_REPEATS
    return 'No Repeats Remaining';
  }
  return 'Order Processing';
};
