import { ProductSpectrums, ProductStrains } from '@/types';

import { Supplier } from './detail/types';

export const DEFAULT_NO_VALUE_STR = '-';

const BROAD_SPECTRUM_ID = 1;

// Derives product usage time from flower strain name
export const getProductUsageTime = (productStrainName?: string | null) => {
  switch (productStrainName) {
    case ProductStrains.SATIVA:
      return 'Day';
    case ProductStrains.INDICA:
      return 'Night';
    case ProductStrains.BALANCED:
      return 'Both';
    default:
      return DEFAULT_NO_VALUE_STR;
  }
};

// Derives category number from strings like "Category 1: (CBD >= 98%)"
export const getProductCategory = (productIngredientName?: string | null) =>
  productIngredientName ? parseInt(productIngredientName.split(' ')[1], 10) : DEFAULT_NO_VALUE_STR;

// Normalizes strings like "foo\r\nbar\nbaz" and resolves to "foo, bar, baz"
export const getProductTerpeneProfile = (contents?: string | null) =>
  contents ? contents.replaceAll('\r', '').replaceAll(/\n+/g, ', ') : DEFAULT_NO_VALUE_STR;

// Suppliers were originally designed to be one to many but never used that way
export const getProductBrand = (suppliers?: Pick<Supplier, 'supplier_name'>[] | null) =>
  suppliers && suppliers[0] ? suppliers[0].supplier_name : DEFAULT_NO_VALUE_STR;

// Derives spectrum type from id
export const getProductSpectrum = (spectrumId?: number | null) =>
  spectrumId === BROAD_SPECTRUM_ID ? ProductSpectrums.BROAD : ProductSpectrums.FULL;

// Derives product usage steps based on legacy logic
export const getProductHowToUseSteps = (howToUse?: string | null) =>
  (howToUse ?? '')
    .replaceAll('\r', '')
    .replaceAll(/\n+/g, '\n')
    .split('\n')
    .filter((step) => step.length > 0);
