import { useQuery } from 'react-query';

import { PatientProductRating, Product } from '@/components/products/detail/types';
import { getProductOffering } from '@/services/product.service';

type IProductResponse = {
  product?: Product | null;
  user_ratings?: PatientProductRating | null;
  product_rating: string;
};

function useProductDetails(productId: string) {
  const { data, isError, isLoading } = useQuery<IProductResponse>({
    queryKey: ['productDetails', productId],
    queryFn: async () => {
      try {
        const res = await getProductOffering(productId);
        return res.data;
      } catch (error) {
        throw new Error('Failed to fetch product details');
      }
    },
  });

  return {
    product: data?.product,
    isError,
    isLoading,
  };
}

export default useProductDetails;
