import React from 'react';

const MedicationTable = (props) => {
  return (
    <React.Fragment>
      {props.patient_client && props.productList.length > 0 && (
        <>
          <p className="mt-5">Medication Prescribed</p>
          <table className="table table-bordered">
            <thead align="center">
              <tr>
                <th>Medication</th>
                <th>Units</th>
              </tr>
            </thead>
            <tbody>
              {props.productList.map((medObj) => (
                <tr key={medObj.id}>
                  <td>{medObj.name}</td>
                  <td align="center">{medObj.remaining_units}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </React.Fragment>
  );
};

export default MedicationTable;
