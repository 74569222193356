import React from 'react';
import { useParams } from 'react-router';

import DefaultForgotPasswordPage from '@/components/auth/DefaultForgotPassword';
import PatientForgotPassword from '@/components/auth/PatientForgotPassword';

function ForgotPasswordPage() {
  const { loginType } = useParams<{ loginType: string }>();

  return loginType === 'patient' ? <PatientForgotPassword /> : <DefaultForgotPasswordPage />;
}

export default ForgotPasswordPage;
