import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Link,
  IconButton,
  Grid,
  Typography,
  Box,
  Container,
  Divider,
} from '@mui/material';
import { ManageAccounts as ManageAccountsOutlinedIcon } from '@mui/icons-material';
import { ArrowForwardIos, AssignmentOutlined, LocalShippingOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import Papaya from '@/components/common/Papaya';
import MontuLogo from '@/components/common/MontuLogo';
import { FF_ENABLE_UMEDS_REBRAND } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import PageHeading from '@/components/common/PageHeading';
import PageDivider from '@/components/common/PageDivider';

const LinkListText = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '1.1rem',
  lineHeight: '1.9rem',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontWeight: '600',
    lineHeight: '1.6rem',
  },
}));

const LinkListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: '24px',
  paddingRight: '24px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
    paddingBottom: '24px',
  },
}));

const LinkListDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}));

const LinkList = styled(List)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('md')]: {
    maxWidth: '740px',
  },
}));

const LinkListMontuBranding = styled(Typography)(() => ({
  thememarginTop: '0.5rem',
  fontSize: '1rem',
  lineHeight: 1,
}));

const LinkListContainer = styled(Container)(({ theme, expandWidth }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: expandWidth ? '100%' : '80%',
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

const faqLinksdata = [
  {
    id: 0,
    content: <LinkListText>Accounts & Appointments</LinkListText>,
    link: 'https://montu.zendesk.com/hc/en-au/categories/22395700905497-Account-Appointments',
    icon: <ManageAccountsOutlinedIcon sx={{ width: '36px', height: '36px' }} />,
  },
  {
    id: 1,
    content: <LinkListText>Prescriptions & Medication</LinkListText>,
    link: 'https://montu.zendesk.com/hc/en-au/categories/22395730838553-Prescriptions-Medication',
    icon: <AssignmentOutlined sx={{ width: '36px', height: '36px' }} />,
  },
  {
    id: 2,
    content: (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkListText sx={{ marginBottom: '5px' }}>Orders & Delivery</LinkListText>
        <LinkListMontuBranding variant="body1">
          powered by&nbsp;&nbsp;
          <MontuLogo fillColor="#1a4457" />
        </LinkListMontuBranding>
      </Box>
    ),
    icon: <LocalShippingOutlined sx={{ width: '36px', height: '36px' }} />,
    link: 'https://montu.zendesk.com/hc/en-au/categories/22395670099865-Orders-Delivery',
  },
];

function generateFaqLinksList(faqData) {
  return faqData.map((item) => (
    <React.Fragment key={item.id}>
      <Link href={item.link} target="_blank" underline="none">
        <LinkListItem
          secondaryAction={
            <IconButton edge="end" aria-label="name">
              <ArrowForwardIos color="secondary" fontSize="small" />
            </IconButton>
          }
          sx={{ paddingTop: '0.8rem', paddingBottom: '0.8rem' }}
        >
          <ListItemAvatar>{item.icon}</ListItemAvatar>
          {item.content}
        </LinkListItem>
      </Link>
      <LinkListDivider variant="middle" />
    </React.Fragment>
  ));
}

const faqs = () => {
  const { flags } = useFeatureFlags();
  return (
    <LinkListContainer expandWidth={flags[FF_ENABLE_UMEDS_REBRAND]}>
      <PageHeading>Help</PageHeading>
      <PageDivider />

      <Box sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={6}>
          <LinkList dense={true}>{generateFaqLinksList(faqLinksdata)}</LinkList>
        </Grid>
      </Box>
      <Papaya />
    </LinkListContainer>
  );
};

export default faqs;
