import React, { ComponentType, useCallback } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { alternaleaf, BrandThemeProvider } from '@montugroup/themes';
import { Alert, AlertTitle, Box, Link, styled, useMediaQuery, useTheme } from '@mui/material';
import { Location } from 'history';

import GlobalContentCard from '@/components/braze/GlobalContentCard';
import Footer from '@/components/layout/footer';
import {
  ContentWrapper,
  MainContentBox,
  UmedsContentWrapper,
  UmedsMainContentBox,
} from '@/components/layout/LayoutStyledComponents';
import MainContentWrapper from '@/components/layout/MainContentWrapper';
import NavbarContainer from '@/components/layout/NavbarContainer';
import Notices from '@/components/layout/Notices';
import UmedsHeader from '@/components/layout/UmedsHeader';
import settings, { UMEDS_ROUTES } from '@/constants/constants';
import {
  FF_ENABLE_UMEDS_REBRAND,
  FF_ORDERMEDICATIONPAGE2024,
  FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT,
  FF_PATIENT_REFILL_CHECKOUT_BANNER,
  FF_REFILL_CHECKOUT_CHANGES_BANNER_LINK,
} from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import { alternaleafMQ } from '@/theme';
import { Logger } from '@/utils/logger';

import MobileAppBar from '../layout/navbar/MobileAppBar';
import Sidebar from '../layout/sidebar/Sidebar';

const logger = new Logger('authenticatedRoute');

type Props = {
  component: ComponentType<any>;
  allowedRoles?: number[];
  displayGlobalContentCard?: boolean;
  [x: string]: any;
};

type RouteProps = {
  location: Location;
  [x: string]: any;
};

const LegacyAlertContainer = styled(Box)({
  width: '100%',
  padding: '0 5%',

  [alternaleafMQ('md')]: {
    padding: '0 10%',
  },
});

const UmedsAlertContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: 0,

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(32),
    paddingRight: theme.spacing(32),
    paddingTop: theme.spacing(8),
  },
}));

// TODO: must add next param to redirect the user when he logged in
function AuthenticatedRoute({ component: Component, allowedRoles, displayGlobalContentCard, ...rest }: Props) {
  const { flags } = useFeatureFlags();
  const isLargeView = useMediaQuery(alternaleaf.breakpoints.up('md'));
  const location = useLocation();
  const theme = useTheme();

  const checkoutChangesBannerFAQLinkUrl = flags[FF_REFILL_CHECKOUT_CHANGES_BANNER_LINK];
  const ffPatientRefillCheckoutBanner = flags[FF_PATIENT_REFILL_CHECKOUT_BANNER];
  const isOrderMedicationPage2024 = flags[FF_ORDERMEDICATIONPAGE2024];
  const ffPatientDischargeCircuitCheckoutDisabledAlert = flags[FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT];

  const moreUserData = JSON.parse(localStorage.getItem('moreUserData') || '{}');
  const { patient } = moreUserData || {};
  const isDischarged = patient?.is_discharge;

  // TODO: Refactor into abstraction based on RFC: https://montugroup.atlassian.net/wiki/spaces/Eng/pages/493813761/RFC-017+Standardised+Error+Management+in+Circuit+Frontend
  const Banner = useCallback(() => {
    const alertPriority = [
      {
        Component: (
          <Alert
            severity="error"
            sx={{
              backgroundColor: 'var(--_components-alert-error-background, #F9E6E6)',
              borderRadius: '4px',
            }}
          >
            {isOrderMedicationPage2024 && <AlertTitle>Checkout unavailable</AlertTitle>}
            {isOrderMedicationPage2024
              ? 'You have been discharged from uMeds.'
              : 'Checkout unavailable as you have been discharged from Montu.'}{' '}
            If this is incorrect, please{' '}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={`${settings.support.site.url}/hc/en-au/articles/24423884075289-How-do-I-contact-Montu`}
            >
              contact us
            </Link>
            .
          </Alert>
        ),
        enabled: ffPatientDischargeCircuitCheckoutDisabledAlert && isDischarged,
        routes: ['/patient/refill', 'patient/order-detail/'],
      },
      {
        Component: (
          <Alert
            severity="warning"
            sx={{
              backgroundColor: 'var(--_components-alert-warning-background, #FFF4E5)',
              borderRadius: '4px',
            }}
          >
            We've improved the checkout experience! For more details, please visit our{' '}
            <Link rel="noopener noreferrer" target="_blank" href={checkoutChangesBannerFAQLinkUrl}>
              FAQ page
            </Link>
            .
          </Alert>
        ),
        enabled: ffPatientRefillCheckoutBanner,
        routes: ['/patient/refill'],
      },
    ];

    return (
      alertPriority.find(({ enabled, routes }) => enabled && routes.find((route) => location.pathname.includes(route)))
        ?.Component || null
    );
  }, [
    checkoutChangesBannerFAQLinkUrl,
    ffPatientDischargeCircuitCheckoutDisabledAlert,
    ffPatientRefillCheckoutBanner,
    isDischarged,
    isOrderMedicationPage2024,
    location.pathname,
  ]);

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (!allowedRoles || !AuthService.getUser()) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }
        const userRoles = AuthService.getUserRoles();
        const currentUser = AuthService.getUser()?.user;
        const userRole = currentUser?.role_id || null;
        const isUMedsRebrand = flags[FF_ENABLE_UMEDS_REBRAND] && userRole === userRoles.patient;
        const isUmedsPage = UMEDS_ROUTES.includes(props.location.pathname);

        if (userRole !== null && allowedRoles.indexOf(userRole) === -1) {
          // clearUserDataFromStorage is here to stop users getting stuck and the only way to get out of it would be to clear their cache/local storage
          AuthService.clearUserDataFromStorage();
          logger.debug('/* , curRole null or not valid role');
          return (
            <Redirect
              to={{
                pathname: '/*',
                state: { from: props.location },
              }}
            />
          );
        }

        if (userRole === userRoles.doctor && !currentUser?.phone && props.location.pathname !== '/profile-detail') {
          toast.error('You need to fill in the profile details!');
          return (
            <Redirect
              to={{
                pathname: '/profile-detail',
                state: { from: props.location },
              }}
            />
          );
        }

        const isHomePage = rest.path === '/home';
        const removeContainerPaddingComponents = ['PatientRebrandConsultation', 'PatientRebrandHome'];

        const MainContentContainer = (
          <div
            id="main-content"
            className={
              userRole === userRoles.patient ? `patient-login ${!isHomePage ? 'main-content' : ''}` : 'main-content'
            }
          >
            {!!Banner && (
              <LegacyAlertContainer>
                <Box style={{ paddingTop: theme.spacing(8) }}>
                  <Banner />
                </Box>
              </LegacyAlertContainer>
            )}
            {displayGlobalContentCard && <GlobalContentCard />}
            <Component {...props} {...rest} />
          </div>
        );

        // TODO: Refactor layout logic out of the AuthenticatedRoute Component
        return (
          <>
            <Notices />
            {isUMedsRebrand ? (
              <BrandThemeProvider variant="alternaleaf">
                {isUmedsPage && flags[FF_ORDERMEDICATIONPAGE2024] ? (
                  <UmedsMainContentBox>
                    <UmedsHeader />
                    {!!Banner && (
                      <UmedsAlertContainer>
                        <Banner />
                      </UmedsAlertContainer>
                    )}
                    <UmedsContentWrapper>
                      {displayGlobalContentCard && <GlobalContentCard />}
                      <Component {...props} {...rest} />
                    </UmedsContentWrapper>
                  </UmedsMainContentBox>
                ) : (
                  <MainContentBox>
                    {isLargeView ? <Sidebar /> : <MobileAppBar />}
                    <ContentWrapper
                      removepadding={
                        removeContainerPaddingComponents.includes(Component.displayName || '') ? 'true' : undefined
                      }
                    >
                      {!!Banner && (
                        <LegacyAlertContainer>
                          <Banner />
                        </LegacyAlertContainer>
                      )}
                      {displayGlobalContentCard && <GlobalContentCard />}
                      <Component {...props} {...rest} />
                    </ContentWrapper>
                  </MainContentBox>
                )}
              </BrandThemeProvider>
            ) : (
              <MainContentWrapper>
                <NavbarContainer />
                {MainContentContainer}
                <Footer />
              </MainContentWrapper>
            )}
          </>
        );
      }}
    />
  );
}

export default AuthenticatedRoute;
