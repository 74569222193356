export type CreditConstants = {
  creditType: Record<string, string>[];
  creditStatus: Record<string, string>[];
};
export const creditConstants: CreditConstants = {
  creditType: [
    {
      label: 'Order Issue',
      value: '1',
    },
    {
      label: 'Product Defect',
      value: '2',
    },
    {
      label: 'Re-Script Credit',
      value: '3',
    },
    {
      label: 'Promotion (Non-Refundable)',
      value: '5',
    },
    {
      label: 'Referral',
      value: '6',
    },
    {
      label: 'Other',
      value: '4',
    },
  ],
  creditStatus: [
    {
      label: 'Cancelled',
      value: '4',
    },
  ],
};
