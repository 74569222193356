import axios from 'axios';

import settings from '@/constants/constants';

export type IAllocateOrderResponse = {
  data: {
    pharmacyId: number;
    pharmacyName: string;
    pharmacyLogoUrl: string;
  };
};

export type Product = {
  productId: number;
  quantity: number;
};

export const getPharmacyAllocation = async (products: Product[]) =>
  axios.post<IAllocateOrderResponse>(`${settings.customerPortalApiUrl}/orders/allocate`, {
    products,
  });
