import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';
import { Logger } from '@/utils/logger';
import { Box, Container, styled } from '@mui/material';
import USER_ROLES from '@/constants/userRoles';

const logger = new Logger('resetPassword');

const url = settings.url;

const PageContainer = styled(Container)`
  padding-top: 140px;
`;

const ContentContainer = styled(Box)`
  max-width: 710px;
  margin: auto;
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileLoading: false,
      passwordLoading: false,
      data: [],
      currentPassword: '',
      password: '',
      confirmPassword: '',
    };

    this.validator = new SimpleReactValidator({
      validators: {
        password: {
          message: 'Password is required & should not contain spaces.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^\S{6,50}$/) && params.indexOf(val) === -1;
          },
          required: true,
        },
      },
    });
    this.profileValidator = new SimpleReactValidator();
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  backtohome = () => {
    this.props.history.push('/home');
  };

  handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid('currentPassword', 'password', 'confirmPassword')) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({
      passwordLoading: false,
    });

    await axios
      .put(url + '/user/password/', {
        body: JSON.stringify({
          currentPassword: this.state.currentPassword,
          password: this.state.password,
        }),
      })
      .then(async (res) => {
        if (res.status === 200 && res.data.id === 1) {
          this.setState({
            loading: false,
          });
          toast.success('Password details are updated');
          const user = AuthService.getUser();
          if (user.user.role_id === USER_ROLES.patient) window.location.replace('/home');
          else window.location.replace('/orders');
        } else if (res.data.id === 0) {
          toast.error('Password details not updated');
          logger.error('Password details not updated, res.data.id === 0');
          this.props.history.push('/password');
          this.forceUpdate();
        }
      });
  };

  render() {
    const { passwordLoading, currentPassword, password, confirmPassword } = this.state;
    const loginType = this.props.match.params.loginType;
    return (
      <PageContainer className="reset-password" maxWidth="md">
        <ContentContainer>
          <h3 className="form-heading">Change your password</h3>

          <form className="form-container" data-private>
            <FormInput
              type="password"
              name="currentPassword"
              label="Current Password"
              error={this.validator.message('password', currentPassword, 'password')}
              onChange={this.handleUserInput}
              value={currentPassword}
            />
            <FormInput
              type="password"
              name="password"
              label="New Password"
              error={this.validator.message('password', password, 'password')}
              onChange={this.handleUserInput}
              value={password}
            />
            <FormInput
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              error={this.validator.message('confirm-password', confirmPassword, `required|in:${this.state.password}`, {
                messages: { in: 'Passwords need to match!' },
              })}
              onChange={this.handleUserInput}
              value={confirmPassword}
            />
            <div className="form-actions pat-customize float-right flex-row-reverse">
              <Button
                text="Change Password"
                loading={passwordLoading}
                type="submit"
                className={`${
                  loginType === 'patient'
                    ? 'btn mr-3 mb-3 pat-reset-pass btn-md float-right'
                    : 'btn form-submit--other btn-primary btn-md float-right mr-3'
                }`}
                onClick={this.handlePasswordSubmit}
              />
              {loginType === 'patient' ? (
                <Button
                  text="Back"
                  loading={passwordLoading}
                  type="submit"
                  className="btn mr-3 mb-3 pat-reset-pass-cancel btn-md float-right"
                  onClick={this.backtohome}
                />
              ) : (
                ''
              )}
            </div>
          </form>
        </ContentContainer>
      </PageContainer>
    );
  }
}

export default ResetPassword;
