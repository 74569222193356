import React, { Component } from 'react';
import { AuthService } from '../../services/authentication.service';
import { toast } from 'react-toastify';
import { Logger } from '@/utils/logger';

const logger = new Logger('ConfirmUser');
class ConfirmUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: true,
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    await AuthService.confirmUser(id)
      .then(() => {
        this.setState({
          confirming: true,
        });
        window.location.replace('/orders');
        toast.success('Your account is now confirmed, please login');
      })
      .catch((err) => {
        this.setState({
          confirming: false,
        });
        toast.error("We can't confirm you, invalid confirmation link");
        logger.error("We can't confirm you, invalid confirmation link", err);

        return err;
      });
  }

  render() {
    const { confirming } = this.state;
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="auth-container">
          {confirming ? (
            <p className="h2">Confirming User...</p>
          ) : (
            <p className="alert alert-danger">Invalid confirmation link</p>
          )}
        </div>
      </div>
    );
  }
}

export default ConfirmUser;
