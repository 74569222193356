import { ReactNode, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material';

const Accordion = styled(MUIAccordion)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  border: '1px solid rgba(0, 0, 0, 0.2)',
  boxShadow: 'none',
  borderRadius: theme.spacing(1),
}));

export interface IFAQAccordion {
  question: string;
  content: ReactNode;
}

export default function FAQAccordion({ question, content }: IFAQAccordion) {
  const [expandFAQ, setExpandFAQ] = useState(false);

  return (
    <Accordion disableGutters expanded={expandFAQ} onChange={() => setExpandFAQ((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq-content" id="faq-content">
        <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
}
