import { Redirect } from 'react-router-dom';
import { BrandThemeProvider } from '@montugroup/themes';

import { FF_ENABLE_MY_TREATMENT_PLAN } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import MedicineInformation from './MedicineInformation';

export default function MedicineInformationContainer() {
  const { flags } = useFeatureFlags();
  const enableMedicineInformation = flags[FF_ENABLE_MY_TREATMENT_PLAN] ?? ffDefaults[FF_ENABLE_MY_TREATMENT_PLAN];

  if (!enableMedicineInformation) {
    return <Redirect to="/product-catalog" />;
  }

  return (
    <BrandThemeProvider variant="alternaleaf">
      <MedicineInformation />
    </BrandThemeProvider>
  );
}
