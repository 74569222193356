import { ProductFormulations } from '@/types';
import { Logger } from '@/utils/logger';

const logger = new Logger('getValidProductTypeName');

/**
 * Validates a product type string returned from the database against our own productTypes array.
 * We could use a validation library like Zod here but its overkill for this simple usecase currently.
 *
 * @param {string} productType - A string representing the product type to validate.
 *
 * @returns If the `productType` passed in is found in the productTypes array it will return the string literal type. If it is not found it will return null
 */
export default function getValidProductTypeName(productType: string) {
  const validProductTypeName = Object.values(ProductFormulations).find((pf) => pf === productType) ?? null;
  if (!validProductTypeName) {
    logger.error(`invalid productTypeName ${productType}`);
  }
  return validProductTypeName;
}
