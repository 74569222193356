import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import styled from '@emotion/styled';

import ErrorPage from '@/components/error/ErrorPage';
import { FF_ENABLE_404_AND_500_ERROR_PAGES } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
`;

const ReloadButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  background-color: #194457;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
`;

function ErrorFallback() {
  const { flags } = useFeatureFlags();
  const enableUmeds404And500Pages = flags[FF_ENABLE_404_AND_500_ERROR_PAGES];

  useEffect(() => {
    datadogRum.addAction('error-boundary');
  }, []);

  if (enableUmeds404And500Pages) {
    return <ErrorPage errorCode="500" />;
  }

  return (
    <Container role="alert">
      <h1>Whoops, something went wrong 😭</h1>
      <ReloadButton onClick={() => window.location.replace('/home')}>Reload</ReloadButton>
    </Container>
  );
}

export default ErrorFallback;
