import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { PaginationVariant, Table } from '@montugroup/design-system';
import { Box, styled, TextField } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { Supplier } from '@/services/supplier.service';

import editIcon from '../../assets/images/edit.png';

type SupplierTableItem = Pick<Supplier, 'supplier_code' | 'supplier_name' | 'email' | 'phone'> & {
  id: number;
  contact_supplier_name: string;
};

export type SuppliersTableProps = {
  data: Supplier[];
  loading: boolean;
};

const QueryFilterContainer = styled(Box)`
  margin-left: 0.25rem;
`;

const QueryFilterInput = styled(TextField)`
  min-width: 250px;
`;

function SuppliersTable({ data, loading }: SuppliersTableProps) {
  const [globalFilter, setGlobalFilter] = useState('');

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value);
  };

  const list: SupplierTableItem[] = data.map((ele) => ({
    id: ele.id,
    supplier_code: ele.supplier_code,
    supplier_name: ele.supplier_name,
    contact_supplier_name: `${ele.first_name} ${ele.last_name}`,
    email: ele.email,
    phone: ele.phone,
  }));

  const columns: ColumnDef<SupplierTableItem>[] = [
    {
      accessorKey: 'id',
      header: 'id',
      enableSorting: true,
    },
    {
      accessorKey: 'supplier_code',
      header: 'Supplier ID',
      enableSorting: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }) => <Link to={`/suppliers/onboard/${row.original.id}`}>{row.original.supplier_code}</Link>,
    },
    {
      accessorKey: 'supplier_name',
      header: 'Supplier name',
      enableSorting: true,
    },
    {
      accessorKey: 'contact_supplier_name',
      header: 'Contact supplier name',
      enableSorting: true,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableSorting: true,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      enableSorting: true,
    },
    {
      id: 'select',
      header: 'Edit',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }) => (
        <Link to={`/suppliers/onboard/${row.original.id}`}>
          <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
        </Link>
      ),
    },
  ];

  return (
    <>
      <QueryFilterContainer>
        <QueryFilterInput onChange={handleQueryChange} label="Search" variant="standard" />
      </QueryFilterContainer>
      <Table
        isLoading={loading}
        columns={columns}
        data={list}
        showPagination
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        globalFilter={globalFilter}
        columnVisibility={{ id: false }}
        initialSort={[{ id: 'id', desc: true }]}
      />
    </>
  );
}

export default SuppliersTable;
