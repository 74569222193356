import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  Link,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';

import { BRAZE_CONTENT_CARD } from '@/assets/data/enums';
import SinglePageContentCard from '@/components/braze/SinglePageContentCard';
import BookRegularOrNewDoctorButton from '@/components/common/BookRegularOrNewDoctorButton';
import FullPageContainer from '@/components/common/FullPageContainer';
import PageDivider from '@/components/common/PageDivider';
import PageHeading from '@/components/common/PageHeading';
import Papaya from '@/components/common/Papaya';
import { CONSULTATION_STATUS } from '@/components/patient/consultations/common';
import ConsultationCard from '@/components/patient/consultations/ConsultationCard';
import ConsultationsTable from '@/components/patient/consultations/ConsultationsTable';
import settings from '@/constants/constants';
import useGetPatientDoctorAvailability, {
  IDoctorAvailabilityResponse,
} from '@/hooks/patient/useGetPatientDoctorAvailability';
import useGetPatientOrderSummary from '@/hooks/patient/useGetPatientOrderSummary';
import useGetPatientConsultations, { Consultation } from '@/hooks/rest/useGetPatientConsultations';
import usePagination from '@/hooks/usePagination';
import AuthService from '@/services/authentication.service';
import Mixpanel from '@/services/mixpanel.service';

function BookingNotice() {
  const theme = useTheme();

  const handleEventTracking = () => {
    Mixpanel('Follow Up Appointment Request');
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="p-3"
      sx={{ backgroundColor: '#FFF4E5', maxWidth: { xs: '100%', sm: '70%' } }}
    >
      <WarningAmberIcon sx={{ color: theme.palette.secondary.main }} />
      <Typography className="pl-3">
        Would you like to see another doctor?{' '}
        <Link
          underline="none"
          href={settings.followUpGoogleDoc}
          target="_blank"
          onClick={handleEventTracking}
          sx={{
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            ':hover': {
              color: theme.palette.secondary.main,
            },
          }}
          rel="noreferrer"
        >
          Submit an appointment request{' '}
        </Link>
        and our team will be in touch to help find an appointment time.
      </Typography>
    </Stack>
  );
}

function getDoctorWaitTime(
  showNewDoctorButton: boolean,
  currentDoctorIsUnavailable: boolean,
  availabilityResponse: IDoctorAvailabilityResponse | undefined,
): number | null {
  if (!showNewDoctorButton) {
    return null;
  }
  if (currentDoctorIsUnavailable && availabilityResponse?.timeFrame) {
    return availabilityResponse.timeFrame;
  }
  if (!currentDoctorIsUnavailable && availabilityResponse?.nextAvailable) {
    const waitTime = Math.round(
      DateTime.fromISO(availabilityResponse.nextAvailable).diff(DateTime.now(), ['days']).days,
    );
    return waitTime > 0 ? waitTime : -1; // -1 to handle same day
  }

  return null;
}

function canPatientBookNewDoctor(
  currentDoctorIsUnavailable: boolean,
  recentlyReturnedPatient: boolean,
  openOrdersCount?: number,
): boolean {
  // Return false if patient has open orders
  if (openOrdersCount && openOrdersCount > 0) {
    return false;
  }
  /**
   * Return true if:
   * - Patient has not returned for 4 over months.
   * - OR: Patient's current Doctor is not available within the set timeframe (default 10 days)
   */
  return currentDoctorIsUnavailable || !recentlyReturnedPatient;
}

function PatientConsultations() {
  const theme = useTheme();
  const user = AuthService.getUser();
  const { isLoading, consultations, hasError, patient, refetch } = useGetPatientConsultations(user?.user?.id);
  const hasScheduledConsultation = consultations.some(
    (consult) => consult.ConsultationStatus?.name === CONSULTATION_STATUS.Scheduled,
  );

  // Checks their Doctor's next 10 days of availability on Calcom.
  const { isLoading: fetchingAvailability, data: availabilityResponse } = useGetPatientDoctorAvailability(
    user?.user?.id,
  );
  const currentDoctorIsUnavailable = availabilityResponse?.followUpAvailabilityStatus === 'Unavailable';

  // Check if patient has any active open orders
  const { isLoading: fetchingOrderSummary, data: openOrdersResponse } = useGetPatientOrderSummary();

  // Returns true if patient's last consultation is within the last 4 months
  const recentlyReturnedPatient = consultations.reduce((current, consult) => {
    if (consult.start_time && DateTime.fromISO(consult.start_time) > DateTime.now().minus({ months: 4 })) {
      return true;
    }
    return current;
  }, false);

  const showDoctorFollowupRoundRobinButton =
    !fetchingAvailability &&
    !fetchingOrderSummary &&
    canPatientBookNewDoctor(currentDoctorIsUnavailable, recentlyReturnedPatient, openOrdersResponse?.openOrdersCount);

  const doctorWaitTime = getDoctorWaitTime(
    showDoctorFollowupRoundRobinButton,
    currentDoctorIsUnavailable,
    availabilityResponse,
  );

  const ButtonSx = {
    minWidth: '230px',
    borderRadius: '100px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      opacity: '0.5',
    },
    [`@media (max-width: ${settings.screenResolution.medium}px)`]: {
      width: '100%',
      '& p': {
        paddingTop: '5px',
      },
    },
    whiteSpace: 'nowrap',
  };

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const doctorBookingDetails = {
    email: patient?.Doctor.DoctorUser.email || null,
    calcom_follow_up_consult_event: patient?.Doctor.calcom_follow_up_consult_event || null,
  };

  const [handlePagination, currentPage, filterData, totalPageCount] = usePagination({
    data: consultations,
    search: null,
  });

  const handlePageChange = (_: unknown, value: number) => {
    // Table pagination API is 0 based but usePagination hook is 1 based
    handlePagination('PickNumber', value + 1);
  };
  return (
    <FullPageContainer>
      <PageHeading>Consultations</PageHeading>
      <PageDivider sx={{ mb: 0 }} />
      <SinglePageContentCard displayType={BRAZE_CONTENT_CARD.PATIENT_CONSULTATIONS} />
      <Stack direction={isSmUp ? 'row' : 'column'} spacing={3} justifyContent="space-between" margin="2rem 0">
        <BookingNotice />
        {consultations.length > 0 && (
          <Box>
            <BookRegularOrNewDoctorButton
              buttonProps={{ sx: ButtonSx, color: 'secondary', variant: 'contained' }}
              calcomFollowUpConsultEvent={patient?.Doctor.calcom_follow_up_consult_event}
              doctorEmail={patient?.Doctor.DoctorUser.email}
              doctorName={`${patient?.Doctor.DoctorUser.first_name} ${patient?.Doctor.DoctorUser.last_name}`}
              disabled={hasScheduledConsultation}
              handleBookingSuccess={refetch}
              handleRescheduleSuccess={refetch}
              handleCancelSuccess={refetch}
              showDoctorFollowupRoundRobin={showDoctorFollowupRoundRobinButton}
              currentDoctorIsUnavailable={currentDoctorIsUnavailable}
              doctorWaitTime={doctorWaitTime}
              bookDoctorConsultationButtonText="Book a consultation"
            />
          </Box>
        )}
      </Stack>

      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', zIndex: 99 }}>
          {hasError && <Alert severity="error">An unexpected error occurred. Please try again.</Alert>}

          {isSmUp ? (
            <ConsultationsTable
              doctorBookingDetails={doctorBookingDetails}
              consultations={filterData}
              tablePaginationProps={{
                count: consultations.length,
                page: currentPage - 1,
                onPageChange: handlePageChange,
                rowsPerPage: 10,
                rowsPerPageOptions: [10],
              }}
            />
          ) : (
            <Box>
              {consultations.length === 0 && <Alert severity="info">No consultations found</Alert>}
              {filterData.map((consult: Consultation) => (
                <ConsultationCard key={consult.id} consultation={consult} doctorBookingDetails={doctorBookingDetails} />
              ))}
              {filterData.length > 0 && (
                <Box display="flex" justifyContent="center" marginTop={8}>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={totalPageCount}
                    page={currentPage}
                    onChange={(_, value) => handlePageChange(_, value - 1)}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      <Papaya />
    </FullPageContainer>
  );
}

export default PatientConsultations;
