import React from 'react';

interface Props {
  width?: string;
  height?: string;
  fillColor?: string;
}

export default function MontuLogo({ width = '80', height = '100%', fillColor = '#FFFFFF' }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 141.049 27.218">
      <g id="montu_logo" transform="translate(1638.526 -360.508)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M-1638.526,361.468c.7,0,1.364-.018,2.026.014a.687.687,0,0,1,.411.3q3.519,4.939,7.022,9.89,1.653,2.333,3.306,4.666c.059.085.128.163.213.271,1.123-1.6,2.224-3.174,3.328-4.744q3.554-5.055,7.121-10.1a.8.8,0,0,1,.548-.281c.613-.036,1.23-.013,1.879-.013v25.056h-4.195V371.083l-.109-.039c-.268.381-.538.761-.805,1.142q-3.387,4.834-6.783,9.663a.9.9,0,0,1-.508.267c-1.062.241-1.662-.275-2.252-1.141-2.215-3.251-4.52-6.442-6.791-9.655-.041-.057-.086-.11-.2-.256v15.464h-4.212Z"
          fill={fillColor}
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M-1573.535,361.435c.7,0,1.373-.007,2.048.008a.5.5,0,0,1,.294.178q3.91,4.29,7.813,8.589,3.561,3.92,7.119,7.844c.163.179.315.367.559.654V361.664h4.225v25.079c-.677,0-1.365.009-2.052-.009-.1,0-.207-.121-.286-.208q-1.944-2.141-3.882-4.286-2.47-2.724-4.944-5.445-1.991-2.193-3.977-4.387c-.866-.954-1.734-1.907-2.691-2.958v17.083h-4.226Z"
          fill={fillColor}
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M-1518.752,361.646h4.328a1.772,1.772,0,0,1,.038.262c.009,5.04,0,10.081.035,15.121a7.547,7.547,0,0,0,.767,3.242,4.624,4.624,0,0,0,3.588,2.6,9.758,9.758,0,0,0,4.44-.163,4.812,4.812,0,0,0,3.454-3.938,13.136,13.136,0,0,0,.285-2.646c.028-4.624.013-9.249.013-13.873v-.585h4.291c.008.152.022.3.022.451,0,5.148.041,10.3-.018,15.446a9.784,9.784,0,0,1-2.054,6.263,7.926,7.926,0,0,1-4.323,2.663,15.9,15.9,0,0,1-9-.185,7.863,7.863,0,0,1-5.489-6.207,16.225,16.225,0,0,1-.373-3.332c-.04-4.917-.018-9.835-.017-14.753C-1518.769,361.906-1518.76,361.8-1518.752,361.646Z"
          fill={fillColor}
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M-1532.719,386.54h-4.4V365.579h-8.135v-3.917h20.66v3.88h-8.127Z"
          fill={fillColor}
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M-1587.8,361.828a12.888,12.888,0,0,1,5.345,10.458,12.909,12.909,0,0,1-12.908,12.909c-.061,0-.122,0-.183-.005h-.009a10.874,10.874,0,0,1-1.733-.139,12.911,12.911,0,0,1-9.014-5.914,13.612,13.612,0,0,0,12.649,8.589,13.609,13.609,0,0,0,13.609-13.609A13.609,13.609,0,0,0-1587.8,361.828Z"
          fill={fillColor}
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M-1597.289,385.051a10.868,10.868,0,0,1-9.134-10.727,10.867,10.867,0,0,1,10.867-10.867,10.866,10.866,0,0,1,9.74,6.047,9.057,9.057,0,0,1,1.257,4.613,9.081,9.081,0,0,1-.45,2.827,10.87,10.87,0,0,1-10.538,8.246c.061,0,.122.005.183.005a12.909,12.909,0,0,0,12.908-12.909,12.888,12.888,0,0,0-5.345-10.458,13.552,13.552,0,0,0-5.853-1.32,13.609,13.609,0,0,0-13.609,13.609,13.572,13.572,0,0,0,.96,5.02A12.911,12.911,0,0,0-1597.289,385.051Z"
          fill="#46ba8e"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M-1585.009,376.944a9.1,9.1,0,0,1-8.645,6.268,9.1,9.1,0,0,1-9.095-9.1,9.1,9.1,0,0,1,9.095-9.1,9.089,9.089,0,0,1,7.838,4.482,10.866,10.866,0,0,0-9.74-6.047,10.867,10.867,0,0,0-10.867,10.867,10.868,10.868,0,0,0,9.134,10.727,12.981,12.981,0,0,0,1.742.139A10.87,10.87,0,0,0-1585.009,376.944Z"
          fill="#f7991c"
        />
      </g>
    </svg>
  );
}
