import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import CircleLoader from '../common/circleLoader';

const PatientPrescriptions = (props) => {
  const { flags, loading: ffLoading } = useFeatureFlags();

  const getPrescriptionUrl = async (prescription) => {
    await axios.get(`${props.redirect}/data/prescriptionUrl`, { params: { key: prescription } }).then((res) => {
      if (!res || !res.data) {
        toast.warn('Precription Not found');
        return;
      }
      window.open(res.data);
    });
  };
  if (ffLoading) return <CircleLoader />;

  return (
    <React.Fragment>
      {props.prescription.length > 0 && (
        <>
          <p className="mt-5">Prescriptions</p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: '13%' }}>Prescription ID</th>
                <th style={{ width: '12%' }}>Created Date</th>
                <th style={{ width: '13%' }}>Expiry Date</th>
                <th>Medication</th>
                <th>Doctor</th>
                <th>Quantity</th>
                <th>Repeats</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.prescription
                .filter((x) => x.productName.length > 0)
                .map((pre, idx) => (
                  <tr key={`prescription-${idx}`}>
                    <td onClick={() => getPrescriptionUrl(pre.url)} style={{ cursor: 'pointer' }}>
                      {pre.orderCode}
                    </td>
                    <td>{pre.orderDate}</td>
                    <td>{pre.expiryDate}</td>
                    <td>
                      <ul style={{ listStyle: 'none' }}>
                        {pre.productName.map((x, idx) => (
                          <li key={idx}>{x}</li>
                        ))}
                      </ul>
                    </td>
                    <td>{pre.doctorName}</td>
                    <td>
                      <ul style={{ listStyle: 'none' }}>
                        {pre.quantity.map((x, idx) => (
                          <li key={idx}>{x}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul style={{ listStyle: 'none' }}>
                        {pre.repeats.map((x, idx) => (
                          <li key={idx}>{x ? x : '-'}</li>
                        ))}
                      </ul>
                    </td>
                    <td>{pre.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </React.Fragment>
  );
};

export default PatientPrescriptions;
