import React from 'react';
/**
 * Validates the key pressed during a keyboard event in a phone number input field.
 *
 * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event triggered by the user.
 * @return {void} This function does not return anything.
 *
 *  The phone number input allows for the following characters:
 * - 0-9
 * - +, -, (, ), ., and space
 * - Backspace, ArrowLeft, ArrowRight, Delete, Tab, and Enter
 */
export const validateKeyDownPhoneNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Enter'];
  const regex = /^[0-9+\-()+.\s]$/;

  if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};

/**
 * Validates a password string.
 *
 * @param {string} password - The password string to be validated.
 * @return {boolean} Returns true if the password is valid, false otherwise.
 *
 * The password must satisfy the following criteria:
 * - At least 8 characters long.
 * - Contains at least one lowercase or uppercase letter.
 * - Contains at least one digit.
 */
export const validatePassword = (password: string): boolean => {
  const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/;
  return passwordPattern.test(password);
};

/**
 * Validates a parcel locker string.
 *
 * @param {string} value - The parcel locker string to be validated.
 * @return {boolean} Returns true if the parcel locker string is valid, false otherwise.
 *
 * The regex tests if the string contains:
 * - "Parcel Locker"(case insensitive)
 */
export const validateParcelLocker = (value: string): boolean =>
  !/(\d{5}\s?\d{5})?\s?(parcel\s*?locker)\s?(\d{5}\s?\d{5})?/i.test(value);
