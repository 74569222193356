import { useQueryClient } from 'react-query';

import { GENERATIVE, OUT_OF_STOCK } from '@/components/patient/enquiryModal/common';
import { fetchProductEnquiryStatus } from '@/services/product.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useProductEnquiryStatus');

const ERROR_ON_FETCH_PRODUCT_ENQUIRY_STATUS = 'Failed to fetch product enquiry status';

export default function useProductEnquiryStatus() {
  const queryClient = useQueryClient();

  return (
    patientId?: number,
    prescriptionId?: number,
    productId?: number,
    rescriptRequestReason?: typeof GENERATIVE | typeof OUT_OF_STOCK,
  ) =>
    queryClient.fetchQuery(['productEnquiryStatus', patientId], async () => {
      try {
        return await fetchProductEnquiryStatus(patientId, prescriptionId, productId, rescriptRequestReason);
      } catch (error) {
        logger.error(ERROR_ON_FETCH_PRODUCT_ENQUIRY_STATUS);
        throw new Error(ERROR_ON_FETCH_PRODUCT_ENQUIRY_STATUS);
      }
    });
}
