/* eslint-disable */
import mixpanel from 'mixpanel-browser';
import settings from '../constants/constants';
import { AuthService } from './authentication.service';
import isEqual from '@/utils/isEqual';

import { Logger } from '@/utils/logger';

const logger = new Logger('createMixpanelTracker');

export function stripDollarSigns(text) {
  // This function exists because someone decided it would be useful to split addresses on $$$$. This is an instant fix for now.
  return text?.replace(/\$/g, '');
}

// TODO: Move to a user util/service
export function getAndFormatMoreUserData() {
  try {
    const moreUserData = JSON.parse(localStorage.getItem('moreUserData'));
    const { patient, patientDiscounts, products, order } = moreUserData || {};

    const prescribedProducts = products
      ?.filter((product) => product?.prescription_id)
      .reduce((acc, product) => {
        acc[product?.id] = product?.name;
        return acc;
      }, {});

    const medicalConditions = order?.medical_condition?.split('\n');

    const isConcession = !!patientDiscounts?.some((discount) => discount?.is_concession);

    const propertiesToReturn = {
      clientCode: patient?.client_code,
      createdBy: patient?.created_by,
      createdDate: patient?.created_date,
      dateOfBirth: patient?.dob,
      id: patient?.id,
      isDischarged: patient?.is_discharge,
      isNewPatient: patient?.is_new_patient,
      patientCode: patient?.patient_code,
      patientPharmacyName: patient?.patient_pharmacy_name,
      patientPharmacyAddress: patient?.patient_pharmacy_address,
      pharmacyId: patient?.pharmacy_id,
      priority: patient?.priority,
      userId: patient?.user_id,
      xeroContactId: patient?.xero_contact_id,
      patientDiscounts: patientDiscounts,
      prescribedProducts: prescribedProducts,
      medicalConditions: medicalConditions,
      isConcession: isConcession,
    };
    return propertiesToReturn;
  } catch {
    logger.error('more user data failed');
  }
  return {};
}

function updateUserProfile(userData, moreUserData) {
  const { first_name, email, address, city, state_id, zip_code, phone } = userData?.user || {};
  mixpanel.identify(email || '');
  mixpanel.people.set({
    $name: first_name,
    $email: email,
    $last_login: new Date(),
    $address: stripDollarSigns(address),
    $city: city,
    $state_id: state_id,
    $zip_code: zip_code,
    $phone: phone,
    $active: 1,
    $client_code: moreUserData?.clientCode,
    $created_by: moreUserData?.createdBy,
    $created_date: moreUserData?.createdDate,
    $date_of_birth: moreUserData?.dateOfBirth,
    $id: moreUserData?.id,
    $is_discharged: moreUserData?.isDischarged,
    $is_new_patient: moreUserData?.isNewPatient,
    $patient_code: moreUserData?.patientCode,
    $patient_pharmacy_name: moreUserData?.patientPharmacyName,
    $patient_pharmacy_address: moreUserData?.patientPharmacyAddress,
    $pharmacy_id: moreUserData?.pharmacyId,
    $priority: moreUserData?.priority,
    $user_id: moreUserData?.userId,
    $xero_contact_id: moreUserData?.xeroContactId,
    $patient_discounts: moreUserData?.patientDiscounts,
    $prescribed_products: moreUserData?.prescribedProducts,
    $medical_conditions: moreUserData?.medicalConditions,
  });
}

function createMixpanelTracker() {
  let prevAllUserData = undefined;
  let initilsed = false;

  const initMixpanel = (userData, moreUserData) => {
    mixpanel.init(settings.MIXPANEL_KEY, {
      debug: true,
      api_host: settings.MIXPANEL_API_HOST,
      trackAutomaticEvents: true,
    });
    updateUserProfile(userData, moreUserData);
  };

  return (eventName, data) => {
    const userData = AuthService.getUser();
    const moreUserData = getAndFormatMoreUserData();
    const allUserData = { ...userData, ...moreUserData };
    const isSameUserObject = isEqual(allUserData, prevAllUserData?.user);

    if (!initilsed) {
      initMixpanel(userData, moreUserData);
      initilsed = true;
    }

    if (!isSameUserObject) {
      initMixpanel(userData, moreUserData);
      prevAllUserData = allUserData;
    }

    return mixpanel.track(eventName, {
      [`Data ${eventName}`]: data,
    });
  };
}

const Mixpanel = createMixpanelTracker();

export default Mixpanel;
