import { createSvgIcon } from '@mui/material';

/**
 * An icon for an inhaler
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2677-49119&m=dev
 *
 * @returns An SvgIcon
 */
export const MgInhaler = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.09936 2.14629L5.3838 3.13639H9.30144L9.0215 2.16197C8.99388 2.06584 8.90914 2 8.81303 2H5.20359C5.13069 2 5.07841 2.07338 5.09936 2.14629Z"
      fill="currentColor"
    />
    <path
      d="M11.3033 6.09056H5.21773C5.07917 6.09056 4.97588 6.22397 5.00491 6.36546L8.13947 21.6407C8.18248 21.8503 8.35984 22 8.5651 22H17.1152C17.6339 22 18.0805 21.6177 18.1823 21.0865L18.9914 16.8618C19.0453 16.5805 18.8392 16.3181 18.5645 16.3181H14.0036C13.7996 16.3181 13.6229 16.1701 13.5786 15.9621L11.5158 6.26853C11.4936 6.16454 11.4053 6.09056 11.3033 6.09056Z"
      fill="currentColor"
    />
  </svg>,
  'MgInhaler',
);

export default MgInhaler;
