import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, styled, TextField, Typography } from '@mui/material';

import useUpdatePassword from '@/hooks/rest/useUpdatePassword';
import { AlertResponse } from '@/pages/profile/PatientRebrandProfile';
import { LoadingButton } from '@/ui-library';
import { Logger } from '@/utils/logger';

import { IAlert } from './types';
import { validatePassword } from './utils';

const logger = new Logger('resetPassword');

const Form = styled(Box)(({ theme }) => ({
  margin: theme.spacing(6, 0),
}));

const FormItem = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(4, 0),
  [theme.breakpoints.up('lg')]: {
    maxWidth: '33%',
    paddingRight: theme.spacing(3),
  },
}));

const PasswordButton = styled(IconButton)(() => ({
  '&:focus': {
    outline: 'none',
  },
}));

export interface ResetPasswordFormData {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export default function PasswordReset() {
  const { mutate, isLoading } = useUpdatePassword();
  const [alert, setAlert] = useState<IAlert>({ severity: 'success', message: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { control, handleSubmit, reset, watch, trigger } = useForm<ResetPasswordFormData>({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const currentPassword = watch('currentPassword');
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const isFormValid: boolean = currentPassword.length > 0 && validatePassword(password) && password === confirmPassword;

  const handleAlertClose = () => {
    setAlert({ severity: 'success', message: '' });
  };

  const handlePasswordSubmit: SubmitHandler<ResetPasswordFormData> = async (formData: ResetPasswordFormData) => {
    if (formData.currentPassword === formData.password) {
      setAlert({ severity: 'error', message: 'New password cannot be the same as current password' });
      return;
    }

    mutate(formData, {
      onSuccess: () => {
        setAlert({ severity: 'success', message: 'Password updated' });
        reset({}, { keepValues: false });
      },
      onError: (error) => {
        setAlert({ severity: 'error', message: 'Something went wrong, please try again' });
        logger.error('Password details not updated', error);
      },
    });
  };

  return (
    <Box mt={10} mb={10}>
      <Typography variant="subtitle1" component="h2" color="primary.main">
        Password
      </Typography>
      <Form component="form" role="form" data-private onSubmit={handleSubmit(handlePasswordSubmit)}>
        <FormItem>
          <Controller
            name="currentPassword"
            control={control}
            rules={{ required: 'You must enter the current password' }}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <TextField
                type="password"
                variant="standard"
                value={value}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                label="Current Password"
                error={!!error}
                helperText={error?.message}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </FormItem>
        <FormItem>
          <Controller
            name="password"
            control={control}
            rules={{
              required: 'You must enter a password',
              validate: (value) =>
                validatePassword(value) || 'Password must contain at least 7 letters and 1 number. Please try again',
            }}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
              <TextField
                type={showPassword ? 'text' : 'password'}
                value={value}
                variant="standard"
                inputRef={ref}
                onChange={onChange}
                onBlur={async () => {
                  await trigger('password');
                }}
                fullWidth
                label="New Password"
                error={!!error}
                helperText={error?.message || 'Password must contain at least 7 letters and 1 number'}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PasswordButton onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </PasswordButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormItem>
        <FormItem>
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: 'Please confirm your password',
              validate: (value, formData) => formData.password === value || 'Your passwords must match',
            }}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                value={value}
                variant="standard"
                inputRef={ref}
                onChange={onChange}
                onBlur={async () => {
                  await trigger('confirmPassword');
                }}
                fullWidth
                label="Confirm Password"
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PasswordButton onClick={() => setShowConfirmPassword((prevShowPassword) => !prevShowPassword)}>
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </PasswordButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormItem>
        <LoadingButton
          loading={isLoading}
          id="btnSubmit"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={!isFormValid}
          sx={{
            marginTop: (theme) => theme.spacing(2),
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}
        >
          Save changes
        </LoadingButton>
        {alert.message && (
          <AlertResponse severity={alert.severity} type={alert.severity} onClose={handleAlertClose}>
            {alert.message}
          </AlertResponse>
        )}
      </Form>
    </Box>
  );
}
