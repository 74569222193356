import React from 'react';
import { Box, Button, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';

import { PharmacyService } from '@/services/pharmacy.service';
import { PharmacyDispenseLimit } from '@/types';
import { Logger } from '@/utils/logger';

const logger = new Logger('PharmacyDispenseStats');

type Response = {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  stats: {
    limits: {
      daily: {
        limit: number;
        used: number;
        available: number;
      };
    };
    counts: {
      pendingDispense: number;
      dispensedToday: number;
      totalToday: number;
    };
  };
  sameDayDeliveryEnabled: boolean;
}[];

type Row = {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  dispensedToday: number;
  allocated: number;
  total: number;
  dispenseLimit: number;
  used: string;
  sameDayDeliveryEnabled: boolean;
};

const COLUMNS: Partial<Record<keyof Row, string>> = {
  pharmacyCode: 'Pharmacy Code',
  pharmacyName: 'Pharmacy Name',
  dispensedToday: 'Dispensed Today',
  allocated: 'Currently allocated',
  total: 'Total orders today',
  used: 'Limit %',
  dispenseLimit: 'Dispense limit',
  sameDayDeliveryEnabled: 'Same Day Delivery',
};

function PharmacyDispenseStats() {
  const [stats, setStats] = React.useState<Response | null>(null);
  const [dispenseLimitAdjustments, setDispenseLimitAdjustments] = React.useState<Record<number, string>>({});
  const [updating, setUpdating] = React.useState<boolean>(false);

  React.useEffect(() => {
    PharmacyService.getPharmacyDispenseStats().then((response) => {
      setStats(response.data.data);
    });
  }, []);

  const rows: Row[] = React.useMemo(
    () =>
      stats
        ? stats.map((stat) => ({
            pharmacyId: stat.pharmacyId,
            pharmacyCode: stat.pharmacyCode,
            pharmacyName: stat.pharmacyName,
            dispensedToday: stat.stats.counts.dispensedToday,
            allocated: stat.stats.counts.pendingDispense,
            total: stat.stats.counts.totalToday,
            used: `${((stat.stats.counts.totalToday / stat.stats.limits.daily.limit) * 100).toFixed(2)}%`,
            dispenseLimit: stat.stats.limits.daily.limit,
            sameDayDeliveryEnabled: stat.sameDayDeliveryEnabled,
          }))
        : [],
    [stats],
  );

  const isDispenseLimitAdjustments = React.useMemo(
    () => !!Object.keys(dispenseLimitAdjustments).length,
    [dispenseLimitAdjustments],
  );

  const updateDispenseLimits = React.useCallback(async () => {
    setUpdating(true);
    try {
      const body = Object.keys(dispenseLimitAdjustments).reduce(
        (progress, key) => [
          ...progress,
          {
            pharmacyId: Number(key),
            dispenseLimitDaily: Number(dispenseLimitAdjustments[Number(key)]),
          },
        ],
        [] as PharmacyDispenseLimit[],
      );

      await PharmacyService.setPharmacyDispenseLimits(body);
      await PharmacyService.getPharmacyDispenseStats().then((response) => {
        setStats(response.data.data);
      });
    } catch (error) {
      logger.error('Error updating dispense limits', error);
    }
    setDispenseLimitAdjustments({});
    setUpdating(false);
  }, [dispenseLimitAdjustments]);

  const handleSameDayDeliveryToggle = async (pharmacyId: number, enabled: boolean) => {
    try {
      await PharmacyService.setPharmacySameDayDelivery(pharmacyId, enabled);
      setStats(
        (prevStats) =>
          prevStats?.map((stat) =>
            stat.pharmacyId === pharmacyId ? { ...stat, sameDayDeliveryEnabled: enabled } : stat,
          ) || null,
      );
    } catch (error) {
      logger.error('Error updating same day delivery status', error);
    }
  };

  if (!rows.length) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Box display="flex" justifyContent="end">
        <Button onClick={updateDispenseLimits} disabled={!isDispenseLimitAdjustments || updating}>
          Save
        </Button>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            {Object.values(COLUMNS).map((col) => (
              <TableCell key={col} style={{ fontWeight: 'bold' }}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const dispenseLimit = Number.isNaN(Number(dispenseLimitAdjustments[row.pharmacyId]))
              ? row.dispenseLimit
              : dispenseLimitAdjustments[row.pharmacyId];

            return (
              <TableRow key={row.pharmacyCode}>
                {Object.keys(COLUMNS)
                  .filter((col) => col !== 'dispenseLimit' && col !== 'sameDayDeliveryEnabled')
                  .map((col) => {
                    const key = col as keyof Row;
                    return <TableCell key={`pharmacy-${row.pharmacyCode}-${col}`}>{row[key]}</TableCell>;
                  })}
                <TableCell>
                  <TextField
                    type="number"
                    value={dispenseLimit}
                    style={{
                      backgroundColor: Number(dispenseLimit) !== row.dispenseLimit ? '#e6ffe6' : 'white',
                    }}
                    onBlur={() => {
                      setDispenseLimitAdjustments((current) =>
                        Object.keys(current).reduce((progress, key) => {
                          const value = current[Number(key)];
                          if (value !== '') {
                            return {
                              ...progress,
                              [Number(key)]: value,
                            };
                          }

                          return progress;
                        }, {} as Record<number, string>),
                      );
                    }}
                    onChange={(event) => {
                      const { value } = event.target;
                      setDispenseLimitAdjustments((current) => ({
                        ...current,
                        [row.pharmacyId]: value,
                      }));
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={row.sameDayDeliveryEnabled}
                    onChange={(event) => handleSameDayDeliveryToggle(row.pharmacyId, event.target.checked)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default PharmacyDispenseStats;
