import { Box, Grid, Skeleton } from '@mui/material';

export default function ProfileFormLoadingSkeleton() {
  return (
    <Box sx={{ mt: 6 }}>
      <Grid container rowSpacing={4} columnSpacing={7}>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
      </Grid>
    </Box>
  );
}
