import { createSvgIcon } from '@mui/material';

/**
 * An icon for topical
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2688-47216&m=dev
 *
 * @returns An SvgIcon
 */

export const MgTopical = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 8C16.5833 8 16.2292 7.85417 15.9375 7.5625C15.6458 7.27083 15.5 6.91667 15.5 6.5C15.5 6.21667 15.6417 5.84167 15.925 5.375C16.2083 4.90833 16.5667 4.45 17 4C17.4333 4.45 17.7917 4.90833 18.075 5.375C18.3583 5.84167 18.5 6.21667 18.5 6.5C18.5 6.91667 18.3542 7.27083 18.0625 7.5625C17.7708 7.85417 17.4167 8 17 8ZM19.5 15C18.8 15 18.2083 14.7583 17.725 14.275C17.2417 13.7917 17 13.2 17 12.5C17 11.9167 17.2583 11.1958 17.775 10.3375C18.2917 9.47917 18.8667 8.7 19.5 8C20.1333 8.7 20.7083 9.47917 21.225 10.3375C21.7417 11.1958 22 11.9167 22 12.5C22 13.2 21.7583 13.7917 21.275 14.275C20.7917 14.7583 20.2 15 19.5 15ZM9 18H11V16H13V14H11V12H9V14H7V16H9V18ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V12C4 10.5 4.475 9.2 5.425 8.1C6.375 7 7.56667 6.33333 9 6.1V4H7V2H13C13.5667 2 14.1 2.0875 14.6 2.2625C15.1 2.4375 15.5667 2.68333 16 3L14.55 4.45C14.3167 4.31667 14.0708 4.20833 13.8125 4.125C13.5542 4.04167 13.2833 4 13 4H11V6.1C12.4333 6.33333 13.625 7 14.575 8.1C15.525 9.2 16 10.5 16 12V20C16 20.55 15.8042 21.0208 15.4125 21.4125C15.0208 21.8042 14.55 22 14 22H6ZM6 20H14V12C14 10.9 13.6083 9.95833 12.825 9.175C12.0417 8.39167 11.1 8 10 8C8.9 8 7.95833 8.39167 7.175 9.175C6.39167 9.95833 6 10.9 6 12V20Z"
      fill="currentColor"
    />
  </svg>,
  'MgTopical',
);

export default MgTopical;
