import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ToastConfirmModal, toastOptions } from '../../components/common/toastConfirm';
import axios from 'axios';
import { toast } from 'react-toastify';
import settings from '../../constants/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('patientApprovals');
const PatientApprovals = (props) => {
  const [downloading, setDownloading] = React.useState(false);

  const downloadFile = async (e, sasDocument, oldFormat = false) => {
    e.preventDefault();
    if (!sasDocument.id || !sasDocument.patient_id) {
      toast.error('SAS document could not be downloaded.');
      logger.error('SAS document could not be downloaded. !sasDocument.id || !sasDocument.patient_id');
    }
    if (!sasDocument.sas_path && !sasDocument.sas_ingredient_path) {
      toast.error('SAS document could not be downloaded.');
      logger.error('SAS document could not be downloaded. !sasDocument.sas_path && !sasDocument.sas_ingredient_path');
    }
    setDownloading(true);
    await axios({
      url: 'sas/document/download',
      method: 'GET',
      params: {
        sasId: sasDocument.id,
        patientId: sasDocument.patient_id,
        oldFormat,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const downloadPath = oldFormat ? sasDocument.sas_path : sasDocument.sas_ingredient_path;
      link.setAttribute('download', downloadPath);
      document.body.appendChild(link);
      link.click();
    });
    setDownloading(false);
  };

  const deletefile = async (e) => {
    e.preventDefault();
    const value = e.target.dataset.key;
    const approvalType = e.target.dataset.old ? 'old=true' : '';
    toast(
      <ToastConfirmModal
        onConfirm={async () =>
          await axios
            .delete(settings.url + `/sas/delete?id=${value}&patientId=${props.id}&${approvalType}`)
            .then((response) => {
              if (response.data.status === 200) {
                toast.success('SAS removed successfully');
                props.history.push('/patients');
              } else {
                toast.success('SAS could not be removed');
              }
            })
        }
      >
        <p>Are you sure you want to delete this?</p>
      </ToastConfirmModal>,
      toastOptions,
    );
  };

  return (
    <React.Fragment>
      {props.sas.length > 0 && (
        <>
          <p className="mt-5">Product Approvals</p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Product</th>
                <th>SAS Document</th>
                <th>Doctor</th>
                <th>Uploaded Date</th>
                <th>Expiry Date</th>
                <th>Uploaded By</th>
                {props.isAdmin && <th>Delete</th>}
              </tr>
            </thead>
            <tbody>
              {props.sas.map((sasObj) => (
                <tr key={`sas-${sasObj.id}`}>
                  <td>{sasObj?.Product?.name}</td>
                  <td>
                    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '200px' }}>
                      <a href="/#" onClick={(e) => downloadFile(e, sasObj, true)} disabled={props.disabled}>
                        {sasObj.sas_path}
                      </a>
                    </div>
                  </td>
                  <td>
                    {sasObj.GeneralPractitioner.Doctor?.first_name + ' ' + sasObj.GeneralPractitioner.Doctor?.last_name}
                  </td>
                  <td>{moment(sasObj.upload_date).format('DD-MMM-YYYY')}</td>
                  <td>{moment(sasObj.expiry_date).format('DD-MMM-YYYY')}</td>
                  <td>{sasObj.User?.first_name + ' ' + sasObj.User?.last_name}</td>
                  {props.isAdmin && (
                    <td>
                      <button
                        className="btn btn-primary btn-md d-block font-weight-bold"
                        onClick={deletefile}
                        disabled={props.disabled}
                        data-key={sasObj.id}
                        data-old={true}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <p className="mt-5">Category/Formulation Approvals</p>
      {props.sasIngredients.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Category</th>
              <th>Formulation</th>
              <th>SAS Document</th>
              <th>Doctor</th>
              <th>Uploaded Date</th>
              <th>Expiry Date</th>
              <th>Uploaded By</th>
              {props.isAdmin && <th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {props.sasIngredients.map((sasObj) => (
              <tr key={`sasIngredient-${sasObj.id}`}>
                <td>{sasObj.ProductIngredient.name}</td>
                <td>{sasObj.ProductFormulation.name}</td>
                <td>
                  <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '200px' }}>
                    <a
                      href="/#"
                      onClick={(e) => downloadFile(e, sasObj)}
                      disabled={downloading}
                      data-key={sasObj.sas_ingredient_path}
                    >
                      {sasObj.sas_ingredient_path}
                    </a>
                  </div>
                </td>
                <td>
                  {sasObj.GeneralPractitioner.Doctor?.first_name + ' ' + sasObj.GeneralPractitioner.Doctor?.last_name}
                </td>
                <td>{moment(sasObj.upload_date).format('DD-MMM-YYYY')}</td>
                <td>{moment(sasObj.expiry_date).format('DD-MMM-YYYY')}</td>
                <td>{sasObj.User?.first_name + ' ' + sasObj.User?.last_name}</td>
                {props.isAdmin && (
                  <td>
                    <button
                      className="btn btn-primary btn-md d-block font-weight-bold"
                      onClick={deletefile}
                      disabled={downloading}
                      data-key={sasObj.id}
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mb-1 d-flex flex-wrap d-inline-block">
          <div className="m-1 p-2 sas-border">
            <p className="text-gray">Approval Pending</p>
          </div>
        </div>
      )}
      <>
        {props.disabled ? (
          <small className="text-muted">Please wait while the file is downloading...</small>
        ) : (
          <small className="text-muted">Click the links to download the approval file</small>
        )}
      </>
      <div>
        <small>
          <Link to={`/approval/${props.id}`}>Upload approval files</Link>
        </small>
      </div>
    </React.Fragment>
  );
};

export default PatientApprovals;
