import { Box, Skeleton, styled } from '@mui/material';

const LoadingBlockContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    gap: theme.spacing(4),
  },
}));

function ProductDetailsLoadingBlock() {
  return (
    <LoadingBlockContainer>
      <Box flex={1}>
        <Skeleton variant="rectangular" height="300px" />
      </Box>
      <Box flex={2}>
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
        <Skeleton variant="rectangular" height="150px" />
      </Box>
    </LoadingBlockContainer>
  );
}
export default ProductDetailsLoadingBlock;
