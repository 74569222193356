import { Chip, ChipProps } from '@mui/material';

import ProductStrainsMap from '@/constants/products/productStrains';
import { ProductStrain } from '@/types';

interface StrainChipProps {
  strain: ProductStrain;
  size?: ChipProps['size'];
}

function StrainChip(props: StrainChipProps) {
  const { strain, size } = props;
  const { icon: Icon, displayName, bgColor, color } = ProductStrainsMap[strain];

  return (
    <Chip
      size={size}
      label={displayName}
      icon={<Icon color="inherit" sx={{ color }} />}
      sx={{
        backgroundColor: bgColor,
      }}
    />
  );
}

export default StrainChip;
