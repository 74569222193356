import React from 'react';
import { productListing } from '@/services/product.service';
import Select from 'react-select';
import FilterableTable from 'rc-data-table';
import CircleLoader from '@/components/common/circleLoader';
import Button from '@/components/common/button';
import { AuthService } from '@/services/authentication.service';
import { Link } from 'react-router-dom';
import axios from 'axios';
import editIcon from '../../assets/images/edit.png';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { FF_SHOPIFY_DISABLE_PRODUCT_EDIT } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';

const ProductListing = () => {
  const { flags } = useFeatureFlags();
  const shopifyDisableProductEdit =
    flags[FF_SHOPIFY_DISABLE_PRODUCT_EDIT] || ffDefaults[FF_SHOPIFY_DISABLE_PRODUCT_EDIT];

  const [data, setData] = React.useState({
    active: { label: 'Active', value: 1 },
    products: [],
    loading: true,
  });
  const userRole = AuthService.getUser().user.role_id;

  const loadData = React.useCallback(async () => {
    const resp = await productListing(data.active.value, false);
    setData({
      ...data,
      products: resp.data,
      loading: false,
    });
  }, [data.active]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  const fields = [
    { name: 'name', displayName: 'Name', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
    { name: 'ingredient', displayName: 'Category', inputFilterable: true, sortable: false, tdClassName: 'sub-text' },
    {
      name: 'formulation',
      displayName: 'Formulation',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text',
    },
    {
      name: 'price',
      displayName: 'Price',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
      sortFieldName: 'price',
    },
    {
      name: 'outOfStock',
      displayName: 'Out Of Stock',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
      sortFieldName: '',
    },
    {
      name: 'sku',
      displayName: 'SKU Code',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
      sortFieldName: '',
    },
    {
      name: 'supplierName',
      displayName: 'Supplier Name',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
      sortFieldName: '',
    },
    {
      // eslint-disable-next-line react/display-name
      name: 'id',
      displayName: shopifyDisableProductEdit ? 'View' : 'Edit',
      thClassName: userRole === 4 ? 'd-none' : 'text-center ',
      tdClassName: userRole === 4 ? 'd-none' : 'sub-text text-center',
      render: (e) => {
        return (
          <Link to={`/productEdit/${e.value}`}>
            {shopifyDisableProductEdit ? (
              <VisibilityIcon />
            ) : (
              <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
            )}
          </Link>
        );
      },
    },
  ];

  const handleClick = () => {
    downloadFile();
  };
  const downloadFile = async () => {
    const products = await productListing(data.active.value, true).then((data) => {
      return data.data;
    });
    const itemsFormatted = [];
    for (const eachProduct of products) {
      itemsFormatted.push({
        Name: eachProduct.name,
        'Active Ingredients': eachProduct.ingredient,
        Price: eachProduct.price,
        'Out Of Stock': eachProduct.outOfStock,
        'SKU Code': eachProduct.sku,
        'Supplier Name': eachProduct.supplierName.join(','),
        Formulation: eachProduct.formulation,
        PrescriptionRange: eachProduct.range,
        CBD: eachProduct.cbd,
        THC: eachProduct.thc,
        CBDStrength: eachProduct.cbdStrength,
        THCStrength: eachProduct.thcStrength,
        Cultivar: eachProduct.cultivar,
        Strain: eachProduct.strain,
        Schedule: eachProduct.schedule,
        Hybrid: eachProduct.hybrid,
        Interval: eachProduct.interval,
        Weight: eachProduct.weight,
        Dosage: eachProduct.dosage,
        Short_name: eachProduct.shortName,
        volume: eachProduct.volume,
        Description: eachProduct.description,
        Condition: JSON.stringify(eachProduct.condition).replace(/\\n/g, ',').replace(/\\r/g, ''),
        Flavor: JSON.stringify(eachProduct.flavor).replace(/\\n/g, ',').replace(/\\r/g, ''),
        Use: JSON.stringify(eachProduct.use).replace(/\\n/g, ',').replace(/\\r/g, ''),
        CBD_Strength_for_dispaly: eachProduct.cbdDisplay,
        THC_Strength_for_dispaly: eachProduct.thcDisplay,
        CBD_THC_Ratio: eachProduct.cbdThcRatio,
      });
    }
    const fileTitle = new Date().getTime() + '-montu-products';
    await axios({
      url: 'excel/clinic/download',
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <h3>Products</h3>
        {AuthService.isAdmin() ? (
          <div className="float-right d-flex">
            {!shopifyDisableProductEdit && (
              <Link to="/productEdit/new" className="btn btn-primary mr-1">
                Add New Product
              </Link>
            )}
            {AuthService.isSuperAdmin() && (
              <Button text="Export All" className="btn btn-md btn-primary float-right" onClick={handleClick} />
            )}
            <div className="form-inline bulk-update mr-5">
              <Select
                name="mlActive"
                placeholder="Filter by Active"
                className="select-field mx-1 rounded"
                options={[
                  { label: 'Active', value: 1 },
                  { label: 'Inactive', value: 0 },
                ]}
                onChange={(e) => setData({ ...data, active: e })}
                value={data.active}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {data.loading ? (
          <CircleLoader />
        ) : (
          <FilterableTable
            className="table-responsive"
            topPagerVisible={false}
            data={data.products}
            fields={fields}
            pageSizes={false}
            pageSize={50}
            initialSort={'id'}
            serverSort={() => {}}
            filterPlaceholder="Search"
            initialSortDir={false}
            noRecordsMessage="There are no record to display"
            noFilteredRecordsMessage="No record match your filters!"
          />
        )}
      </div>
    </div>
  );
};

export default ProductListing;
