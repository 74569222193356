import React from 'react';
import { Container, styled } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import Footer from '@/components/layout/footer';
import HeaderUnauth from '@/components/layout/header-unauth';
import MainContentWrapper from '@/components/layout/MainContentWrapper';
import montuTheme from '@/theme';

const ContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function UnauthenticatedLayout({ children }: React.PropsWithChildren) {
  return (
    <CssVarsProvider theme={montuTheme}>
      <MainContentWrapper>
        <HeaderUnauth />
        <ContentContainer>{children}</ContentContainer>
        <Footer />
      </MainContentWrapper>
    </CssVarsProvider>
  );
}

export default UnauthenticatedLayout;
