import React, { Component } from 'react';
import axios from 'axios';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';

const url = settings.url;

class patientCreditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.dataBindPatientCredit();
  }

  //Get all credit from api
  dataBindPatientCredit = async () => {
    axios.get(`${url}/patientcredit/user/` + AuthService.getUser().user.id, {}).then((data) => {
      this.setState({ data: data.data.result });
    });
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        {
          <div className="container-fluid" style={{ padding: '100px' }}>
            <h3>Your Credits</h3>
            <div>
              <table className="table w-100">
                <thead>
                  <tr>
                    <td>Created Date</td>
                    <td>Total Credit</td>
                    <td>Consumed Credit</td>
                    <td>Available Credit</td>
                    <td>Expiration Date</td>
                  </tr>
                </thead>
                <tbody>
                  {data
                    ? data.map((item) => (
                        <tr key={item.id}>
                          <td>{item.createdOn}</td>

                          <td>${item.amount}</td>

                          <td>${item.consumedAmount}</td>

                          <td>${item.availableAmount}</td>

                          <td>{item.expiredOn}</td>
                        </tr>
                      ))
                    : 'No records found'}
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default patientCreditView;
