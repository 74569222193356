import React, { Component } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';

import settings from '@/constants/constants';

class TermsAndPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="p-4">
          <ul className="hide">
            <h2>TERMS AND CONDITIONS</h2>
            <h4>This PARTNERSHIP AGREEMENT (the "Agreement") is made on the date signed below between:</h4>
            <li>
              (1) Montu Group Pty Ltd, ABN 35 634 198 360, located at 1 Nicholson St, East Melbourne, VIC 3002
              ("Montu"); and
            </li>
            <li>(2) The Pharmacy with ABN and registered address as defined above in this agreement ("Pharmacy")</li>
            &nbsp;
            <h4>IT IS HEREBY AGREED as follows:</h4>
            <p>
              <h6>1. PARTNERSHIP</h6>
              <li>
                1.1. Montu supplies the Pharmacy with a range of Circle branded medicinal cannabis products (the
                "Products") to be dispensed to patients presenting a valid prescription.
              </li>
              <li>
                1.2. All product costs, dispensing and shipping fees are paid directly to Montu by the patient before
                dispensing.
              </li>
            </p>
            <p>
              <h6>2. FEES AND PAYMENTS</h6>
              <li>
                2.1. Montu shall pay the Pharmacy a fixed fee of AUD30.00 (excl. GST) per transaction for dispensing
                services.
              </li>
              <li>
                2.2. Montu will issue the Pharmacy with an RCTI on the 1st of each month, consolidating all transactions
                of the previous month.
              </li>
              <li>
                2.3. The Pharmacy shall issue Montu with a matching tax invoice within 14 days of receipt of the RCTI.
              </li>
              <li>2.4. Montu shall make payment within 14 days of invoice receipt.</li>
            </p>
            <p>
              <h6>3. PRODUCT OWNERSHIP AND RESPONSIBILITIES</h6>
              <li>
                3.1. The Pharmacy confirms that it has processes and facilities in place to accommodate the storage and
                dispensing of Schedule 8 medications.
              </li>
              <li>
                3.2. The Pharmacy acknowledges and agrees that property in the Products supplied by Montu to the
                Pharmacy does not pass onto the Pharmacy.
              </li>
              <li>
                3.3. Risk of any loss, damage or deterioration of or to the Products passes to the Pharmacy on the
                sooner of delivery onto premises or delivery into control.
              </li>
              <li>
                3.4. While ownership of the Products remains with Montu, the Pharmacy must store them separately and
                identify them as belonging to Montu.
              </li>
              <li>
                3.5. The Pharmacy shall process, store, handle and display all Products in accordance with the
                instructions displayed on the Products. Failure to comply with this clause shall render the Pharmacy
                solely liable for any defect in those Products.
              </li>
              <li>
                3.6. The Pharmacy shall report to Montu any technical or quality complaints relating to the Products via
                phone ({settings.support.phone.display}) or email (circle.au@montugroup.com) within 24 hours of initial
                awareness.
              </li>
            </p>
            <p>
              <h6>4. CHANGES AND TERMINATION</h6>
              <li>
                4.1. At its discretion, Montu may alter the terms of this Agreement at any time. Any change in terms or
                fees shall be communicated by Montu to the Pharmacy in writing.
              </li>
              <li>
                4.2. Either party shall be able to terminate this Agreement, by providing the other party with 14 days
                written notice.
              </li>
            </p>
          </ul>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default TermsAndPolicy;
