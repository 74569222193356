import axios from 'axios';

import { GENERATIVE, OUT_OF_STOCK } from '@/components/patient/enquiryModal/common';
import settings from '@/constants/constants';

const API_URL = `${settings.url}/patient`;

// eslint-disable-next-line no-shadow
export enum RescriptRequestReasons {
  Discontinued = GENERATIVE,
  OutOfStock = OUT_OF_STOCK,
}

export interface PatientRescriptRequestPayload {
  prescriptionId: number;
  productId: number;
  rescriptRequestReason: RescriptRequestReasons;
}

const createPatientRescriptRequest = async (patientId: number, data: PatientRescriptRequestPayload) => {
  const res = await axios.post(`${API_URL}/${patientId}/rescript-request`, data);

  return res.data;
};

export interface PatientOrderTrackingStatus {
  orderCode: string;
  orderDate: string;
  orderTimezone: string;
  trackingId: string | null;
  carrier: string | null;
  orderStatus: number;
}

export const getPatientOrderTrackingStatus = async () => {
  const result = await axios.get(`${API_URL}/orders/track-last-shipped-order`);
  return result.data;
};

const getPatientOrders = () => axios.get(`${API_URL}/orders`);

const getPatientOrderDetails = (orderId: string | number) => axios.get(`${API_URL}/orderDetail/${orderId}`);

const getPatientDoctorAvailability = (patientId?: string | number) =>
  axios.get(`${API_URL}/currentDoctorsAvailability/${patientId}`);

const getPatientConsultations = (patientId?: string | number) => axios.get(`${API_URL}/consultations/${patientId}`);

export const PatientService = {
  createPatientRescriptRequest,
  getPatientConsultations,
  getPatientDoctorAvailability,
  getPatientOrders,
  getPatientOrderDetails,
};
