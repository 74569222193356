import { useQuery } from 'react-query';
import axios, { isAxiosError } from 'axios';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

type IOrderReceiptResponse = {
  data: {
    signedUrl: string;
  };
};
const logger = new Logger('useGetOrderReceipt');

export default function useGetOrderReceipt(orderCode: string, enabled: boolean) {
  const { data, isError, isLoading } = useQuery<IOrderReceiptResponse>({
    queryKey: ['orderReceipt', orderCode],
    queryFn: async () => {
      try {
        const res = await axios.get(`${settings.customerPortalApiUrl}/orders/${orderCode}/receipt`);
        return res.data;
      } catch (error) {
        logger.error('Failed to fetch order receipt');
        throw error;
      }
    },
    retry: (_, error) => {
      if (isAxiosError(error)) {
        return false;
      }
      return true;
    },
    enabled,
  });

  return {
    data,
    isError,
    isLoading,
  };
}
