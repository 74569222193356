import { useLocation } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';

import { INavMenu } from './navbar.config';
import { NavMenuLink, NavMenuListItem } from './NavMenuItem';

interface INavMenuItem {
  menuItem: INavMenu;
  onClick?: () => void;
}

function MobileNavMenuItem({ menuItem, onClick }: INavMenuItem) {
  const location = useLocation();
  const isMedicineInfoActive =
    menuItem.id === 5 &&
    (location.pathname === menuItem.link ||
      location.pathname === '/medicine-information' ||
      location.pathname.includes('/product-detail'));
  const isActive = isMedicineInfoActive || location.pathname === menuItem.link;

  return (
    <NavMenuListItem onClick={onClick} className={isActive ? 'active' : ''}>
      <NavMenuLink
        to={menuItem.link}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <ListItemIcon
          sx={{
            color: (theme) => theme.palette.common.white,
          }}
        >
          <menuItem.icon />
        </ListItemIcon>
        {menuItem.name}
      </NavMenuLink>
    </NavMenuListItem>
  );
}

export default MobileNavMenuItem;
