import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { mq } from '@/theme';
import { Logger } from '@/utils/logger';

const logger = new Logger('Prescription');

type Props = {
  data: {
    repeats: number;
    thcStrengthForDisplay?: string;
    cbdStrengthForDisplay?: string;
  };
  hideRepeats?: boolean;
};

const MQLarge = mq('lg');

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${MQLarge} {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 0.65rem;
  }
`;

const Contents = styled.div`
  background-color: ${({ theme }) => theme.palette?.grey?.[50]};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.25rem 0.5rem;
  height: fit-content;
  margin-bottom: 0.25rem;
  margin-top: 0;

  ${MQLarge} {
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    justify-content: flex-start;
    align-self: center;
    grid-column-start: 1;
    grid-row-start: 1;
    padding: 0;
    margin: 0;
  }
`;

const Items = styled.div`
  align-items: center;
  display: flex;

  &:first-of-type {
    margin-right: 0.5rem;
  }

  &:only-of-type {
    margin-right: 0;
  }

  ${MQLarge} {
    white-space: nowrap;

    &:first-of-type {
      margin-right: 0;
    }
  }
`;

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette?.grey?.[800]};
`;

const ItemValue = styled(Typography)`
  font-weight: 800;
  color: ${({ theme }) => theme.palette?.grey?.[800]};
`;

const Quantity = styled.div`
  background-color: ${({ theme }) => theme.palette?.grey?.[50]};
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  height: fit-content;
  margin: 0;

  ${MQLarge} {
    background-color: transparent;
    align-self: center;
    grid-column-start: 2;
    grid-row-start: 1;
    padding: 0;
  }
`;

function isNotEmptyOrDash(val?: string | null) {
  try {
    if (typeof val !== 'string') return false;
    if (!val) return false;
    if (!val.trim().replace(/-/g, '')) return false;
    return true;
  } catch (e) {
    logger.error('isNotEmptyOrDash', e);
    return false;
  }
}

export default function Prescription({
  data: { repeats, cbdStrengthForDisplay, thcStrengthForDisplay },
  hideRepeats = false,
}: Props) {
  const displayThc = isNotEmptyOrDash(thcStrengthForDisplay);
  const displayCbd = isNotEmptyOrDash(cbdStrengthForDisplay);
  const displayAtLeastThcOrCbd = displayThc || displayCbd;

  return (
    <Container>
      {displayAtLeastThcOrCbd && (
        <Contents>
          {displayThc && (
            <Items>
              <ItemName>THC:&nbsp;</ItemName>
              <ItemValue>{thcStrengthForDisplay}</ItemValue>
            </Items>
          )}
          {displayThc && (
            <Items>
              <ItemName>CBD:&nbsp;</ItemName>
              <ItemValue>{cbdStrengthForDisplay}</ItemValue>
            </Items>
          )}
        </Contents>
      )}
      {!hideRepeats && (
        <Quantity>
          <Items>
            <ItemName>Repeats:&nbsp;</ItemName>
            <ItemValue>{repeats}</ItemValue>
          </Items>
        </Quantity>
      )}
    </Container>
  );
}
