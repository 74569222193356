import { FF_ENABLE_SHOPIFY_CHECKOUT, FF_SHIPPING_PRICE } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const locale = 'en-AU';
const currency = 'AUD';

function formatPrice(price: number) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(price);
}

export type ShippingPriceInfo = {
  shipping: number;
  shippingGst: number;
  shippingTotal: number;
  formattedShipping: string;
  formattedShippingGst: string;
  formattedShippingTotal: string;
  locale: string;
  currency: string;
  loading: boolean;
};

export function useShippingPrice(): ShippingPriceInfo {
  const { flags, loading } = useFeatureFlags();
  const shopifyCheckoutEnabled = flags[FF_ENABLE_SHOPIFY_CHECKOUT];

  if (shopifyCheckoutEnabled) {
    return {
      shipping: 0,
      shippingGst: 0,
      shippingTotal: 0,
      // these will be ignored by OrderSummary when shopifyCheckoutEnabled=true
      formattedShipping: '',
      formattedShippingGst: '',
      formattedShippingTotal: '',
      locale: 'en-AU',
      currency: 'AUD',
      loading,
    };
  }
  const ffShipping = flags[FF_SHIPPING_PRICE] || ffDefaults[FF_SHIPPING_PRICE];

  const shipping = parseFloat(ffShipping);
  const shippingGst = 0.1 * shipping;
  const shippingTotal = shipping + shippingGst;

  const formattedShipping = formatPrice(shipping);
  const formattedShippingGst = formatPrice(shippingGst);
  const formattedShippingTotal = formatPrice(shippingTotal);

  return {
    shipping,
    shippingGst,
    shippingTotal,
    formattedShipping,
    formattedShippingGst,
    formattedShippingTotal,
    locale,
    currency,
    loading,
  };
}

export type ShippingParts = ReturnType<typeof useShippingPrice>;

export default useShippingPrice;
