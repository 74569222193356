import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { AddressDataType, LocationInput } from '@montugroup/design-system';
import { MailOutline } from '@mui/icons-material';
import { Box, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { FormData } from '@/components/user/types';
import settings from '@/constants/constants';
import { FF_ENABLE_GOOGLE_PLACES_UMEDS } from '@/constants/featureFlags';
import { IState, IUserProfile } from '@/hooks/rest/useGetUserProfile';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { AlertResponse } from '@/pages/profile/PatientRebrandProfile';
import AuthService from '@/services/authentication.service';
import { getStates } from '@/services/data.service';
import { UserService } from '@/services/user.service';
import { State } from '@/types';
import { LoadingButton } from '@/ui-library';
import MgPhone from '@/ui-library/icons/MgPhone';
import isValidPhoneNumber from '@/utils/isValidPhoneNumber';
import { Logger } from '@/utils/logger';

import { IAlert } from './types';
import { validateKeyDownPhoneNumber, validateParcelLocker } from './utils';

const logger = new Logger('profileForm');

interface IProfileForm {
  data: IUserProfile;
}

export default function ProfileForm({ data }: IProfileForm) {
  const { patient, user, states, countries } = data;
  const [line1, line2] = user.address?.split('$$$$') || [];
  const countryId = countries.find((country) => country.id === user?.country_id);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<FormData>();

  const profileState = states?.find((state) => state.id === user.state_id);
  const [userStates, setUserStates] = useState<IState[]>(states || []);
  const [alert, setAlert] = useState<IAlert>({ severity: 'success', message: '' });
  const { flags } = useFeatureFlags();
  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES_UMEDS];
  const [enableManualAddress, setEnableManualAddress] = useState(false);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const fullAddress = useWatch({ control, name: 'full_address' });

  const handleAlertClose = () => {
    setAlert({ severity: 'success', message: '' });
  };

  const onChangeCountry = async (countryCode: string) => {
    try {
      const countryStates: State[] = await getStates(countryCode);
      setUserStates(countryStates.map((state) => ({ name: state.name, id: state.id })));
    } catch (e) {
      logger.error('Error retrieving states', e);
    }

    setValue('userCountry', countryCode);
    setValue('userState', '');
  };

  const handleAutoCompleteAddress = (addressData: AddressDataType) => {
    setValue(
      'full_address',
      `${addressData.subpremise} ${addressData.street_number} ${addressData.address}, ${addressData.suburb}, ${addressData.city}, ${addressData.postcode}`.trim(),
    );
    setValue('address_line_1', `${addressData.street_number} ${addressData.address}`.trim());
    setValue('address_line_2', addressData.subpremise);
    setValue('city', addressData.suburb || addressData.city);
    setValue('postcode', addressData.postcode);
    setValue('userState', addressData.state);
    setEnableSubmitButton(true);
  };

  const handleReset = () => {
    setValue('full_address', '');
  };

  const formDefaultValues = useMemo(
    () => ({
      firstName: user.first_name,
      lastName: user.last_name,
      patientDob: patient?.dob ? new Date(data.patient.dob) : null,
      email: data.user.email,
      phone: data.user.phone,
      address_line_1: line1,
      address_line_2: line2,
      city: data.user.city,
      userState: profileState?.name,
      postcode: data.user.zip_code,
      userCountry: String(countryId?.id),
      full_address: `${line1}${line2 ? `, ${line2}` : ''}, ${data.user.city}, ${data.user.zip_code}` || '',
    }),
    [user, patient, countryId, line1, line2],
  );

  useEffect(() => {
    Object.entries(formDefaultValues).forEach(([fieldName, value]) => {
      if (value !== undefined) {
        setValue(fieldName as keyof FormData, value);
      }
    });
  }, [formDefaultValues, setValue]);

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    const stateId = userStates.find((state) => state.name === formData.userState)?.id;

    const payload = {
      patient: { dob: formData.patientDob },
      user: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        address: `${formData.address_line_1}$$$$${formData.address_line_2}`,
        city: formData.city,
        state_id: stateId,
        zip_code: formData.postcode,
        country_id: parseInt(formData.userCountry, 10),
      },
    };

    try {
      await UserService.updateUserProfile(payload);
      AuthService.updatePhone(formData.phone);
      reset(formData);
      setEnableSubmitButton(false);
      setAlert({ severity: 'success', message: 'Profile Updated' });
    } catch (e) {
      setAlert({ severity: 'error', message: 'Something went wrong, please try again' });
    }
  };

  return (
    <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 4 }}>
      <Grid container rowSpacing={{ xs: 6, md: 4 }} columnSpacing={7}>
        <Grid item xs={12} sm={4}>
          <TextField
            {...register('firstName')}
            variant="standard"
            fullWidth
            defaultValue={user.first_name || ''}
            id="firstName"
            label="First name"
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...register('lastName')}
            variant="standard"
            fullWidth
            defaultValue={user.last_name || ''}
            id="lastName"
            label="Last name"
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="patientDob"
            control={control}
            defaultValue={new Date(patient.dob) || null}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="Date of Birth"
                disabled
                sx={{
                  width: '100%',
                }}
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                  inputAdornment: {
                    position: 'start',
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            {...register('email')}
            variant="standard"
            fullWidth
            defaultValue={user.email || ''}
            id="email"
            label="Email Address"
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline sx={{ color: 'action.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: 'Phone number is required',
              validate: (value: string) => isValidPhoneNumber(value) || 'Invalid phone number format',
            }}
            defaultValue={user?.phone || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="standard"
                type="tel"
                value={value}
                fullWidth
                id="phone"
                label="Phone Number"
                autoComplete="tel"
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MgPhone />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => validateKeyDownPhoneNumber(e)}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        {isGooglePlacesEnabled && !enableManualAddress ? (
          <Grid item xs={12} sm={8}>
            <Controller
              name="full_address"
              defaultValue={`${line1}, ${line2}, ${user.city}, ${user.zip_code}` || ''}
              control={control}
              rules={{ required: 'You must enter the street address' }}
              render={({ field: { value } }) => (
                <LocationInput
                  value={value}
                  name="full_address"
                  id="full_address"
                  variant="standard"
                  label="Shipping Address"
                  shrinkLabel
                  googlePlacesApiKey={settings.googlePlacesApiKey}
                  onSelectedAddress={handleAutoCompleteAddress}
                  onReset={handleReset}
                  manualAddressFn={() => setEnableManualAddress(!enableManualAddress)}
                />
              )}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={8}>
              <TextField
                {...register('address_line_1', {
                  required: 'You must enter a street address',
                  validate: (value: string) =>
                    validateParcelLocker(value) ||
                    'Parcel lockers are not allowed. Please enter a residential or PO Box address.',
                })}
                variant="standard"
                defaultValue={line1 || ''}
                fullWidth
                id="address_line_1"
                label="Street address 1"
                InputLabelProps={{ shrink: true }}
                error={!!errors.address_line_1}
                helperText={errors.address_line_1?.message}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                {...register('address_line_2')}
                variant="standard"
                fullWidth
                defaultValue={line2 || ''}
                id="address_line_2"
                label="Street address 2"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...register('city', {
                  required: 'You must enter a city',
                })}
                variant="standard"
                defaultValue={user.city || ''}
                fullWidth
                id="city"
                label="City"
                InputLabelProps={{ shrink: true }}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="userState"
                control={control}
                defaultValue={profileState?.name || ''}
                rules={{ required: 'You must select a state' }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    variant="standard"
                    select
                    fullWidth
                    label="State"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.userState}
                    helperText={errors.userState?.message}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    {userStates.map((userState) => (
                      <MenuItem key={userState.id} value={userState.name}>
                        {userState.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                {...register('postcode', {
                  required: 'You must enter a postcode',
                })}
                variant="standard"
                defaultValue={user.zip_code || ''}
                fullWidth
                id="postcode"
                label="Postcode"
                InputLabelProps={{ shrink: true }}
                error={!!errors.postcode}
                helperText={errors.postcode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="userCountry"
                control={control}
                defaultValue={String(countryId?.id) || ''}
                rules={{ required: 'You must select a country' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChangeCountry(e.target.value);
                      onChange(e);
                    }}
                    variant="standard"
                    select
                    fullWidth
                    label="Country"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    error={!!error}
                    helperText={error?.message}
                  >
                    {countries?.map((countryItem) => (
                      <MenuItem key={countryItem.id} value={countryItem.id}>
                        {countryItem.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <LoadingButton
        type="submit"
        size="large"
        variant="contained"
        data-testid="submit-button"
        loading={isSubmitting}
        disabled={!(isDirty || (fullAddress?.length > 0 && enableSubmitButton))}
        sx={{
          mt: { xs: 6, md: 4 },
          width: {
            xs: '100%',
            md: 'auto',
          },
        }}
      >
        Update Profile
      </LoadingButton>

      {alert.message && (
        <AlertResponse severity={alert.severity} type={alert.severity} onClose={handleAlertClose}>
          {alert.message}
        </AlertResponse>
      )}
    </Box>
  );
}
