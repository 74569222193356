import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ResourceCard = (props) => {
  return (
    <div className={`card shadow ${props.cssClass} resource-card rounded m-2`}>
      <div className="card-body">
        <h4 className="card-title">{props.title}</h4>
        <hr className="res-short-line" />
        <p className="font-light">{props.body}</p>
      </div>
      {props.link && (
        <Link to={props.link} className="card-footer text-decoration-none dashboard-link">
          See more <i className="ml-2 fa fa-arrow-right"></i>
        </Link>
      )}
    </div>
  );
};

ResourceCard.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  link: PropTypes.string,
  cssClass: PropTypes.string.isRequired,
};

export default ResourceCard;
