import parsePhoneNumber from 'libphonenumber-js';

/**
 * Validates a phone number.
 * For Australian (AU) numbers, the country calling code is not required.
 * For numbers from other countries, the country calling code must be included.
 *
 * @param {string} phone - The phone number to validate.
 * @return {boolean} - Returns true if the phone number is valid, otherwise false.
 */
const isValidPhoneNumber = (phone: string) => {
  const phoneNumber = parsePhoneNumber(phone, { defaultCountry: 'AU' });

  return !!phoneNumber && phoneNumber.isValid();
};

export default isValidPhoneNumber;
