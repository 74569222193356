import { createSvgIcon } from '@mui/material';

/**
 * An icon for tablets
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2677-49112&m=dev
 *
 * @returns An SvgIcon
 */

export const MgTablets = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_2677_49112)">
      <path
        d="M20.7504 8.02311C21.8582 9.13091 22.4122 10.4567 22.4122 12.0006C22.4122 13.5444 21.8582 14.8703 20.7504 15.9781C20.2201 16.5084 19.6132 16.915 18.9296 17.1978C18.2461 17.4807 17.5272 17.6221 16.773 17.6221H7.22703C6.47278 17.6221 5.75389 17.4807 5.07035 17.1978C4.38681 16.915 3.77988 16.5084 3.24955 15.9781C2.14175 14.8703 1.58785 13.5444 1.58785 12.0006C1.58785 10.4567 2.14175 9.13091 3.24955 8.02311C3.77988 7.49278 4.38682 7.0862 5.07035 6.80335C5.75389 6.52051 6.47278 6.37909 7.22703 6.37909H16.773C17.5272 6.37909 18.2461 6.52051 18.9296 6.80335C19.6132 7.0862 20.2201 7.49278 20.7504 8.02311ZM10.9924 8.37667L7.22703 8.35899C6.75562 8.35899 6.296 8.45327 5.84817 8.64183C5.40034 8.83039 5.00553 9.09556 4.66377 9.43733C3.95666 10.1444 3.60311 10.9989 3.6031 12.0006C3.6031 13.0023 3.95666 13.8567 4.66377 14.5639C5.00553 14.9056 5.40034 15.1708 5.84817 15.3593C6.296 15.5479 6.75562 15.6422 7.22703 15.6422L10.9924 15.6245V8.37667ZM19.3362 9.43733C18.9945 9.09556 18.5997 8.83039 18.1518 8.64183C17.704 8.45327 17.2444 8.35899 16.773 8.35899L13.0076 8.37667V15.6245L16.773 15.6422C17.2444 15.6422 17.704 15.5479 18.1518 15.3593C18.5997 15.1708 18.9945 14.9056 19.3362 14.5639C20.0433 13.8567 20.3969 13.0023 20.3969 12.0006C20.3969 10.9989 20.0433 10.1444 19.3362 9.43733Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2677_49112">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'MgTablets',
);

export default MgTablets;
