import React from 'react';
import { Alert, Box, CircularProgress, Pagination, styled, TextField, useMediaQuery, useTheme } from '@mui/material';

import FullPageContainer from '@/components/common/FullPageContainer';
import PageDivider from '@/components/common/PageDivider';
import PageHeading from '@/components/common/PageHeading';
import { Prescription } from '@/components/patient/prescription/common';
import PrescriptionCard from '@/components/patient/prescription/PrescriptionCard';
import PrescriptionsTable from '@/components/patient/prescription/PrescriptionTable';
import useGetPatientPrescriptions from '@/hooks/rest/useGetPatientPrescriptions';

import usePagination from '../../hooks/usePagination';

const Toolbox = styled(Box)({
  display: 'flex',
  justifyContent: 'stretch',
});

const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

// Frontend filtering...
function searchPrescriptions(prescriptions: Prescription[], query: string) {
  if (!query) {
    return prescriptions;
  }
  return prescriptions.filter((prescription) => {
    if (
      prescription.presciptionCode.toLowerCase().includes(query.toLowerCase()) ||
      prescription.productName.toLowerCase().includes(query.toLowerCase())
    ) {
      return true;
    }
    return false;
  });
}

function PatientPrescriptions() {
  const [search, setSearch] = React.useState('');

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    data: prescriptionsData,
    isLoading: loadingPrescriptions,
    isError: errorPrescriptions,
  } = useGetPatientPrescriptions();

  const filteredPrescriptions = searchPrescriptions(prescriptionsData || [], search);

  const [handlePagination, currentPage, filterData, totalPageCount] = usePagination({
    data: filteredPrescriptions,
    search,
  });
  const prescriptions = filterData as Prescription[];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handlePageChange = (_: unknown, value: number) => {
    // Table pagination API is 0 based but usePagination hook is 1 based
    handlePagination('PickNumber', value + 1);
  };

  const isLoading = loadingPrescriptions;
  const hasError = errorPrescriptions;

  return (
    <FullPageContainer>
      <TitleRow>
        <PageHeading>Prescriptions</PageHeading>
        <Toolbox>
          <Box>{hasError && <Alert severity="error">An unexpected error occurred. Please try again.</Alert>}</Box>
          <TextField
            value={search}
            onChange={handleSearchChange}
            variant="outlined"
            placeholder="Search..."
            sx={{ width: '100%', mt: '-1rem' }}
          />
        </Toolbox>
      </TitleRow>
      <PageDivider />
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {isSmUp ? (
            <PrescriptionsTable
              prescriptions={prescriptions}
              tablePaginationProps={{
                count: filteredPrescriptions.length,
                page: currentPage - 1,
                onPageChange: handlePageChange,
                rowsPerPage: 10,
                rowsPerPageOptions: [10],
              }}
            />
          ) : (
            <Box>
              {prescriptions.length === 0 && <Alert severity="info">No prescriptions found</Alert>}
              {prescriptions.map((prescription) => (
                <PrescriptionCard
                  key={`${prescription.presciptionCode}-${prescription.productName}`}
                  prescription={prescription}
                />
              ))}
              <Box display="flex" justifyContent="center" marginTop={8}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={totalPageCount}
                  page={currentPage}
                  onChange={(_, value) => handlePageChange(_, value - 1)}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </FullPageContainer>
  );
}

export default PatientPrescriptions;
