import { useQuery } from 'react-query';

import { Prescription } from '@/components/patient/prescription/common';
import { getPatientPrescriptions } from '@/services/data.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGetPatientPrescriptions');

export const useGetPatientPrescriptions = () => {
  const { data, isError, error, isLoading, isSuccess } = useQuery<Prescription[]>({
    queryKey: ['patientPrescriptions'],
    queryFn: async () => {
      const response = await getPatientPrescriptions();
      return response.data as Prescription[];
    },
  });

  if (isError) {
    logger.error('Error fetching prescriptions, error:', error);
  }

  return {
    data,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useGetPatientPrescriptions;
