import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

import SupportHeader from '@/components/supportSelfService/SupportHeader';
import SupportTabButtons from '@/components/supportSelfService/SupportTabButtons';
import SupportTabContent from '@/components/supportSelfService/SupportTabContent';
import SupportTask from '@/components/supportSelfService/SupportTask';
import SupportTaskSubmenu from '@/components/supportSelfService/SupportTaskSubmenu';
import { SupportTaskProps } from '@/components/supportSelfService/types';
import settings from '@/constants/constants';
import theme from '@/theme';
import { buildIdFromEndpoint, groupTasksByCategory } from '@/utils/supportTasks';

function SupportSelfService() {
  const [tasks, setTasks] = useState<SupportTaskProps[]>([]);
  const [teams, setTeams] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [highlightedTaskId, setHighlightedTaskId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  const getTasks = async () => {
    try {
      const res = await axios({
        method: 'get',
        url: `${settings.url}/support/self-service-tasks`,
      });
      setTasks(res.data.tasks);
      setTeams(res.data.teams);
    } catch (err) {
      const e = err as unknown as { message: string };
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getTasks();
    })();
  }, []);

  const tasksByCategory = groupTasksByCategory(tasks);
  const categories = Object.keys(tasksByCategory) as SupportTaskProps['category'][];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 6,
        py: 4,
        maxWidth: theme.breakpoints.values.lg,
        m: '0 auto',
      }}
    >
      <SupportHeader tasks={tasks} teams={teams} loading={loading} error={error} />

      {categories.length > 0 && (
        <>
          <SupportTabButtons categories={categories} tabIndex={tabIndex} setTabIndex={setTabIndex} />
          {categories.map((category, index) => (
            <SupportTabContent tabIndex={index} selectedTabIndex={tabIndex} key={category}>
              <>
                <SupportTaskSubmenu
                  tasks={tasks.filter((task) => task.category === category)}
                  setHighlightedTaskId={setHighlightedTaskId}
                />

                {tasksByCategory[category].map((task) => (
                  <SupportTask
                    key={task.title}
                    highlight={highlightedTaskId === buildIdFromEndpoint(task.endpoint)}
                    {...task}
                  />
                ))}
              </>
            </SupportTabContent>
          ))}
        </>
      )}
    </Box>
  );
}

export default SupportSelfService;
