import { toast } from 'react-toastify';
import axios from 'axios';

import { Logger } from '@/utils/logger';

import settings from '../constants/constants';

import { AuthService } from './authentication.service';

const logger = new Logger('sas.service');

axios.defaults.baseURL = settings.url;
axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getUser() ? AuthService.getUser()?.token : null}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const API_URL = `${settings.url}/sas`;

export type SASUploadData = {
  file: File | string;
  patient: number | string;
  gp_id?: number;
  incredient: number;
  formulation: number;
  expiryDate: string;
  tz: string;
  patient_script: boolean;
  patient_sas_approval: boolean;
  patient_s8_approval: boolean;
  mbNumber: string;
  approvalDate: string;
};

type SASUploadResponse = {
  active: boolean;
  id: number;
  approval_date: Date;
  approval_date_tz: string;
  created_by: number;
  created_date: Date;
  expiry_date: Date;
  expiry_date_tz: string;
  formulation_id: string;
  general_practitioner_id: number;
  ingredient_id: string;
  mb_number: string;
  modified_date: Date;
  patient_id: string;
  patient_script: boolean;
  patient_sas_approval: boolean;
  patient_s8_approval: boolean;
  sas_ingredient_path: string;
  upload_date: Date;
  upload_date_tz: string;
};

const sasUpload = async (data: SASUploadData) => {
  // remove once sasApprovalForm.jsx converted to tsx
  if (!data) {
    toast.error('Incorrect data!');
    logger.error('sasUpload Incorrect data');
    throw new Error('Invalid SAS Upload');
  }

  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('patient', data.patient.toString());
  if (data.gp_id) {
    formData.append('gp_id', data.gp_id.toString());
  }
  formData.append('incredient', data.incredient.toString());
  formData.append('formulation', data.formulation.toString());
  formData.append('expiryDate', data.expiryDate);
  formData.append('tz', data.tz);
  formData.append('patient_script', data.patient_script.toString());
  formData.append('patient_sas_approval', data.patient_sas_approval.toString());
  formData.append('patient_s8_approval', data.patient_s8_approval.toString());
  formData.append('mbNumber', data.mbNumber);
  formData.append('approvalDate', data.approvalDate);

  try {
    const response = await axios.post<{
      message: string;
      success: SASUploadResponse | undefined;
    }>(API_URL, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

    toast.success('Uploads have been successful', {});

    return response;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      toast.error(response?.data.message, {});
      logger.error(`catch ${response?.data.message}`);
      throw new Error(response?.data.message);
    } else {
      toast.error('Upload failed', {});
      throw error;
    }
  }
};

export const SasService = {
  sasUpload,
};
