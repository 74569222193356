import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Button, Stack, styled, Typography } from '@mui/material';

import useCalendarBooking from '@/hooks/consultations/useCalcomBooking';
import { User } from '@/types/user.types';

type BookConsultationCardProps = {
  link: string;
  price: string;
  refetch: () => void;
  user: User | undefined;
};

const FollowUpContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  padding: theme.spacing(6, 5.25),
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, 0.20)',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(98),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.spacing(6),
  letterSpacing: 0,
}));

const Description = styled(Typography)(({ theme }) => ({ fontSize: theme.spacing(3.5) }));

const Price = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.spacing(7),
  lineHeight: theme.spacing(9),
}));

const BookButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[2],
  alignSelf: 'stretch',
  color: theme.palette.common.white,
  height: theme.spacing(10),
}));

function BookConsultationCard({ link, price, refetch, user }: BookConsultationCardProps) {
  const prefilledFormData = {
    email: user?.email,
    name: `${user?.first_name} ${user?.last_name}`,
    phoneNumber: user?.phone,
  };

  const { formData: calendarFormData, origin } = useCalendarBooking({
    formData: prefilledFormData,
    handleBookingSuccess: refetch,
    handleRescheduleSuccess: refetch,
    handleCancelSuccess: refetch,
  });

  return (
    <FollowUpContainer>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Title>Follow-up consultation</Title>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ScheduleIcon sx={{ width: '1.25rem', height: '1.25rem', color: (theme) => theme.palette.secondary.main }} />
          <Typography>10 minutes</Typography>
        </Stack>
      </Stack>
      <Description>
        During your follow-up session, you'll have the chance to evaluate your treatment progress
      </Description>
      <Price>{price}</Price>
      <BookButton data-cal-link={link} data-cal-config={calendarFormData} data-cal-origin={origin} variant="contained">
        Book consultation
      </BookButton>
    </FollowUpContainer>
  );
}

export default BookConsultationCard;
