const checkValIsEmpty = (value) => value === null || value === '' || value === 'undefined';
const truncateString = (val, charLength = 20) => {
  let result = val;
  if (!checkValIsEmpty(val) && !checkValIsEmpty(charLength)) {
    if (val.length > charLength) {
      result = `${val.substring(0, charLength)}...`;
    }
  }
  return result;
};
export default truncateString;
