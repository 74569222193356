import { SkipNext, SkipPrevious } from '@mui/icons-material';
import { styled } from '@mui/material';
import Pagination, { PaginationRenderItemParams } from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { HandlePagination } from '@/hooks/usePagination';

const CenteredPagination = styled(Pagination)({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

export default function ConsultationPagination({
  currentPage,
  totalPageCount,
  handlePagination,
}: {
  currentPage: number;
  totalPageCount: number;
  handlePagination: HandlePagination;
}) {
  const handlePageChange = (_: unknown, value: number) => {
    handlePagination('Next', value);
    window.scrollTo({ top: 0 });
  };

  const handlePaginationIcons = (icon: PaginationRenderItemParams) => {
    if (icon.type === 'previous' || icon.type === 'next') {
      return <PaginationItem {...icon} color="primary" />;
    }
    if (icon.type === 'first') {
      return (
        <PaginationItem
          {...icon}
          color="primary"
          slots={{
            first: SkipPrevious,
          }}
        />
      );
    }

    if (icon.type === 'last') {
      return (
        <PaginationItem
          {...icon}
          color="primary"
          slots={{
            last: SkipNext,
          }}
        />
      );
    }
    return null;
  };

  return (
    <CenteredPagination
      count={totalPageCount}
      page={currentPage}
      showFirstButton
      showLastButton
      variant="outlined"
      renderItem={(item) => handlePaginationIcons(item)}
      onChange={(_, value) => handlePageChange(_, value)}
      sx={{
        maxWidth: 'md',
      }}
    />
  );
}
