import { Redirect } from 'react-router-dom';

import { FF_ENABLE_MY_TREATMENT_PLAN } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import Brand from '@/utils/brand';

import ProductCatalog from './productCatalog';
import ProductCatalogRebrand from './ProductCatalogRebrand';

// TODO: RouteProps to be removed once we remove the withFeatureFlags from routes
type RouteProps = {
  [key: string]: any;
};

export default function ProductCatalogContainer(props: RouteProps) {
  const { flags } = useFeatureFlags();
  const isPatient = AuthService.isPatient();
  const enableMedicineInformation = flags[FF_ENABLE_MY_TREATMENT_PLAN] ?? ffDefaults[FF_ENABLE_MY_TREATMENT_PLAN];

  if (isPatient && enableMedicineInformation) {
    return <Redirect to="/medicine-information" />;
  }

  return Brand.isRebrand() ? <ProductCatalogRebrand {...props} /> : <ProductCatalog {...props} />;
}
