import { Link as RouterLink } from 'react-router-dom';
import { Card, Link, List, ListItem, styled, Typography } from '@mui/material';

import {
  getCode,
  getCreatedDate,
  getExpiryDate,
  getInterval,
  getProductName,
  getQuantity,
  Prescription,
} from './common';
import PrescriptionStatusChip from './PrescriptionStatusChip';

const KeyValueListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-start;
  text-align: right;
`;

export type PrescriptionCardProps = {
  prescription: Prescription;
};

export function PrescriptionCard({ prescription }: PrescriptionCardProps) {
  return (
    <Card variant="outlined" sx={{ mb: '1rem', background: (theme) => theme.palette.background.default }}>
      <List>
        <KeyValueListItem>
          <Typography fontWeight="bold">Script Created</Typography>
          <Typography>{getCreatedDate(prescription)}</Typography>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Script ID</Typography>
          <Typography>{getCode(prescription)}</Typography>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Product</Typography>
          <Typography>{getProductName(prescription)}</Typography>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Qty</Typography>
          <Typography>{getQuantity(prescription)}</Typography>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Supplies</Typography>
          <Link component={RouterLink} to="/patient/refill">
            Refer to refill page
          </Link>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Interval</Typography>
          <Typography>{getInterval(prescription)}</Typography>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Script Expiry</Typography>
          <Typography>{getExpiryDate(prescription)}</Typography>
        </KeyValueListItem>
        <KeyValueListItem>
          <Typography fontWeight="bold">Status</Typography>
          <PrescriptionStatusChip prescription={prescription} />
        </KeyValueListItem>
      </List>
    </Card>
  );
}

export default PrescriptionCard;
