import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import '../../components/resources/accordion.scss';

const AccordionFAQ = () => {
  // Used this method to reduce the code and avoid the code duplication
  const accordionData = [
    {
      id: 1,
      question: 'How do I know if my friend is a good candidate for plant medicine?',
      answer:
        'Their eligibility will be assessed by an experienced healthcare professional. If you think they could benefit from speaking to us, simply share your unique link and they’ll go through the alternaleaf consultation process, just like you did.',
      ol: [],
    },
    {
      id: 2,
      question: 'How do I use my referral credit?',
      answer: 'Your referral credit is automatically applied to your next order.',
      ol: [],
    },
    {
      id: 3,
      question: 'How can I see my referrals?',
      answer:
        'You can view your referral credit at any time by heading to Credits in your Circuit profile. We will also email you every time you successfully refer someone to alternaleaf.',
      ol: [],
    },
    {
      id: 4,
      question: 'Does my referral credit expire?',
      answer: 'Your credits are valid for a period of 6 months from the date they are issued.',
      ol: [],
    },
    {
      id: 5,
      question: 'Where can I find my referral link?',
      answer: 'You’ll find the link in Circuit.',
      ol: [],
    },
    {
      id: 6,
      question: 'Is there a limit to how many people I can refer to alternaleaf?',
      answer: 'There’s no limit to how many people you can refer.',
      ol: [],
    },
    {
      id: 7,
      question: 'When do I receive my referral credit?',
      answer: 'Your referral credit will be added to your Montu account when your friend receives a prescription.',
      ol: [],
    },
    {
      id: 8,
      question: 'Can I use my referral credit on my next alternaleaf consultation?',
      answer:
        'At this stage you can use it only on Montu orders. alternaleaf and Montu partnered to give you a reward that helps offset the cost of your medication, and helps your friend access our leading team of doctors and nurses for an affordable price.',
      ol: [],
    },
    {
      id: 9,
      question: 'What happens if my referral credits are more than my order?',
      answer:
        'If your order is less than your credit value, the remaining credits will be applied to your next order/s.',
      ol: [],
    },
    {
      id: 10,
      question: 'Is there a minimum spend per order to use my points?',
      answer:
        'There is no minimum spend. Your credits will be automatically applied to your next order. You can review your referral credits in the Credits section of your Circuit profile.',
      ol: [],
    },
    {
      id: 11,
      question: 'What happens if I haven’t received my referral credit?',
      answer: 'There are a few things to check:',
      ol: [
        'Did your friend use your referral link to book their alternaleaf consultation? We can’t apply discounts or credit retrospectively so make sure they use your link!',
        'Are they a new patient?',
        'Has your friend received a prescription?',
      ],
      secondAnswer: 'If all these things are a ‘yes’, please contact us and we will look into it for you.',
    },
    {
      id: 12,
      question: 'What if my friend does not get a prescription?',
      answer: 'The referral credit will be awarded at the point your friend has been approved for a prescription.',
      ol: [],
    },
    {
      id: 13,
      question: 'What if I use credits on an order that is refunded?',
      answer: 'The credits will be reapplied to your Circuit profile within a few days.',
      ol: [],
    },
  ];

  return (
    <React.Fragment>
      <div className="h-100 mt-4 resources-content">
        <div className="container form-container mt-5 faqbc">
          <Accordion allowZeroExpanded>
            {accordionData.map((faq) => (
              <AccordionItem key={faq.id}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <b style={{ fontFamily: 'Avenir-Roman' }}>{faq.question}</b>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ fontFamily: 'Avenir-Roman' }}>{faq.answer}</p>
                  <ol className="pl-5" style={{ fontFamily: 'Avenir-Roman' }}>
                    {faq.ol.map((list, index) => (
                      <li key={index}>{list}</li>
                    ))}
                  </ol>
                  {faq.secondAnswer && <p style={{ fontFamily: 'Avenir-Roman' }}>{faq.secondAnswer}</p>}
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccordionFAQ;
