import { useQuery } from 'react-query';

import { getPharmacyAllocation, Product } from './api';

export function useQueryPharmacyAllocation(products: Product[]) {
  return useQuery({
    queryKey: ['pharmacy-allocation'],
    queryFn: async () => {
      const { data } = await getPharmacyAllocation(products);
      return data.data;
    },
  });
}

export default useQueryPharmacyAllocation;
