import { NavLink } from 'react-router-dom';
import { MenuItem, styled } from '@mui/material';

import SidebarNavMenuItem from '../sidebar/SidebarNavMenuItem';

import MobileNavMenuItem from './MobileNavMenuItem';
import { INavMenu } from './navbar.config';

export const NavMenuListItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: theme.spacing(3.5),
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  transition: '0.5s',

  '&:hover': {
    backgroundColor: '#A08CA0',
  },
  '&.active': {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const NavMenuLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(2, 1),
  color: theme.palette.common.white,
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 0),
  },
}));

interface INavMenuItem {
  menuItem: INavMenu;
  toggleText?: boolean;
  isSideBar?: boolean;
  onClick?: () => void;
}

function NavMenuItem({ menuItem, onClick, toggleText = true, isSideBar = false }: INavMenuItem) {
  if (isSideBar) {
    return <SidebarNavMenuItem menuItem={menuItem} toggleText={toggleText} onClick={onClick} />;
  }

  return <MobileNavMenuItem menuItem={menuItem} onClick={onClick} />;
}

export default NavMenuItem;
