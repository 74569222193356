import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import FilterableTable from 'rc-data-table';
import Button from '../common/button';
import CircleLoader from '../common/circleLoader';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';
import USER_ROLES from '@/constants/userRoles';

const url = settings.url;
let filterData;
const filter = (props) => {
  filterData = props.records;
};

class ClinicView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async componentDidMount() {
    if (AuthService.getUser().user.role_id === USER_ROLES.doctor) {
      await axios.get(url + '/clinic/gp/view').then((data) => this.setState({ data: data.data, loading: false }));
    }
    if (AuthService.isAdmin()) {
      await axios.get(url + '/clinic').then((data) => this.setState({ data: data.data, loading: false }));
    }
  }

  downloadFile = async () => {
    const { data } = this.state;
    let itemsNotFormatted = filterData ? filterData : data;
    let itemsFormatted = [];

    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        clinic_Id: item.clinic_code,
        first_name: item.name.replace(/,/g, ''),
        email: item.email,
        phone: item.phone,
        city: item.city,
        state: item.state,
      });
    });
    let fileTitle = new Date().getTime() + '-montu-clincs';
    await axios({
      url: 'excel/clinic/download',
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    let { loading, data } = this.state;
    const fields = [
      { name: 'id', displayName: 'id', sortable: true, visible: false, tdClassName: 'sub-text' },
      { name: 'clinic_code', displayName: 'Clinic ID', sortable: true, visible: true, tdClassName: 'sub-text' },
      { name: 'name', displayName: 'Name', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'email',
        displayName: 'Email',
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'phone',
        displayName: 'Phone',
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'city',
        displayName: 'Suburb',
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'state',
        displayName: 'State',
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'id',
        displayName: 'Edit',
        render: (e) => {
          return (
            <Link to={`/editclinic/${e.value}`} className="btn btn-primary btn-md">
              Edit
            </Link>
          );
        },
      },
      { name: 'filter', displayName: 'Filter', render: filter, tdClassName: 'd-none sub-text', thClassName: 'd-none' },
    ];
    return (
      <div>
        {loading ? (
          <CircleLoader />
        ) : (
          <div className="container-fluid">
            <h3 className="float-left">Clinics</h3>
            <Button text="Export All" className="btn btn-md btn-primary float-sm-right" onClick={this.downloadFile} />
            <FilterableTable
              className="table-responsive w-100 d-block d-lg-table p-2"
              data={data}
              fields={fields}
              initialSort={'id'}
              initialSortDir={false}
              serverSort={() => {}}
              topPagerVisible={false}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          </div>
        )}
      </div>
    );
  }
}

export default ClinicView;
