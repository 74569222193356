import { SvgIcon } from '@mui/material';

import { ProductFormulation, ProductFormulations } from '@/types';
import {
  MgCannabis,
  MgCapsule,
  MgCartridge,
  MgDevice,
  MgInhaler,
  MgOralLiquid,
  MgPastille,
  MgResin,
  MgSpray,
  MgTablets,
  MgTopical,
  MgWafer,
} from '@/ui-library/icons';

interface FormulationIconDefn {
  icon: typeof SvgIcon;
  color: string;
  displayName: string;
}

const ProductFormulationsMap: Record<ProductFormulation, FormulationIconDefn> = {
  [ProductFormulations.HERB_DRIED]: {
    icon: MgCannabis,
    color: '#64A570',
    displayName: 'Flower',
  },
  [ProductFormulations.INHALATION]: {
    icon: MgCartridge,
    color: '#00465B',
    displayName: 'Inhalation',
  },
  [ProductFormulations.CAPSULE]: {
    icon: MgCapsule,
    color: '#1DBA81',
    displayName: 'Capsule',
  },
  [ProductFormulations.ORAL_LIQUID]: {
    icon: MgOralLiquid,
    color: '#f89a1b',
    displayName: 'Oral liquid',
  },
  [ProductFormulations.PASTILLE]: {
    icon: MgPastille,
    color: '#30e6d3',
    displayName: 'Pastille',
  },
  [ProductFormulations.TABLET_CHEWABLE]: {
    icon: MgTablets,
    color: '#ba2cbf',
    displayName: 'Chewable tablet',
  },
  [ProductFormulations.DEVICE]: {
    icon: MgDevice,
    color: '#406AC9',
    displayName: 'Device',
  },
  [ProductFormulations.SPRAY]: {
    icon: MgSpray,
    color: '#406ac9', // todo real colour
    displayName: 'Spray',
  },
  [ProductFormulations.TOPICAL]: {
    icon: MgTopical,
    color: '#406ac9', // todo real colour
    displayName: 'Topical',
  },
  [ProductFormulations.WAFER]: {
    icon: MgWafer,
    color: '#406ac9', // todo real colour
    displayName: 'Wafer',
  },
  [ProductFormulations.CONCENTRATED_EXTRACT]: {
    icon: MgResin,
    color: '#5C361A',
    displayName: 'Concentrated Extract',
  },
  [ProductFormulations.INHALANT_PRESSURIZED]: {
    icon: MgInhaler,
    color: '#0697D6',
    displayName: 'Inhalant (pressure)',
  },
} as const;

export default ProductFormulationsMap;
