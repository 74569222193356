import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Dialog, DialogContent, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import Button from '@/components/common/IconButton';
import { BrandedForm } from '@/components/forms/BrandedForms';
import settings from '@/constants/constants';
import { PatientConsentFormData } from '@/services/patientConsent.service';

import AlternaleafLogo from '../../layout/alternaleaf.svg';
import FormInput from '../FormInputTyped';

import ControlledSignaturePad from './ControlledSignaturePad';
import { UpperLeftDecoration } from './decoration';

const DecoratedContainer = styled(Box)`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ScrollContainer = styled(Box)`
  border-radius: 1rem;
  background-color: #f7f5f7;
  padding: 1rem;
  max-height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem;
`;

const FormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    gap: '1rem',
  },

  '*': {
    flex: 1,
  },
}));

const ErrorDisplay = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width: '75vw',
    height: '75vh',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  '&:not(:disabled)': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  textTransform: 'capitalize',
  fontSize: '1rem',
  lineHeight: 1.25,
  borderColor: 'transparent',
  borderWidth: 0,
  borderRadius: '6px',
  padding: '0.75rem 2rem',
}));

const RoundedDialog = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 2rem;
    margin: 0;
    padding: 0;
  }
`;

interface PatientConsentDialogProps {
  isOpen: boolean;
  onSubmit: (data: PatientConsentFormData) => void;
  requireReload?: boolean;
}

export function PatientConsentDialog(props: PatientConsentDialogProps) {
  const { isOpen, onSubmit, requireReload } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [shouldDisplayError, setShouldDisplayError] = useState<boolean>(false);
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));

  const { register, handleSubmit, formState, control } = useForm<PatientConsentFormData>();
  const { errors, isValid } = formState;

  const submitHandler: SubmitHandler<PatientConsentFormData> = async (data) => {
    try {
      setIsSubmitting(true);
      await onSubmit(data);
      // Dialog will automatically close after a successful submission.
    } catch {
      setShouldDisplayError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const hasError = requireReload || shouldDisplayError;

  return (
    <RoundedDialog open={isOpen} maxWidth="lg" fullScreen={!isLargeView} fullWidth={!isLargeView} scroll="paper">
      <UpperLeftDecoration />
      {!hasError && (
        <DialogContent>
          <DecoratedContainer>
            <div style={{ marginBottom: '1rem' }}>
              <img src={AlternaleafLogo} alt="Alternaleaf" style={{ height: '1rem' }} />
            </div>
            <TitleRow>
              <Typography component="h2" variant="h4" color="primary">
                Treatment consent form
              </Typography>
              <Typography variant="body2" color="secondary">
                All fields are mandatory *
              </Typography>
            </TitleRow>
            <div className="mb-4">
              <Typography variant="body2">
                Our records show you have not completed the required medical consent form, kindly complete the below
                form to continue using our services.
              </Typography>
            </div>
            <div>
              <ScrollContainer>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  The purpose of this form is to obtain/confirm your informed consent for treatment if your doctor
                  prescribes medical cannabis treatment for your condition.
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  Informed consent is a person's decision, given voluntarily, to agree to a healthcare treatment,
                  procedure, or other intervention that is made:
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  Following the provision of accurate and relevant information about the healthcare intervention and
                  alternate options available; and
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  With adequate knowledge and understanding of the benefits and material risks of the proposed
                  intervention relevant to the person who would be having the treatment, procedure or other
                  intervention. (Reference: Australian Commission on Safety and Quality in Health Care.)
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  Please review the following statements regarding your treatment and sign below to confirm that our
                  clinicians have provided you information about these matters, given you the opportunity to ask
                  questions and discuss concerns.
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Medication interaction
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I understand that medicinal cannabis might interact with my other medications, and doses may need to
                  be adjusted accordingly. I agree to notify my prescribing doctor of any changes in my other
                  medications, and that I will not use any form of cannabis other than that prescribed, including any
                  illicit form of cannabis (marijuana).
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Side-effects
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I understand the risks associated with medicinal cannabis treatment and that the potential long-term
                  side-effects are unknown. I agree to report any adverse effects I experience from taking medicinal
                  cannabis, including but not limited to changes in the levels of sedation, fatigue, dry mouth, nausea,
                  diarrhoea, and drowsiness.
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Unregistered Medicine
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I understand that medicinal cannabis is an unregistered medicine in Australia and that its quality,
                  safety, and efficacy have not been assessed by the Australian government's Therapeutic Goods
                  Administration. I agree that the prescribing doctor will report my treatment outcomes to the
                  government.
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Cost
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I understand that the cost of medicinal cannabis is solely my responsibility.
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Driving
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I understand that I must not drive or operate heavy machinery whilst taking medicinal cannabis
                  containing THC. If I drive under these circumstances, I am breaking the law and a legally issued
                  prescription does not provide a defence to such an offence.
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Monitoring
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I agree to follow my doctor's recommendation regarding dosing and to attend regular follow-up
                  consultations as directed by my doctor. I agree to keep a log of my doses and changes in symptoms due
                  to medicinal cannabis, and understand that my clinical outcomes may be shared for research purposes.
                </Typography>
                <Typography component="p" variant="h6" lineHeight={1.5} mt={2}>
                  Pharmacy Dispensing
                </Typography>
                <Typography component="p" lineHeight={1.5} mt={2}>
                  I consent to: (a) Montu forwarding my prescription and relevant personal information to a pharmacy to
                  dispence; and (b) the pharmacy dispensing my script. I understand that I'm able to request a copy of
                  my script to have dispensed at the pharmacy of my choice.
                </Typography>
              </ScrollContainer>
            </div>
            <BrandedForm action="" onSubmit={handleSubmit(submitHandler)}>
              <FormRow>
                <FormInput
                  {...register('firstName', {
                    required: 'Please enter your first name',
                  })}
                  label="First name *"
                  error={errors?.firstName?.message}
                  errorClass="error--red"
                  disabled={isSubmitting}
                />
                <FormInput
                  {...register('lastName', {
                    required: 'Please enter your last name',
                  })}
                  label="Last name *"
                  error={errors?.lastName?.message}
                  errorClass="error--red"
                  disabled={isSubmitting}
                />
              </FormRow>
              <FormRow>
                <ControlledSignaturePad fieldName="signature" label="Signature *" control={control} />
              </FormRow>
              <FormRow>
                <div>
                  <SubmitButton type="submit" text="Submit" loading={isSubmitting} disabled={!isValid} />
                </div>
              </FormRow>
            </BrandedForm>
          </DecoratedContainer>
        </DialogContent>
      )}
      {hasError && (
        <DialogContent>
          <DecoratedContainer>
            <div style={{ marginBottom: '1rem' }}>
              <img src={AlternaleafLogo} alt="Alternaleaf" style={{ height: '1rem' }} />
            </div>
            <TitleRow>
              <Typography variant="h5" component="h2">
                Treatment consent form
              </Typography>
            </TitleRow>
            <ErrorDisplay>
              <Typography variant="body1">
                An error occurred. Please reload the page and try again. <br />
                If you're still experiencing issues please call {settings.support.phone.display} for support.
              </Typography>
            </ErrorDisplay>
          </DecoratedContainer>
        </DialogContent>
      )}
    </RoundedDialog>
  );
}

export default PatientConsentDialog;
