import axios from 'axios';

import settings from '@/constants/constants';
import { ProductFormulation, ProductStrain } from '@/types';

// todo: use ts-rest contract once published

export type ProductDto = {
  id: number;
  name: string;
  shortName: string | null;
  active: boolean;
  thcStrength: string | null;
  thcStrengthForDisplay: string | null;
  thcPercentage: number | null;
  cbdThcRatio: string | null;
  cbdStrength: string | null;
  cbdStrengthForDisplay: string | null;
  formulation: {
    id: number;
    name: ProductFormulation;
    whiteLabelImageUrl: string | null;
  } | null;
  strain: {
    id: number;
    name: ProductStrain;
  } | null;
  isConcession: boolean | null;
};

export type PrescriptionProductDto = {
  id: number;
  productId: number;
  product: ProductDto | null;
};

export type PrescriptionDto = {
  id: number;
  prescriptionCode: string;
  prescriptionDate: string;
  active: boolean;
  expired: boolean;
  isAlternatePrescription: boolean | null;
  expiryDate: string | null;
  prescriptionProducts: PrescriptionProductDto[];
};

export const getPrescriptionsWithPrescribedProducts = async () =>
  axios.get<PrescriptionDto[]>(`${settings.url}/prescription/prescribed-products`);
