import { useRef } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import { Box, styled, useTheme } from '@mui/material';

const SignatureFormControl = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 7rem;
  overflow: hidden;
  ${theme.breakpoints.up('md')} {
    height: 10rem;
  }
  margin-bottom: 1rem;
  canvas {
    height: 100%;
    width: 100%;
    background-color: white;
  }
`,
);
const SignatureFormGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const TextButton = styled('button')(
  ({ theme }) => `
    background: transparent;
    border: none;
    outline: none;
    color: ${theme.palette.secondary.main};
    text-decoration: underline;
  `,
);

interface SignatureCanvasProps<T extends FieldValues> {
  fieldName: FieldPath<T>;
  label?: string;
  control: Control<T>;
}

export function ControlledSignaturePad<T extends FieldValues>(props: SignatureCanvasProps<T>) {
  const { fieldName, control, label } = props;
  const theme = useTheme();
  const signatureRef = useRef<SignatureCanvas | null>(null);

  const formatSignature = () => {
    if (signatureRef.current) {
      return signatureRef.current.getTrimmedCanvas().toDataURL('image.png');
    }
    return null;
  };

  return (
    <div>
      <Controller<T>
        name={fieldName}
        control={control}
        rules={{ required: 'Please sign here' }}
        render={({ field, fieldState }) => {
          const clearSignature = () => {
            if (signatureRef.current) {
              signatureRef.current.clear();
              field.onChange(null);
            }
          };

          const hasError = Boolean(fieldState.error?.message);

          return (
            <SignatureFormGroup>
              {label && <span className="label">{label}</span>}
              <SignatureFormControl
                className="form-control"
                data-hasError={hasError}
                sx={hasError ? { borderColor: `${theme.palette?.error.main}!important` } : {}}
              >
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="green"
                  backgroundColor="#fff"
                  canvasProps={{ style: { width: '100%', height: '6rem' } }}
                  onBegin={() => field.onChange(formatSignature())}
                  onEnd={() => field.onChange(formatSignature())}
                />
              </SignatureFormControl>
              {fieldState.error?.message ? (
                <div className="error--red" style={{ marginBottom: '1rem', marginTop: '-1rem' }}>
                  {fieldState.error.message}
                </div>
              ) : null}
              <TextButton
                onClick={clearSignature}
                sx={{ fontSize: '0.75rem', marginTop: '-0.5rem', marginLeft: '1rem' }}
              >
                Clear Signature
              </TextButton>
            </SignatureFormGroup>
          );
        }}
      />
    </div>
  );
}

export default ControlledSignaturePad;
