import { KeyboardEvent, useState } from 'react';
import { Help as HelpIcon } from '@mui/icons-material';
import { Box, Link, styled, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { red } from '@mui/material/colors';
import moment from 'moment';

import settings from '@/constants/constants';
import { FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { mq } from '@/theme';
import { Logger } from '@/utils/logger';

import { ProductCardProduct } from '../ProductCard';

const logger = new Logger('ProductCardIssue');

export const DISCONTINUED_TITLE = 'Discontinued';
export const OUT_OF_STOCK_TITLE = 'Out of stock';

const MQLarge = mq('lg');

const Container = styled(Box)`
  background-color: ${red[50]};
  color: ${red[600]};
  font-weight: 600;
  padding: 0.5rem 0.6rem;
  border: 1px solid ${red[600]};
  border-radius: 6px;
  text-align: center;

  ${MQLarge} {
    width: 100%;
    min-width: 6.5rem; /* equals to the quantity component width */
  }
`;

const IssueLabelContainer = styled(Container)`
  width: 100%;

  ${MQLarge} {
    width: fit-content;
  }
`;

const ButtonCardContainer = styled(Container)`
  ${({ theme }) => `
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
    max-width: 8rem;
    cursor: pointer;
    transition: ${theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.standard,
    })};

    &:hover {
      background-color: ${red[100]};
      color: ${red[800]};
    }
  `}
`;

const Title = styled(Box)``;

export type GetProductCardIssueArgs = Pick<
  ProductCardProduct,
  'isOutOfStock' | 'remainingUnits' | 'isIntervalLocked' | 'intervalEndDate' | 'isExpired' | 'isGenerativeScripting'
>;

const linkText = 'click here';

export const productCardIssueText = {
  supportPhone: settings.support.phone.display,

  prescriptionExpired: {
    title: 'Prescription expired',
    info: `Book a follow up with your doctor ${linkText}`,
    linkTo: '/patient/consultations',
  },

  outOfRepeats: {
    title: 'Out of repeats',
    info: `Book a follow up with your doctor ${linkText}`,
    linkTo: '/patient/consultations',
  },

  outOfStock: {
    title: OUT_OF_STOCK_TITLE,
    getDefaultInfo: (phoneNumber: string) => `Please call ${phoneNumber} for alternative options`,
    getInfoWithDate: (returnDate: string, phoneNumber: string) =>
      `Expected return ${returnDate}. You may also call ${phoneNumber} for alternative options.`,
  },

  intervalPeriod: {
    title: 'Interval period',
    defaultInfo: 'You have ordered the maximum prescribed quantity of this product for your interval period.',
    getInfoWithDate: (intervalEndDate: string) =>
      `You have ordered the maximum prescribed quantity of this product for your interval period. You can reorder again from: ${intervalEndDate}`,
  },

  discontinued: {
    title: DISCONTINUED_TITLE,
  },
};

interface ProductCardIssueProps {
  title: string;
  info?: string;
  linkTo?: string;
}

function InfoWithLink({ text, linkTo }: { text: string; linkTo: string }) {
  const [preLinkText, postLinkText] = text.split(linkText);

  return (
    <>
      {preLinkText}
      <Link
        href="/patient/consultations"
        onClick={() => {
          window.location.href = linkTo;
        }}
        underline="hover"
        color="primary"
      >
        {` ${linkText} `}
      </Link>
      {postLinkText}
    </>
  );
}

export function getProductCardIssue(args: GetProductCardIssueArgs): ProductCardIssueProps | null {
  const { isOutOfStock, remainingUnits, isIntervalLocked, intervalEndDate, isExpired, isGenerativeScripting } = args;
  const hasNoRemainingUnits = remainingUnits <= 0;

  if (isExpired) {
    return {
      title: productCardIssueText.prescriptionExpired.title,
      info: productCardIssueText.prescriptionExpired.info,
      linkTo: productCardIssueText.prescriptionExpired.linkTo,
    };
  }

  if (hasNoRemainingUnits) {
    return {
      title: productCardIssueText.outOfRepeats.title,
      info: productCardIssueText.outOfRepeats.info,
      linkTo: productCardIssueText.outOfRepeats.linkTo,
    };
  }

  if (isOutOfStock) {
    const defaultInfoText = productCardIssueText.outOfStock.getDefaultInfo(productCardIssueText.supportPhone);
    return {
      title: productCardIssueText.outOfStock.title,
      info: defaultInfoText,
    };
  }

  if (isIntervalLocked) {
    const validDate = moment(intervalEndDate).isValid();
    if (!validDate || !intervalEndDate) {
      logger.warn('Invalid end date or no intervalEndDate', validDate, intervalEndDate);
    }

    const defaultInfoText = productCardIssueText.intervalPeriod.defaultInfo;
    const infoTextWithDate = productCardIssueText.intervalPeriod.getInfoWithDate(
      validDate && intervalEndDate ? moment(intervalEndDate).format('DD-MMM-YYYY') : '',
    );

    return {
      title: productCardIssueText.intervalPeriod.title,
      info: validDate && intervalEndDate ? infoTextWithDate : defaultInfoText,
    };
  }

  if (isGenerativeScripting) {
    return {
      title: productCardIssueText.discontinued.title,
    };
  }

  return null;
}

export default function ProductCardIssue(props: ProductCardIssueProps) {
  const { flags } = useFeatureFlags();
  const ffEnableProductIssueEnquiries = flags[FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES];

  const { title, info, linkTo } = props;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleContainerClick = () => {
    setOpen((prev) => !prev);
  };

  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
  // https://www.w3.org/WAI/content-assets/wai-aria-practices/patterns/button/examples/js/button.js
  const handleContainerKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      setOpen((prev) => !prev);
    }
  };
  const handleContainerKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      e.preventDefault();
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box {...(ffEnableProductIssueEnquiries && { width: '100%', display: 'flex', justifyContent: 'end' })}>
      {ffEnableProductIssueEnquiries && [OUT_OF_STOCK_TITLE, DISCONTINUED_TITLE].includes(title) ? (
        <IssueLabelContainer>
          <Title>{title}</Title>
        </IssueLabelContainer>
      ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title={linkTo ? <InfoWithLink text={info || ''} linkTo={linkTo} /> : info}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <ButtonCardContainer
              onClick={handleContainerClick}
              onKeyDown={handleContainerKeyDown}
              onKeyUp={handleContainerKeyUp}
              role="button"
              tabIndex={0}
            >
              <Title>{title}</Title>
              <HelpIcon />
            </ButtonCardContainer>
          </Tooltip>
        </ClickAwayListener>
      )}
    </Box>
  );
}
