import { createSvgIcon } from '@mui/material';

/**
 * An icon for oral liquid
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2677-48928&m=dev
 *
 * @returns An SvgIcon
 */

export const MgOralLiquid = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 22C-0.0731812 22 6.05803 9.90787 11.2324 2.99694C11.6188 2.48088 12.3812 2.48088 12.7676 2.99694C17.942 9.90786 24.0732 22 12 22Z"
      fill="currentColor"
    />
  </svg>,
  'MgOralLiquid',
);

export default MgOralLiquid;
