import { useQueryClient } from 'react-query';

import { PatientRescriptRequestPayload, PatientService } from '@/services/patient.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useProductEnquiryStatus');
const ERROR_ON_CREATE_PATIENT_RESCRIPT_REQUEST = 'Failed to create patient rescript request';

export default function useCreatePatientRescriptRequest() {
  const queryClient = useQueryClient();

  return (patientId: number, data: PatientRescriptRequestPayload) =>
    queryClient.fetchQuery(['patientRescriptRequest', patientId], async () => {
      try {
        return await PatientService.createPatientRescriptRequest(patientId, data);
      } catch (error) {
        logger.error(ERROR_ON_CREATE_PATIENT_RESCRIPT_REQUEST);
        throw new Error(ERROR_ON_CREATE_PATIENT_RESCRIPT_REQUEST);
      }
    });
}
