import React from 'react';

import TopBanner from '@/components/resources/top-banner';

function ComingSoon() {
  return (
    <div className="resources-content">
      <TopBanner pText="" h1Text="More coming soon" />
    </div>
  );
}

export default ComingSoon;
