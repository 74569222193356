import React from 'react';
import '../../components/resources/accordion.scss';

const AccordionTermsAndConditions = () => {
  return (
    <React.Fragment>
      <div className="h-100 resources-content">
        <div className="container form-container mt-5" style={{ fontFamily: 'Avenir-Roman' }}>
          <p>'alternaleaf' refers to Alternaleaf Pty Ltd.</p>
          <p>'Montu' refers to Montu Group Pty Ltd.</p>
          <p>'Referrer' refers to a current patient of alternaleaf and Montu.</p>
          <p>
            'Referee' refers to a person booking their initial consultation with alternaleaf using a valid referral
            link.
          </p>
          <ol className="pl-5">
            <li>
              Anyone accepting and sharing the referral link or by signing up with a referral link, is agreeing to these
              terms and conditions.
            </li>
            <li>
              Referrers may be eligible for a referral payment for each new Referee who uses their unique referral link
              to book their initial alternaleaf consultation.
            </li>
            <li>Referrers must be an alternaleaf and Montu patient.</li>
            <li>
              Referee’s must not be an existing or past alternaleaf patient and must be eligible for medical cannabis
              treatment, as determined by an alternaleaf healthcare professional.
            </li>
            <li>Referral links are issued by alternaleaf and Montu only.</li>
            <li>Referrers cannot use their own referral link or create duplicate/multiple accounts.</li>
            <li>
              Eligible Referees will receive the discounted amount off their initial consultation, as advertised at the
              time of booking. This amount is subject to change at alternaleaf or Montu’s discretion.
            </li>
            <li>
              Referees can only be referred once. The referral credits will be applied to the Referrer who is assigned
              to the link used to make the initial booking.
            </li>
            <li>
              Referrers will receive the credit amount that is advertised at the time the booking was made by the
              Referee.
            </li>
            <li>
              Referee's must use the referral link when booking a consultation. Discounts and credits cannot be applied
              retrospectively.{' '}
            </li>
            <li>
              The referral discount will be applied to the consultation invoice. If it hasn’t been applied, please
              contact us.
            </li>
            <li>Referral links must be used for non-commercial purposes. Referrers agree not to:</li>
            <ol type="a" className="pl-5">
              <li>
                Make misleading claims about medical cannabis, alternaleaf or Montu, use offensive content, create fake
                websites/social media profiles, or misrepresent their connection to alternaleaf and Montu;
              </li>
              <li>
                Duplicate, sell, or transfer referral links in any manner or make it available to the general public;
                and
              </li>
              <li>
                Invite referrals by sending bulk unsolicited emails or using automated systems to distribute the
                referral link.
              </li>
            </ol>
            <li>
              If the Referee reschedules their consultation, the referral link must be used to rebook in order for the
              referral discount and credit to be applied. The consultation cannot be booked through our Patient Care
              team.
            </li>
            <li>
              Referral credits are listed in the Credits section in the Referrer’s Circuit profile, at the time payment
              is received for the Referee’s Montu order.{' '}
            </li>
            <li>Referral credits are valid for a period of 6 months from the date they are issued.</li>
            <li>There are no limitations on the number of referrals a Referer can make.</li>
            <li>Referee names and details cannot be shared for privacy reasons.</li>
            <li>
              Use of alternaleaf or Montu’s logo, branding, images, photos, screenshots from company websites or
              portals, or any other intellectual property is not permitted in relation to the referral program or
              otherwise, unless written permission has been obtained from alternaleaf and/or Montu.
            </li>
            <li>
              alternaleaf and Montu reserve the right to deny and retract any referral discounts or credits at any time
              for any reason, including if someone does not meet these terms, is believed to be abusing the program, or
              does not follow our Community Guidelines (available on the alternaleaf and Montu websites).
            </li>
            <li>
              alternaleaf and Montu reserve the right to deactivate the account of anyone who violates these terms and
              conditions.
            </li>
            <li>
              alternaleaf and Montu reserve the right to change the terms and conditions of the Referral Rewards at any
              time without notice.
            </li>
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccordionTermsAndConditions;
