import TagManager from 'react-gtm-module';

import { Logger } from '@/utils/logger';

const logger = new Logger('googleTagManager');

function init({
  googleTagManagerId,
  googleTagManagerAuth,
}: {
  googleTagManagerId: string;
  googleTagManagerAuth: string;
}) {
  if (googleTagManagerId && googleTagManagerAuth) {
    logger.debug('Initializing', { googleTagManagerId, googleTagManagerAuth });
    TagManager.initialize({
      gtmId: googleTagManagerId,
      auth: googleTagManagerAuth,
    });
  }
}

export default init;
