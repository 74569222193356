import React from 'react';
import { AuthService } from '../../services/authentication.service';
import USER_ROLES from '@/constants/userRoles';
import Brand from '@/utils/brand';
import { Box, Link, styled } from '@mui/material';
import sagedLogo from './saged.png';

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: '#194457',
  color: '#fff',
  marginTop: 'auto',
  padding: '1rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '0.5rem',
    '& *': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const ContentContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const LinkToSaged = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
`;

const SagedLogoContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[700],
  width: 'fit-content',
  padding: '8px 12px',
  marginLeft: '0.375rem',
  borderRadius: '8px',
  gap: '2px',
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  justifyContent: 'center',
  height: '40px',
  alignItems: 'center',
  fontSize: '.875rem',
}));

const footer = () => {
  const roleId = AuthService.getUser()?.user.role_id;
  const brandName = Brand.isRebrand() ? 'Alternaleaf' : 'Montu Group';
  const shouldRenderSagedLink = roleId === USER_ROLES.doctor || roleId === USER_ROLES.pharmacist;
  return (
    <FooterContainer>
      <ContentContainer>
        © {new Date().getFullYear()} {brandName}. All rights reserved.
      </ContentContainer>
      {shouldRenderSagedLink && (
        <ContentContainer>
          <LinkToSaged href="https://saged.com.au/" target="_blank" rel="noopener" underline="none">
            <span>Medical Cannabis Courses for {roleId === USER_ROLES.pharmacist ? 'Pharmacists' : 'Doctors'} by</span>
            <SagedLogoContainer>
              <img src={sagedLogo} alt="Saged" />
            </SagedLogoContainer>
          </LinkToSaged>
        </ContentContainer>
      )}
    </FooterContainer>
  );
};

export default footer;
