import { Link, useHistory } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, IconButton, styled } from '@mui/material';

import AltLogo from '../common/AltLogo';
import UmedsLogo from '../common/UmedsLogo';

const AlternaleafContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 32),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));
const UmedsContainer = styled(Box)(({ theme }) => ({
  background: '#042B37',
  padding: theme.spacing(5, 32),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
  },
}));

function UmedsHeader() {
  const history = useHistory();
  return (
    <>
      <AlternaleafContainer>
        <IconButton
          disableRipple
          size="small"
          sx={{
            padding: 0,
            '&.MuiButtonBase-root:focus': {
              outline: 'none',
            },
          }}
          onClick={() => history.push({ pathname: '/home' })}
        >
          <ChevronLeft color="primary" fontSize="large" sx={{ marginRight: 2 }} />
          <AltLogo fillColor="#300431" width="150" />
        </IconButton>
      </AlternaleafContainer>
      <UmedsContainer>
        <UmedsLogo />
        {history.location.pathname !== '/patient/orders' ? (
          <Button variant="outlined" color="secondary" component={Link} to="/patient/orders">
            Order history
          </Button>
        ) : null}
      </UmedsContainer>
    </>
  );
}

export default UmedsHeader;
