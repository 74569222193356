import { Link as RouterLink } from 'react-router-dom';
import {
  Alert,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
} from '@mui/material';

import RoundedPaper from '@/components/common/RoundedPaper';

import {
  getCode,
  getCreatedDate,
  getExpiryDate,
  getInterval,
  getProductName,
  getQuantity,
  getUniqueKey,
  Prescription,
} from './common';
import PrescriptionStatusChip from './PrescriptionStatusChip';

export type PrescriptionsTableProps = {
  prescriptions: Prescription[];
  tablePaginationProps?: TablePaginationProps;
};

export function PrescriptionsTable({ prescriptions, tablePaginationProps }: PrescriptionsTableProps) {
  const hasAnyData = prescriptions.length > 0;

  return (
    <TableContainer component={RoundedPaper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Script Created</TableCell>
            <TableCell>Script ID</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Supplies</TableCell>
            <TableCell>Interval Duration</TableCell>
            <TableCell>Script Expiry</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasAnyData && (
            <TableRow>
              <TableCell colSpan={8}>
                <Alert severity="info">No prescriptions found</Alert>
              </TableCell>
            </TableRow>
          )}
          {prescriptions.map((prescription) => (
            <TableRow key={getUniqueKey(prescription)}>
              <TableCell>{getCreatedDate(prescription)}</TableCell>
              <TableCell>{getCode(prescription)}</TableCell>
              <TableCell>{getProductName(prescription)}</TableCell>
              <TableCell>{getQuantity(prescription)}</TableCell>
              <TableCell>
                <Link component={RouterLink} to="/patient/refill">
                  Refer to refill page
                </Link>
              </TableCell>
              <TableCell>{getInterval(prescription)}</TableCell>
              <TableCell>{getExpiryDate(prescription)}</TableCell>
              <TableCell>
                <PrescriptionStatusChip prescription={prescription} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {tablePaginationProps && (
          <TableFooter>
            <TableRow>
              <TablePagination {...tablePaginationProps} />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export default PrescriptionsTable;
