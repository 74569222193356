import { ReactNode } from 'react';
import { Box, Divider, styled, Typography } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import MontuLogo from '@/components/common/MontuLogo';
import useGetUser from '@/hooks/rest/useGetUser';
import theme, { alternaleafMQ } from '@/theme';

const MQMedium = alternaleafMQ('md');
const MQLarge = alternaleafMQ('lg');

const MainContainer = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${theme.colorSchemes.light.palette.common.white};
  padding: 2rem 5%;

  ${MQMedium} {
    padding: 2rem 10%;
  }
`;

const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #1a4457;
  gap: 1rem;
`;

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const TextHeader = styled(Typography)`
  font-size: 1.3rem;
  line-height: 1;
  font-weight: bold;

  ${MQMedium} {
    font-size: 1.5rem;
  }

  ${MQLarge} {
    font-size: 2.125rem;
    line-height: 1.235;
  }
`;

const TextBranding = styled(Typography)`
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1;

  > svg {
    margin-bottom: 0.35rem; /* aligning with the text from the design */
  }

  ${MQLarge} {
    font-size: 1.2rem;

    > svg {
      margin-bottom: 0.15rem; /* aligning with the text from the design */
    }
  }
`;

const MPatNumber = styled(Typography)`
  font-weight: bold;
  margin-top: 0.25rem;

  font-size: 1rem;
  line-height: 1;

  ${MQLarge} {
    font-size: 1.5rem;
  }
`;

const WrapperDivider = styled(Divider)`
  border: 0.5px solid ${theme.colorSchemes.light.palette.primary.main};
  margin: 1.6rem 0 0;

  ${MQLarge} {
    margin-bottom: 1.6rem;
  }
`;

interface Props {
  header: string;
  children: ReactNode;
}

function MontuBrandWrapper({ header, children }: Props) {
  const userDetails = useGetUser();

  return (
    <MainContainer className="montu-wrapper">
      <Header>
        <TextContainer data-dd-privacy="allow">
          <TextHeader variant="h4">{header}</TextHeader>

          <TextBranding variant="body1">
            powered by&nbsp;&nbsp;
            <MontuLogo fillColor="#1a4457" />
          </TextBranding>
        </TextContainer>

        <MPatNumber data-dd-privacy="allow" variant="h5">
          {userDetails.mPatNumber}
        </MPatNumber>
      </Header>

      <WrapperDivider />

      <CssVarsProvider theme={theme}>{children}</CssVarsProvider>
    </MainContainer>
  );
}

export default MontuBrandWrapper;
