import { Box, Button, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

export type Product = {
  id: string | number;
  name: string;
  price: number;
  quantity: number;
};

export type OrderData = {
  products: Product[];
  totalPrice: number;
  dispensingFee?: number;
};

export type OrderBreakdownProps = {
  orderData: OrderData;
  cancelled?: boolean;
  invoiceLink?: string;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const OrderBreakdownContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#EBEEEF', // todo: confirm the semantic color for this hexcode
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
  },
}));

const OrderInvoiceButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  width: '100%',
}));

const ProductsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const FeesContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const ProductItemContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const TotalContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const RefundContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const ReceiptButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    marginBottom: 0,
  },
}));

export function OrderBreakdown({
  orderData: { products, totalPrice, dispensingFee },
  cancelled,
  invoiceLink,
}: OrderBreakdownProps) {
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <OrderBreakdownContainer>
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        lineHeight={isLargeView ? 1.3 : 1.6}
        sx={{ marginBottom: isLargeView ? 3 : 2 }}
      >
        Items
      </Typography>
      <ProductsContainer>
        {products.length > 0
          ? products.map(({ id, name, quantity, price }) => (
              <ProductItemContainer key={id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <StyledTypography
                    variant="body2"
                    sx={{
                      maxWidth: {
                        xs: '200px',
                        sm: 'none',
                      },
                    }}
                  >
                    {name}
                  </StyledTypography>
                  <StyledTypography variant="body2" fontWeight="bold">
                    ${price.toFixed(2)}
                  </StyledTypography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <StyledTypography variant="body2">Qty: {quantity}</StyledTypography>
                </Box>
              </ProductItemContainer>
            ))
          : null}
      </ProductsContainer>
      <Divider />
      {dispensingFee ? (
        <FeesContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledTypography variant="body2">Shipping fee</StyledTypography>
            <StyledTypography variant="body2" fontWeight="bold">
              ${dispensingFee.toFixed(2)}
            </StyledTypography>
          </Box>
          <Box>
            <Typography variant="body2">(inclusive of GST)</Typography>
          </Box>
        </FeesContainer>
      ) : null}

      <Divider />
      {cancelled ? (
        <>
          <RefundContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2">Refund</Typography>
              <Typography variant="body2" fontWeight="bold">
                -${totalPrice.toFixed(2)}
              </Typography>
            </Box>
          </RefundContainer>
          <Divider />
        </>
      ) : null}
      <TotalContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1">Total</Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {cancelled ? 0 : `$${totalPrice.toFixed(2)}`}
          </Typography>
        </Box>
      </TotalContainer>
      <OrderInvoiceButtonContainer>
        <ReceiptButton href={invoiceLink} fullWidth variant="outlined">
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              padding: theme.spacing(2),
              color: theme.palette.primary.main,
              borderColor: 'var(--mui-palette-primary-_states-disabled)',
            }}
          >
            View receipt
          </Typography>
        </ReceiptButton>
      </OrderInvoiceButtonContainer>
    </OrderBreakdownContainer>
  );
}

export default OrderBreakdown;
