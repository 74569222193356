import { Box, styled } from '@mui/material';

export const FormCheckboxContainer = styled(Box)`
  position: relative;
  display: block;
  padding-left: 1.25rem;
`;

export const FormCheckbox = styled('input')`
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;

  // Use [disabled] and :disabled for workaround https://github.com/twbs/bootstrap/issues/28247
  &[disabled] ~ label,
  &:disabled ~ label {
    color: #6c757d; //TODO: Tie this to theme color
  }
`;

export const FormCheckboxLabel = styled('label')`
  margin-bottom: 0;
  font-weight: 500;
`;
