// --- Braze Content Card ---
// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum BRAZE_CONTENT_CARD {
  PRODUCT_CATALOG = 'product-catalog',
  PATIENT_HOME = 'patient-home',
  PATIENT_REFILL = 'patient-refill',
  PATIENT_CONSULTATIONS = 'patient-consultations',
  PATIENT_PRESCRIPTION = 'patient-prescription',
  PATIENT_ORDERS = 'patient-orders',
  PATIENT_CREDIT = 'patient-credit',
}
