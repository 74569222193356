import React from 'react';
import settings from '../../constants/constants';
import ProductItem from './product-item';
import TopBanner from './top-banner';
import useShippingPrice from '@/hooks/useShippingPrice';

function Products() {
  const shippingParts = useShippingPrice();

  return (
    <React.Fragment>
      <div className="h-100 resources-content">
        <TopBanner h1Text="Circle Product Information" />
        <section className="py-4">
          <div className="container" style={{ maxWidth: '1000px' }}>
            <p className="m-0 mt-5 text-center font-light" style={{ fontSize: '20px' }}>
              CBD-dominant Products
            </p>
            <hr className="m-0 bg-dark" />
            <div className="my-4 d-flex flex-wrap justify-content-between">
              <ProductItem
                imgUrl="https://circuit.montu.com.au/assets/images/products/Cannabis+Oil+Mockups_Pure.webp"
                title="Circle | CBD PURE 100"
                dose={{ l1: 'CBD 100 mg/mL', l2: 'THC 0 mg/mL' }}
                quantityDesc={{ l1: 'Cannabis Oil', l2: '10 mL bottle' }}
                tgaName="Circle CBD PURE 100 Oil Drops 10mL"
                titration="Initial Dose: 0.2 ml/day | Maximal Dose: 3.0 ml/day"
                treatmentArea="Chronic Pain, Anxiety, Epilepsy, PTSD"
                price={{ cost: 99, perMg: '(10 cents per mg)' }}
                cmiSheet="https://www.montu.com.au/cmi-cbd-pure-100"
              />
              <ProductItem
                imgUrl="https://circuit.montu.com.au/assets/images/products/CBD+-+Cannabis+Oil.webp"
                title="Circle | CBD 20"
                dose={{ l1: 'CBD 20 mg/mL', l2: 'THC <3 mg/mL' }}
                quantityDesc={{ l1: 'Cannabis Oil', l2: '30 mL bottle' }}
                tgaName="Circle CBD 20 Oil Drops 30mL"
                titration="Initial Dose: 0.4 ml/day | Maximal Dose: 4.0 ml/day"
                treatmentArea="Chronic Pain, Anxiety, Epilepsy, PTSD"
                price={{ cost: 119, perMg: '(17 cents per mg)' }}
                cmiSheet="https://www.montu.com.au/cmi-cbd-20"
              />
            </div>
            <p className="m-0 mt-5 text-center font-light" style={{ fontSize: '20px' }}>
              Balanced Products (CBD:THC)
            </p>
            <hr className="m-0 bg-dark" />
            <div className="my-4 d-flex flex-wrap justify-content-between">
              <ProductItem
                imgUrl="https://circuit.montu.com.au/assets/images/products/Balance+2-1+Cannabis+Oil+New.webp"
                title="Circle | BALANCE 2:1"
                dose={{ l1: 'CBD 20 mg/mL', l2: 'THC 12 mg/mL' }}
                quantityDesc={{ l1: 'Cannabis Oil', l2: '30 mL bottle' }}
                tgaName="Circle BALANCE CBD 20 : THC 12 Oil Drops 30mL"
                titration="Initial Dose: 0.2 ml/day | Maximal Dose: 3.0 ml/day"
                treatmentArea="Cancer Pain, PTSD, Neuropathic Pain, Anorexia, Multiple Sclerosis, IBS"
                price={{ cost: 199, perMg: '(21 cents per mg)' }}
                cmiSheet="https://www.montu.com.au/cmi-balance-2-1"
              />
              <ProductItem
                imgUrl="https://circuit.montu.com.au/assets/images/products/Balance+1-1+Cannabis+Oil.webp"
                title="Circle | BALANCE 1:1"
                dose={{ l1: 'CBD 10 mg/mL', l2: 'THC 10 mg/mL' }}
                quantityDesc={{ l1: 'Cannabis Oil', l2: '30 mL bottle' }}
                tgaName="Circle BALANCE 1:1 (CBD10 : THC10) Cannabis Oil"
                titration="Initial Dose: 0.2 ml/day | Maximal Dose: 3.0 ml/day"
                treatmentArea="Palliative Care, Cancer Pain, PTSD, Neuropathic Pain, Anorexia, Multiple Sclerosis, IBS"
                price={{ cost: 129, perMg: '(22 cents per mg)' }}
                cmiSheet="https://www.montu.com.au/cmi-balance-1-1"
              />
            </div>
            <p className="m-0 mt-5 text-center font-light" style={{ fontSize: '20px' }}>
              THC-dominant Products
            </p>
            <hr className="m-0 bg-dark" />
            <div className="my-4 d-flex flex-wrap justify-content-between">
              <ProductItem
                imgUrl="https://circuit.montu.com.au/assets/images/products/THC+-+Cannabis+Oil.webp"
                title="Circle | THC 20"
                dose={{ l1: 'THC 20 mg/mL', l2: 'CBD <3 mg/mL' }}
                quantityDesc={{ l1: 'Cannabis Oil', l2: '30 mL bottle' }}
                tgaName="Circle THC 20 Oil Drops 30mL"
                titration="Initial Dose: 0.2 ml/day | Maximal Dose: 2.0 ml/day"
                treatmentArea="Insomnia, CINV (Nausea), Multiple Sclerosis"
                price={{ cost: 149, perMg: '(22 cents per mg)' }}
                cmiSheet="https://www.montu.com.au/cmi-thc-20"
              />
              <ProductItem
                imgUrl="https://circuit.montu.com.au/assets/images/products/Circle+THC+18+Flower.webp"
                title="Circle | THC 18%"
                dose={{ l1: 'THC 18%', l2: 'CBD <1% ' }}
                quantityDesc={{ l1: 'Dried Cannabis', l2: '10 gram jar' }}
                tgaName="Circle THC18% Dried Flower 10g"
                titration="Initial Dose: 0.1 g/day | Maximal Dose: 3.0 g/day"
                treatmentArea="Insomnia, CINV (Nausea), Multiple Sclerosis"
                price={{ cost: 119, perMg: '(8 cents per mg)' }}
                cmiSheet="https://www.montu.com.au/cmi-thc-18"
                customImgClass="product-image-small"
              />
            </div>
          </div>
        </section>
        <div className="mt-5 pt-4 card-header border-0 d-flex justify-content-center" style={{ marginBottom: '-40px' }}>
          <div>
            <p className="mb-0 pharmacy-text">
              <b>* Transparent Pricing Guarantee:</b>
            </p>
            <p className="mb-0 pricingGuaranteeText">
              The Circle product range is fairly priced with average patient costs of $150-300 per month. Each
              prescription is distributed to patients{' '}
            </p>
            <p className="mb-0 pricingGuaranteeText">
              with a fixed pharmacy dispensing fee of {shippingParts.formattedShipping} (excl GST) through our trusted
              pharmacy network for patient price transparency.{' '}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Products;
