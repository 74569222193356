import axios from 'axios';

import settings from '@/constants/constants';

const API_URL = `${settings.url}/user`;

const fetchUserProfile = () => axios.get(`${API_URL}/profile`);

const updateUserProfile = (data: unknown) => axios.post(`${API_URL}/profile`, data);

const getUserOnboardEmail = (hash: string) => axios.get(`${API_URL}/getEmailOnboardHash/${hash}`);

const registerUserPassword = (data: unknown) => axios.post(`${API_URL}/registerPassword`, data);

const resendLoginInvite = (data: unknown) => axios.post(`${API_URL}/resendLoginInvite`, data);

// eslint-disable-next-line import/prefer-default-export
export const UserService = {
  fetchUserProfile,
  getUserOnboardEmail,
  registerUserPassword,
  resendLoginInvite,
  updateUserProfile,
};
