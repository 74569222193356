import { Button, ButtonProps } from '@/ui-library';

export default function CheckProductEnquiryBtn({
  handleCheckProductStatus,
  ...props
}: { handleCheckProductStatus: () => void } & ButtonProps) {
  return (
    <Button color="primary" onClick={handleCheckProductStatus} {...props}>
      Check product status
    </Button>
  );
}
