import { ImgHTMLAttributes, useState } from 'react';

export default function Thumbnail({ src, alt, ...rest }: ImgHTMLAttributes<HTMLImageElement>) {
  const [imageUrl, setImageUrl] = useState<string | undefined>(src ?? '');

  const handleImageError = () => {
    setImageUrl('https://via.placeholder.com/100');
  };

  return <img src={imageUrl} alt={alt} onError={handleImageError} {...rest} />;
}
