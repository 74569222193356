import * as React from 'react';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Popover, Stack } from '@mui/material';

type Props<T> = {
  statuses: T[];
  selectedStatus: T | null;
  onSelect: (status: T | null) => void;
};

// Note - Current only one status can be selected at a time
function FilterByOrderStatusSelect<T extends string>({ statuses, selectedStatus, onSelect }: Props<T>) {
  const [anchorElement, setAnchorElement] = React.useState<HTMLButtonElement | null>();

  const onFilterButtonClick: React.MouseEventHandler<HTMLButtonElement> = (e) =>
    setAnchorElement((current) => (current ? null : e.currentTarget));

  const onClose = () => setAnchorElement(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as T;
    onSelect(value === selectedStatus ? null : value);
    onClose();
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" position="relative">
        <Stack>
          {/* 
            '../common/button' does not support MUI icons and FA does not have a free
            'filter icon' so I've used the default MUI button and styled it to match
            '../common/button'. 
          */}
          <Button
            startIcon={<FilterListIcon />}
            size="small"
            onClick={onFilterButtonClick}
            style={{
              background: '#194457',
              color: 'white',
            }}
          >
            Filter by
          </Button>
        </Stack>
      </Stack>
      <Popover
        id="order-status-filters-popover"
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card raised sx={{ maxHeight: '50vh', overflow: 'scroll' }}>
          <CardContent>
            <Stack spacing={2} alignContent="center">
              <FormGroup>
                {statuses.map((status) => (
                  <FormControlLabel
                    key={`order-status-filter-${status}`}
                    control={
                      <Checkbox color="info" value={status} onChange={onChange} checked={selectedStatus === status} />
                    }
                    label={status}
                  />
                ))}
              </FormGroup>
            </Stack>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}

export default FilterByOrderStatusSelect;
