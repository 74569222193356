export const VALID_APP_ENVS = ['test', 'development', 'qa', 'staging', 'uat', 'production'] as const;
type AppEnv = (typeof VALID_APP_ENVS)[number];

export const viteAppEnv = (): AppEnv => {
  const appEnv = import.meta.env.VITE_APP_ENV;
  if (!appEnv) {
    throw new Error('App Environment not configured');
  }
  return appEnv;
};

export const viteAppBaseUrl = (): string => {
  const appBaseUrl = import.meta.env.VITE_APP_BASE_URL;
  if (!appBaseUrl) {
    throw new Error('App Base URL not configured');
  }
  return appBaseUrl;
};

export const viteFFOverrides = (): Record<string, string | number | boolean> | null => {
  const ffOverrides = import.meta.env.VITE_FF_OVERRIDES;

  if (ffOverrides) {
    try {
      return JSON.parse(ffOverrides);
    } catch (e) {
      throw new Error('VITE_FF_OVERRIDES is not a valid JSON string');
    }
  }

  return null;
};

export default null;
