/* eslint-disable no-undefined */
import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import CircleLoader from '../common/circleLoader';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';
import { getCountries, getStates } from '../../services/data.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('clinicDetails');
const url = settings.url;
export class clinicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: this.props.match.params.id ? true : false,
      content: this.props.match.params.id === undefined ? true : false,
      clinic: '',
      loading: false,
      email: '',
      phone: '',
      address: '',
      sub_address: '',
      city: '',
      state: '',
      defaultCity: '',
      defaultState: '',
      defaultCountry: { value: 'australia', label: 'Australia' },
      zip_code: '',
      country: 13,
      countries: [],
      states: [],
      data: [],
    };
    //copy for state
    this.initialState = this.state;
    this.validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      await axios.get(url + `/clinic/${this.props.match.params.id}`).then((data) => {
        let splitaddress = data.data[0].address;
        splitaddress = splitaddress.split('$$$$');
        let address = splitaddress[0];
        let sub_address = splitaddress[1];
        this.setState({
          data: data.data[0],
          clinic: data.data[0].name,
          email: data.data[0].email,
          phone: data.data[0].phone,
          address: address,
          sub_address: sub_address,
          city: data.data[0].city,
          state: data.data[0].state,
          zip_code: data.data[0].zip_code,
          fetched: false,
        });
      });
    }

    const countryList = await getCountries();
    if (countryList) {
      this.setState({
        countries: countryList,
        content: false,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        content: true,
      });
      this.setState(this.initialState);
      const { country } = this.state;
      const countryList = await getCountries();
      if (countryList) {
        this.setState({
          countries: countryList,
          content: false,
        });
      }
      const stateList = await getStates(country);
      if (stateList) {
        this.setState({
          states: stateList,
        });
      }
    }
  }

  handleCountry = async (e) => {
    this.setState({
      country: e.value,
    });
    const value = e.value;
    const stateList = await getStates(value);
    this.setState({ states: stateList });
  };

  handleState = async (e) => {
    const value = e.value;
    this.setState({ state: value });
  };

  handleCity = async (e) => {
    const value = e.value;
    this.setState({ city: value });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  onSubmission = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ loading: true });
    if (this.props.match.params.id) {
      let sub_address = this.state.sub_address ? this.state.sub_address : '';
      await axios
        .put(url + `/clinic/${this.props.match.params.id}`, {
          data: JSON.stringify({
            name: this.state.clinic,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address + '$$$$' + sub_address,
            city: this.state.city,
            state: this.state.state,
            zip_code: this.state.zip_code,
            country: 13,
          }),
        })
        .then(async (res) => {
          if (res.status === 200) {
            this.setState({
              submitted: true,
              loading: false,
              clinic: '',
              email: '',
              phone: '',
              address: '',
              sub_address: '',
              city: '',
              state: '',
              zip_code: '',
              country: 13,
            });
            this.props.history.push('/viewclinics');
            toast.success('Clinic details are updated');
          }
        })
        .catch((err) => {
          toast.error('Clinic details not updated');
          logger.error('Clinic details not updated', err);
          this.setState({
            loading: false,
          });
          return err;
        });
    }
    if (!this.props.match.params.id) {
      await AuthService.clinic(
        this.state.clinic,
        this.state.email,
        this.state.phone,
        this.state.address + '$$$$' + this.state.sub_address,
        this.state.city,
        this.state.state,
        this.state.zip_code,
      );
      this.props.history.push('/viewclinics');
    }
  };

  render() {
    const {
      fetched,
      content,
      loading,
      clinic,
      email,
      phone,
      address,
      sub_address,
      country,
      defaultCountry,
      countries,
      state,
      zip_code,
      city,
    } = this.state;
    let CountryOp = countries.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });

    return (
      <React.Fragment>
        <div className="container">
          <h3>Onboarding-Clinic</h3>
          <div className="row">
            <div className="col-md-12 mt-3">
              {(this.props.match.params.id !== undefined ? !fetched : !content) ? (
                <form>
                  <div className="col-md-6">
                    <div className="form-group">
                      <FormInput
                        type="text"
                        name="clinic"
                        label="Clinic Name"
                        value={clinic}
                        onChange={this.handleUserInput}
                        error={this.validator.message('name', clinic, 'required')}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <FormInput
                        type="email"
                        name="email"
                        label="Clinic Email"
                        value={email}
                        onChange={this.handleUserInput}
                        error={this.validator.message('email', email, 'required|email')}
                      />
                    </div>
                    <div className="form-group">
                      <FormInput
                        type="text"
                        name="phone"
                        label="Clinic Phone Number"
                        value={phone}
                        onChange={this.handleUserInput}
                        error={this.validator.message('phone-number', phone, 'required')}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <FormInput
                        type="text"
                        name="address"
                        label="Address"
                        info="Street Address"
                        value={address}
                        onChange={this.handleUserInput}
                        error={this.validator.message('address', address, 'required')}
                      />
                    </div>
                    <div className="form-group ">
                      <FormInput
                        type="text"
                        name="sub_address"
                        placeholder=""
                        info="Address Line 2"
                        value={sub_address ? sub_address : ''}
                        onChange={this.handleUserInput}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 my-auto">
                        <FormInput
                          type="text"
                          name="city"
                          defaultValue={city}
                          info="City"
                          onChange={this.handleUserInput}
                          error={this.validator.message('City', city, 'required')}
                        />
                      </div>
                      <div className="form-group col-md-6 my-auto">
                        <FormInput
                          type="text"
                          name="state"
                          defaultValue={state}
                          info="State / Province / Region"
                          onChange={this.handleUserInput}
                          error={this.validator.message('State', state, 'required')}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 my-auto">
                        <FormInput
                          type="text"
                          name="zip_code"
                          value={zip_code}
                          placeholder="Postal-Code"
                          info="Postal / Zip Code"
                          onChange={this.handleUserInput}
                          error={this.validator.message('zip-code', zip_code, 'required')}
                        />
                      </div>
                      <div className="form-group col-md-6 my-auto">
                        <Select
                          name="country"
                          defaultValue={defaultCountry}
                          onChange={this.handleCountry}
                          isSearchable
                          options={CountryOp}
                        />
                        {this.validator.message('Country', country, 'required')}
                        <small className="form-text text-muted ml-1">Country</small>
                      </div>
                    </div>
                  </div>
                  <Button
                    text="Update"
                    type="submit"
                    loading={loading}
                    className="btn btn-primary btn-md ml-3"
                    onClick={this.onSubmission}
                  />
                </form>
              ) : (
                <CircleLoader />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default clinicDetails;
