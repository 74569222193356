import { createSvgIcon } from '@mui/material';

/**
 * An icon for spray
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2688-47231&m=dev
 *
 * @returns An SvgIcon
 */

export const MgSpray = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M8 22C7.45 22 6.97917 21.8041 6.5875 21.4125C6.19583 21.0208 6 20.55 6 20V15.7C6 14.4666 6.175 13.2791 6.525 12.1375C6.875 10.9958 7.425 9.8583 8.175 8.72497C7.54167 8.57497 7.02083 8.2333 6.6125 7.69997C6.20417 7.16663 6 6.56663 6 5.89997V4.84997C6 4.04997 6.34583 3.37913 7.0375 2.83747C7.72917 2.2958 8.45 2.06663 9.2 2.14997L18.1 3.02497C18.3833 3.0583 18.6042 3.1708 18.7625 3.36247C18.9208 3.55413 19 3.77497 19 4.02497V6.99997C19 7.24997 18.9125 7.4708 18.7375 7.66247C18.5625 7.85413 18.35 7.96663 18.1 7.99997L17.2 8.09997C17.4333 8.91663 17.7417 9.64997 18.125 10.3C18.5083 10.95 18.9667 11.4166 19.5 11.7L18.5 13.45C17.6167 12.9333 16.9333 12.2208 16.45 11.3125C15.9667 10.4041 15.5833 9.39163 15.3 8.27497L14.2 8.37497C14.3333 9.2083 14.5875 10.05 14.9625 10.9C15.3375 11.75 15.7167 12.5166 16.1 13.2C16.4 13.7333 16.625 14.2916 16.775 14.875C16.925 15.4583 17 16.05 17 16.65V20C17 20.55 16.8042 21.0208 16.4125 21.4125C16.0208 21.8041 15.55 22 15 22H8ZM8 5.09997V5.89997C8 6.19997 8.10833 6.44163 8.325 6.62497C8.54167 6.8083 8.8 6.8833 9.1 6.84997L17 6.09997V4.89997L9.1 4.14997C8.8 4.11663 8.54167 4.19163 8.325 4.37497C8.10833 4.5583 8 4.79997 8 5.09997ZM8 20H15V16.65C15 16.2166 14.9458 15.7916 14.8375 15.375C14.7292 14.9583 14.5667 14.5583 14.35 14.175C13.8333 13.275 13.3833 12.3458 13 11.3875C12.6167 10.4291 12.35 9.49163 12.2 8.57497L10.7 8.69997L10.05 9.54997C9.38333 10.4333 8.875 11.4041 8.525 12.4625C8.175 13.5208 8 14.6 8 15.7V20Z"
      fill="currentColor"
    />
  </svg>,
  'MgSpray',
);

export default MgSpray;
