export type SupplierProduct = {
  id: number;
  product_id: number;
  supplier_id: number;
  createdAt: string;
  updatedAt: string;
};

export type Supplier = {
  id: number;
  supplier_code: string;
  supplier_name: string;
  secondary_email: string | null;
  is_montu_brand: boolean;
  SupplierProducts: SupplierProduct[];
};

export type Product = {
  id: number;
  name: string;
  short_name: string | null;
  sku_code: string;
  price: number;
  pre_approved: boolean | null;
  active: boolean;
  order: number | null;
  description: string | null;
  description_for_display: string | null;
  how_to_use: string | null;
  size: string | null;
  contents: string | null;
  conditions: string | null;
  available_to_all_gp: boolean;
  thc_strength: string | null;
  thc_strength_for_display: string | null;
  thc_percentage: number | null;
  cbd_thc_ratio: string | null;
  cultivar: string | null;
  cbd_strength: string | null;
  cbd_strength_for_display: string | null;
  dosage: string | null;
  interval: number | null;
  is_out_of_stock: boolean;
  new_product: boolean;
  coming_soon: boolean;
  new_at_lowprice: boolean;
  campaign_discount_price: boolean;
  stock_return_date: Date | null;
  reasoning_toggle: boolean;
  patient_catalog: boolean;
  reasoning: string | null;
  weight: number | null;
  group_index: number | null;
  image_url: string | null;
  is_concession: boolean;
  is_supply_chain_risk: boolean;
  is_generative_scripting: boolean;
  is_excluded_from_new_prescriptions: boolean;
  Suppliers: Supplier[];
};

export const ProductFormulations = {
  ORAL_LIQUID: 'Oral Liquid',
  HERB_DRIED: 'Herb, Dried',
  CAPSULE: 'Capsule',
  PASTILLE: 'Pastille',
  DEVICE: 'Device',
  INHALATION: 'Inhalation',
  TOPICAL: 'Topical',
  SPRAY: 'Spray',
  WAFER: 'Wafer',
  TABLET_CHEWABLE: 'Tablet, Chewable',
  CONCENTRATED_EXTRACT: 'Concentrated Extract',
  INHALANT_PRESSURIZED: 'Inhalant (pressure)',
} as const;

export type ProductFormulation = (typeof ProductFormulations)[keyof typeof ProductFormulations];

export const ProductFormulationFilters = {
  ...ProductFormulations,
  ALL: 'all',
} as const;

export type ProductFormulationFilter = (typeof ProductFormulationFilters)[keyof typeof ProductFormulationFilters];

export const ProductStrains = {
  SATIVA: 'Sativa',
  INDICA: 'Indica',
  BALANCED: 'Balanced',
} as const;

export const ProductSpectrums = {
  BROAD: 'Broad',
  FULL: 'Full',
};

export const BRANDS = {
  MONTU: 1,
  ALTERNALEAF: 2,
  UMEDS: 3,
};

export type ProductStrain = (typeof ProductStrains)[keyof typeof ProductStrains];
