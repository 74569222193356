import { useEffect } from 'react';
import { getCalApi } from '@calcom/embed-react';

import { BookingDetails } from '@/components/common/CalendarPopupButton';
import settings from '@/constants/constants';

interface IFormData {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

interface UseCalendarPopupButtonProps {
  formData?: IFormData;
  onOpen?: () => void;
  handleBookingSuccess?: (value?: BookingDetails) => void;
  handleRescheduleSuccess?: (value?: BookingDetails) => void;
  handleCancelSuccess?: (value?: BookingDetails) => void;
}

function useCalendarBooking({
  formData,
  onOpen,
  handleBookingSuccess,
  handleRescheduleSuccess,
  handleCancelSuccess,
}: UseCalendarPopupButtonProps) {
  useEffect(() => {
    (async function initCalendar() {
      const cal = await getCalApi(settings.calendarConfig.embedJsUrl);
      cal('ui', { theme: 'light' });

      if (handleBookingSuccess) {
        cal('on', {
          action: 'bookingSuccessful',
          callback: ({ detail }) => {
            handleBookingSuccess(detail.data as BookingDetails);
          },
        });
      }

      if (handleRescheduleSuccess) {
        cal('on', {
          action: 'rescheduleBookingSuccessful',
          callback: ({ detail }) => {
            handleRescheduleSuccess(detail.data as BookingDetails);
          },
        });
      }

      if (handleCancelSuccess) {
        cal('on', {
          action: 'bookingCancelled',
          callback: ({ detail }) => {
            handleCancelSuccess(detail.data as BookingDetails);
          },
        });
      }

      if (typeof onOpen === 'function') {
        cal('on', { action: 'linkReady', callback: onOpen });
      }
    })();
  }, [onOpen, handleBookingSuccess, handleRescheduleSuccess, handleCancelSuccess]);

  return {
    formData: formData ? JSON.stringify(formData) : undefined,
    origin: settings.calendarConfig.calOrigin,
  };
}

export default useCalendarBooking;
