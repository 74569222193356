import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import Modal from 'bootstrap/js/dist/modal';
import FilterableTable from 'rc-data-table';
import {
  FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS,
  FF_ENABLE_SUPER_ADMIN_INVENTORY,
} from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import { PharmacyService } from '@/services/pharmacy.service';
import removeIcon from '../../assets/images/remove.png';
import settings from '@/constants/constants';
import { INVENTORY_STATUS } from '@/constants/inventory';
import { AuthService } from '@/services/authentication.service';
import {
  addMultipleStockToInventory,
  getCircuitProducts,
  getInventoryStatusList,
  getPharmacistDetails,
  getPharmacyProducts,
} from '@/services/pharmacist.service';
import Button from '../common/button';
import CircleLoader from '../common/circleLoader';
import FormInput from '../common/FormInput';

class ProductOverview extends React.Component {
  state = {
    loading: true,
    products: [],
    selectedProduct: null,
    productHistory: [],
    inventoryStatusList: [],
    showAdjustmentModal: false,
  };

  componentDidMount() {
    this.inventoryAdjustmentModal = null;
    this.getProducts();
  }

  render() {
    return (
      <div className="container-fluid">
        <h3>Product Overview</h3>
        <Button
          text="Add New Adjustment"
          className="btn btn-md btn-primary mx-1 float-right"
          onClick={() => this.makeAdjustment()}
        />
        <div>
          {this.state.loading ? (
            <CircleLoader />
          ) : (
            <FilterableTable
              className="table-responsive"
              topPagerVisible={false}
              data={this.state.products}
              loading={this.state.loading}
              fields={this.getTableFields()}
              totalRecords={this.state.products.length}
              currentPage={0}
              serverSort={() => {}}
              pageSizes={false}
              pageSize={this.state.products.length}
              initialSort="name"
              initialSortDir
              filterPlaceholder="Search"
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          )}
        </div>
        {this.state.showAdjustmentModal && (
          <InventoryAdjustment
            handleClose={this.hideAdjustmentModal}
            products={this.state.products}
            selectedProduct={this.state.products.find((item) => item.id === this.state.selectedProduct)}
            inventoryStatusList={this.state.inventoryStatusList}
            updateProductStock={this.updateProductStock}
            pharmacy_id={this.props.pharmacy_id}
            flags={this.props.flags}
          />
        )}
      </div>
    );
  }

  getAvailableProducts = async () => {
    const products = await getCircuitProducts();
    return products.data;
  };

  getInventoryProducts = async (pharmacy_id) => {
    const products = this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY]
      ? await PharmacyService.getPharmacyProducts(pharmacy_id)
      : await getPharmacyProducts();
    return products.data.products;
  };

  isStockZero = (stock) => (stock && stock > 0 ? stock : 0);

  getProducts = async () => {
    let pharmacyId = this.props.pharmacy_id;
    if (this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] && !pharmacyId) {
      const { data: pharmacistDetails } = await getPharmacistDetails();
      pharmacyId = pharmacistDetails.pharmacy_id;
    }

    if (this.props.flags[FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS]) {
      this.getProductsNew(pharmacyId);
    } else {
      this.getProductsOld(pharmacyId);
    }
  };

  getProductsNew = async (pharmacyId) => {
    if (!pharmacyId) {
      return;
    }
    const pharmacyInventory = await PharmacyService.getPharmacyProductInventory(pharmacyId);
    const products = pharmacyInventory.data.data;

    this.setState({
      products,
      loading: false,
    });
  };

  //TODO: FX-614 - Remove this when the feature is being enabled permanently.
  getProductsOld = async (pharmacyId) => {
    const availableProducts = await this.getAvailableProducts();
    const inventoryProducts = await this.getInventoryProducts(pharmacyId);

    const products = [];
    for (const item of availableProducts) {
      let inventoryProduct = null;
      if (inventoryProducts.length > 0) {
        inventoryProduct = inventoryProducts.find((product) => product.product_id === item.id);
      }
      const data = {
        ...item,
        stock: this.isStockZero(inventoryProduct?.total_stock),
        availableStock: this.isStockZero(inventoryProduct?.available_stock),
        allocatedStock: this.isStockZero(inventoryProduct?.allocated_stock),
      };
      products.push(data);
    }
    const inventoryStatusList = await getInventoryStatusList();
    this.setState({
      products,
      inventoryStatusList: inventoryStatusList.data,
      loading: false,
    });
  };

  makeAdjustment = (selectedProduct) => {
    this.setState(
      {
        showAdjustmentModal: true,
        selectedProduct,
      },
      () => {
        this.showAdjustmentModal();
      },
    );
  };

  showAdjustmentModal = () => {
    const element = document.getElementById('inventoryAdjustmentModal');
    if (!element) return;
    this.inventoryAdjustmentModal = new Modal(element, {
      backdrop: false,
      keyboard: false,
    });

    this.inventoryAdjustmentModal.show();
  };

  hideAdjustmentModal = () => {
    this.setState({
      showAdjustmentModal: false,
      selectedProduct: null,
    });
    this.getProducts();
    this.inventoryAdjustmentModal.hide();
  };

  updateProductStock = (stock) => {
    const index = this.state.products.findIndex((product) => product.id === this.state.selectedProduct);
    this.setState({
      products: [
        ...this.state.products.slice(0, index),
        {
          ...this.state.products[index],
          stock,
        },
        ...this.state.products.slice(index + 1),
      ],
    });
  };

  getTableFields = () => [
    {
      name: 'name',
      displayName: 'Product Name',
      inputFilterable: true,
      sortable: true,
      tdClassName: 'sub-text',
      render: (e) => (
        <>
          {e.record.name}
          {!e.record.active && this.props.flags[FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS] && ' - (Inactive)'}{' '}
          {e.record.active}
        </>
      ),
    },
    {
      name: 'stock',
      displayName: 'Stock on Hand',
      inputFilterable: false,
      sortable: false,
      thClassName: 'text-center',
      tdClassName: 'sub-text text-center',
    },
    {
      name: 'allocatedStock',
      displayName: 'Allocated Stock',
      inputFilterable: false,
      sortable: false,
      thClassName: 'text-center',
      tdClassName: 'sub-text text-center',
    },
    {
      name: 'availableStock',
      displayName: 'Available Stock',
      inputFilterable: false,
      sortable: false,
      thClassName: 'text-center',
      tdClassName: 'sub-text text-center',
    },
  ];
}

class InventoryAdjustment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustmentType: INVENTORY_STATUS.INCREMENT,
      quantity: this.props.selectedProduct ? this.props.selectedProduct.stock : 0,
      selectedQuantity: null,
      newQuantity: this.props.selectedProduct ? this.props.selectedProduct.stock : null,
      reference: '',
      actualProductList: props.products,
      productList: props.products,
      newInventoryProducts: [],
      valid: false,
      loading: false,
      role_id: AuthService.getUser().user.role_id,
    };

    this.validator = new SimpleReactValidator({
      validators: {
        revisedQuantity: {
          message: 'Negative value not allowed',
          rule: (val) => val >= 0,
          required: true,
        },
        enteredQuantity: {
          message: 'Invalid Quantity',
          rule: (val) => val > 0,
          required: true,
        },
      },
    });
  }

  componentDidMount() {
    if (this.state.newInventoryProducts.length === 0) {
      this.initialProduct(this.state.newInventoryProducts.length);
    }
  }

  initialProduct(id) {
    const newProducts = this.state.newInventoryProducts;
    newProducts.push({
      id: id + 1,
      productId: '',
      name: '',
      stock: 0,
      typeId: '1',
      typeName: 'Increase',
      qty: 0,
      reason: '',
      newQty: 0,
    });
    this.setState({
      newInventoryProducts: newProducts,
    });
  }

  renderSelectOptions = () => {
    const options = [{ value: '1', label: 'Increase' }];

    if (!this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] || this.state.role_id === USER_ROLES.superAdmin) {
      options.push({ value: '2', label: 'Decrease' });
    }

    return options;
  };

  addRow = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        valid: true,
      });
      return;
    }
    this.validator.hideMessages();
    this.setState({
      valid: false,
    });

    this.initialProduct(this.state.newInventoryProducts[this.state.newInventoryProducts.length - 1].id);
  };

  removeRow = async (id) => {
    let newProducts = [];
    newProducts = this.state.newInventoryProducts.filter((x) => x.id != id);
    this.setState({
      newInventoryProducts: newProducts,
    });
  };

  confirmAdjustment = async (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ loading: true }, function () {
      this.addStock();
    });
  };

  addStock = async () => {
    const response = await addMultipleStockToInventory({
      inventory_products: this.state.newInventoryProducts,
      pharmacy_id: this.props.pharmacy_id,
    });
    if (response.status) {
      this.setState({ loading: false });
      this.props.handleClose();
      toast.success(response.data.message);
    }
  };

  getProductOptions() {
    const modifiedproducts = this.state.productList
      .map((x) => ({
        label: x.name,
        value: x.id,
      }))
      .filter((x) => !this.state.newInventoryProducts.map((item) => item.productId).includes(x.value));

    return modifiedproducts;
  }

  handlePreviousProduct = async (e) => {
    const product = this.state.actualProductList.filter((x) => x.id === e.target.value);
    const existingproductList = this.state.productList;

    if (existingproductList.filter((x) => x.id === e.target.value) === null) {
      alert(e.target.value);
      existingproductList.push({
        id: product.id,
        name: product.name,
        stock: product.stock,
      });
      this.setState({
        productList: existingproductList,
      });
    }
  };

  handleProduct = async (e, id) => {
    const rows = this.state.newInventoryProducts;

    // Check if the product already added
    const existingproductList = this.state.productList;
    const product = existingproductList.find((x) => x.id === e.value);

    const row = rows.find((row) => row.id === id);
    if (row) {
      row.productId = e.value;
      row.name = e.label;
      row.stock = product ? product.stock : 0;
    }

    this.setState({
      newInventoryProducts: rows,
    });
  };

  handleAdjustment = async (e, id) => {
    const rows = this.state.newInventoryProducts;

    const row = rows.find((row) => row.id === id);
    if (row) {
      row.typeId = e.value;
      row.typeName = e.label;
      row.newQty =
        row.typeId === settings.inventoryStatus.INCREMENT
          ? parseFloat(row.stock) + parseFloat(row.qty)
          : parseFloat(row.stock) - parseFloat(row.qty);
    }

    this.setState({
      newInventoryProducts: rows,
    });
  };

  handleQuantity = (id) => (event) => {
    const rows = this.state.newInventoryProducts;
    const qty = event.target.value;
    const row = rows.find((row) => row.id === id);

    if (event.target.validity.valid === false) {
      row.qty = '';
      row.newQty = 0;
      this.setState({
        newInventoryProducts: rows,
      });
      return;
    }

    if (row) {
      row.qty = qty;
      row.newQty =
        row.typeId === settings.inventoryStatus.INCREMENT
          ? parseFloat(row.stock) + parseFloat(row.qty)
          : parseFloat(row.stock) - parseFloat(row.qty);
      this.setState({
        newInventoryProducts: rows,
      });
    }
  };

  handleReson = (id) => (event) => {
    const rows = this.state.newInventoryProducts;

    const row = rows.find((row) => row.id === id);
    if (row) {
      row.reason = event.target.value;

      this.setState({
        newInventoryProducts: rows,
      });
    }
  };

  render() {
    return (
      <div
        className="modal"
        id="inventoryAdjustmentModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="inventoryAdjustmentModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '95%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-block ml-2">
                <h5> Product Adjustments </h5>

                <p>
                  {this.state.role_id === USER_ROLES.superAdmin || !this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY]
                    ? 'Increase or decrease the available products'
                    : 'Increase the available products'}
                </p>
              </div>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <td style={{ width: '25%' }}>Product</td>
                    <td style={{ width: '12%' }}>Warehouse Stock</td>
                    <td>Adjustment Type</td>
                    <td style={{ width: '10%' }}>Quantity</td>
                    <td>Reason for the Adjustment</td>
                    <td style={{ width: '12%' }}>Revised Quantity</td>
                    <td className="text-center">Remove</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.newInventoryProducts &&
                    this.state.newInventoryProducts.map((row) => (
                      <>
                        <tr key={row.id} className="product-row">
                          <td style={{ width: '20%', borderBottom: '1px solid white' }}>
                            <Select
                              name={`product${row.id}`}
                              value={{
                                label: row.name,
                                value: row.productId ? row.productId : '',
                              }}
                              options={this.getProductOptions()}
                              className="mb-2"
                              isSearchable
                              onChange={(e) => this.handleProduct(e, row.id)}
                              placeholder="Select Product"
                            />
                          </td>
                          <td style={{ width: '12%', borderBottom: '1px solid white' }}>
                            <FormInput
                              type="number"
                              noLabel
                              className="mb-2"
                              value={row.stock}
                              name="warehouseStock"
                              disabled="true"
                            />
                          </td>
                          <td style={{ borderBottom: '1px solid white' }}>
                            <Select
                              name="adjustmentType"
                              value={{
                                label: row.typeName,
                                value: row.typeId ? row.typeId : '',
                              }}
                              options={this.renderSelectOptions()}
                              isSearchable
                              className="mb-2"
                              onChange={(e) => this.handleAdjustment(e, row.id)}
                              placeholder="Select Product"
                            />
                          </td>
                          <td style={{ width: '10%', borderBottom: '1px solid white' }}>
                            <FormInput
                              type="number"
                              noLabel
                              min="1"
                              value={row.qty}
                              name="enteredQuantity"
                              className="mb-2"
                              onChange={this.handleQuantity(row.id)}
                            />
                          </td>
                          <td style={{ borderBottom: '1px solid white' }}>
                            <FormInput
                              type="text"
                              noLabel
                              value={row.reason}
                              name="reason"
                              className="mb-2"
                              onChange={this.handleReson(row.id)}
                            />
                          </td>
                          <td style={{ width: '12%', borderBottom: '1px solid white' }}>
                            <FormInput
                              type="number"
                              noLabel
                              value={row.newQty}
                              className="mb-2"
                              disabled="true"
                              name="revisedQuantity"
                            />
                          </td>
                          <td style={{ borderBottom: '1px solid white' }}>
                            <img
                              src={removeIcon}
                              alt="remove_icon"
                              className="prescriptionIcon"
                              onClick={() => this.state.newInventoryProducts.length != 1 && this.removeRow(row.id)}
                            />
                          </td>
                        </tr>
                        <tr className="validate-row">
                          <td style={{ borderBottom: '1px solid white' }}>
                            {this.validator.message('Product', row.productId, 'required')}
                          </td>
                          <td style={{ borderBottom: '1px solid white' }} />
                          <td style={{ borderBottom: '1px solid white' }}>
                            {this.validator.message('Select Adjustment type', row.typeId, 'required')}
                          </td>
                          <td style={{ borderBottom: '1px solid white' }}>
                            {this.validator.message('Qunatity', row.qty, 'enteredQuantity')}
                          </td>
                          <td style={{ borderBottom: '1px solid white' }}>
                            {this.validator.message('Reason for the Adjustment', row.reason, 'required')}
                          </td>
                          <td style={{ borderBottom: '1px solid white' }}>
                            {this.validator.message('Revised Quantity', row.newQty, 'revisedQuantity')}
                          </td>
                          <td style={{ borderBottom: '1px solid white' }} />
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>

              <div className="row ml-2 add-row">
                <p onClick={() => this.addRow()}>Add Row</p>
              </div>

              <div className="row button-wrapper">
                <div className="col-lg-6">
                  <Button text="Cancel" className="btn btn-cancel float-sm-right" onClick={this.props.handleClose} />
                </div>
                <div className="col-lg-6">
                  <Button
                    text="Submit"
                    className="btn btn-md btn-submit float-sm-left"
                    loading={this.state.loading}
                    onClick={(e) => this.confirmAdjustment(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //TODO: FX-614 - It appears we can remove these functions as they don't appear to be in use anymore after the changes relating to FF_ENABLE_SUPER_ADMIN_INVENTORY
  getSelectedAdjustmentType = () => {
    const type = this.props.inventoryStatusList.find((item) => item.id === this.state.adjustmentType);
    if (!type) return null;
    return {
      label: type.name,
      value: type.id,
    };
  };

  handleAdjustmentTypeChange = (e) => {
    let newQuantity = Number(this.state.quantity);
    const adjustmentType = e.value;
    if (adjustmentType === INVENTORY_STATUS.INCREMENT) {
      newQuantity = Number(newQuantity) + Number(this.state.selectedQuantity);
    } else {
      newQuantity = Number(newQuantity) - Number(this.state.selectedQuantity);
    }
    this.setState({
      adjustmentType,
      newQuantity,
    });
  };

  handleQuantityChange = (e) => {
    if (isNaN(e.target.value)) return;
    let newQuantity = Number(this.state.quantity);
    if (this.state.adjustmentType === INVENTORY_STATUS.INCREMENT) {
      newQuantity = Number(newQuantity) + Number(e.target.value);
    } else {
      newQuantity = Number(newQuantity) - Number(e.target.value);
    }
    this.setState({
      selectedQuantity: e.target.value,
      newQuantity,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const { role_id } = this.state;
    const { adjustmentType } = this.state;

    if (
      role_id === USER_ROLES.pharmacist &&
      adjustmentType === '2' &&
      this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY]
    ) {
      toast.error('You do not have permission to decrease stock.');
      return;
    }

    const data = {
      productId: this.props.selectedProduct.id,
      adjustmentType: this.state.adjustmentType,
      selectedQuantity: this.state.selectedQuantity,
      reference: this.state.reference,
    };
    try {
      const response = await addStockToInventory(data);
      if (response.status === 200) {
        this.props.updateProductStock(response.data.stock);
        toast.success(response.data.message);
      } else {
        toast.error('Failed to update stock: ' + response.message);
      }
    } catch (error) {
      toast.error('An error occurred while updating stock.');
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      adjustmentType: INVENTORY_STATUS.INCREMENT,
      date: moment(),
      quantity: 0,
      selectedQuantity: null,
      newQuantity: null,
      reference: '',
    });
    this.validator.hideMessages();
    this.props.handleClose();
  };
}

export default ProductOverview;
