import { DateTime } from 'luxon';

import { Allocation, AllocationsStat, Pharmacy } from '@/pages/order-allocation-tool/orderAllocationTool.types';
import { AssignOrderFailure } from '@/services/support.service';

// Same day shipping cutoff and order times relative to this TZ
const SHIPPING_TZ = 'Australia/Melbourne';

export const getDateTimeWithShippingTz = (date?: Date): DateTime =>
  date ? DateTime.fromJSDate(date).setZone(SHIPPING_TZ) : DateTime.now().setZone(SHIPPING_TZ);

export function groupAllocationsByPharmacyId(allocations: Allocation[], pharmacies?: Pharmacy[]) {
  const allPharmacies = (pharmacies || []).reduce((acc, { id }) => {
    acc[id] = [];
    return acc;
  }, {} as Record<number, Allocation[]>);

  return allocations.reduce((acc, allocation) => {
    if (!acc[allocation.pharmacyId]) {
      acc[allocation.pharmacyId] = [];
    }

    acc[allocation.pharmacyId].push(allocation);
    return acc;
  }, allPharmacies);
}

export function formatAllocationRowOutput({ pharmacyCode, orders }: AllocationsStat) {
  return `Pharmacy Code: ${pharmacyCode}\nOrder Codes: ${orders.map(({ orderCode }) => orderCode).join(', ')}`;
}

export function formatFailedOrderAllocation({ orderStatusId, reason }: AssignOrderFailure) {
  const suffix = orderStatusId ? ` (${orderStatusId})` : '';
  return `${reason}${suffix}`;
}

export function formatSameDayDeliveryRange(start: Date, end: Date) {
  const dateFormat = 'cccc, LLLL d t';
  return `${DateTime.fromJSDate(start).toFormat(dateFormat)}  - ${DateTime.fromJSDate(end).toFormat(
    dateFormat,
  )}. ${SHIPPING_TZ} time`;
}

export function calculateAverageDeliveryDays(allocations: Allocation[]) {
  const totalDeliveryDays = allocations.reduce(
    (acc, { estimatedDeliveryTimeInDays }) => acc + estimatedDeliveryTimeInDays,
    0,
  );
  const avgDays = totalDeliveryDays / allocations.length;
  return (Number.isNaN(avgDays) ? 0 : avgDays).toFixed(2);
}
