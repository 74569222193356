import React from 'react';
import { Container, styled } from '@mui/material';

import { FF_ENABLE_UMEDS_REBRAND } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

interface Props {
  children: React.ReactNode;
}

const StyledContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: '100% !important',
  height: '100%',
  padding: '2rem 1.5rem',
  [theme.breakpoints.up('lg')]: {
    padding: '2rem 10rem',
  },
}));

function FullPageContainer({ children }: Props) {
  const { flags } = useFeatureFlags();

  if (flags[FF_ENABLE_UMEDS_REBRAND]) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <StyledContainer disableGutters>{children}</StyledContainer>;
}

export default FullPageContainer;
