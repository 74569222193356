import { useQuery } from 'react-query';

import { PatientService } from '@/services/patient.service';

export type IDoctorAvailabilityResponse = {
  followUpAvailabilityStatus?: string;
  timeFrame?: number;
  nextAvailable?: string;
  calcomFollowUpConsultEvent?: string;
  calcomUsername?: string;
};

function useGetPatientDoctorAvailability(patientID?: number) {
  const { isLoading, data, isError, isSuccess } = useQuery<IDoctorAvailabilityResponse>({
    queryKey: ['getPatientDoctorAvailability'],
    queryFn: async () => {
      try {
        const response = await PatientService.getPatientDoctorAvailability(patientID);
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch doctors availability: ${(error as { message: string }).message}`);
      }
    },
  });

  return {
    isLoading,
    data,
    isError,
    isSuccess,
  };
}

export default useGetPatientDoctorAvailability;
