import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { InventoryService } from '@/services/inventory.service';

export type BetaPharmacyInventoryResponse = {
  pharmacyId: number;
  pharmacyName: string;
  pharmacyCode: string;
  products: {
    productId: number;
    productName: string;
    inventory: {
      total: number;
      allocated: number;
      unallocated: number;
      allocations: { orderId: number; quantity: number }[];
    };
  }[];
}[];

export type Pharmacy = { pharmacyId: number; pharmacyName: string; pharmacyCode: string };
export type Product = { productId: number; productName: string };
type Row = {
  productId: number;
  productName: string;
  columns: {
    pharmacyId: number;
    pharmacyName: string;
    total: number;
    allocated: number;
    unallocated: number;
  }[];
};

function PharmacyInventory() {
  const [inventories, setInventories] = React.useState<BetaPharmacyInventoryResponse | undefined>();
  const [productFilter, setProductFilter] = React.useState<number[]>([]);
  const [pharmacyFilter, setPharmacyFilter] = React.useState<number[]>([]);

  React.useEffect(() => {
    InventoryService.getAllInventory().then((response) => {
      setInventories(response.data.data);
    });
  }, []);

  const [allPharmacies, allProducts] = React.useMemo(() => {
    if (!inventories) return [[], []];
    const tempPharmacies: Pharmacy[] = [];
    const tempProducts: Product[] = [];
    inventories.forEach((inventory) => {
      tempPharmacies.push({
        pharmacyId: inventory.pharmacyId,
        pharmacyName: inventory.pharmacyName,
        pharmacyCode: inventory.pharmacyCode,
      });
      inventory.products.forEach((product) => {
        if (!tempProducts.find((p) => p.productId === product.productId)) {
          tempProducts.push({ productId: product.productId, productName: product.productName });
        }
      });
    });

    return [tempPharmacies, tempProducts];
  }, [inventories]);

  useEffect(() => {
    if (!productFilter.length) {
      setProductFilter(allProducts.map((p) => p.productId));
    }

    if (!pharmacyFilter.length) {
      setPharmacyFilter(allPharmacies.map((p) => p.pharmacyId));
    }
  }, [allPharmacies, allProducts]);

  const filteredPharmacies = React.useMemo(
    () => allPharmacies.filter((p) => pharmacyFilter.includes(p.pharmacyId)),
    [allPharmacies, pharmacyFilter],
  );

  const filteredProducts = React.useMemo(
    () => allProducts.filter((p) => productFilter.includes(p.productId)),
    [allProducts, productFilter],
  );

  const rows: Row[] = React.useMemo(() => {
    if (!inventories) return [];
    const tempRows: Row[] = [];
    filteredProducts.forEach((product) => {
      const columns: Row['columns'] = [];
      filteredPharmacies.forEach((pharmacy) => {
        const pharmacyInventory = inventories.find((inventory) => inventory.pharmacyId === pharmacy.pharmacyId)!;
        const pharmacyInventoryProduct = pharmacyInventory.products.find((p) => p.productId === product.productId);
        columns.push({
          pharmacyId: pharmacy.pharmacyId,
          pharmacyName: pharmacy.pharmacyName,
          total: pharmacyInventoryProduct?.inventory.total || 0,
          allocated: pharmacyInventoryProduct?.inventory.allocated || 0,
          unallocated: pharmacyInventoryProduct?.inventory.unallocated || 0,
        });
      });
      tempRows.push({
        productId: product.productId,
        productName: product.productName,
        columns,
      });
    });

    return tempRows;
  }, [filteredPharmacies, filteredProducts, inventories]);

  if (!inventories) {
    return <div>Loading...</div>;
  }

  const getColumnBackground = (index: number) => (index % 2 === 0 ? 'white' : 'rgba(0, 0, 0, 0.08)');

  return (
    <div>
      <Box display="flex" gap={4}>
        <Autocomplete<Pharmacy, true>
          multiple
          options={allPharmacies}
          disableCloseOnSelect
          getOptionLabel={(option) => option.pharmacyName}
          value={filteredPharmacies}
          onChange={(_, newValue) => setPharmacyFilter(newValue.map((p) => p.pharmacyId))}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.pharmacyName}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Pharmacies" placeholder="Search Pharmacies" />
          )}
          isOptionEqualToValue={(p1, p2) => p1.pharmacyId === p2.pharmacyId}
          fullWidth
        />
        <Button onClick={() => setPharmacyFilter([])}>Clear all</Button>
        <Button onClick={() => setPharmacyFilter(allPharmacies.map((p) => p.pharmacyId))}>Add all</Button>
      </Box>

      <Box display="flex" gap={4} marginTop={5}>
        <Autocomplete<Product, true>
          multiple
          options={allProducts}
          disableCloseOnSelect
          getOptionLabel={(option) => option.productName}
          value={filteredProducts}
          onChange={(_, newValue) => setProductFilter(newValue.map((p) => p.productId))}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.productName}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Products" placeholder="Search Products" />
          )}
          isOptionEqualToValue={(p1, p2) => p1.productId === p2.productId}
          fullWidth
        />
        <Button onClick={() => setProductFilter([])}>Clear all</Button>
        <Button onClick={() => setProductFilter(allProducts.map((p) => p.productId))}>Add all</Button>
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            {filteredPharmacies.map(({ pharmacyId, pharmacyName }, index) => (
              <TableCell
                colSpan={3}
                key={`pharmacy-${pharmacyId}`}
                style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: getColumnBackground(index) }}
              >
                {pharmacyName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Product Name</TableCell>
            {filteredPharmacies.map(({ pharmacyId }, index) => {
              const style: React.CSSProperties = { textAlign: 'center', backgroundColor: getColumnBackground(index) };

              return (
                <React.Fragment key={`pharmacy-${pharmacyId}`}>
                  <TableCell style={style} key={`pharmacy-${pharmacyId}-total`}>
                    Total
                  </TableCell>
                  <TableCell style={style} key={`pharmacy-${pharmacyId}-allocated`}>
                    Allocated
                  </TableCell>
                  <TableCell style={style} key={`pharmacy-${pharmacyId}-unallocated`}>
                    Unallocated
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
          {rows.map(({ productId, productName, columns }) => (
            <TableRow key={`product-${productId}`}>
              <TableCell style={{ fontWeight: 'bold' }}>{productName}</TableCell>
              {columns.map(({ total, allocated, unallocated, pharmacyId }, index) => {
                const style: React.CSSProperties = { textAlign: 'center', backgroundColor: getColumnBackground(index) };

                return (
                  <React.Fragment key={`product-${productId}-pharmacy-${pharmacyId}`}>
                    <TableCell style={style} key={`product-${productId}-pharmacy-${pharmacyId}-total`}>
                      {total}
                    </TableCell>
                    <TableCell style={style} key={`product-${productId}-pharmacy-${pharmacyId}-allocated`}>
                      {allocated}
                    </TableCell>
                    <TableCell style={style} key={`product-${productId}-pharmacy-${pharmacyId}-unallocated`}>
                      {unallocated}
                    </TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default PharmacyInventory;
