import { Box, Typography } from '@mui/material';

import { ProductEnquiryStatus, RequestButtonFilled, TextHeading } from './common';

export default function ProductReplacementFound({
  requestActionDate,
  rescriptedProduct,
  onViewProduct,
}: {
  requestActionDate?: string | null | undefined;
  rescriptedProduct?: ProductEnquiryStatus['rescriptCreatedWithoutRequest'] | null | undefined;
  onViewProduct: () => void;
}) {
  return (
    <Box>
      <TextHeading variant="h6">Alternative prescription now available</TextHeading>
      <Typography>
        {requestActionDate ? (
          <>
            Your doctor has reviewed your request and written an alternative prescription on{' '}
            <span style={{ fontWeight: 700 }}>{requestActionDate}</span>. View it now on your refill page, click below.
          </>
        ) : (
          rescriptedProduct && (
            <>
              Your doctor has already prescribed an alternative product:{' '}
              <span style={{ fontWeight: 700 }}>{rescriptedProduct.productName}</span> in the new prescription:{' '}
              <span style={{ fontWeight: 700 }}>{rescriptedProduct.rescriptCode}</span>. View it now on your refill
              page, click below.
            </>
          )
        )}
      </Typography>
      <RequestButtonFilled data-testid="go-to-refill-page" onClick={onViewProduct}>
        Go to refill page
      </RequestButtonFilled>
    </Box>
  );
}
