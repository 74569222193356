import { createSvgIcon } from '@mui/material';

/**
 * An icon for a medical capsule
 *
 * @see https://www.figma.com/design/XwlkhpUor955wqBNBD1kEA/Alternaleaf-%2F-Design-System-%2F-MUI--v5.16.0?node-id=2688-47223&m=dev
 *
 * @returns An SvgIcon
 */
export const MgCapsule = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M8.625 21C7.05833 21 5.72917 20.4542 4.6375 19.3625C3.54583 18.2708 3 16.9417 3 15.375C3 14.625 3.14167 13.9083 3.425 13.225C3.70833 12.5417 4.11667 11.9333 4.65 11.4L11.4 4.65C11.9333 4.11667 12.5417 3.70833 13.225 3.425C13.9083 3.14167 14.625 3 15.375 3C16.9417 3 18.2708 3.54583 19.3625 4.6375C20.4542 5.72917 21 7.05833 21 8.625C21 9.375 20.8583 10.0917 20.575 10.775C20.2917 11.4583 19.8833 12.0667 19.35 12.6L12.6 19.35C12.0667 19.8833 11.4583 20.2917 10.775 20.575C10.0917 20.8583 9.375 21 8.625 21ZM15.275 13.85L17.95 11.2C18.2833 10.8667 18.5417 10.475 18.725 10.025C18.9083 9.575 19 9.10833 19 8.625C19 7.625 18.6458 6.77083 17.9375 6.0625C17.2292 5.35417 16.375 5 15.375 5C14.8917 5 14.425 5.09167 13.975 5.275C13.525 5.45833 13.1333 5.71667 12.8 6.05L10.15 8.725L15.275 13.85ZM8.625 19C9.10833 19 9.575 18.9083 10.025 18.725C10.475 18.5417 10.8667 18.2833 11.2 17.95L13.85 15.275L8.725 10.15L6.05 12.8C5.71667 13.1333 5.45833 13.525 5.275 13.975C5.09167 14.425 5 14.8917 5 15.375C5 16.375 5.35417 17.2292 6.0625 17.9375C6.77083 18.6458 7.625 19 8.625 19Z"
      fill="currentColor"
    />
  </svg>,
  'MgCapsule',
);

export default MgCapsule;
