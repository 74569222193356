import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { BrandThemeProvider } from '@montugroup/themes';
import { Box, Skeleton, skeletonClasses, styled, Typography } from '@mui/material';
import moment from 'moment';

import { BRAZE_CONTENT_CARD } from '@/assets/data/enums';
import BrainfishHelpDeskPopup from '@/components/brainfish/HelpDeskPopup';
import SinglePageContentCard from '@/components/braze/SinglePageContentCard';
import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import { OrderSummaryButton } from '@/components/order/order-summary/OrderSummaryButton';
import { OrderSummaryV2 } from '@/components/order/order-summary/OrderSummaryV2';
import { GENERATIVE, OUT_OF_STOCK, ProductEnquiryStatus } from '@/components/patient/enquiryModal/common';
import EnquiryErrorDialog from '@/components/patient/enquiryModal/EnquiryErrorDialog';
import EnquiryLoadingDialog from '@/components/patient/enquiryModal/EnquiryLoadingDialog';
import EnquiryProductDialog from '@/components/patient/enquiryModal/EnquiryProductDialog';
import RequestConfirmationDialog from '@/components/patient/enquiryModal/requstConfirmationDialog/RequestConfirmationDialog';
import { getDeviceFormulationId } from '@/components/products/ProductTypeFilter';
import { PurchaseStepper } from '@/components/products/purchaseStepper/PurchaseStepper';
import { IncrementProductRefillCard } from '@/components/products/refill/IncrementProductRefillCard';
import ProductCategoryAccordion from '@/components/products/refill/ProductCategoryAccordion';
import ProductRefillCard from '@/components/products/refill/ProductRefillCard';
import {
  UnavailableProductIssue,
  UnavailableProductRefillCard,
} from '@/components/products/refill/UnavailableProductRefillCard';
import { PrescribedProduct } from '@/hooks/patient/refill/types';
import { ConfirmationPromise, usePatientRefillController } from '@/hooks/patient/refill/usePatientRefillController';
import useCreatePatientRescriptRequest from '@/hooks/patient/useCreatePatientRescriptRequest';
import { AuthService } from '@/services/authentication.service';
import { RescriptRequestReasons } from '@/services/patient.service';
import { fetchProductEnquiryStatus } from '@/services/product.service';
import {} from '@/theme';
import { numberFormat } from '@/utils/helpers';
import { Logger } from '@/utils/logger';

import { FF_ENABLE_ORDER_PHARMACY_ALLOCATION } from '../../../constants/featureFlags';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import { getCheckoutData } from './getCheckoutData';
import {
  getProductPurchaseIssue,
  mapToProductCard,
  mapToProductRefillCardPrescription,
  mapToProductRefillCardProduct,
} from './util';

const logger = new Logger('PatientRefill');

/// ///////////////////
// CONSTANTS
/// ///////////////////
const FORMULATION_ID_DEVICE = getDeviceFormulationId(); // Devices (e.g. vapes)

/// /////////////////
// Types
/// /////////////////

interface SelectedProductData {
  quantity_original: number;
  product_name: string;
  repeats: number;
  price: number;
  notAddable: boolean | number;
  remaining_units: number;
  interval: number;
  is_out_of_stock: boolean;
  short_name: string | null;
  is_concession: boolean;
}

interface SelectedProduct {
  id: number;
  data: SelectedProductData & {
    product_id: number;
    quantity: number;
  };
}
interface ProductIssuePair {
  product: PrescribedProduct;
  issue: UnavailableProductIssue | null;
}

/// /////////////////
// Style Components
/// /////////////////

const PageContent = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  marginBottom: theme.spacing(4),
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
  "title"
  "steps"
  "products"
  "purchase"`,
  [`${theme.breakpoints.up('lg')}`]: {
    gridTemplateColumns: '2fr 1fr',
    gridTemplateAreas: `"steps steps"
   "title title"
   "products purchase"`,
  },
}));

const PageSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-of-type)': {
    borderTop: '1px solid',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderColor: theme.palette.action.active,
  },
  '&:empty': {
    margin: 0,
  },
  [`& .${skeletonClasses.root}`]: {
    marginBottom: theme.spacing(4),
    height: theme.spacing(16),
  },
}));

const DisclaimerRRP = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  margin: `0 ${theme.spacing(-4)}`,
  background: theme.palette.action.disabledBackground,
  [`${theme.breakpoints.up('lg')}`]: {
    background: 'none',
  },
}));

const DesktopOnly = styled(Box)(({ theme }) => ({
  display: 'none',
  position: 'relative',
  [`${theme.breakpoints.up('lg')}`]: {
    display: 'flex',
  },
}));

const MobileOnlySticky = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: theme.spacing(4),
  left: 0,
  padding: 0,
  display: 'flex',
  '& > *': {
    flex: 1,
  },
  [`${theme.breakpoints.up('lg')}`]: {
    display: 'none',
  },
}));

/// //////////////
// UTILITY FUNCTIONS
/// //////////////

const generateProductIssuePairs = (products: PrescribedProduct[]): ProductIssuePair[] =>
  products.map((product) => {
    const issue = getProductPurchaseIssue(product, logger);
    return {
      product,
      issue,
    };
  });

// Show PIR button for any device/medication with a valid prescription
export const shouldShowPIR = (product: PrescribedProduct) =>
  product.prescription_id !== undefined && product.prescription_id !== null;

/// //////////////
// LOADING SKELETON
/// //////////////

export function LoadingSkeleton() {
  return (
    <PageContent data-testid="loading-skeleton">
      <PageSection sx={{ gridArea: 'error' }} />
      <PageSection sx={{ gridArea: 'title' }}>
        <Skeleton variant="text" />
      </PageSection>
      <PageSection sx={{ gridArea: 'steps' }}>
        <Skeleton variant="rectangular" />
      </PageSection>
      <PageSection sx={{ gridArea: 'products' }}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </PageSection>
      <Box sx={{ gridArea: 'purchase' }}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="text" />
      </Box>
    </PageContent>
  );
}

/// ////////////
// Component
/// ////////////

function PatientRefill() {
  /** ***********
   * Hooks
   ************ */

  const { user } = AuthService.getUser()!;
  const createPatientRescriptRequest = useCreatePatientRescriptRequest();
  const { flags } = useFeatureFlags();

  /** ***********
   * Route Params
   ************* */
  const routeParams = useParams<{ id: string }>();
  const location = useLocation<{ preLoadProducts?: number[] }>();
  const orderId = Number.parseInt(routeParams.id, 10);

  const patientRefillController = usePatientRefillController(orderId, user);
  const {
    refillAPIData,
    isSubmitting,
    selectedProducts,
    hasSelectedAnyProducts,
    creditDiscounts,
    isPageLoading,
    refillApiSucceeded,
  } = patientRefillController.state;
  const { getProductQuantity, setProductQuantity, setInitialProductQuantities, submitOrder, validateOrder } =
    patientRefillController.actions;

  /** ***********
   * Component State
   ************* */

  const [selectedProductId, setSelectedProductId] = useState<number>();

  const [isCheckProductEnquiryLoading, setIsCheckProductEnquiryLoading] = useState(false);
  const [isEnquiryProductDialogOpen, setIsEnquiryProductDialogOpen] = useState(false);
  const [productEnquiryStatus, setProductEnquiryStatus] = useState<ProductEnquiryStatus | null>();
  const [isRequestConfirmationDialogOpen, setIsRequestConfirmationDialogOpen] = useState(false);
  const [hasEnquiryError, setHasEnquiryError] = useState(false);
  const [isCreateRescriptRequestLoading, setIsCreateRescriptRequestLoading] = useState<boolean>();

  // Respond to data being loaded
  useEffect(() => {
    if (typeof refillAPIData === 'undefined') {
      logger.info(`userId=${user!.id} loaded refill page`);
      return;
    }

    localStorage.setItem('moreUserData', JSON.stringify(refillAPIData));
    // This is if you click "re-order" from orders page
    const preLoadProducts = location.state?.preLoadProducts;

    // Set selected products first, so that the product rows will have the correct quantity on first non-loading render.
    setInitialProductQuantities(preLoadProducts);
  }, [refillAPIData]);

  /** *************
   * Derived State
   ************** */

  const checkoutData = getCheckoutData(selectedProducts, creditDiscounts);
  const isDischarge = Boolean(refillAPIData?.patient?.is_discharge);
  const isCheckoutDisabled = isDischarge || isSubmitting || !hasSelectedAnyProducts;
  const selectedProduct = mapToProductCard(
    (refillAPIData?.products || []).find((product) => product.id === selectedProductId),
  );

  // Divide Products into Available and Unavailable
  const productIssuePairs = generateProductIssuePairs(refillAPIData?.products || []);
  const unavailableProductsWithIssues = productIssuePairs.filter((pip) => pip.issue !== null);

  // All Products
  const availableProducts = productIssuePairs.filter((pip) => pip.issue === null).map((pip) => pip.product);

  // Divide into Devices and Medication
  const availProdDevices = availableProducts.filter((x) => x.formulation_id === FORMULATION_ID_DEVICE);
  const availProdNonDevices = availableProducts.filter((x) => x.formulation_id !== FORMULATION_ID_DEVICE);

  // Divide devices into Prescribed and Non-Prescribed
  const prescribedDevices = availProdDevices.filter((x) => Boolean(x.prescription_id));
  const nonPrescribedDevices = availProdDevices.filter((x) => !x.prescription_id);

  /** **********
   * Callbacks
   ************ */

  const handleCheckProductStatus = useCallback(
    (product: PrescribedProduct) => async () => {
      setIsCheckProductEnquiryLoading?.(true);
      setSelectedProductId?.(product.id);
      try {
        const isOutOfStock = product.is_out_of_stock || product.reasoning_toggle;
        const rescriptRequestReason = isOutOfStock ? OUT_OF_STOCK : GENERATIVE;
        const data = await fetchProductEnquiryStatus(
          refillAPIData?.patient.id,
          product.prescription_id,
          product.id,
          rescriptRequestReason,
        );
        setProductEnquiryStatus?.({
          ...data,
          rescriptActionDate: data.rescriptActionDate ? moment(data.rescriptActionDate).format('DD/MM/YYYY') : null,
          rescriptRequestDate: data.rescriptRequestDate ? moment(data.rescriptRequestDate).format('DD/MM/YYYY') : null,
        });
        setIsCheckProductEnquiryLoading?.(false);
        setIsEnquiryProductDialogOpen?.(true);
      } catch {
        setIsCheckProductEnquiryLoading?.(false);
        setHasEnquiryError?.(true);
      }
    },
    [
      refillAPIData,
      setIsCheckProductEnquiryLoading,
      setSelectedProductId,
      setIsEnquiryProductDialogOpen,
      setHasEnquiryError,
    ],
  );

  // Ordering process is currently Find Existing Order => Validate Order => Place Order
  const handleSubmit = async () => {
    try {
      const confirmNotAddableCallback: ConfirmationPromise<SelectedProduct[]> = async (notAddableProducts) =>
        new Promise((resolve) => {
          toast(
            <ToastConfirmModal onConfirm={() => resolve(true)} onCancel={() => resolve(false)}>
              <div className="mx-auto">
                <p>
                  Your refill order contains a medication that was shipped out to you{' '}
                  {notAddableProducts[0].data.notAddable} day(s) ago. Please note that medication can not be dispensed
                  until the designated interval time has elapsed.
                </p>
                {notAddableProducts.map((x) => (
                  <p key={x.id}>- {x.data.product_name}</p>
                ))}
                Would you like to proceed in placing this order?
              </div>
            </ToastConfirmModal>,
            toastOptions,
          );
        });

      await submitOrder({
        confirmNotAddableCallback,
        validateOrderCallback: validateOrder,
      });
    } catch (error: any) {
      toast.error(error.message, { toastId: 'submit-order-error' });
    }
  };

  // Determines the order summary data
  const handleCheckout = async () => {
    await handleSubmit();
  };

  const handleRequestAlternativeProduct = () => {
    setIsEnquiryProductDialogOpen(false);
    setIsRequestConfirmationDialogOpen(true);
  };

  const handleOnSendRequest = async () => {
    setIsCreateRescriptRequestLoading(true);

    try {
      // Todo: Can we assume that data.patient is always populated at this point?
      const newPatientRescriptRequest = await createPatientRescriptRequest(refillAPIData!.patient!.id, {
        prescriptionId: selectedProduct!.prescriptionId!,
        productId: selectedProduct!.id,
        rescriptRequestReason: selectedProduct!.isGenerativeScripting
          ? RescriptRequestReasons.Discontinued
          : RescriptRequestReasons.OutOfStock,
      });

      setIsCreateRescriptRequestLoading(false);
      setIsRequestConfirmationDialogOpen(false);
      setIsEnquiryProductDialogOpen(true);
      setProductEnquiryStatus({
        ...(productEnquiryStatus || {}),
        rescriptRequestDate: moment(newPatientRescriptRequest.created_date).format('DD/MM/YYYY'),
      });
    } catch {
      setIsCreateRescriptRequestLoading(false);
      setHasEnquiryError(true);
    }
  };

  /** **********
   * Render Logic
   ************ */

  return (
    <BrandThemeProvider variant="alternaleaf">
      <ManagedErrorToasts />
      <SinglePageContentCard displayType={BRAZE_CONTENT_CARD.PATIENT_REFILL} />
      {isPageLoading && <LoadingSkeleton />}
      {!isPageLoading && (
        <PageContent>
          {refillApiSucceeded && (
            <>
              <PageSection sx={{ gridArea: 'title' }}>
                <Typography variant="h5" component="h1">
                  Order medication
                </Typography>
              </PageSection>
              <PageSection sx={{ gridArea: 'steps' }}>
                <PurchaseStepper stage="Order" />
              </PageSection>
              <PageSection sx={{ gridArea: 'products' }}>
                {availProdNonDevices.map((product) => (
                  <ProductRefillCard
                    key={product.id}
                    product={mapToProductRefillCardProduct(product)}
                    prescription={mapToProductRefillCardPrescription(product)}
                    quantity={getProductQuantity(product.id)}
                    setQuantity={setProductQuantity(product.id)}
                  />
                ))}
                <ProductCategoryAccordion
                  label="Prescribed devices"
                  count={0}
                  startCollapsed={prescribedDevices.length === 0}
                >
                  {prescribedDevices.map((product) => (
                    <IncrementProductRefillCard
                      key={product.id}
                      product={mapToProductRefillCardProduct(product)}
                      prescription={mapToProductRefillCardPrescription(product)}
                      quantity={getProductQuantity(product.id)}
                      setQuantity={setProductQuantity(product.id)}
                    />
                  ))}
                </ProductCategoryAccordion>
                <ProductCategoryAccordion label="Other Products" count={0} startCollapsed>
                  {nonPrescribedDevices.map((product) => (
                    <IncrementProductRefillCard
                      key={product.id}
                      product={mapToProductRefillCardProduct(product)}
                      prescription={mapToProductRefillCardPrescription(product)}
                      quantity={getProductQuantity(product.id)}
                      setQuantity={setProductQuantity(product.id)}
                    />
                  ))}
                </ProductCategoryAccordion>
                <ProductCategoryAccordion
                  label="Can't order now"
                  count={unavailableProductsWithIssues.length}
                  startCollapsed
                >
                  {unavailableProductsWithIssues.map((pip) => (
                    <UnavailableProductRefillCard
                      key={pip.product.id}
                      product={pip.product}
                      issue={pip.issue!}
                      handleCheckProductStatus={handleCheckProductStatus(pip.product)}
                      showRequestReplacementOption={shouldShowPIR(pip.product)}
                    />
                  ))}
                </ProductCategoryAccordion>
              </PageSection>
              <Box sx={{ gridArea: 'purchase' }}>
                <DesktopOnly>
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <Box sx={{ position: 'sticky', top: '8rem' }}>
                      <OrderSummaryV2
                        checkoutData={checkoutData}
                        handleCheckout={handleCheckout}
                        disabled={isCheckoutDisabled}
                      />
                    </Box>
                  </Box>
                </DesktopOnly>
                {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] && (
                  <DisclaimerRRP>
                    <Typography variant="body2">
                      <strong>Disclaimer:&nbsp;</strong>
                      Prices shown here are the RRP, and your pharmacy may choose to charge less.
                    </Typography>
                  </DisclaimerRRP>
                )}
              </Box>
              {hasSelectedAnyProducts && (
                <MobileOnlySticky>
                  <OrderSummaryButton
                    totalNumberOfProducts={checkoutData.totalNumberOfProducts}
                    onClick={handleCheckout}
                    amount={numberFormat(checkoutData.totalPrice)}
                    disabled={isCheckoutDisabled}
                  />
                </MobileOnlySticky>
              )}
            </>
          )}
        </PageContent>
      )}
      <EnquiryLoadingDialog
        open={isCheckProductEnquiryLoading}
        onClose={() => setIsCheckProductEnquiryLoading(false)}
      />
      <EnquiryErrorDialog open={hasEnquiryError} onClose={() => setHasEnquiryError(false)} />
      {selectedProductId && productEnquiryStatus && (
        <EnquiryProductDialog
          open={isEnquiryProductDialogOpen}
          onClose={() => {
            setIsEnquiryProductDialogOpen(false);
          }}
          product={selectedProduct!}
          productEnquiryStatus={productEnquiryStatus}
          handleRequestAlternativeProduct={handleRequestAlternativeProduct}
          handleBookingSuccess={() =>
            setProductEnquiryStatus({ ...productEnquiryStatus, hasScheduledConsultation: true })
          }
        />
      )}
      <RequestConfirmationDialog
        open={isRequestConfirmationDialogOpen}
        onClose={() => setIsRequestConfirmationDialogOpen(false)}
        onSendRequest={handleOnSendRequest}
        loading={isCreateRescriptRequestLoading}
      />
      <BrainfishHelpDeskPopup />
    </BrandThemeProvider>
  );
}

export default PatientRefill;
