import { Paper, PaperProps } from '@mui/material';

export default function RoundedPaper(props: PaperProps) {
  return (
    <Paper
      {...props}
      elevation={0}
      variant="outlined"
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    />
  );
}
