import { Link as RouterLink } from 'react-router-dom';
import { Receipt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { teal } from '@/components/products/detail/common';

// todo: use montu css var theme provider once available
export function CTASection() {
  return (
    <Box marginBottom={6}>
      <Button
        component={RouterLink}
        to="/patient/refill"
        size="large"
        variant="contained"
        startIcon={<Receipt />}
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
          backgroundColor: teal,
          py: 4,
          px: 10,
          width: { xs: '100%', md: 'auto' },
          '&:hover': {
            backgroundColor: '#102d39',
            borderColor: '#0e252f',
          },
        }}
      >
        Create order
      </Button>
    </Box>
  );
}

export default CTASection;
